import React, { FC, useEffect, useState, useMemo } from 'react';
import styled from './EditListContent.module.css';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ContextMenu from '../../../../components/ContextMenu/ContextMenu';
import CompanyService from '../../../../services/CompanyService';
import useAuth from '../../../../hooks/useAuth';
import LawListModel, { isLawList, LawListSettingsModel } from '../../../../models/LawListModel';
import LawTypeFilter, { convertToLawTypeFilter } from '../../../../models/LawTypeFilter';
import _ from 'lodash';
import { LawType } from '../../../../models/LawType';
import SubscriptionModel, { isNewUnaccepted, isSubscription } from '../../../../models/SubscriptionModel';
import CustomLawModel, { isCustomLaw } from '../../../../models/CustomLawModel';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Filterbar from '../../../../components/Filterbar/Filterbar';
import RevisionTopbar from '../../../../components/RevisionTopbar/RevisionTopbar';
import { SETTINGS_COMPANY_LAWLISTS, SETTINGS_COMPANY_KEYWORDS } from '../../../../constants/Routes';
import lawlistIcon from '../../../../assets/images/lawlistIcon.svg';
import KeywordModel, { KeywordSettingsModel } from '../../../../models/KeywordModel';
import Table from '../../../../components/Table/Table';
import { Column, SortingRule } from 'react-table';
import LawCell from '../../../../components/Table/LawCell/LawCell';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../../../../models/LawListGroupFilter';
import KeywordCell from '../../../../components/Table/KeywordCell/KeywordCell';
import EditListContentFullView from '../../../../components/FullView/EditListContentFullView/EditListContentFullView';
import EditListContentToaster from '../../../../components/EditListContentToaster/EditListContentToaster';
import EditListContentModel from '../../../../models/ConnectedLawsModel';
import { getUserSettingsSelectedLawGroupsFallback } from '../../../../models/UserSettings';
import { useTranslation } from 'react-i18next';

const EditListContent: FC = ({}) => {
    const { t, i18n } = useTranslation();

    const CONTEXT_LINKS: {
        title: string;
        icon: string;
        active: boolean;
    }[] = [{ title: t('contextMenuLawLists'), icon: lawlistIcon, active: true }];

    const history = useHistory();
    const { id } = useParams();
    const { company, getUserSettings } = useAuth();
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [fullListObject, setFullListObject] = useState<LawListModel | KeywordModel | undefined>(undefined);
    const [lawTypeFilter, setLawTypeFilter] = useState<LawTypeFilter[]>([]);
    const [lawListGroupFilter, setLawListGroupFilter] = useState<LawListGroupFilter[]>([]);
    const [data, setData] = useState<(SubscriptionModel | CustomLawModel)[]>([]);
    const [filteredData, setFilteredData] = useState<(SubscriptionModel | CustomLawModel)[]>([]);
    const [hasCustomLaws, setHasCustomLaws] = useState(false);
    const [customLawsSelected, setCustomLawsSelected] = useState(true);
    const [visibleDataCount, setVisibleDataCount] = useState(0);
    const [globalSearch, setGlobalSearch] = useState('');
    const [selectedKeywords, setSelectedKeywords] = useState<(KeywordModel & { checked: boolean })[]>([]);
    const [showColumnSearch, setShowColumnSearch] = useState(false);
    const [columnSearch, setColumnSearch] = useState<
        {
            id: string;
            value: string | undefined;
        }[]
    >([]);
    const [customFilterActive, setCustomFilterActive] = useState(false);
    const [sorting, setSorting] = useState<SortingRule<object>[]>([]);
    const [columns, setColumns] = useState(() => {
        const columns: Array<Column<SubscriptionModel | CustomLawModel> & {
            id: string;
            visible: boolean;
            alwaysVisible?: boolean;
            Header: string;
            languageId?: string;
        }> = [
            {
                id: 'law',
                Header: t('columnLaw'),
                languageId: 'columnLaw',
                accessor: row => `${row.name} ${isSubscription(row) ? row.subId : row.customLawEndDate}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.law;
                    const lawB = rowB.values.law;
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
                visible: true,
                width: 2,
                alwaysVisible: true,
                Cell: LawCell,
            },
            {
                id: 'description',
                Header: t('columnDescription'),
                languageId: 'columnDescription',
                accessor: 'description',
                disableSortBy: true,
                visible: true,
            },
            {
                id: 'text',
                Header: t('columnText'),
                languageId: 'columnText',
                accessor: 'text',
                disableSortBy: true,
                visible: true,
            },
        ];

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                columns.push({
                    id: 'customerText' + i,
                    accessor: 'customerText' + i,
                    visible: true,
                    Header: title,
                    disableSortBy: true,
                });
            }
        }

        if (company?.hasLawLists) {
            columns.push({
                id: 'lawLists',
                visible: true,
                accessor: row => (row.lawLists ? row.lawLists.map(lawList => (lawList.lawListGroup ? lawList.lawListGroup.name + ': ' : '') + lawList.name) : ''),
                Cell: KeywordCell,
                Header: t('columnLawLists'),
                languageId: 'columnLawLists',
                disableSortBy: true,
            });
        }

        if (company?.hasKeyWords) {
            columns.push({
                id: 'keywords',
                visible: true,
                accessor: row => row.keywordIds.map((id: number) => _.find(company.keyWords, kw => kw.id === id)?.text),
                Cell: KeywordCell,
                Header: t('columnKeywords'),
                languageId: 'columnKeywords',
                disableSortBy: true,
            });
        }

        return columns;
    });
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [originalSelectedRows, setOriginalSelectedRows] = useState<Record<string, boolean>>({});
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

    const fetchData = async (fetchLawList: boolean) => {
        if (!id || !company) {
            history.goBack();
        } else {
            try {
                setLoading(true);

                let dataObject: LawListSettingsModel | KeywordSettingsModel;
                if (fetchLawList) {
                    dataObject = await CompanyService().getLawListSettings(company.id, +id);
                    setFullListObject(dataObject.lawList);
                } else {
                    dataObject = await CompanyService().getKeywordSettings(company.id, +id);
                    setFullListObject(dataObject.keyword);
                }

                let userSettings = getUserSettings();
                if (!userSettings?.id) {
                    userSettings = undefined;
                }

                const lawTypeFilter: LawTypeFilter[] = convertToLawTypeFilter(dataObject.lawTypes, dataObject.customLaws.length > 0, userSettings);
                const lawListGroupFilter: LawListGroupFilter[] = convertToLawListGroupFilter(company.lawLists, userSettings?.selectedLawLists);

                const keywords = company.keyWords.map(kw => ({
                    ...kw,
                    checked: userSettings ? userSettings.selectedKeywords.includes(kw.id) : false,
                }));

                const combinedData = [...dataObject.subscriptions, ...dataObject.customLaws];

                const initialSelectedRows: Record<string, boolean> = {};
                combinedData.forEach(obj => {
                    if (isSubscription(obj)) {
                        initialSelectedRows[obj.subscriptionId] = dataObject.includedSubscriptionIds.indexOf(obj.subscriptionId) > -1;
                    } else if (!isSubscription(obj)) {
                        initialSelectedRows[obj.customLawId] = dataObject.includedCustomLawIds.indexOf(obj.customLawId) > -1;
                    }
                });

                setOriginalSelectedRows(initialSelectedRows);
                setSelectedRows(initialSelectedRows);
                setLawListGroupFilter(lawListGroupFilter);
                setLawTypeFilter(lawTypeFilter);
                setData(combinedData);
                setHasCustomLaws(dataObject.customLaws.length > 0);
                setSelectedKeywords(keywords);
                setCustomLawsSelected(userSettings ? userSettings.customLawsSelected : true);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleToggleLawTypeOpen = (type: LawType) => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        );
    };

    const handleToggleLawTypeChecked = (type: LawType) => {
        if (type === 'CUSTOM_LAW') {
            setCustomLawsSelected(!customLawsSelected);
        } else {
            setLawTypeFilter(s =>
                s.map(lawType => {
                    if (lawType.name === type) {
                        return {
                            ...lawType,
                            lawGroups: lawType.lawGroups.map(lawGroup => ({
                                ...lawGroup,
                                checked: !lawType.lawGroups.every(l => l.checked),
                            })),
                        };
                    }
                    return lawType;
                }),
            );
        }
        setCustomFilterActive(false);
    };

    const handleToggleLawGroupChecked = (lawGroupId: number) => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
        );
        setCustomFilterActive(false);
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
        );
        setCustomLawsSelected(!allChecked);
        setCustomFilterActive(false);
    };

    const handleSelectedKeywordChange = (keywordId: number) => {
        setSelectedKeywords(s =>
            s.map(keyword => ({
                ...keyword,
                checked: keyword.id === keywordId ? !keyword.checked : keyword.checked,
            })),
        );
        setCustomFilterActive(false);
    };

    const handleToggleColumnSearch = () => {
        if (showColumnSearch) {
            setColumnSearch(s => s.map(c => ({ ...c, value: undefined })));
        }
        setShowColumnSearch(!showColumnSearch);
    };

    const handleResetLawTypeFilter = () => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
        );
        setCustomLawsSelected(true);
        setCustomFilterActive(false);
    };

    const handleResetAllFilters = () => {
        handleResetLawTypeFilter();
        setGlobalSearch('');
        setSelectedKeywords(s => s.map(kw => ({ ...kw, checked: false })));
        setColumnSearch(s => s.map(c => ({ ...c, value: undefined })));
        setLawListGroupFilter(s =>
            s.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: false,
                })),
            })),
        );
        setCustomLawsSelected(true);
        setShowColumnSearch(false);
        setCustomFilterActive(false);
    };

    const handleLawListFilterChange = (lawListId: number) => {
        setLawListGroupFilter(s =>
            s.map(llg => ({
                ...llg,
                lawLists: llg.lawLists.map(ll => ({
                    ...ll,
                    checked: ll.lawListId === lawListId ? !ll.checked : ll.checked,
                })),
            })),
        );
        setCustomFilterActive(false);
    };

    const handleLawListGroupFilterChange = (lawListGroupId: number) => {
        setLawListGroupFilter(s =>
            s.map(llg => {
                if (llg.lawListGroupId === lawListGroupId) {
                    return {
                        ...llg,
                        lawLists: llg.lawLists.map(ll => ({
                            ...ll,
                            checked: !llg.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return llg;
            }),
        );
        setCustomFilterActive(false);
    };

    const handleSelectRowChange = (id: string) => {
        setSelectedRows(s => ({
            ...s,
            [id]: !s[id],
        }));
    };

    const handleSelectRowsChange = (filteredRowIds: string[]) => {
        setSelectedRows(sr => {
            const anyChecked = _.some(filteredRowIds, id => sr[id] === true);
            return _.mapValues(sr, (value, id) => {
                if (_.includes(filteredRowIds, id)) {
                    const obj = data.find(d => isSubscription(d) && d.subscriptionId === +id);
                    if (obj !== undefined && isSubscription(obj) && isNewUnaccepted(obj)) {
                        return value;
                    }
                    return !anyChecked;
                }
                return value;
            });
        });
    };

    const handleToggleColumnVisible = (id: string) => {
        setColumns(s => s.map(col => ({ ...col, visible: col.id === id ? !col.visible : col.visible })));
    };

    const handleToggleCustomFilter = () => {
        const userSettings = getUserSettings();
        if (!userSettings?.id) {
            return;
        }

        if (customFilterActive) {
            setCustomFilterActive(false);
            setColumns(s => s.map(col => ({ ...col, visible: true })));
            setSelectedKeywords(s => s.map(kw => ({ ...kw, checked: false })));
            setLawTypeFilter(s =>
                s.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: true,
                    })),
                })),
            );
            setLawListGroupFilter(s =>
                s.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        chcked: false,
                    })),
                })),
            );
            setCustomLawsSelected(true);
        } else {
            setCustomFilterActive(true);
            setColumns(s =>
                s.map(col => ({
                    ...col,
                    visible: col.alwaysVisible
                        ? true
                        : (userSettings.bffColumnVisible && col.id === 'text') ||
                          (userSettings.descColumnVisible && col.id === 'description') ||
                          (userSettings.column1Visible && col.id === 'customerText1') ||
                          (userSettings.column2Visible && col.id === 'customerText2') ||
                          (userSettings.column3Visible && col.id === 'customerText3') ||
                          (userSettings.column4Visible && col.id === 'customerText4') ||
                          (userSettings.column5Visible && col.id === 'customerText5') ||
                          (userSettings.keywordColumnVisible && col.id === 'keywords') ||
                          (userSettings.lawListColumnVisible && col.id === 'lawLists'),
                })),
            );
            setSelectedKeywords(s =>
                s.map(kw => ({
                    ...kw,
                    checked: userSettings.selectedKeywords.includes(kw.id),
                })),
            );
            setLawTypeFilter(s =>
                s.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: getUserSettingsSelectedLawGroupsFallback(userSettings.selectedLawGroups, data).includes(lawGroup.lawGroupId),
                    })),
                })),
            );
            setCustomLawsSelected(userSettings.customLawsSelected);
            setLawListGroupFilter(s =>
                s.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: userSettings.selectedLawLists.includes(lawList.lawListId),
                    })),
                })),
            );
        }
    };

    const handleCancelEdit = () => {
        if (history.location.pathname.indexOf(SETTINGS_COMPANY_LAWLISTS) > -1) {
            history.push({
                pathname: SETTINGS_COMPANY_LAWLISTS,
                state: state,
            });
        } else if (history.location.pathname.indexOf(SETTINGS_COMPANY_KEYWORDS) > -1) {
            history.push(SETTINGS_COMPANY_KEYWORDS);
        }
    };

    const handleSave = async () => {
        if (company && fullListObject) {
            try {
                const subscriptionIdsToAdd: number[] = data
                    .filter(obj => isSubscription(obj) && selectedRows[obj.subscriptionId] === true && originalSelectedRows[obj.subscriptionId] === false)
                    .map(obj => _.get(obj, 'subscriptionId'));
                const subscriptionIdsToRemove: number[] = data
                    .filter(obj => isSubscription(obj) && selectedRows[obj.subscriptionId] === false && originalSelectedRows[obj.subscriptionId] === true)
                    .map(obj => _.get(obj, 'subscriptionId'));

                const customLawIdsToAdd = data
                    .filter(obj => isCustomLaw(obj) && selectedRows[obj.customLawId] === true && originalSelectedRows[obj.customLawId] === false)
                    .map(obj => _.get(obj, 'customLawId'));
                const customLawIdsToRemove = data
                    .filter(obj => isCustomLaw(obj) && selectedRows[obj.customLawId] === false && originalSelectedRows[obj.customLawId] === true)
                    .map(obj => _.get(obj, 'customLawId'));

                if (isLawList(fullListObject)) {
                    const editLawListContent: EditListContentModel = {
                        companyId: company.id,
                        id: fullListObject.lawListId,
                        subscriptionIdsToAdd,
                        subscriptionIdsToRemove,
                        customLawIdsToAdd,
                        customLawIdsToRemove,
                    };

                    await CompanyService().saveLawListConnections(editLawListContent);
                    history.push({
                        pathname: SETTINGS_COMPANY_LAWLISTS,
                        state: state,
                    });
                } else {
                    const editKeywordContent: EditListContentModel = {
                        companyId: company.id,
                        id: fullListObject.id,
                        subscriptionIdsToAdd,
                        subscriptionIdsToRemove,
                        customLawIdsToAdd,
                        customLawIdsToRemove,
                    };
                    await CompanyService().saveKeywordConnections(editKeywordContent);
                    history.push(SETTINGS_COMPANY_KEYWORDS);
                }
            } catch (error) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (history.location.pathname.indexOf(SETTINGS_COMPANY_LAWLISTS) > -1) {
            fetchData(true);
        } else if (history.location.pathname.indexOf(SETTINGS_COMPANY_KEYWORDS) > -1) {
            fetchData(false);
        }
    }, []);

    useEffect(() => {
        setColumns(s =>
            s.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        );
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        // Apply lawGroup filtering
        const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
        let filteredData = data.filter(obj => {
            if (isSubscription(obj)) {
                return selectedLawGroupIds.includes(obj.lawGroupId);
            } else {
                return customLawsSelected;
            }
        });

        // Apply lawList filtering
        const selectedLawListIds = lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId));
        if (selectedLawListIds.length) {
            filteredData = filteredData.filter(f => selectedLawListIds.some(id => f.lawLists.find(lawList => lawList.lawListId === id)));
        }

        // Apply keyword filtering
        const selectedKeywordIds = selectedKeywords.filter(kw => kw.checked).map(kw => kw.id);
        if (selectedKeywordIds.length) {
            filteredData = filteredData.filter(f => selectedKeywordIds.some(id => f.keywordIds.includes(id)));
        }

        if (filteredData[selectedIndex] !== data[selectedIndex]) {
            setSelectedIndex(-1);
        }
        setFilteredData(filteredData);
    }, [data, lawTypeFilter, lawListGroupFilter, selectedKeywords, customLawsSelected]);

    const addedCount = useMemo(() => {
        const addedCount = _.filter(originalSelectedRows, (row, id) => row === false && selectedRows[id] === true).length;
        return addedCount;
    }, [selectedRows, originalSelectedRows]);

    const removedCount = useMemo(() => {
        const removedCount = _.filter(originalSelectedRows, (row, id) => row === true && selectedRows[id] === false).length;
        return removedCount;
    }, [selectedRows, originalSelectedRows]);

    const hiddenColumns = useMemo(() => columns.filter(col => !col.visible).map(col => col.id), [columns]);

    return (
        <div className={styled.EditListContent}>
            {loading && <LoadingSpinner />}
            <RevisionTopbar
                title={
                    fullListObject
                        ? isLawList(fullListObject)
                            ? (fullListObject.lawListGroup ? fullListObject.lawListGroup.name + ' > ' : '') + fullListObject.name
                            : fullListObject.text
                        : ''
                }
                backUrl={fullListObject && isLawList(fullListObject) ? SETTINGS_COMPANY_LAWLISTS : SETTINGS_COMPANY_KEYWORDS || ''}
            />
            <div className={styled.Content}>
                <ContextMenu
                    lawTypeFilter={lawTypeFilter}
                    hasCustomLaws={hasCustomLaws}
                    customLawsSelected={customLawsSelected}
                    onToggleLawTypeOpen={handleToggleLawTypeOpen}
                    onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                    onToggleLawTypeChecked={handleToggleLawTypeChecked}
                    onToggleLawGroupChecked={handleToggleLawGroupChecked}
                    visibleDataCount={visibleDataCount}
                    totalDataCount={data.length}
                    onResetLawTypeFilter={handleResetLawTypeFilter}
                    onResetAllFilters={handleResetAllFilters}
                    links={CONTEXT_LINKS}
                />
                <div className={styled.List}>
                    <Filterbar
                        globalSearch={globalSearch}
                        onGlobalSearchChange={setGlobalSearch}
                        selectedKeywords={selectedKeywords}
                        onSelectedKeywordChange={handleSelectedKeywordChange}
                        lawListGroupFilter={lawListGroupFilter}
                        onLawListGroupFilterChange={handleLawListGroupFilterChange}
                        onLawListFilterChange={handleLawListFilterChange}
                        showColumnSearch={showColumnSearch}
                        onColumnSearchToggle={handleToggleColumnSearch}
                        columns={columns.filter(col => !col.alwaysVisible)}
                        onColumnShowChange={handleToggleColumnVisible}
                        customFilterActive={customFilterActive}
                        onCustomFilterToggle={handleToggleCustomFilter}
                    />

                    <div className={styled.TableWrapper}>
                        <Table
                            columns={columns}
                            data={filteredData}
                            showColumnSearch={showColumnSearch}
                            globalSearch={globalSearch}
                            columnSearch={columnSearch}
                            selected={selectedIndex}
                            onVisibleDataCountChange={setVisibleDataCount}
                            onSelect={setSelectedIndex}
                            selectedRows={selectedRows}
                            onSelectedRowChange={handleSelectRowChange}
                            onSelectedRowsChange={handleSelectRowsChange}
                            hiddenColumns={hiddenColumns}
                            sorting={sorting}
                            onSortingChange={setSorting}
                            hideDisabledCheckboxes
                            multiSelect
                        />

                        <EditListContentFullView open={selectedIndex > -1} data={filteredData[selectedIndex]} onClose={() => setSelectedIndex(-1)} />

                        <EditListContentToaster
                            fullViewOpen={selectedIndex > -1}
                            addedCount={addedCount}
                            removedCount={removedCount}
                            title={fullListObject ? (isLawList(fullListObject) ? fullListObject.name : fullListObject.text) : ''}
                            isLawList={(fullListObject && isLawList(fullListObject)) || false}
                            onCancel={handleCancelEdit}
                            onSave={handleSave}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditListContent;
