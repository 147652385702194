import React, { FC } from 'react';
import styled from './RemoveRevisionQuestionModal.module.css';
import Modal from '../Modal';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    onClose: () => void;
    onConfirm: () => void;
}

const RemoveRevisionQuestionModal: FC<Props> = ({ onClose, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('buttonRemove')} subtitle={t('removeRevisionQuestionModalSubTitle')} className={styled.RemoveRevisionQuestionModal}>
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onClose}>
                    {t('buttonNo')}
                </Button>
                <Button variant="Outline" onClick={onConfirm}>
                    {t('buttonYes')}
                </Button>
            </div>
        </Modal>
    );
};

export default RemoveRevisionQuestionModal;
