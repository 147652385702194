import LawTypeFilter, { getActiveLawTypeFilters } from './LawTypeFilter';
import LawListGroupFilter, { getActiveLawListGroupFilter } from './LawListGroupFilter';
import { State as LawListState } from '../contexts/LawListContext';
import { State as ChangeListState } from '../contexts/ChangeListContext';
import { State as ChangeArchiveState } from '../contexts/ChangeArchiveContext';
import CustomLawModel from './CustomLawModel';
import { columnKeys } from '../constants/ExcelColumnWidth';

interface ActiveFilterExport {
    originalLawCount: number;
    filteredLawCount: number;
    lawTypeFilter?: LawTypeFilter[];
    lawListGroupFilter?: LawListGroupFilter[];
    customLawsSelected?: boolean;
    selectedKeywords?: string[];
    globalSearch: string;
    columnSearch: {
        header: string;
        value: string | undefined;
    }[];
}

export const getActiveFilterExport = (
    data: CustomLawModel[],
    columns: { key: columnKeys; Header: string }[],
    columnSearch: { id: string; value: string | undefined }[],
    globalSearch: string,
    exportDataLength: number,
): ActiveFilterExport => {
    const activeFilterExport: ActiveFilterExport = {
        globalSearch: globalSearch,
        originalLawCount: data.length,
        filteredLawCount: exportDataLength,
        columnSearch: [],
    };

    columnSearch.forEach(cs => {
        columns.forEach((cc: { key: columnKeys; Header: any }) => {
            if (cc.key === cs.id) {
                activeFilterExport.columnSearch.push({ header: cc.Header, value: cs.value });
            }
        });
    });

    return activeFilterExport;
};

export const getActiveFilterExportFromState = (state: LawListState | ChangeListState | ChangeArchiveState, exportDataLength: number): ActiveFilterExport => {
    // INIT always existing variables
    const activeFilterExport: ActiveFilterExport = {
        globalSearch: state.globalSearch,
        originalLawCount: state.data.length,
        filteredLawCount: exportDataLength,
        columnSearch: [],
    };

    if ('lawTypeFilter' in state) {
        activeFilterExport.lawTypeFilter = getActiveLawTypeFilters(state.lawTypeFilter);
    }

    if ('columnSearch' in state) {
        state.columnSearch.forEach(cs => {
            let isCustom = false;
            state.customColumns.forEach((cc: { id: string; Header: any }) => {
                if (cc.id === cs.id) {
                    activeFilterExport.columnSearch.push({ header: cc.Header, value: cs.value });
                    isCustom = true;
                }
            });
            if (!isCustom) {
                state.columns.forEach((cc: { id: string; Header: any }) => {
                    if (cc.id === cs.id) {
                        activeFilterExport.columnSearch.push({ header: cc.Header, value: cs.value });
                    }
                });
            }
        });
    }

    if ('selectedKeywords' in state) {
        const activeSelectedKeywords: string[] = [];
        state.selectedKeywords
            .filter(kw => kw.checked)
            .map(kw => {
                activeSelectedKeywords.push(kw.text);
            });
        activeFilterExport.selectedKeywords = activeSelectedKeywords;
    }

    if ('lawListGroupFilter' in state) {
        activeFilterExport.lawListGroupFilter = getActiveLawListGroupFilter(state.lawListGroupFilter);
    }

    if ('customLawsSelected' in state) {
        activeFilterExport.customLawsSelected = state.customLawsSelected;
    }

    return activeFilterExport;
};

export default ActiveFilterExport;
