import React, { FC, useMemo } from 'react';
import styled from './FullViewFooter.module.css';
import SubscriptionModel from '../../../models/SubscriptionModel';
import Button from '../../Button/Button';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import SendExternalMail from '../../SendExternalMail/SendExternalMail';

interface Props {
    data: SubscriptionModel;
    disabled?: boolean;
    onDelegateChange: () => void;
    onAcknowledgeChange: () => void;
    onAcknowledgeNewChange: () => void;
    onForceDelegation: () => void;
}

const FullViewFooter: FC<Props> = ({ data, disabled, onDelegateChange, onAcknowledgeChange, onAcknowledgeNewChange }) => {
    const { t, i18n } = useTranslation();
    const { isAssessor, isAboveUser, isDelegateAssessor } = useAuth();

    const variantStyle = useMemo(() => {
        if (!data.acceptanceStatus) {
            return '';
        }
        if (data.acceptanceStatus.includes('NEW')) {
            return 'NEW';
        } else if (data.acceptanceStatus.includes('OTHER')) {
            return 'DELEGATED';
        } else if (data.acceptanceStatus.includes('ME') || data.acceptanceStatus === 'NOT_ACCEPTED') {
            return 'ME';
        }
        return '';
    }, [data.acceptanceStatus]);

    const title = useMemo(() => {
        let title = '';
        if (data.acceptanceStatus === 'DELEGATED_OTHER') {
            title = t('changeListFooterTitleDelegated');
        } else if (data.acceptanceStatus === 'DELEGATED_ME' || data.acceptanceStatus === 'NOT_ACCEPTED') {
            title = t('changeListFooterTitleAcceptable');
        } else if (data.acceptanceStatus === 'NEW_NOT_ACCEPTED' || data.acceptanceStatus === 'NEW_DELEGATED_ME' || data.acceptanceStatus === 'NEW_DELEGATED_OTHER') {
            title = t('changeListFooterTitleNew');
        }
        return title;
    }, [data.acceptanceStatus, i18n.language]);

    const text = useMemo(() => {
        if ((data.acceptanceStatus === 'NEW_NOT_ACCEPTED' && !isAssessor()) || (data.acceptanceStatus.includes('DELEGATED_ME') && !isDelegateAssessor())) {
            return t('changeListFooterTextInsufficientRights');
        }

        let text = '';
        if (data.acceptanceStatus === 'DELEGATED_OTHER' || data.acceptanceStatus === 'NEW_DELEGATED_OTHER') {
            const fullNames = data.waitingForAcceptance.map(u => u.fullName);
            text = t('changeListFooterTextDelegated', { delegates: fullNames?.join(', ') });
        } else if (data.userCanAccept) {
            if (data.newLaw) {
                text = t('changeListFooterTextNew');
            } else {
                text = t('changeListFooterTextAcceptable');
            }
        }
        return text;
    }, [data.acceptanceStatus, data.waitingForAcceptance, i18n.language]);

    const buttons = useMemo(() => {
        const buttons = [];

        if (data.acceptanceStatus.includes('DELEGATED_ME') && !isDelegateAssessor()) {
            return null;
        }

        if (data.userCanAccept) {
            if (data.newLaw) {
                buttons.push(
                    <Button variant="Outline" onClick={onAcknowledgeNewChange} disabled={disabled} key={data.subscriptionId}>
                        {t('changeListFooterButtonAcceptNew')}
                    </Button>,
                );
            } else {
                buttons.push(
                    <Button variant="Outline" onClick={onAcknowledgeChange} disabled={disabled} key={data.subscriptionId}>
                        {t('changeListFooterButtonAcceptChange')}
                    </Button>,
                );
            }
        }

        if (isAboveUser() && (data.acceptanceStatus.includes('DELEGATED_ME') || data.acceptanceStatus.includes('DELEGATED_OTHER'))) {
            buttons.push(
                <Button key={data?.subscriptionId} variant="Outline" onClick={onDelegateChange} disabled={disabled}>
                    {t('changeListFooterButtonChangeDelegates')}
                </Button>,
            );
        }

        if (isAssessor() && data) {
            buttons.push(<SendExternalMail key={data.subscriptionId + data.lawId} data={data}></SendExternalMail>);
        }

        return buttons;
    }, [data.acceptanceStatus, disabled, i18n.language]);

    if (data.acceptanceStatus === 'NOT_ACCEPTED' && !isAssessor()) {
        return null;
    }

    return (
        <div className={[styled.FullViewFooter, styled[variantStyle]].join(' ')}>
            <h4 className={styled.Title}>{title}</h4>
            <p className={styled.Text} title={text}>
                {text}
            </p>
            <div className={styled.Buttons}>{buttons && buttons.map(btn => btn)}</div>
        </div>
    );
};

export default FullViewFooter;
