import React, { createContext, useState, SetStateAction, Dispatch, FC, useMemo, useEffect } from 'react';
import SubscriptionModel, { isSubscription } from '../models/SubscriptionModel';
import LawTypeFilter from '../models/LawTypeFilter';
import LawListGroupFilter from '../models/LawListGroupFilter';
import useAuth from '../hooks/useAuth';
import { Column, SortingRule } from 'react-table';
import LawCell from '../components/Table/LawCell/LawCell';
import KeywordCell from '../components/Table/KeywordCell/KeywordCell';
import _ from 'lodash';
import LawModel from '../models/LawModel';
import { useTranslation } from 'react-i18next';

interface State {
    data: (SubscriptionModel | LawModel)[];
    filteredData: State['data'];
    visibleDataCount: number;
    hasCustomLaws: boolean;
    selectedIndex: number;

    lawTypeFilter: LawTypeFilter[];
    lawListGroupFilter: LawListGroupFilter[];

    sorting: SortingRule<object>[];

    globalSearch: string;

    columns: Array<
        Column<SubscriptionModel | LawModel> & {
            Header: string;
            languageId?: string;
            id: string;
            visible: boolean;
            editable?: boolean;
            field?: string;
            alwaysVisible?: boolean;
            showNotification?: boolean;
        }
    >;
    customColumns: State['columns'];

    showSubscriptions: boolean;
    columnSearch: {
        id: string;
        value: string | undefined;
    }[];
    showColumnSearch: boolean;

    loading: boolean;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const intialState: State = {
    filteredData: [],
    data: [],
    visibleDataCount: 0,
    hasCustomLaws: false,
    selectedIndex: -1,

    lawTypeFilter: [],
    lawListGroupFilter: [],

    globalSearch: '',

    sorting: [],

    columns: [],
    customColumns: [],

    showSubscriptions: false,

    columnSearch: [],
    showColumnSearch: true,

    loading: false,
};

const LawLibraryContext = createContext<Context>({
    state: intialState,
    setState: () => null,
});

const LawLibraryContextProvider: FC = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { company } = useAuth();

    const columns = useMemo(() => {
        const columns: State['columns'] = [
            {
                id: 'law',
                Header: t('columnLaw'),
                languageId: 'columnLaw',
                accessor: row => `${row.name} ${row.subId}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.law;
                    const lawB = rowB.values.law;
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
                width: 2,
                visible: true,
                alwaysVisible: true,
                showNotification: true,
                Cell: LawCell,
            },
            {
                id: 'description',
                Header: t('columnDescription'),
                languageId: 'columnDescription',
                accessor: 'description',
                disableSortBy: true,
                visible: true,
                alwaysVisible: true,
            },
            {
                id: 'subscribed',
                Header: '',
                accessor: row => {
                    return isSubscription(row);
                },
                visible: false,
            },
        ];

        if (company?.hasLawLists) {
            columns.push({
                id: 'lawLists',
                Header: t('columnLawLists'),
                languageId: 'columnLawLists',
                accessor: row => (row.lawLists ? row.lawLists.map(lawList => (lawList.lawListGroup ? lawList.lawListGroup.name + ': ' : '') + lawList.name) : ''),
                disableSortBy: true,
                Cell: KeywordCell,
                alwaysVisible: true,
                visible: true,
            });
        }

        if (company?.hasKeyWords) {
            columns.push({
                id: 'keywords',
                Header: t('columnKeywords'),
                languageId: 'columnKeywords',
                accessor: row => (row.keywordIds ? row.keywordIds.map((id: number) => _.find(company?.keyWords, kw => kw.id === id)?.text) : ''),
                Cell: KeywordCell,
                field: 'keywordIds',
                visible: true,
                alwaysVisible: true,
                disableSortBy: true,
            });
        }

        return columns;
    }, []);

    const [state, setState] = useState<State>({ ...intialState, columns });

    useEffect(() => {
        setState(s => ({
            ...s,
            columns: s.columns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        }));
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        setState(s => {
            const { lawTypeFilter, data } = s;

            // Apply lawGroup filtering
            const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
            const filteredData = data.filter(obj => {
                return selectedLawGroupIds.includes(obj.lawGroupId);
            });

            return {
                ...s,
                filteredData,
            };
        });
    }, [state.data, state.lawTypeFilter]);

    return <LawLibraryContext.Provider value={{ state, setState }}>{children}</LawLibraryContext.Provider>;
};

export { LawLibraryContext, LawLibraryContextProvider };
