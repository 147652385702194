import React, { FC, MouseEvent } from 'react';
import styled from './AccordionItem.module.css';
import { ReactComponent as AccordionIcon } from '../../../assets/images/accordionIcon.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/editIcon.svg';
import useAuth from '../../../hooks/useAuth';
import useMobileDevice from '../../../hooks/useMobileDevice';

interface Props {
    title: string;
    open: boolean;
    index?: number;
    onToggleOpen: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
    content: any;
    edit?: {
        onEdit: (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => void;
        disabled?: boolean;
        title?: string;
    };
}

const AccordionItem: FC<Props> = ({ title, open, index, onToggleOpen, edit, content }) => {
    const { isEditor } = useAuth();
    const isMobileDevice = useMobileDevice();

    return (
        <div id={title} className={[styled.AccordionItem, open ? styled.Open : ''].join(' ')}>
            <div className={[styled.Header, index === 0 ? styled.First : ''].join(' ')} onClick={onToggleOpen}>
                <span className={styled.Title}>
                    {title}
                    {edit && isEditor() && !isMobileDevice && (
                        <EditIcon
                            width={20}
                            height={20}
                            className={edit.disabled ? styled.Disabled : ''}
                            onClick={!edit.disabled && edit.onEdit && isEditor() ? e => edit.onEdit(e) : undefined}
                            title={edit.title}
                        />
                    )}
                </span>
                <AccordionIcon className={styled.Icon} />
            </div>
            {open && <div className={styled.Content}>{content}</div>}
        </div>
    );
};

export default AccordionItem;
