import React, { FC, useState } from 'react';
import styled from './Login.module.css';
import Button from '../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import { HOME } from '../../constants/Routes';
import useStorage from '../../hooks/useStorage';
import UserService from '../../services/UserService';
import { AxiosError } from 'axios';
import Input from '../../components/Input/Input';
import { userNameRegex } from '../../constants/Validation';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

const Login: FC = () => {
    const { setToken } = useStorage();
    const history = useHistory();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [userNameError, setUserNameError] = useState<string | undefined>(undefined);
    const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [forgotPasswordModalState, setForgotPasswordModalState] = useState<{ open: boolean; close?: boolean }>({ open: false });

    const handleUsernameChange = (value: string): void => {
        setUsername(value.replace(/\s/g, ''));
    };

    const handlePasswordChange = (value: string): void => {
        setPassword(value);
    };

    const validateUserName = () => {
        //Validate
        const valid: boolean = username.length > 0 && userNameRegex(username);

        if (!valid) {
            setUserNameError('E-postadressen är inte giltig');
        }

        return valid;
    };

    const validatePassword = () => {
        const valid: boolean = password.length > 0;
        console.log(username);

        if (!valid) {
            setPasswordError('Lösenord måste vara ifyllt');
        }

        return valid;
    };

    const authenticate = () => {
        setLoading(true);
        const startTimestamp = new Date().getTime();
        const forcedDelay = 1000;
        UserService()
            .authenticateUser(username, password)
            .then(response => {
                const responseTimestamp = new Date().getTime();
                const difference = startTimestamp - responseTimestamp + forcedDelay;
                if (difference > 0) {
                    setTimeout(() => {
                        setToken(response.headers.authorization);
                        setLoading(false);
                        history.push(HOME);
                    }, difference);
                } else {
                    setToken(response.headers.authorization);
                    setLoading(false);
                    history.push(HOME);
                }
            })
            .catch((error: AxiosError) => {
                const status = error.response?.status;
                const duration = error.response?.headers.duration;
                const email = error.response?.headers.email;

                if (status === 403 && duration !== undefined && email !== undefined) {
                    setError(`Too many attempts, ${username} will be locked for ${duration} min.`);
                } else if (status === 401) {
                    setError('Epostadress och lösenord matchar inte!');
                } else {
                    setError('Ett oväntat fel uppstod.');
                }
                setPassword('');
                setLoading(false);
            });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setError(undefined);
        setPasswordError(undefined);
        setUserNameError(undefined);

        const passwordValid: boolean = validatePassword();
        const userNameValid: boolean = validateUserName();

        if (passwordValid && userNameValid) {
            authenticate();
        }
    };

    const handleOpenForgotPasswordModal = (): void => {
        setForgotPasswordModalState({ open: true });
    };

    const handleCloseForgotPasswordModal = (): void => {
        setForgotPasswordModalState({ open: false });
    };

    return (
        <div className={styled.LoginBackground}>
            <div className={styled.Login}>
                <div className={styled.Header}>
                    <p>Välkommen till Lagbevakningen</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={styled.InputGroup}>
                        <Input className={styled.Input} type="text" autoComplete="username" label="E-postadress" value={username} onChange={handleUsernameChange} autoFocus />
                        <Input className={styled.Input} type="password" autoComplete="current-password" label="Lösenord" value={password} onChange={handlePasswordChange} />
                        <div className={styled.ErrorDiv}>
                            {error && <p className={styled.Error}>{error}</p>}
                            {userNameError && <p className={styled.Error}>{userNameError}</p>}
                            {passwordError && <p className={styled.Error}>{passwordError}</p>}
                        </div>
                    </div>
                    <div className={styled.ButtonGroup}>
                        <Button variant="Primary" type="submit" disabled={loading} className={styled.LoginBtn}>
                            {!loading ? 'Logga in' : 'Laddar...'}
                        </Button>
                        <Button variant="Light" className={styled.PwdBtn} onClick={handleOpenForgotPasswordModal}>
                            {'Glömt lösenord'}
                        </Button>
                    </div>
                </form>
            </div>
            {forgotPasswordModalState.open && <ForgotPassword onClose={handleCloseForgotPasswordModal} />}
        </div>
    );
};

export default Login;
