import React, { createContext, useState, SetStateAction, Dispatch, FC } from 'react';
import UserModel from '../models/UserModel';
import CompanyModel from '../models/CompanyModel';

interface State {
    user: UserModel | undefined;
    company: CompanyModel | undefined;
    signedInAsUser: UserModel | undefined;
    numberOfChangesForCompany?: number;
    numberOfConsultantChangesForCompany?: number;
    numberOfUnfinishedRevisionsForCompany?: number;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const initialState: State = {
    user: undefined,
    company: undefined,
    signedInAsUser: undefined,
    numberOfChangesForCompany: undefined,
    numberOfConsultantChangesForCompany: undefined,
    numberOfUnfinishedRevisionsForCompany: undefined,
};

const AuthContext = createContext<Context>({
    state: initialState,
    setState: () => undefined,
});

const AuthContextProvider: FC = ({ children }) => {
    const [state, setState] = useState<State>({ ...initialState });
    return <AuthContext.Provider value={{ state, setState }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
