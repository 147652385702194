import React, { FC, useMemo, ReactNode, useState, useEffect } from 'react';
import FullView from '../FullView';
import styled from './EditRevisionFullView.module.css';
import Accordion from '../../Accordion/Accordion';
import useAuth from '../../../hooks/useAuth';
import AccordionTextItem from '../../Accordion/AccordionTextItem/AccordionTextItem';
import AccordionListItem from '../../Accordion/AccordionListItem/AccordionListItem';
import _ from 'lodash';
import EditFullViewFooter from './EditFullViewFooter/EditFullViewFooter';
import CustomLawRevisionModel from '../../../models/CustomLawRevisionModel';
import AccordionCommentItem from '../../Accordion/AccordionCommentItem/AccordionCommentItem';
import AccordionRevisionQuestionItem from '../../Accordion/AccordionRevisionQuestionItem/AccordionRevisionQuestionItem';
import RevisionQuestionService from '../../../services/RevisionQuestionService';
import { useTranslation } from 'react-i18next';
import RevisionQuestionGroup from '../../../models/RevisionQuestionGroup';
import RevisionSubscriptionModel, { isRevisionSubscription } from '../../../models/RevisionSubscriptionModel';
import { sortRevisionQuestionGroups } from '../../../utils/sorter';
import AccordionMediaItem from '../../Accordion/AccordionMediaItem/AccordionMediaItem';
import { isSubscription } from '../../../models/SubscriptionModel';

interface Props {
    data?: RevisionSubscriptionModel | CustomLawRevisionModel;
    open: boolean;
    finished?: boolean;
    revisionOk?: boolean;
    hideFooter: boolean;
    onFooterAction: () => void;
    onEdit: (key: string, title: string) => void;
    onClose: () => void;
    editRevisionLeftViewOpen?: boolean;
}

const EditRevisionFullView: FC<Props> = ({ data, open, finished, revisionOk, hideFooter, onFooterAction, onEdit, onClose, editRevisionLeftViewOpen }) => {
    const { t, i18n } = useTranslation();
    const { company, isRevisor, isEditor, isAdminSubs, isGroupCompany, isGroupHasRevisionQuestions, isAboveSuperUser } = useAuth();
    const [revisionQuestionGroupFromLaw, setRevisionQuestionGroupFromLaw] = useState<RevisionQuestionGroup[]>([]);
    const [showImages, setShowImages] = useState<boolean>(true);

    const finishedDefaultOpenAccordions = () => (company?.hasRevisionQuestions || isAboveSuperUser() ? [1, 9] : [0]);
    const unfinishedDefaultOpenAccordions = () => (company?.hasRevisionQuestions || isAboveSuperUser() ? [0, 3, 9] : [2]);
    const [openAccordions, setOpenAccordions] = useState<number[]>();

    const handleToggleMediaAccordion = (index: number) => {
        setOpenAccordions(s => (s !== undefined ? (s.includes(index) ? s.filter(i => i !== index) : [...s, index]) : s));
    };

    useEffect(() => {
        const customLawHidden = document.getElementById('CUSTOMLAW');
        if (customLawHidden !== null) {
            customLawHidden.style.display = 'none';
        }

        if (finished !== undefined && openAccordions === undefined) {
            if (finished) {
                setOpenAccordions(finishedDefaultOpenAccordions());
            } else {
                setOpenAccordions(unfinishedDefaultOpenAccordions());
            }
        }
        if (data?.mediaAttachments === null && openAccordions !== undefined) {
            const newOpenAccordions = openAccordions.filter(item => item !== 9);
            setOpenAccordions(newOpenAccordions);
        }

        if (data?.mediaAttachments !== null && openAccordions !== undefined && showImages && !openAccordions.includes(9)) {
            handleToggleMediaAccordion(9);
        }
    }, [data]);

    useEffect(() => {
        if (isAboveSuperUser() && !company?.hasRevisionQuestions && data && isRevisionSubscription(data)) {
            const lawId = data.lawId;
            RevisionQuestionService()
                .getRevisionQuestionsForLaw(lawId)
                .then(revisionQuestionGroups => {
                    setRevisionQuestionGroupFromLaw(revisionQuestionGroups);
                });
        }
    }, [data]);

    const accordionItems = useMemo(() => {
        const items: { title: string; value: ReactNode; edit?: { onEdit?: () => void; disabled?: boolean; title?: string } }[] = [];
        if (data && ((company?.hasRevisionQuestions && !isGroupCompany()) || isAboveSuperUser() || (company?.hasRevisionQuestions && isGroupHasRevisionQuestions()))) {
            if (isRevisionSubscription(data)) {
                data.revisionQuestionGroups = isAboveSuperUser() && !company?.hasRevisionQuestions ? revisionQuestionGroupFromLaw : data.revisionQuestionGroups;
                const revisionQuestionGroups: { title: string; revisionQuestions: string[] }[] = sortRevisionQuestionGroups(data);
                items.push({
                    title: t('columnRevisionQuestions'),
                    value: <AccordionRevisionQuestionItem data={revisionQuestionGroups.map(rqg => ({ ...rqg, title: rqg.title ? rqg.title : t('ownQuestions') }))} withCounter />,
                });
            } else {
                if (company?.hasRevisionQuestions) {
                    items.push({
                        title: t('columnRevisionQuestions'),
                        value: (
                            <AccordionRevisionQuestionItem
                                data={[{ title: t('ownQuestions'), revisionQuestions: data.customRevisionQuestions.map(crq => crq.question) }]}
                                withCounter
                            />
                        ),
                    });
                }
            }
        }

        items.push({
            title: t('columnComment'),
            value: <AccordionCommentItem data={data?.comments.map(c => ({ date: c.commentDate, username: c.userName, comment: c.comment })) || []} />,
        });

        if (data && isRevisionSubscription(data)) {
            items.push({
                title: t('columnDescription'),
                value: <AccordionTextItem data={data?.description || ''} />,
            });
        }

        // Lägg till ett extra item för att få lika många accordions i arrayen. Så att logik för utfällda flikar fungerar korrekt.
        if (data?.lawType === 'CUSTOM_LAW') {
            items.push({
                title: 'CUSTOMLAW',
                value: '',
            });
        }

        items.push({
            title: t('columnText'),
            value: <AccordionTextItem data={data?.text || ''} />,
            edit: {
                onEdit: () => onEdit('text', t('columnText')),
                disabled: (isGroupCompany() && !isAdminSubs() && !isAboveSuperUser()) || !isRevisor() || !isEditor() || (finished && revisionOk),
            },
        });

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                items.push({
                    title,
                    value: <AccordionTextItem data={_.get(data, `customerText${i}`)} />,
                    edit: {
                        onEdit: () => onEdit(`customerText${i}`, title),
                        disabled: !isRevisor() || !isEditor() || (finished && revisionOk),
                    },
                });
            }
        }

        if (company?.hasLawLists) {
            items.push({
                title: t('columnLawLists'),
                value: <AccordionListItem data={data?.lawLists?.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name) || []} />,
                edit: {
                    onEdit: () => onEdit('lawLists', t('columnLawLists')),
                    disabled: !isRevisor() || !isEditor() || (finished && revisionOk),
                },
            });
        }

        if (company?.hasKeyWords) {
            items.push({
                title: t('columnKeywords'),
                value: <AccordionListItem data={data?.keywordIds?.map(kwId => _.find(company.keyWords, kw => kw.id === kwId)?.text || '') || []} />,
                edit: {
                    onEdit: () => onEdit('keywordIds', t('columnKeywords')),
                    disabled: !isRevisor() || !isEditor() || (finished && revisionOk),
                },
            });
        }

        items.push({
            title: 'Bilder',
            value: <AccordionMediaItem selectedSub={data} editRevisionLeftViewOpen={editRevisionLeftViewOpen} />,
        });

        return items;
    }, [data, i18n.language, revisionQuestionGroupFromLaw, editRevisionLeftViewOpen]);

    const handleToggleAccordion = (index: number) => {
        if (index == 9) {
            setShowImages(!showImages);
        }
        setOpenAccordions(s => (s !== undefined ? (s.includes(index) ? s.filter(i => i !== index) : [...s, index]) : s));
    };

    return (
        <FullView
            hiddenIdBar={data ? (isRevisionSubscription(data) ? 'Lid:' + data.lawId : 'CLid:' + data.customLawId) : ''}
            lawSourceId={data ? (isRevisionSubscription(data) ? data.lawId : data.customLawId) : -1}
            title={data?.name || ''}
            subId={data ? (isRevisionSubscription(data) ? data.subId : data.customLawEndDate) : ''}
            isCustomLaw={(data && !isRevisionSubscription(data)) as boolean}
            lawSource={data?.lawSources.length ? data.lawSources[0] : undefined}
            open={open}
            onClose={onClose}
            revisionStatus={data && data.revisionStatus}
        >
            <div className={styled.Content}>
                <Accordion
                    id={data ? (isRevisionSubscription(data) ? data.lawId : data.customLawId) : -1}
                    data={accordionItems}
                    initiallyOpenIndicies={openAccordions || []}
                    onToggle={handleToggleAccordion}
                />
            </div>
            {!hideFooter && <EditFullViewFooter finished={finished} status={data?.revisionStatus} onClick={onFooterAction} onClose={onClose} />}
        </FullView>
    );
};

export default EditRevisionFullView;
