import React, { FC, useMemo, ReactNode, useState } from 'react';
import CustomLawModel from '../../../models/CustomLawModel';
import SubscriptionModel, { isSubscription } from '../../../models/SubscriptionModel';
import useAuth from '../../../hooks/useAuth';
import FullView from '../FullView';
import Accordion from '../../Accordion/Accordion';
import AccordionTextItem from '../../Accordion/AccordionTextItem/AccordionTextItem';
import AccordionListItem from '../../Accordion/AccordionListItem/AccordionListItem';
import styled from './CreateRevisionFullView.module.css';
import _ from 'lodash';
import AccordionRevisionQuestionItem from '../../Accordion/AccordionRevisionQuestionItem/AccordionRevisionQuestionItem';
import { useTranslation } from 'react-i18next';

interface Props {
    data?: SubscriptionModel | CustomLawModel;
    open: boolean;
    onClose: () => void;
}

const CreateRevisionFullView: FC<Props> = ({ data, open, onClose }) => {
    const { t, i18n } = useTranslation();
    const { company } = useAuth();

    const [openAccordions, setOpenAccordions] = useState<number[]>([]);

    const accordionItems = useMemo(() => {
        const items: { title: string; value: ReactNode }[] = [];

        items.push({
            title: t('columnDescription'),
            value: <AccordionTextItem data={data?.description || ''} />,
        });

        items.push({
            title: t('columnText'),
            value: <AccordionTextItem data={data?.text || ''} />,
        });

        if (data && company && company.hasRevisionQuestions) {
            if (isSubscription(data)) {
                const revisionQuestionGroups: { title: string; revisionQuestions: string[] }[] = [];

                data.revisionQuestionGroups
                    .sort((a, b) => (a.titleOrder > b.titleOrder ? 1 : a.titleOrder < b.titleOrder ? -1 : 0))
                    .map(rqg => ({
                        ...rqg,
                        revisionQuestions: rqg.revisionQuestions
                            .filter(rq => !rq.excluded)
                            .sort((a, b) =>
                                a.isCustomQuestion && b.isCustomQuestion
                                    ? a.id > b.id
                                        ? 1
                                        : a.id < b.id
                                        ? -1
                                        : 0
                                    : a.questionOrder! > b.questionOrder!
                                    ? 1
                                    : a.questionOrder! < b.questionOrder!
                                    ? -1
                                    : 0,
                            ),
                    }))
                    .forEach(rqg => {
                        if (rqg.revisionQuestions.length > 0) {
                            const groupIndex = revisionQuestionGroups.findIndex(({ title }) => title === rqg.title);
                            if (groupIndex > -1) {
                                revisionQuestionGroups[groupIndex] = {
                                    ...revisionQuestionGroups[groupIndex],
                                    revisionQuestions: [...revisionQuestionGroups[groupIndex].revisionQuestions, ...rqg.revisionQuestions.map(rq => rq.question)],
                                };
                            } else {
                                revisionQuestionGroups.push({ title: rqg.title, revisionQuestions: rqg.revisionQuestions.map(rq => rq.question) });
                            }
                        }
                    });

                const customRevisionQuestions = data.customRevisionQuestions.filter(crq => !crq.excluded);
                if (customRevisionQuestions.length > 0) {
                    const index = revisionQuestionGroups.push({ title: t('ownQuestions'), revisionQuestions: [] });
                    customRevisionQuestions.forEach(crq => {
                        revisionQuestionGroups[index - 1].revisionQuestions.push(crq.question);
                    });
                }

                items.push({
                    title: t('revisionQuestions'),
                    value: <AccordionRevisionQuestionItem data={revisionQuestionGroups} withCounter />,
                });
            } else {
                items.push({
                    title: t('revisionQuestions'),
                    value: (
                        <AccordionRevisionQuestionItem
                            data={[{ title: undefined, revisionQuestions: data.customRevisionQuestions.filter(crq => !crq.excluded).map(crq => crq.question) }]}
                            withCounter
                        />
                    ),
                });
            }
        }

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                items.push({
                    title,
                    value: <AccordionTextItem data={_.get(data, `customerText${i}`)} />,
                });
            }
        }

        if (company?.hasLawLists) {
            items.push({
                title: t('columnLawLists'),
                value: <AccordionListItem data={data?.lawLists?.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name) || []} />,
            });
        }

        if (company?.hasKeyWords) {
            items.push({
                title: t('columnKeywords'),
                value: <AccordionListItem data={data?.keywordIds?.map(kwId => _.find(company.keyWords, kw => kw.id === kwId)?.text || '') || []} />,
            });
        }

        return items;
    }, [data, i18n]);

    const handleToggleAccordion = (index: number) => {
        setOpenAccordions(s => (s.includes(index) ? s.filter(i => i !== index) : [...s, index]));
    };

    return (
        <FullView
            lawSourceId={data ? (isSubscription(data) ? data.lawId : data.customLawId) : -1}
            title={data?.name || ''}
            subId={data ? (isSubscription(data) ? data.subId : data.customLawEndDate) : ''}
            isCustomLaw={(data && !isSubscription(data)) as boolean}
            lawSource={data?.lawSources.length ? data.lawSources[0] : undefined}
            open={open}
            onClose={onClose}
        >
            <div className={styled.Content}>
                <Accordion
                    id={data ? (isSubscription(data) ? data.lawId : data.customLawId) : -1}
                    data={accordionItems}
                    initiallyOpenIndicies={openAccordions}
                    onToggle={handleToggleAccordion}
                />
            </div>
        </FullView>
    );
};
export default CreateRevisionFullView;
