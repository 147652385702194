import React, { FC } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './DuplicateUserModal.module.css';
import { useTranslation } from 'react-i18next';
import UserModel from '../../../models/UserModel';
import errorIcon from '../../../assets/images/errorIcon.svg';
import statusWarningIcon from '../../../assets/images/statusWarningIcon.svg';

interface Props {
    duplicateUser: UserModel | undefined;
    onCancel: () => void;
    onConfirm: () => void;
    forbiddenEmailChange?: boolean;
    redundantAccessRequest?: boolean;
}

const DuplicateUserModal: FC<Props> = ({ duplicateUser, onCancel, onConfirm, forbiddenEmailChange, redundantAccessRequest }) => {
    const { t } = useTranslation();

    return (
        <Modal title={t('duplicateUserTitle')} className={styled.Modal}>
            {!forbiddenEmailChange && (
                <div className={styled.Content}>
                    <img src={statusWarningIcon} alt="warning" className={styled.ErrorIcon} />
                    <p>
                        <strong>{duplicateUser?.fullName}</strong>, {duplicateUser?.userName} {t('duplicateUserText')}
                    </p>

                    <div className={styled.CompanyList}>
                        <ul>
                            {duplicateUser?.connectedCompanies.length == 0 && (
                                <li>
                                    <strong>{duplicateUser?.companyName}</strong>
                                </li>
                            )}

                            {duplicateUser?.connectedCompanies &&
                                duplicateUser?.connectedCompanies.map(c => (
                                    <li key={c.id}>
                                        <strong>
                                            {c.name} {duplicateUser.companyId != c.id ? '(' + t('columnExternalUser') + ')' : ''}
                                        </strong>
                                    </li>
                                ))}
                        </ul>
                    </div>

                    {!redundantAccessRequest && (
                        <div>
                            <p>{t('duplicateUserSupportTest')}</p>
                            <div className={styled.Buttons}>
                                <Button variant="Outline" onClick={onCancel}>
                                    {t('buttonNo')}
                                </Button>
                                <Button variant="Primary" onClick={onConfirm}>
                                    {t('buttonYes')}
                                </Button>
                            </div>
                        </div>
                    )}

                    {redundantAccessRequest && (
                        <div>
                            <div className={styled.Buttons}>
                                <Button variant="Primary" onClick={onCancel}>
                                    {t('buttonOk')}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {forbiddenEmailChange && (
                <div className={styled.Content}>
                    <img src={errorIcon} alt="error" className={styled.ErrorIcon} />
                    <p>
                        <strong>{duplicateUser?.userName}</strong> {t('duplicateUserForbiddenEmailChange')}
                    </p>
                    <div className={styled.Buttons}>
                        <Button variant="Primary" onClick={onCancel}>
                            {t('buttonOk')}
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default DuplicateUserModal;
