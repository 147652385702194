import React, { FC, useMemo, ReactNode, useState } from 'react';
import AcceptanceLogModel from '../../../models/AcceptanceLogModel';
import AccordionTextItem from '../../Accordion/AccordionTextItem/AccordionTextItem';
import FullView from '../FullView';
import styled from './ChangeArchiveFullView.module.css';
import Accordion from '../../Accordion/Accordion';
import useAuth from '../../../hooks/useAuth';
import AccordionListItem from '../../Accordion/AccordionListItem/AccordionListItem';
import { ChangeActionEnum } from '../../../models/ChangeAction';
import { useTranslation } from 'react-i18next';
import ChangeTextLink from '../../ChangeTextLink/ChangeTextLink';

interface Props {
    data?: AcceptanceLogModel;
    open: boolean;
    onEditComment: () => void;
    onRevertChange: () => void;
    onClose: () => void;
}

const ChangeArchiveFullView: FC<Props> = ({ data, open, onEditComment, onRevertChange, onClose }) => {
    const { t, i18n } = useTranslation();
    const { user, company, isGroupCompany } = useAuth();

    const [openAccordions, setOpenAccordions] = useState<number[]>([]);

    const accordionItems = useMemo(() => {
        const items: {
            title: string;
            value: ReactNode;
            edit?: {
                onEdit?: () => void;
                disabled?: boolean;
                title?: string;
            };
        }[] = [];

        items.push({
            title: t('columnAction'),
            value: <AccordionTextItem data={data ? t(ChangeActionEnum[data.changeAction]) : ''} />,
        });

        const link = data ? <ChangeTextLink lawId={data.lawId} lawSources={data.lawSources} /> : null;
        items.push({
            title: t('lawChangeText'),
            value: <AccordionTextItem data={data?.changeText || ''} link={link} />,
        });

        items.push({
            title: t('columnComment'),
            value: <AccordionTextItem data={data?.comment || ''} />,
            edit:
                data && user && isGroupCompany() && data.userId === user.userId
                    ? {
                          onEdit: onEditComment,
                      }
                    : undefined,
        });

        items.push({
            title: t('columnAcceptedBy'),
            value: <AccordionTextItem data={data?.userFullName || ''} />,
        });

        items.push({
            title: t('columnChangeOfImpact'),
            value: <AccordionTextItem data={data?.changeHasImpact === true ? t('buttonYes') : data?.changeHasImpact === false ? t('buttonNo') : '' || ''} />,
        });

        if (company?.hasLawLists) {
            items.push({
                title: t('columnLawLists'),
                value: <AccordionListItem data={data?.lawLists || []} />,
            });
        }

        if (company?.hasKeyWords) {
            items.push({
                title: t('columnKeywords'),
                value: <AccordionListItem data={data?.keyWords || []} />,
            });
        }

        return items;
    }, [data, i18n.language]);

    const handleToggleAccordion = (index: number) => {
        setOpenAccordions(s => (s.includes(index) ? s.filter(i => i !== index) : [...s, index]));
    };

    return (
        <FullView title={data?.name || ''} subId={data?.subId || ''} isCustomLaw={false} open={open} onClose={onClose} onRevertChange={onRevertChange}>
            <div className={styled.Content}>
                <Accordion id={data?.userId || -1} data={accordionItems} initiallyOpenIndicies={openAccordions} onToggle={handleToggleAccordion} />
            </div>
        </FullView>
    );
};

export default ChangeArchiveFullView;
