import React, { useEffect, useState } from 'react';
import styled from './App.module.css';
import Navbar from '../components/Navbar/Navbar';
import { Switch, Route, useHistory } from 'react-router-dom';
import {
    HOME,
    LAWS,
    REVISION,
    CHANGES,
    LOGIN,
    COMPANY_LOGIN,
    SETTINGS,
    SETTINGS_COMPANY_LAWLISTS_EDIT,
    SETTINGS_COMPANY_KEYWORDS_EDIT,
    FORGOTPASSWORD,
    RESETPASSWORD,
    READER_LOGIN,
    SETTINGS_COMPANY_DETACHED_COPIES_CREATE,
    SETTINGS_COMPANY_DETACHED_COPIES_REMOVE,
    CONSULTANT,
    HELP,
    READER_LOGIN_OLD,
    TOKEN_LOGIN,
    HARDLOCK,
    CONTRACT,
    INVOICING,
    MERGE_LAWS,
} from '../constants/Routes';

import Home from './Home/Home';
import Laws from './Laws/Laws';
import Changes from './Changes/Changes';
import Revision from './Revision/Revision';
import Login from './Login/Login';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ForgotPassword/ResetPassword/ResetPassword';
import PrivateRoute from '../components/Routes/PrivateRoute';
import PublicRoute from '../components/Routes/PublicRoute';
import useStorage from '../hooks/useStorage';
import UserService from '../services/UserService';
import CompanyService from '../services/CompanyService';
import useAuth from '../hooks/useAuth';
import CompanyLogin from './CompanyLogin/CompanyLogin';
import useMisc from '../hooks/useMisc';
import Settings from './Settings/Settings';
import EditListContent from './Settings/Company/EditListContent/EditListContent';
import ReaderLogin from './ReaderLogin/ReaderLogin';
import EditDetachedCopies from './Settings/Company/DetachedCopies/EditDetachedCopies/EditDetachedCopies';
import Consultant from './Consultant/Consultant';
import Help from './Help/Help';
import { getAuthApi, getApi } from '../config/axios.config';
import ErrorModal from '../components/Modal/ErrorModal/ErrorModal';
import ApiErrorModel from '../models/ApiErrorModel';
import SignContract from './SignContract/SignContract';
import HardLock from './HardLock/HardLock';
import VersionErrorModal from '../components/Modal/VersionErrorModal/VersionErrorModal';
import { useTranslation } from 'react-i18next';
import Invoicing from '../Invoicing/containers/InvoiceList/Invoicing';
import MergeLaws from '../MergeLaws/containers/MergeLaws';
import HomeService from '../services/HomeService';
import Modal from '../components/Modal/Modal';
import Button from '../components/Button/Button';
import MobileHome from '../containers/Mobile/Home/HomeMobile';
import useMobileDevice from '../hooks/useMobileDevice';
import MobileNavbar from '../components/Navbar/MobileNavbar';
import MobileRevision from './Mobile/Revision/MobileRevision';
import TokenLogin from './TokenLogin/TokenLogin';

const App: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { getToken, isCompanyStored, getCompanyId, isSignedInUserIdStored, getSignedInAsUserId } = useStorage();
    const isMobileDevice = useMobileDevice();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

    const {
        setUser,
        setCompany,
        setSignedInAsUser,
        logout,
        company,
        numberOfChangesForCompany,
        fetchNumberOfChangesForCompany,
        numberOfConsultantChangesForCompany,
        fetchNumberOfConsultantChangesForCompany,
        numberOfUnfinishedRevisionsForCompany,
        fetchNumberOfUnfinishedRevisionsForCompany,
        isAboveSuperUser,
        logoutAndRedirectExternalPage,
        logoutAndRedirect,
    } = useAuth();
    const { envName, commit, fetchEnv } = useMisc();

    const token = getToken();

    const [error, setError] = useState<ApiErrorModel>();
    const [versionError, setVersionError] = useState(false);
    const [dbTimestamp, setDbTimestamp] = useState('');

    useEffect(() => {
        getAuthApi().interceptors.response.use(
            response => {
                if (error) {
                    setError(undefined);
                }
                return response;
            },
            err => {
                if (err.response) {
                    if (err.response.headers['expiredtoken'] === 'true') {
                        logout();
                    } else if (err.response.status === 409) {
                        setVersionError(true);
                    } else if (err.response.data) {
                        setError(err.response.data);
                    }
                }
                throw err;
            },
        );
        getApi().interceptors.response.use(
            response => {
                if (error) {
                    setError(undefined);
                }
                return response;
            },
            err => {
                if (err.response && err.response.data) {
                    setError(err.response.data);
                }
                throw err;
            },
        );
    }, []);

    useEffect(() => {
        fetchEnv();
        if (!getToken()) return;

        async function fetchInformation(): Promise<void> {
            try {
                const user = await UserService().getCurrentUser();
                setUser(user);

                if (user && user.changePassword) {
                    setShowChangePasswordModal(true);
                    logout();
                    return;
                }

                const dbTimestamp = await HomeService().getDbTimestamp();
                setDbTimestamp(dbTimestamp);

                // Signed in as consultant or above
                if (isSignedInUserIdStored()) {
                    const signedInUserId = getSignedInAsUserId();
                    const signedInUser = await UserService().getUser(signedInUserId);
                    setSignedInAsUser(signedInUser);
                }

                const hasConnectedCompanies = user.connectedCompanies.length > 0;
                if (hasConnectedCompanies && !isCompanyStored()) {
                    history.push(COMPANY_LOGIN);
                } else if (hasConnectedCompanies && isCompanyStored()) {
                    const companyId = getCompanyId();
                    const company = await CompanyService().getCompanyById(companyId);
                    setCompany(company);
                } else if (user.companyId) {
                    const company = await CompanyService().getCompanyById(user.companyId);
                    setCompany(company);
                } else if (getCompanyId()) {
                    const company = await CompanyService().getCompanyById(getCompanyId());
                    setCompany(company);
                }
            } catch (error) {
                logout();
            } finally {
                setLoading(false);
            }
        }

        fetchInformation();
    }, [token]);

    useEffect(() => {
        if (company && numberOfChangesForCompany === undefined) {
            fetchNumberOfChangesForCompany();
        }
        if (company && numberOfConsultantChangesForCompany === undefined && isAboveSuperUser()) {
            fetchNumberOfConsultantChangesForCompany();
        }
        if (company && numberOfUnfinishedRevisionsForCompany === undefined) {
            fetchNumberOfUnfinishedRevisionsForCompany();
        }
    }, [company]);

    useEffect(() => {
        document.documentElement.lang = i18n.language;
    }, [i18n.language]);

    if (loading && token) {
        return <div>Loading...</div>;
    }

    const handleRedirectToLogin = () => {
        setShowChangePasswordModal(false);
        history.push(LOGIN);
    };

    return (
        <div className={styled.App}>
            {error && <ErrorModal error={error} onClose={() => setError(undefined)} />}
            {versionError && <VersionErrorModal onClose={() => setVersionError(false)} />}
            {isMobileDevice ? <MobileNavbar /> : <Navbar envName={envName} dbTimestamp={dbTimestamp} commit={commit} />}
            <div className={styled.Content}>
                <Switch>
                    <PrivateRoute path={HOME} component={isMobileDevice ? MobileHome : Home} exact />
                    <PrivateRoute path={LAWS} component={Laws} />
                    <PrivateRoute path={CHANGES} component={Changes} />
                    <PrivateRoute path={REVISION} component={isMobileDevice ? MobileRevision : Revision} />
                    <PrivateRoute path={CONSULTANT} component={Consultant} />
                    <PrivateRoute path={INVOICING} component={Invoicing} />
                    <PrivateRoute path={MERGE_LAWS} component={MergeLaws} />
                    <PrivateRoute path={SETTINGS_COMPANY_LAWLISTS_EDIT + '/:id?'} component={EditListContent} />
                    <PrivateRoute path={SETTINGS_COMPANY_KEYWORDS_EDIT + '/:id?'} component={EditListContent} />
                    <PrivateRoute path={SETTINGS_COMPANY_DETACHED_COPIES_CREATE} component={EditDetachedCopies} />
                    <PrivateRoute path={SETTINGS_COMPANY_DETACHED_COPIES_REMOVE} component={EditDetachedCopies} />
                    <PrivateRoute path={SETTINGS} component={Settings} />
                    <Route path={COMPANY_LOGIN} component={CompanyLogin} exact />
                    <Route path={READER_LOGIN} component={ReaderLogin} />
                    <Route path={READER_LOGIN_OLD} component={ReaderLogin} />
                    <Route path={TOKEN_LOGIN} component={TokenLogin} />
                    <PrivateRoute path={HELP} component={Help} />
                    <PublicRoute path={LOGIN} component={Login} />
                    <PrivateRoute path={CONTRACT} component={SignContract} />
                    <PrivateRoute path={HARDLOCK} component={HardLock} />
                    <PublicRoute path={FORGOTPASSWORD} component={ForgotPassword} />
                    <PublicRoute exact path={RESETPASSWORD + '/:code'} component={ResetPassword} />
                </Switch>
            </div>

            {showChangePasswordModal && (
                <Modal title={'Välkommen!'}>
                    <div>
                        <p>Eftersom detta är första gången du loggar in, har vi skickat ett mail med instruktioner om hur du sätter ditt egna lösenord</p>
                        <div className={styled.Buttons}>
                            <Button variant="Primary" onClick={handleRedirectToLogin}>
                                {t('buttonOk')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default App;
