import React, { FC, useState, useEffect } from 'react';
import { LAWS_LISTS, LOGIN } from '../../constants/Routes';
import UserService from '../../services/UserService';
import { useHistory } from 'react-router-dom';
import useStorage from '../../hooks/useStorage';
import useQuery from '../../hooks/useQuery';
import CorruptReaderLoginModal from '../../components/Modal/CorruptReaderLoginModal/CorruptReaderLoginModal';
import { AxiosError } from 'axios';

const ReaderLogin: FC = ({}) => {
    const history = useHistory();
    const query = useQuery();
    const { setToken, setSsoModeToStorage } = useStorage();
    const [loading, setLoading] = useState(true);
    const [corruptReaderLogin, setCorruptReaderLogin] = useState(false);
    const [error, setError] = useState<string | null>(null);

    let ssoMode = false;
    let unsupportedOldLink = false;

    // Token from users with an external provider (SSO)
    if (query.get('Authorization')) {
        const jwtToken = query.get('Authorization');
        if (jwtToken) {
            setToken(jwtToken);
            setSsoModeToStorage(true);
            ssoMode = true;
            history.push(LAWS_LISTS);
        }
    }

    let username = '';
    let password = '';

    // Save to support old reader login. Shows custom error message
    if (query.get('companyId')) {
        unsupportedOldLink = true;
    } else {
        // New readerLogin params
        username = query.get('t') || '';
        password = query.get('v') || '';
    }

    useEffect(() => {
        if (!ssoMode) {
            setLoading(true);
            if (username && password) {
                UserService()
                    .authenticateUser(username, password)
                    .then(response => {
                        setToken(response.headers.authorization);
                        setLoading(false);
                        history.push(LAWS_LISTS);
                    })
                    .catch((error: AxiosError) => {
                        setCorruptReaderLogin(true);
                        setError('Något gick fel. Försök igen.');
                        setLoading(false);
                    });
            } else if (unsupportedOldLink) {
                setCorruptReaderLogin(true);
            } else if (query.getAll('').length == 0) {
                // User is using old bookmark from 2.0 with no additional params. Redirecting to login page
                history.push(LOGIN);
            } else {
                setCorruptReaderLogin(true);
            }
        }
    }, []);

    const handleConfirmReaderLoginModal = () => {
        setCorruptReaderLogin(false);
        history.push(LOGIN);
    };

    return <div>{corruptReaderLogin && <CorruptReaderLoginModal unsupportedOldLink={unsupportedOldLink} onConfirm={() => handleConfirmReaderLoginModal()} />}</div>;
};

export default ReaderLogin;
