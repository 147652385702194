import React, { createRef, FC, useState } from 'react';
import styled from './ColorButton.module.css';
import { ReactComponent as UnderlineIcon } from '../../../assets/images/colorIcon.svg';
import useClickOutside from '../../../hooks/useClickOutside';
import { DraftInlineStyle } from 'draft-js';

const colors = [
    { class: 'black', color: 'black' },
    { class: 'red', color: '#c63418' },
    { class: 'green', color: '#00a300' },
];

interface Props {
    onClick: (color: string) => void;
    currentStyle: DraftInlineStyle;
}

const ColorButton: FC<Props> = ({ onClick, currentStyle }) => {
    const ref = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);

    const activeColor = colors.filter(obj => currentStyle.has('color-' + obj.color))[0];

    useClickOutside(ref, () => {
        setOpen(false);
    });

    const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        setOpen(!open);
    };

    const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, color: string): void => {
        e.preventDefault();
        setOpen(false);
        onClick(color);
    };

    return (
        <div ref={ref} className={styled.Container}>
            <button tabIndex={-1} className={styled.ColorButton} onMouseDown={handleOpen}>
                <UnderlineIcon className={[styled.ColorButtonIcon, styled[activeColor ? activeColor.class : 'black'], open ? styled.Open : ''].join(' ')} />
            </button>
            {open && (
                <div className={styled.ColorList}>
                    {colors.map(color => (
                        <div key={color.color} className={styled.ColorItem} style={{ backgroundColor: color.color }} onMouseDown={e => handleSelect(e, color.color)} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ColorButton;
