import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from './EditMobileRevision.module.css';
import { REVISION_UNFINISHED } from '../../../../constants/Routes';
import useEditRevision from '../../../../hooks/useEditRevision';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import MobileTable from '../../../../components/Mobile/Table/MobileTable';
import { Column } from 'react-table';
import i18n from 'i18next';
import RevisionSubscriptionModel from '../../../../models/RevisionSubscriptionModel';
import OngoingRevisionIcon from '../../../../assets/images/ongoingRevisionIcon.svg';
import MobileLawCell from '../../../../components/Mobile/Table/MobileLawCell/MobileLawCell';
import RevisionModel from '../../../../models/RevisionModel';
import CustomLawRevisionModel from '../../../../models/CustomLawRevisionModel';
import LawTypeFilter from '../../../../models/LawTypeFilter';
import LawListGroupFilter from '../../../../models/LawListGroupFilter';
import KeywordModel from '../../../../models/KeywordModel';
import EditRevisionFullView from '../../../../components/FullView/EditRevisionFullView/EditRevisionFullView';
import _ from 'lodash';
import RevisionEditor from '../../../../components/RevisionEditor/RevisionEditor';
import { RevisionObjStatus } from '../../../../models/RevisionObjStatus';
import backArrow from '../../../../assets/images/backIcon.svg';

interface State {
    loading: boolean;
    revision?: RevisionModel;
    data: (RevisionSubscriptionModel | CustomLawRevisionModel)[];
    filteredData: State['data'];
    lawTypeFilter: LawTypeFilter[];
    lawListGroupFilter: LawListGroupFilter[];
    selectedKeywords: (KeywordModel & { checked: boolean })[];
    customLawsSelected: boolean;
    hasCustomLaws: boolean;
    globalSearch: string;
    showColumnSearch: boolean;
    columnSearch: {
        id: string;
        value: string | undefined;
    }[];
    customFilterActive: boolean;
    columns: Array<Column<RevisionSubscriptionModel | CustomLawRevisionModel> & { Header: string; languageId?: string; id: string; visible: boolean; alwaysVisible?: boolean }>;
    totalCompleted: number;
    selectedIndex: number;
    visibleDataCount: number;
}
const EditMobileRevision = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { revisionId } = useParams();
    const { loading, fetchData, revision, handleSelection, selectedIndex, filteredData, handleUpdateSubscriptionRevisionStatus } = useEditRevision();
    const initialEdit = { key: '', title: '', value: '' };
    const auditName = revision?.name;
    const [editRevisionOpen, setEditRevisionOpen] = useState(false);
    const [closeRevisionOpen, setCloseRevisionOpen] = useState(false);
    const [edit, setEdit] = useState(initialEdit);
    const [editRevisionWarningModal, setEditRevisionWarningModal] = useState<{ open: boolean; edit: any }>({ open: false, edit: undefined });

    const columns = useMemo(() => {
        const columns: Array<Column<RevisionSubscriptionModel> & { Header: string; languageId?: string }> = [
            {
                id: 'lawsCellHeader',
                Header: t('laws'),
                languageId: 'law',
                accessor: 'string',
                Cell: MobileLawCell,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.original.name.toLowerCase();
                    const lawB = rowB.original.name.toLowerCase();
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
            },
        ];

        return columns;
    }, [i18n.language]);

    useEffect(() => {
        if (revisionId) {
            fetchData(+revisionId);
        } else {
            history.push(REVISION_UNFINISHED);
        }
    }, [revisionId]);

    useEffect(() => {
        if (selectedIndex < 0) {
            setEditRevisionOpen(false);
            setCloseRevisionOpen(false);
            setEdit(initialEdit);
        }
    }, [selectedIndex]);

    const openLaw = (selectedLawIndex: number) => {
        return {
            onClick: () => {
                handleSelection(selectedLawIndex);
            },
        };
    };
    const handleConfirmRevisionSubscription = (comment: string, status: RevisionObjStatus): void => {
        handleUpdateSubscriptionRevisionStatus(comment, status, () => {
            setEditRevisionOpen(false);
            handleSelection(-1);
        });
    };

    return (
        <>
            <p className={styled.backButton} onClick={history.goBack}>
                <img className={styled.backImage} src={backArrow} />
                {t('back')}
            </p>
            <div className={styled.Header}>
                <img src={OngoingRevisionIcon} alt="ramboll" width="50px" />
                <span className={styled.Title}>{auditName}</span>
            </div>
            {loading && <LoadingSpinner />}
            <MobileTable columns={columns} data={filteredData} onSelectLaw={openLaw}></MobileTable>
            <EditRevisionFullView
                finished={revision?.finished}
                revisionOk={revision?.revisionOk}
                open={selectedIndex > -1}
                data={filteredData[selectedIndex]}
                onFooterAction={() => {
                    setEditRevisionOpen(true);
                }}
                hideFooter={editRevisionOpen || edit.key.length > 0}
                onEdit={(key, title) => {
                    setEditRevisionWarningModal({
                        open: true,
                        edit: {
                            key,
                            title,
                            value: _.get(filteredData[selectedIndex], key),
                        },
                    });
                }}
                onClose={() => {
                    setEdit(initialEdit);
                    setEditRevisionOpen(false);
                    handleSelection(-1);
                }}
            />
            {editRevisionOpen && selectedIndex > -1 && (
                <RevisionEditor
                    finished={revision?.finished}
                    initialComment={filteredData[selectedIndex].comments[0]}
                    initialStatus={filteredData[selectedIndex].revisionStatus}
                    onCancel={() => {
                        setEditRevisionOpen(false);
                    }}
                    onConfirm={handleConfirmRevisionSubscription}
                    loading={loading}
                    selectedSub={filteredData[selectedIndex]}
                />
            )}
        </>
    );
};

export default EditMobileRevision;
