import React, { InputHTMLAttributes, ChangeEvent } from 'react';
import styled from './Toggle.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    title?: string;
    checked: boolean;
    className?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Toggle: React.FC<Props> = ({ title, checked, className, onChange }) => {
    return (
        <div className={[styled.Toggle, className ? className : ''].join(' ')}>
            <label className={styled.Label}>
                <input type="checkbox" checked={checked} onChange={onChange} />
                <span className={styled.Slider}></span>
            </label>
            <div className={styled.Title}>{title}</div>
        </div>
    );
};

export default Toggle;
