import React, { ChangeEvent } from 'react';
import styled from './Search.module.css';
import searchIcon from '../../assets/images/searchIcon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    value: string;
    onChange: (value: string) => void;
}

const Search: React.FC<Props> = ({ value = '', onChange }) => {
    const { t } = useTranslation();

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        onChange(value);
    };

    return (
        <div className={styled.Search}>
            <img src={searchIcon} alt="Search" className={styled.Icon} />
            <input type="text" className={styled.Input} placeholder={t('filterbarSearchPlaceholder')} value={value} onChange={handleChange} />
        </div>
    );
};

export default Search;
