import React, { createContext, useState, SetStateAction, Dispatch, FC, useEffect, useMemo } from 'react';
import SubscriptionModel, { isSubscription } from '../models/SubscriptionModel';
import LawTypeFilter from '../models/LawTypeFilter';
import LawListGroupFilter from '../models/LawListGroupFilter';
import KeywordModel from '../models/KeywordModel';
import useAuth from '../hooks/useAuth';
import _ from 'lodash';
import KeywordCell from '../components/Table/KeywordCell/KeywordCell';
import { CellProps, Column, Row, SortingRule } from 'react-table';
import LawCell from '../components/Table/LawCell/LawCell';
import CustomLawModel from '../models/CustomLawModel';
import RevisionDateCell from '../components/Table/RevisionDateCell/RevisionDateCell';
import { useTranslation } from 'react-i18next';
import RevisionQuestionsCell from '../components/Table/RevisionQuestionsCell/RevisionQuestionsCell';
import { columnKeys } from '../constants/ExcelColumnWidth';
import { UNGROUPED_LAWLIST_NAME } from '../models/LawListModel';

export interface State {
    data: (SubscriptionModel | CustomLawModel)[];
    filteredData: State['data'];
    visibleDataCount: number;
    hasCustomLaws: boolean;
    selectedIndex: number;

    lawTypeFilter: LawTypeFilter[];
    lawListGroupFilter: LawListGroupFilter[];
    customLawsSelected: boolean;

    customFilterActive: boolean;

    selectedKeywords: (KeywordModel & { checked: boolean })[];

    columns: Array<
        Column<SubscriptionModel | CustomLawModel> & {
            Header: string;
            languageId?: string;
            id: columnKeys;
            visible: boolean;
            editable?: boolean;
            field?: string;
            alwaysVisible?: boolean;
            showNotification?: boolean;
        }
    >;
    customColumns: State['columns'];

    sorting: SortingRule<object>[];

    globalSearch: string;

    columnSearch: {
        id: string;
        value: string | undefined;
    }[];
    showColumnSearch: boolean;

    loading: boolean;

    editRevisionQuestionsOpen: boolean;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const intialState: State = {
    filteredData: [],
    data: [],
    visibleDataCount: 0,
    hasCustomLaws: false,

    lawTypeFilter: [],
    lawListGroupFilter: [],
    customLawsSelected: false,

    customFilterActive: false,

    selectedKeywords: [],
    selectedIndex: -1,

    columns: [],
    customColumns: [],

    sorting: [],

    globalSearch: '',

    columnSearch: [],
    showColumnSearch: true,

    loading: false,

    editRevisionQuestionsOpen: false,
};

const LawListContext = createContext<Context>({
    state: intialState,
    setState: () => null,
});

const LawListContextProvider: FC = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { company, getUserSettings, user, signedInAsUser } = useAuth();

    const getRevisionQuestionData = (data: SubscriptionModel | CustomLawModel) => {
        const titleIndexes: number[] = [];
        let revisionQuestions: string[] = [];
        let titleIndex = 0;
        if (isSubscription(data)) {
            revisionQuestions = data.revisionQuestionGroups.flatMap(rqg => {
                const includedRevisionQuestions = rqg.revisionQuestions.filter(rq => !rq.excluded);
                const list = [rqg.title, ...includedRevisionQuestions.map(rq => rq.question)];

                titleIndexes.push(titleIndex);
                titleIndex += list.length;

                return list;
            });
        }

        let customRevisionQuestions = data.customRevisionQuestions.filter(crq => !crq.excluded).map(crq => crq.question);
        if (customRevisionQuestions.length > 0) {
            titleIndexes.push(titleIndex);
            customRevisionQuestions = [t('ownQuestions'), ...customRevisionQuestions];
        }
        return { revisionQuestions, customRevisionQuestions, titleIndexes };
    };

    const columns = useMemo(() => {
        const userSettings = getUserSettings();

        const columns: State['columns'] = [
            {
                id: 'law',
                Header: t('columnLaw'),
                languageId: 'columnLaw',
                accessor: row => `${row.name} ${isSubscription(row) ? row.subId : row.customLawEndDate}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.law;
                    const lawB = rowB.values.law;
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
                visible: true,
                width: 2,
                alwaysVisible: true,
                showNotification: true,
                Cell: LawCell,
            },
            {
                id: 'latestRevisionDate',
                Header: t('columnLatestRevisionDate'),
                languageId: 'columnLatestRevisionDate',
                accessor: row => row.latestRevisionDate || 'Aldrig',
                visible: userSettings ? userSettings.latestRevisionDateColumnVisible : true,
                Cell: RevisionDateCell,
                sortInverted: true,
            },
            {
                id: 'description',
                Header: t('columnDescription'),
                languageId: 'columnDescription',
                accessor: 'description',
                disableSortBy: true,
                visible: userSettings ? userSettings.descColumnVisible : true,
            },
            {
                id: 'text',
                Header: t('columnText'),
                languageId: 'columnText',
                accessor: 'text',
                disableSortBy: true,
                editable: true,
                field: 'text',
                visible: userSettings ? userSettings.bffColumnVisible : true,
            },
        ];

        return columns;
    }, []);

    const customColumns = useMemo(() => {
        const userSettings = getUserSettings();

        const customColumns: State['customColumns'] = [];

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                customColumns.push({
                    id: ('customerText' + i) as columnKeys,
                    accessor: 'customerText' + i,
                    visible: userSettings ? _.get(userSettings, `column${i}Visible`) : true,
                    editable: true,
                    field: 'customerText' + i,
                    Header: title,
                    disableSortBy: true,
                });
            }
        }

        if (company?.hasLawLists) {
            customColumns.push({
                id: 'lawLists',
                visible: userSettings ? userSettings.lawListColumnVisible : true,
                accessor: row => {
                    return row.lawLists.map(lawList => {
                        if (lawList.lawListGroup && lawList.lawListGroup.name !== UNGROUPED_LAWLIST_NAME) {
                            return lawList.lawListGroup.name + ': ' + lawList.name;
                        }
                        return lawList.name;
                    });
                },
                Cell: KeywordCell,
                Header: t('columnLawLists'),
                languageId: 'columnLawLists',
                disableSortBy: true,
                editable: true,
                field: 'lawLists',
            });
        }

        if (company?.hasKeyWords) {
            customColumns.push({
                id: 'keywords',
                visible: userSettings ? userSettings.keywordColumnVisible : true,
                accessor: row => row.keywordIds.map((id: number) => _.find(company.keyWords, kw => kw.id === id)?.text),
                Cell: KeywordCell,
                editable: true,
                field: 'keywordIds',
                Header: t('columnKeywords'),
                languageId: 'columnKeywords',
                disableSortBy: true,
            });
        }

        if (company?.hasRevisionQuestions) {
            customColumns.push({
                id: 'revisionQuestions',
                visible: userSettings ? userSettings.revisionQuestionColumnVisible : false,
                accessor: row => {
                    const data = getRevisionQuestionData(row);
                    return [...data.revisionQuestions, ...data.customRevisionQuestions].join(' ');
                },
                Cell: function revisionQuestionCell(props: any) {
                    const data = getRevisionQuestionData(props.row.original);
                    return <RevisionQuestionsCell {...{ ...props, cell: { ...props.cell, value: data } }} />;
                },
                editable: true,
                field: 'revisionQuestions',
                Header: t('columnRevisionQuestions'),
                languageId: 'columnRevisionQuestions',
                disableSortBy: true,
            });
        }

        return customColumns;
    }, []);

    const [state, setState] = useState<State>({ ...intialState, customColumns, columns });

    useEffect(() => {
        setState(s => ({
            ...s,
            columns: s.columns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
            customColumns: s.customColumns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        }));
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        setState(s => {
            const { lawTypeFilter, customLawsSelected, lawListGroupFilter, selectedKeywords, data } = s;
            // Apply lawGroup filtering
            const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
            let filteredData = data.filter(obj => {
                if (isSubscription(obj)) {
                    return selectedLawGroupIds.includes(obj.lawGroupId);
                } else {
                    return customLawsSelected;
                }
            });

            // Apply lawList filtering
            const selectedLawListIds = lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId));
            if (selectedLawListIds.length) {
                filteredData = filteredData.filter(f => selectedLawListIds.some(id => f.lawLists.find(lawList => lawList.lawListId === id)));
            }

            // Apply keyword filtering
            const selectedKeywordIds = selectedKeywords.filter(kw => kw.checked).map(kw => kw.id);
            if (selectedKeywordIds.length) {
                filteredData = filteredData.filter(f => selectedKeywordIds.some(id => f.keywordIds.includes(id)));
            }

            return {
                ...s,
                filteredData,
            };
        });
    }, [state.data, state.lawTypeFilter, state.lawListGroupFilter, state.selectedKeywords, state.customLawsSelected]);

    return <LawListContext.Provider value={{ state, setState }}>{children}</LawListContext.Provider>;
};

export { LawListContext, LawListContextProvider };
