import React, { FC, ComponentType } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { CONTRACT, HARDLOCK, HOME, LOGIN } from '../../constants/Routes';
import useStorage from '../../hooks/useStorage';
import useAuth from '../../hooks/useAuth';

interface Props extends RouteProps {
    component: ComponentType<any>;
}

const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
    const { getToken } = useStorage();
    const { needsContractSignature, isSuperuser } = useAuth();
    return (
        <Route
            {...rest}
            render={props => {
                if (getToken()) {
                    if (!props.location.key) {
                        return <Redirect to="/" />;
                    } else if (needsContractSignature() && isSuperuser() && props.location.pathname !== CONTRACT) {
                        return <Redirect to={{ pathname: CONTRACT, state: { from: props.location } }} />;
                    } else if (needsContractSignature() && !isSuperuser() && props.location.pathname !== HARDLOCK) {
                        return <Redirect to={{ pathname: HARDLOCK, state: { from: props.location } }} />;
                    } else if ((props.location.pathname == CONTRACT || props.location.pathname == HARDLOCK) && !needsContractSignature()) {
                        return <Redirect to={{ pathname: HOME, state: { from: props.location } }} />;
                    } else {
                        return <Component {...props} />;
                    }
                } else {
                    return <Redirect to={{ pathname: LOGIN, state: { from: props.location } }} />;
                }
            }}
        />
    );
};

export default PrivateRoute;
