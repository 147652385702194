import React, { FC, ComponentType } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { HOME, RESETPASSWORD } from '../../constants/Routes';
import useStorage from '../../hooks/useStorage';

interface Props extends RouteProps {
    component: ComponentType<any>;
}

const PublicRoute: FC<Props> = ({ component: Component, ...rest }) => {
    const { getToken } = useStorage();
    return (
        <Route
            {...rest}
            render={props => {
                // Whitelist for public routes.
                if (props.location.pathname.startsWith(RESETPASSWORD)) {
                    return <Component {...props} />;
                }

                if (!props.location.key) {
                    // Rewrites the url to "/"
                    return <Redirect to="/" />;
                }

                return !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: HOME }} />;
            }}
        />
    );
};

export default PublicRoute;
