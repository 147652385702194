import React, { FC, useState } from 'react';
import styled from './InvoiceButton.module.css';
import accordionIcon from '../../../assets/images/accordionIcon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    invoiced: boolean;
    disabled?: boolean;
    onChange: (() => void) | undefined;
    title?: string;
}

const InvoiceButton: FC<Props> = ({ invoiced, disabled, onChange, title }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleSelect = () => {
        if (!disabled && onChange) {
            onChange();
            setOpen(false);
        }
    };

    const handleOpen = (open: boolean): void => {
        if (!disabled && onChange) {
            setOpen(open);
        }
    };

    return (
        <div className={[styled.InvoiceButton, open ? styled.Open : ''].join(' ')} title={title}>
            <div className={[styled.Header, invoiced ? styled.InvoiceButton : '', disabled ? styled.Disabled : ''].join(' ')} onClick={() => handleOpen(!open)}>
                <div>{invoiced ? t('buttonInvoiced') : t('buttonNotInvoiced')}</div>
                {onChange && <img src={accordionIcon} alt="Accordion" />}
            </div>
            <div className={styled.List} onClick={handleSelect}>
                {invoiced ? t('buttonNotInvoiced') : t('buttonInvoiced')}
            </div>
        </div>
    );
};

export default InvoiceButton;
