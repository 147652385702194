import React, { createContext, useState, SetStateAction, Dispatch, FC, useEffect } from 'react';
import LawTypeFilter from '../models/LawTypeFilter';
import LawListGroupFilter from '../models/LawListGroupFilter';
import KeywordModel from '../models/KeywordModel';
import { Column } from 'react-table';
import LawCell from '../components/Table/LawCell/LawCell';
import useAuth from '../hooks/useAuth';
import _ from 'lodash';
import KeywordCell from '../components/Table/KeywordCell/KeywordCell';
import RevisionSubscriptionModel, { isRevisionSubscription } from '../models/RevisionSubscriptionModel';
import CustomLawRevisionModel from '../models/CustomLawRevisionModel';
import { RevisionObjStatusEnum, sortByRevisionStatus, sortFinsihedRevisionByRevisionStatus } from '../models/RevisionObjStatus';
import RevisionModel from '../models/RevisionModel';
import { useTranslation } from 'react-i18next';
import photoIcon from '../assets/images/photoIcon.svg';

interface State {
    loading: boolean;
    revision?: RevisionModel;
    data: (RevisionSubscriptionModel | CustomLawRevisionModel)[];
    filteredData: State['data'];
    lawTypeFilter: LawTypeFilter[];
    lawListGroupFilter: LawListGroupFilter[];
    selectedKeywords: (KeywordModel & { checked: boolean })[];
    customLawsSelected: boolean;
    hasCustomLaws: boolean;
    globalSearch: string;
    showColumnSearch: boolean;
    columnSearch: {
        id: string;
        value: string | undefined;
    }[];
    customFilterActive: boolean;
    columns: Array<Column<RevisionSubscriptionModel | CustomLawRevisionModel> & { Header: string; languageId?: string; id: string; visible: boolean; alwaysVisible?: boolean }>;
    totalCompleted: number;
    selectedIndex: number;
    visibleDataCount: number;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const initialState: State = {
    loading: false,
    revision: undefined,
    data: [],
    filteredData: [],
    lawTypeFilter: [],
    lawListGroupFilter: [],
    selectedKeywords: [],
    customLawsSelected: true,
    hasCustomLaws: false,
    globalSearch: '',
    showColumnSearch: true,
    columnSearch: [],
    customFilterActive: false,
    columns: [],
    totalCompleted: 0,
    selectedIndex: -1,
    visibleDataCount: 0,
};

const EditRevisionContext = createContext<Context>({
    state: initialState,
    setState: () => undefined,
});

const EditRevisionContextProvider: FC = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { company, user, signedInAsUser, getUserSettings } = useAuth();

    const [state, setState] = useState<State>({ ...initialState });

    useEffect(() => {
        setState(s => {
            const columns: State['columns'] = [
                {
                    id: 'law',
                    Header: t('columnLaw'),
                    languageId: 'columnLaw',
                    accessor: row => `${row.name} ${isRevisionSubscription(row) ? row.subId : row.customLawEndDate}`,
                    visible: true,
                    width: 2,
                    alwaysVisible: true,
                    disableSortBy: true,
                    Cell: LawCell,
                },
                {
                    id: 'status',
                    Header: t('columnStatus'),
                    languageId: 'columnStatus',
                    width: 0.5,
                    accessor: x => t(RevisionObjStatusEnum[x.revisionStatus]),
                    disableSortBy: true,
                    visible: true,
                    alwaysVisible: true,
                },
                {
                    id: 'media',
                    Header: 'Media',
                    languageId: 'columnStatus',
                    accessor: x => {
                        if (x.mediaAttachments?.length > 0) {
                            return photoIcon;
                        }
                    },
                    disableSortBy: false,
                    visible: true,
                    alwaysVisible: true,
                    width: 0.3,
                    disableFilters: true,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell: { value } }) => <img src={value} />,
                },
                {
                    id: 'comment',
                    Header: t('columnComment'),
                    languageId: 'columnComment',
                    accessor: row =>
                        row.comments && row.comments.length
                            ? row.comments.sort((a, b) =>
                                  s.revision?.finished ? Date.parse(b.commentDate) - Date.parse(a.commentDate) : Date.parse(a.commentDate) - Date.parse(b.commentDate),
                              )[0].comment
                            : '',
                    disableSortBy: true,
                    visible: true,
                    alwaysVisible: true,
                },
                {
                    id: 'text',
                    Header: t('columnText'),
                    languageId: 'columnText',
                    accessor: 'text',
                    disableSortBy: true,
                    visible: false,
                },
                {
                    id: 'description',
                    Header: t('columnDescription'),
                    languageId: 'columnDescription',
                    accessor: 'description',
                    disableSortBy: true,
                    visible: false,
                },
            ];

            for (let i = 1; i <= 5; i++) {
                const title = _.get(company, 'customHeaderName' + i);
                if (!_.isEmpty(title)) {
                    columns.push({
                        id: 'customerText' + i,
                        accessor: 'customerText' + i,
                        visible: false,
                        Header: title,
                        disableSortBy: true,
                    });
                }
            }

            if (company?.hasLawLists) {
                columns.push({
                    id: 'lawLists',
                    visible: false,
                    accessor: row => (row.lawLists ? row.lawLists.map(lawList => (lawList.lawListGroup ? lawList.lawListGroup.name + ': ' : '') + lawList.name) : ''),
                    Cell: KeywordCell,
                    Header: t('columnLawLists'),
                    languageId: 'columnLawLists',
                    disableSortBy: true,
                });
            }

            if (company?.hasKeyWords) {
                columns.push({
                    id: 'keywords',
                    visible: false,
                    accessor: row => row.keywordIds.map((id: number) => _.find(company.keyWords, kw => kw.id === id)?.text),
                    Cell: KeywordCell,
                    Header: t('columnKeywords'),
                    languageId: 'columnKeywords',
                    disableSortBy: true,
                });
            }

            return {
                ...s,
                columns,
            };
        });
    }, [state.revision?.finished]);

    useEffect(() => {
        setState(s => ({
            ...s,
            columns: s.columns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        }));
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        setState(s => {
            const { lawTypeFilter, customLawsSelected, lawListGroupFilter, selectedKeywords, data, revision } = s;

            // Apply lawGroup filtering
            const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
            let filteredData = data.filter(obj => {
                if (isRevisionSubscription(obj)) {
                    return selectedLawGroupIds.includes(obj.lawGroupId);
                } else {
                    return customLawsSelected;
                }
            });

            // Apply lawList filtering
            const selectedLawListIds = lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId));
            if (selectedLawListIds.length) {
                filteredData = filteredData.filter(f => selectedLawListIds.some(id => f.lawLists.find(lawList => lawList.lawListId === id)));
            }

            // Apply keyword filtering
            const selectedKeywordIds = selectedKeywords.filter(kw => kw.checked).map(kw => kw.id);
            if (selectedKeywordIds.length) {
                filteredData = filteredData.filter(f => selectedKeywordIds.some(id => f.keywordIds.includes(id)));
            }

            if (revision?.finished) {
                filteredData = filteredData.sort((a, b) => sortFinsihedRevisionByRevisionStatus(a.revisionStatus, b.revisionStatus));
            } else {
                filteredData = filteredData.sort((a, b) => sortByRevisionStatus(a.revisionStatus, b.revisionStatus));
            }

            return {
                ...s,
                filteredData,
            };
        });
    }, [state.data, state.lawTypeFilter, state.lawListGroupFilter, state.selectedKeywords, state.customLawsSelected]);

    return <EditRevisionContext.Provider value={{ state, setState }}>{children}</EditRevisionContext.Provider>;
};

export { EditRevisionContext, EditRevisionContextProvider };
