import React, { FC } from 'react';
import useAuth from '../../hooks/useAuth';
import styled from './StatusCard.module.css';
import statusOkIcon from '../../assets/images/statusOkIcon.svg';
import statusNotOkIcon from '../../assets/images/statusNotOkIcon.svg';
import statusWarningIcon from '../../assets/images/statusWarningIcon.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DashboardStatus, { getStatusTypeRoute } from '../../models/DashboardStatus';

const images: { [status: string]: string } = {
    OK: statusOkIcon,
    LOW_WARNING: statusWarningIcon,
    HIGH_WARNING: statusNotOkIcon,
};

interface Props {
    data: DashboardStatus[];
}

const StatusCard: FC<Props> = ({ data }) => {
    const { user } = useAuth();
    const { t } = useTranslation();

    return (
        <div className={styled.NewsFeed}>
            <div className={styled.Header}>{user && <h4>{t('dashboardStatusTitle', { firstName: user.firstName })}</h4>}</div>
            {data.length > 0 && (
                <div className={styled.Content}>
                    {data.map(obj => (
                        <div className={styled.Status} key={obj.titleTextKey}>
                            <img src={images[obj.warningLevel]} />
                            <Link
                                to={{
                                    pathname: `${getStatusTypeRoute(obj.statusType)}`,
                                    state: { linkFromLandingPage: true },
                                }}
                            >
                                <strong>{t(obj.titleTextKey)}:</strong> {t(obj.textKey, { placeholderValue: obj.placeholderValue })}
                            </Link>
                        </div>
                    ))}
                </div>
            )}
            {data.length == 0 && (
                <div className={styled.Content}>
                    <div className={styled.EmptyContentText}>
                        <img src={statusOkIcon} alt="OK" />
                        <h4>{t('dashboardStatusTextNoWorkToDo')}</h4>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatusCard;
