import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getApi, getAuthApi, getAuthApiForPasswordChange } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import useStorage from '../hooks/useStorage';
import UserModelChangePassword from '../models/UserModelChangePassword';

const PasswordService = () => {
    const axiosInstance = getApi();
    const axiosInstanceAuth = getAuthApi();
    const axiosInstanceAuthPassword = getAuthApiForPasswordChange();

    const { getToken, setToken, clearStorage } = useStorage();

    function postForgotPasswordEmail(username: string): Promise<AxiosResponse> {
        return axiosInstance
            .put(`/forgotpass?username=${username}`)
            .then((response: AxiosResponse) => {
                console.info(response);
                return response;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                console.error(error);
                throw error;
            });
    }

    function process(code: string): Promise<UserModelChangePassword> {
        clearStorage();
        return axiosInstance
            .get<UserModelChangePassword>(`/forgotpass/process?uuid=${code}`)
            .then((response: AxiosResponse<UserModelChangePassword>) => {
                setToken(response.headers.authorization);
                console.info(response);
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                console.error(error);
                throw error;
            });
    }

    function changePassword(changePasswordModel: UserModelChangePassword): Promise<string> {
        return axiosInstanceAuthPassword
            .post<UserModelChangePassword>(`/password/change`, changePasswordModel)
            .then((response: AxiosResponse) => {
                console.info(response);
                clearStorage();
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                console.error(error);
                clearStorage();
                throw error;
            });
    }

    return {
        postForgotPasswordEmail,
        process,
        changePassword,
    };
};

export default PasswordService;
