import React, { FC, useState, useEffect, useMemo } from 'react';
import UserModel from '../../models/UserModel';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import styled from './CompanyUsersModal.module.css';
import Dropdown from '../Dropdown/Dropdown';
import _ from 'lodash';
import useAuth from '../../hooks/useAuth';
import { RoleEnum } from '../../models/Role';
import { useTranslation } from 'react-i18next';

interface Props {
    companyName: string;
    companyUsers: UserModel[];
    onSubmit: (userId: number) => void;
    onClose: () => void;
}

const CompanyUsersModal: FC<Props> = ({ companyName, companyUsers, onSubmit, onClose }) => {
    const { t } = useTranslation();
    const { getHighestRole } = useAuth();
    const [content, setContent] = useState<{ id: number; text: string }[]>([]);
    const [selectedId, setSelectedId] = useState(0);

    const title = useMemo(() => (selectedId > -1 ? _.find(content, c => c.id === selectedId)?.text : undefined), [content, selectedId]);

    useEffect(() => {
        setContent([
            {
                id: 0,
                text: t('noFilter'),
            },
            ...companyUsers.map(u => ({
                id: u.userId,
                text: `${u.fullName} (${t(RoleEnum[getHighestRole(u.roles)])})`,
            })),
        ]);
    }, []);

    return (
        <Modal title={companyName} subtitle={t('companyLoginModalTitle')} className={styled.CompanyUsersModal}>
            <div className={styled.Content}>
                <div className={styled.Dropdown}>
                    <Dropdown title={title || t('inputFieldSelectUser').toString()} content={content} onChange={setSelectedId} />
                </div>
                <div className={styled.Buttons}>
                    <Button variant="Outline" onClick={onClose}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" disabled={!(selectedId > -1)} onClick={() => onSubmit(selectedId)}>
                        {t('buttonLogin')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CompanyUsersModal;
