import React, { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import sv from 'date-fns/locale/sv';

import 'react-datepicker/dist/react-datepicker.css';
import styled from './DateRangePicker.module.css';

interface Props {
    startDate: Date;
    onStartDateChange: (date: Date) => void;
    endDate: Date;
    onEndDateChange: (date: Date) => void;
}

const DateRangePicker: FC<Props> = ({ startDate, onStartDateChange, endDate, onEndDateChange }) => {
    return (
        <div className={styled.DateRangePicker}>
            <ReactDatePicker
                selected={startDate}
                onChange={onStartDateChange}
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                placeholderText="Från"
                className={styled.DatePicker}
                dateFormat="yyyy-MM-dd"
                locale={sv}
                selectsStart
                showDisabledMonthNavigation
            />
            <div className={styled.Separator}>-</div>
            <ReactDatePicker
                selected={endDate}
                onChange={onEndDateChange}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="Till"
                className={styled.DatePicker}
                dateFormat="yyyy-MM-dd"
                locale={sv}
                selectsEnd
                showDisabledMonthNavigation
            />
        </div>
    );
};

export default DateRangePicker;
