import React, { FC, useState } from 'react';
import Modal from '../Modal';
import styled from './CreateRevisionConfirmModal.module.css';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

interface Props {
    title: string;
    selectedCount: number;
    username: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const CreateRevisionConfirmModal: FC<Props> = ({ title, selectedCount, username, onCancel, onConfirm }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const handleOnConfirm = () => {
        setLoading(true);
        onConfirm();
    };

    return (
        <Modal title={title} className={styled.Modal}>
            {loading && <LoadingSpinner message={t('spinnerCreateRevisionLoading')} />}
            <div className={styled.Content}>
                <p>{t('createRevisionConfirmModalResponsible', { responsible: username })}</p>
                <p>{t('createRevisionConfirmModalSelectedCount', { selectedCount })}</p>

                <p>{t('createRevisionConfirmModalText')}</p>

                <div className={styled.Options}>
                    <Button variant="Primary" onClick={onCancel}>
                        {t('buttonNo')}
                    </Button>
                    <Button variant="Outline" onClick={handleOnConfirm} disabled={loading}>
                        {t('buttonYes')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateRevisionConfirmModal;
