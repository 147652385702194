import React, { FC } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './RemoveReaderLoginModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    onCancel: () => void;
    onConfirm: () => void;
}

const RemoveReaderLoginModal: FC<Props> = ({ onCancel, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('removeReaderLoginModalTitle')} subtitle={t('removeReaderLoginModalSubTitle')} className={styled.Modal}>
            <Button variant="Primary" onClick={onCancel}>
                {t('buttonCancel')}
            </Button>
            <Button variant="Outline" onClick={onConfirm}>
                Ok
            </Button>
        </Modal>
    );
};

export default RemoveReaderLoginModal;
