export type CompanyDocumentType = 'CURRENT_AGREEMENT' | 'TENDER' | 'DOCUMENT';

export enum CompanyDocumentTypeEnum {
    'CURRENT_AGREEMENT' = 'CURRENT_AGREEMENT',
    'TENDER' = 'TENDER',
    'DOCUMENT' = 'DOCUMENT',
}

export enum CompanyDocumentTypeTranslationEnum {
    'CURRENT_AGREEMENT' = 'documentTypeCurrentAgreement',
    'TENDER' = 'documentTypeTender',
    'DOCUMENT' = 'documentTypeDocument',
}

export const getDocumentTypesTranslationKey = (type: CompanyDocumentTypeEnum): string => {
    switch (type) {
        case CompanyDocumentTypeEnum.CURRENT_AGREEMENT: {
            return CompanyDocumentTypeTranslationEnum.CURRENT_AGREEMENT;
        }
        case CompanyDocumentTypeEnum.TENDER: {
            return CompanyDocumentTypeTranslationEnum.TENDER;
        }
        case CompanyDocumentTypeEnum.DOCUMENT: {
            return CompanyDocumentTypeTranslationEnum.DOCUMENT;
        }
    }
};

export const getDocumentTypesTranslationKeyWithString = (type: string): string => {
    switch (type) {
        case 'CURRENT_AGREEMENT': {
            return CompanyDocumentTypeTranslationEnum.CURRENT_AGREEMENT;
        }
        case 'TENDER': {
            return CompanyDocumentTypeTranslationEnum.TENDER;
        }
        case 'DOCUMENT': {
            return CompanyDocumentTypeTranslationEnum.DOCUMENT;
        }
        default: {
            return '';
        }
    }
};

export const getDocumentTypes = (): CompanyDocumentTypeEnum[] => {
    const types = [];
    types.push(CompanyDocumentTypeEnum.CURRENT_AGREEMENT);
    types.push(CompanyDocumentTypeEnum.TENDER);
    types.push(CompanyDocumentTypeEnum.DOCUMENT);
    return types;
};
