import { AxiosError, AxiosResponse } from 'axios';
import ApiErrorModel from '../models/ApiErrorModel';
import BannerModel from '../models/BannerModel';
import NewsFeedModel from '../models/NewsFeedModel';
import { getAuthApi } from '../config/axios.config';
import DashboardStatus from '../models/DashboardStatus';

const HomeService = () => {
    const axiosInstance = getAuthApi();

    function getStartBanner(): Promise<BannerModel> {
        return axiosInstance
            .get<BannerModel>('/home/banner')
            .then((response: AxiosResponse<BannerModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getNewsFeed(): Promise<NewsFeedModel[]> {
        return axiosInstance
            .get<NewsFeedModel[]>('/home/news')
            .then((response: AxiosResponse<NewsFeedModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getDashboardStatuses(companyId: number): Promise<DashboardStatus[]> {
        return axiosInstance
            .get<DashboardStatus[]>(`/home/statuslist?companyId=${companyId}`)
            .then((response: AxiosResponse<DashboardStatus[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeNewsFeed(id: number): Promise<AxiosResponse> {
        return axiosInstance.delete(`/home/news?id=${id}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function updateNewsFeed(newsFeed: NewsFeedModel): Promise<NewsFeedModel> {
        return axiosInstance
            .put<NewsFeedModel>('/home/news', newsFeed)
            .then((response: AxiosResponse<NewsFeedModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateNewsFeedOrder(newsFeedList: NewsFeedModel[]): Promise<NewsFeedModel[]> {
        return axiosInstance
            .put<NewsFeedModel[]>('/home/news/all', newsFeedList)
            .then((response: AxiosResponse<NewsFeedModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function createNewsFeed(newsFeed: NewsFeedModel): Promise<NewsFeedModel> {
        return axiosInstance
            .post<NewsFeedModel>('/home/news', newsFeed)
            .then((response: AxiosResponse<NewsFeedModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getDbTimestamp(): Promise<string> {
        return axiosInstance
            .get<string>('/home/databasetimestamp')
            .then((response: AxiosResponse<string>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getStartBanner,
        getNewsFeed,
        removeNewsFeed,
        updateNewsFeed,
        createNewsFeed,
        getDashboardStatuses,
        updateNewsFeedOrder,
        getDbTimestamp,
    };
};

export default HomeService;
