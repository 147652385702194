import React, { FC } from 'react';
import styled from './InvoiceChangeInvoiceStatusModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
    onAccept: () => void;
    onClose: () => void;
    numberOfCompaniesMarked: number;
}

const InvoiceChangeInvoiceStatusToNotInvoicedModal: FC<Props> = ({ onAccept, onClose, numberOfCompaniesMarked }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('invoicedChangeStatusToNotInvoicedTitle')}
            subtitle={t('invoicedChangeStatusToNotInvoicedText', { numberOfCompaniesMarked: numberOfCompaniesMarked })}
            className={styled.Modal}
        >
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onClose}>
                    {t('buttonNo')}
                </Button>
                <Button variant="Outline" onClick={onAccept}>
                    {t('buttonYes')}
                </Button>
            </div>
        </Modal>
    );
};

export default InvoiceChangeInvoiceStatusToNotInvoicedModal;
