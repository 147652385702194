import { useTable, useSortBy, Column, SortingRule, ColumnInstance } from 'react-table';
import React, { FC } from 'react';
import styled from './MobileTable.module.css';
import { ReactComponent as SortIcon } from '../../../assets/images/accordionIcon.svg';

interface Props {
    columns: Column<any>[];
    data: any[];
    sorting?: SortingRule<object>[];
    onSelectRevision?: (selected: any) => any;
    onSelectLaw?: (selected: any) => any;
}
const MobileTable: React.FC<Props> = ({ columns, data, sorting, onSelectRevision, onSelectLaw }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            disableSortBy: !sorting,
            disableSortRemove: true,
            initialState: {
                sortBy: sorting || undefined,
            },
        },
        ...(sorting ? [useSortBy] : []),
    );

    const getSortingIcon = (col: ColumnInstance) => {
        if (!sorting || !col.canSort) {
            return null;
        }
        if (col.isSorted) {
            return (
                <span className={col.isSortedDesc ? styled.SortDesc : styled.SortAsc}>
                    <SortIcon />
                </span>
            );
        }
    };

    return (
        <div className={styled.TableContainer}>
            <table className={styled.Table} {...getTableProps()}>
                <thead className={styled.TableHead}>
                    {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    key={i}
                                    className={[styled.TH, column.id == 'status' ? styled.StatusCell : ''].join(' ')}
                                    {...(sorting ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps())}
                                >
                                    <div className={[styled.Aligner, column.id === 'lawsCellHeader' ? styled.LawsCellHeader : ''].join(' ')}>
                                        {column.render('Header')}
                                        {getSortingIcon(column)}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                className={styled.Cell}
                                key={i + 'row'}
                                {...row.getRowProps(onSelectRevision ? onSelectRevision(row.original) : onSelectLaw ? onSelectLaw(row.id) : onSelectLaw)}
                            >
                                {row.cells.map(cell => {
                                    return (
                                        <td key={i + 'data'} className={styled.TableData} {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default MobileTable;
