import React, { FC } from 'react';
import styled from './FullViewHeader.module.css';
import Button from '../../../Button/Button';
import { useHistory } from 'react-router-dom';
import { CHANGES_LIST } from '../../../../constants/Routes';

interface Props {
    subscriptionId: number;
    isNew: boolean;
}

const FullViewHeader: FC<Props> = ({ subscriptionId, isNew }) => {
    const history = useHistory();
    return (
        <div className={[styled.FullViewHeader, isNew ? styled.New : styled.Change].join(' ')}>
            <div className={styled.Text}>
                <h6>{isNew ? 'Ny författning' : 'Ändringar'}</h6>
                <p>{isNew ? 'Denna författning ska kvitteras under Ändringar' : 'En nyare utgåva finns att kvittera under Ändringar'}</p>
            </div>
            <Button variant="Outline" onClick={() => history.push(CHANGES_LIST + `/${subscriptionId}`)}>
                Granska
            </Button>
        </div>
    );
};

export default FullViewHeader;
