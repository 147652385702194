import React, { FC } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './RemoveUserModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    userName: string;
    externalUser: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const RemoveUserModal: FC<Props> = ({ userName, externalUser, onCancel, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={externalUser ? t('removeUserModalExternalTitle') : t('removeUserModalTitle')}
            subtitle={externalUser ? t('removeUserModalExternalSubTitle', { userName }) : t('removeUserModalSubTitle', { userName })}
            className={styled.Modal}
        >
            <Button variant="Primary" onClick={onCancel}>
                {t('buttonNo')}
            </Button>
            <Button variant="Outline" onClick={onConfirm}>
                {t('buttonYes')}
            </Button>
        </Modal>
    );
};

export default RemoveUserModal;
