import { useEffect, RefObject } from 'react';

const useClickOutside = (ref: RefObject<HTMLElement | undefined>, callback: Function): void => {
    const handleClick = (e: MouseEvent): void => {
        const target = e.target as HTMLElement;
        if (ref.current && !ref.current.contains(target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return (): void => {
            document.removeEventListener('mousedown', handleClick);
        };
    });
};

export default useClickOutside;
