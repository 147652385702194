import React from 'react';
import styled from './Laws.module.css';
import { Route } from 'react-router-dom';
import LawLists from './LawLists/LawLists';
import LawLibrary from './LawLibrary/LawLibrary';
import { LAWS_LIBRARY, LAWS_LISTS } from '../../constants/Routes';
import { LawListContextProvider } from '../../contexts/LawListContext';
import { LawLibraryContextProvider } from '../../contexts/LawLibraryContext';

const LawListComponent = () => (
    <LawListContextProvider>
        <LawLists />
    </LawListContextProvider>
);

const LawLibraryComponent = () => (
    <LawLibraryContextProvider>
        <LawLibrary />
    </LawLibraryContextProvider>
);

const Laws: React.FC = () => {
    return (
        <div className={styled.Laws}>
            <Route path={LAWS_LISTS} component={LawListComponent} />
            <Route path={LAWS_LIBRARY} component={LawLibraryComponent} />
        </div>
    );
};

export default Laws;
