import { AxiosResponse } from 'axios';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import { LawListContentModel } from './SubscriptionService';
import SubscriptionModel from "../models/SubscriptionModel";

const DetachedCopiesService = () => {
    const axiosInstance = getAuthApi();

    function getSubscriptionsForAddCopy(companyId: number): Promise<LawListContentModel> {
        return axiosInstance
            .get<LawListContentModel>(`/subscription/detachedcopy/subscriptionlist?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListContentModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getDetachedCopiesForRemove(companyId: number): Promise<LawListContentModel> {
        return axiosInstance
            .get<LawListContentModel>(`/subscription/detachedcopy?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListContentModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function createDetachedCopy(subscriptionId: number, lawListIds: number[]): Promise<SubscriptionModel[]> {
        return axiosInstance
            .post<SubscriptionModel[]>(`/subscription/detachedcopy?subscriptionId=${subscriptionId}&lawListIds=${lawListIds}`, undefined)
            .then((response: AxiosResponse<SubscriptionModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function deleteDetachedCopy(subscriptionIds: number[]): Promise<AxiosResponse<void>> {
        return axiosInstance.delete<void>(`/subscription/detachedcopy?subscriptionIds=${subscriptionIds}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function hasDetachedCopies(companyId: number): Promise<boolean> {
        return axiosInstance
            .get<boolean>(`/subscription/detachedcopy/hasDetachedCopies?companyId=${companyId}`)
            .then((response: AxiosResponse<boolean>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getSubscriptionsForAddCopy,
        getDetachedCopiesForRemove,
        createDetachedCopy,
        deleteDetachedCopy,
        hasDetachedCopies,
    };
};

export default DetachedCopiesService;
