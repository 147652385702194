import React, { FC, useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './RevertChangeModal.module.css';
import CompanyService from '../../../services/CompanyService';
import { useTranslation } from 'react-i18next';
import detachedCopy from '../../../assets/images/detachedCopy.svg';

interface Props {
    lawId: number;
    onSubmitRevertAllChange: () => void;
    onSubmitRevertOwnChange: () => void;
    onSubmitToSupport: () => void;
    onClose: () => void;
    hasRevertOwnRights: boolean;
    hasRevertAllRights: boolean;
    isOwnAssessment: boolean;
    subscriptionHasCopies: boolean;
}

const RevertChangeModal: FC<Props> = ({
    lawId,
    hasRevertOwnRights,
    hasRevertAllRights,
    isOwnAssessment,
    subscriptionHasCopies,
    onSubmitRevertAllChange,
    onSubmitRevertOwnChange,
    onSubmitToSupport,
    onClose,
}) => {
    const { t } = useTranslation();
    const [affectedCompanies, setAffectedCompanies] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const { company, isAboveSuperUser } = useAuth();
    const isGroupCompany = company?.type === 'GROUP' && company.corporationId;

    useEffect(() => {
        if (isGroupCompany && isAboveSuperUser() && company?.corporationId) {
            setLoading(true);
            CompanyService()
                .getCompanyNamesThatHasSubscription(lawId, company?.corporationId)
                .then(companies => {
                    setAffectedCompanies(companies);
                    setLoading(false);
                });
        }
    }, []);

    return (
        <Modal title={t('changeArchiveRevertModalTitle')} subtitle={t('changeArchiveRevertModalSubTitle')}>
            {!loading && (
                <div className={styled.Content}>
                    {!loading && subscriptionHasCopies && (
                        <div className={styled.Warning}>
                            <img src={detachedCopy} alt="!" />
                            <p className={styled.SubCopiesWarning}>{t('changeArchiveRevertModalSubscriptionCopiesWarning')}</p>
                        </div>
                    )}
                    {isGroupCompany && hasRevertAllRights && (
                        <div className={styled.CompanyList}>
                            <h4>{t('changeArchiveRevertModalText', { count: affectedCompanies.length })}</h4>
                            <ul>
                                {affectedCompanies.map(affectedCompany => (
                                    <li key={affectedCompany}>{affectedCompany}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {!hasRevertAllRights && !hasRevertOwnRights && (
                        <div className={styled.ButtonRow1}>
                            <Button variant="Primary" onClick={onClose}>
                                {t('buttonNo')}
                            </Button>
                            <Button variant="Outline" onClick={onSubmitToSupport} disabled={loading}>
                                {t('changeArchiveSupportButton')}
                            </Button>
                        </div>
                    )}
                    {hasRevertAllRights && (
                        <div className={styled.ButtonRow2}>
                            <Button variant="Outline" onClick={onSubmitRevertAllChange} disabled={loading}>
                                {t('changeArchiveRevertAllButton')}
                            </Button>
                        </div>
                    )}
                    {hasRevertOwnRights && (
                        <div className={styled.ButtonRow2}>
                            <Button variant="Primary" onClick={onClose}>
                                {t('buttonNo')}
                            </Button>
                            <Button variant="Outline" onClick={onSubmitRevertOwnChange} disabled={loading}>
                                {isOwnAssessment && t('changeArchiveRevertOwnButton')}
                                {!isOwnAssessment && t('changeArchiveRevertOneButton')}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

export default RevertChangeModal;
