import React from 'react';
import styled from './Filterbar.module.css';
import Search from '../Search/Search';
import Dropdown from '../Dropdown/Dropdown';
import Toggle from '../Toggle/Toggle';
import FilterbarMenu from './FilterbarMenu/FilterbarMenu';
import LawListDropdown from '../LawListDropdown/LawListDropdown';
import useAuth from '../../hooks/useAuth';
import { Route } from 'react-router-dom';
import { LAWS_LIBRARY } from '../../constants/Routes';
import KeywordModel from '../../models/KeywordModel';
import LawListGroupFilter from '../../models/LawListGroupFilter';
import { Column } from 'react-table';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import { useTranslation } from 'react-i18next';

interface Props {
    showSubscriptions?: boolean;
    onChangeShowSubscription?: () => void;
    globalSearch: string;
    onGlobalSearchChange: (globalSearch: string) => void;
    customFilterActive?: boolean;
    onCustomFilterToggle?: (() => void) | undefined;
    myChangesFilterActive?: boolean;
    delegatedFilterActive?: boolean;
    onMyChangesToggle?: (() => void) | undefined;
    onDelegatedToggle?: (() => void) | undefined;
    selectedKeywords?: (KeywordModel & { checked: boolean })[];
    onSelectedKeywordChange?: (keywordId: number) => void;
    onSelectAllKeywords?: (checked: boolean) => void;
    showColumnSearch?: boolean;
    onColumnSearchToggle?: () => void;
    lawListGroupFilter?: LawListGroupFilter[];
    onLawListGroupFilterChange?: (lawListGroupId: number) => void;
    onLawListFilterChange?: (lawListId: number) => void;
    onAllLawListChange?: (checked: boolean) => void;
    startDate?: Date;
    onStartDateChange?: (startDate: Date) => void;
    endDate?: Date;
    onEndDateChange?: (startDate: Date) => void;
    columns?: Array<Partial<Column & { visible: boolean }>>;
    onColumnShowChange?: (id: string) => void;
    onSaveUserSettings?: () => void;
    onExport?: () => void;
    onExportRevisionQuestionsExcel?: () => void;
    onAlterRevision?: () => void;
    hideMenu?: boolean;
}

const Filterbar: React.FC<Props> = ({
    showSubscriptions,
    onChangeShowSubscription,
    globalSearch,
    onGlobalSearchChange,
    customFilterActive,
    onCustomFilterToggle,
    myChangesFilterActive,
    onMyChangesToggle,
    selectedKeywords,
    onSelectedKeywordChange,
    onSelectAllKeywords,
    showColumnSearch,
    onColumnSearchToggle,
    lawListGroupFilter,
    onLawListGroupFilterChange,
    onLawListFilterChange,
    onAllLawListChange,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    columns,
    onColumnShowChange,
    onSaveUserSettings,
    onExport,
    onExportRevisionQuestionsExcel,
    onAlterRevision,
    children,
    hideMenu,
    onDelegatedToggle,
    delegatedFilterActive,
}) => {
    const { company, getUserSettings } = useAuth();
    const { t } = useTranslation();

    return (
        <div className={styled.Filterbar}>
            <div className={styled.FlexWrapper}>
                <div className={styled.Search}>
                    <Search value={globalSearch} onChange={onGlobalSearchChange} />
                </div>
                {((company?.hasLawLists && lawListGroupFilter && onLawListFilterChange && onLawListGroupFilterChange) ||
                    (company?.hasKeyWords && selectedKeywords && onSelectedKeywordChange)) && (
                    <div className={styled.Dropdowns}>
                        {company?.hasLawLists && lawListGroupFilter && onLawListFilterChange && onLawListGroupFilterChange && (
                            <LawListDropdown
                                title={t('filterbarLawLists')}
                                lawListGroupFilter={lawListGroupFilter}
                                onLawListGroupFilterChange={onLawListGroupFilterChange}
                                onLawListFilterChange={onLawListFilterChange}
                                onAllChange={onAllLawListChange}
                            />
                        )}
                        {company?.hasKeyWords && selectedKeywords && onSelectedKeywordChange && (
                            <Dropdown
                                title={t('filterbarKeywords').toString()}
                                content={selectedKeywords}
                                onChange={onSelectedKeywordChange}
                                onAllChange={onSelectAllKeywords}
                                multiSelect
                            />
                        )}
                    </div>
                )}
                {startDate && onStartDateChange && endDate && onEndDateChange && (
                    <DateRangePicker startDate={startDate} onStartDateChange={onStartDateChange} endDate={endDate} onEndDateChange={onEndDateChange} />
                )}
            </div>

            <div className={styled.Options}>
                {children && children}

                {delegatedFilterActive !== undefined && onDelegatedToggle && getUserSettings() && (
                    <Toggle title={t('filterbarDelegatedUsers')} checked={delegatedFilterActive} onChange={onDelegatedToggle} />
                )}

                {myChangesFilterActive !== undefined && onMyChangesToggle && getUserSettings() && (
                    <Toggle title={t('filterbarMyChangesFilter')} checked={myChangesFilterActive} onChange={onMyChangesToggle} />
                )}

                {customFilterActive !== undefined && onCustomFilterToggle && getUserSettings() && (
                    <Toggle title={t('filterbarPersonalFilter')} checked={customFilterActive} onChange={onCustomFilterToggle} />
                )}

                <Route path={LAWS_LIBRARY}>
                    {showSubscriptions !== undefined && onChangeShowSubscription && (
                        <Toggle title={t('filterbarMonitored')} checked={showSubscriptions} onChange={onChangeShowSubscription} />
                    )}
                </Route>
                {showColumnSearch !== undefined && onColumnSearchToggle && <Toggle title={t('filterbarColumnSearch')} checked={showColumnSearch} onChange={onColumnSearchToggle} />}
            </div>
            {!children && !hideMenu && (
                <FilterbarMenu
                    columns={columns}
                    onColumnShowChange={onColumnShowChange}
                    onSaveUserSettings={onSaveUserSettings}
                    onExport={onExport}
                    onExportRevisionQuestionsExcel={onExportRevisionQuestionsExcel}
                    onAlterRevision={onAlterRevision}
                />
            )}
        </div>
    );
};

export default Filterbar;
