import React from 'react';
import { CellProps } from 'react-table';
import styled from './MobileLawCel.module.css';
import SubscriptionModel, { isSubscription } from '../../../../models/SubscriptionModel';
import CustomLawModel, { isCustomLaw } from '../../../../models/CustomLawModel';
import LawModel from '../../../../models/LawModel';
import RevisionSubscriptionModel, { isRevisionSubscription } from '../../../../models/RevisionSubscriptionModel';
import CustomLawRevisionModel, { isCustomLawRevision } from '../../../../models/CustomLawRevisionModel';
import { useTranslation } from 'react-i18next';
import PhotoIcon from '../../../../assets/images/photoIcon.svg';
import LawStatusIcon from '../../StatusIcon/LawStatusIcon/LawStatusIcon';

const MobileLawCell = (props: CellProps<SubscriptionModel | LawModel | CustomLawModel | RevisionSubscriptionModel | CustomLawRevisionModel>) => {
    const { t } = useTranslation();

    const subscription = props.row.original;

    return (
        <div className={styled.LawCellWrapper}>
            <div className={styled.LawDescription}>{subscription.name}</div>
            {isSubscription(subscription) && subscription.subId && (
                <div className={styled.flexContainer}>
                    <div className={styled.SubId}>
                        {t('lawSubId')} {subscription.subId}
                    </div>
                    <div className={styled.StatusIconContainer}>
                        {LawStatusIcon(subscription.revisionStatus)}
                        {isRevisionSubscription(subscription) && subscription.mediaAttachments?.length > 0 && <img className={styled.StatusIcon} src={PhotoIcon} />}
                    </div>
                </div>
            )}
            {isCustomLaw(subscription) && subscription.customLawId && (
                <div className={styled.flexContainer}>
                    <div className={styled.SubId}>
                        {t('lawSubId')} {subscription.customLawId}
                    </div>
                    <div className={styled.StatusIconContainer}>
                        {LawStatusIcon(subscription.revisionStatus)}
                        {isCustomLawRevision(subscription) && subscription.mediaAttachments?.length > 0 && <img className={styled.StatusIcon} src={PhotoIcon} />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileLawCell;
