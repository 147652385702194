// noinspection DuplicatedCode

import React, { FC } from 'react';
import styled from './MediaInput.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    onChange: (file: File) => void;
    accept?: string | '';
    label?: string;
    subLabel?: string;
    placeholder?: string;
    error?: string;
    className?: string;
    disabled?: boolean;
}

const MediaInput: FC<Props> = ({ onChange, accept, label, subLabel, placeholder, error, className, disabled }) => {
    const { t } = useTranslation();

    const acceptDefault = 'image/x-png,image/gif,image/jpeg';

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            onChange(event.target.files[0]);
        }
    };

    return (
        <div className={[styled.Container, className ? className : ''].join(' ')}>
            {label && <p className={styled.Label}>{label}</p>}
            <div className={styled.InputContainer}>
                <input name="file" id="file" type={'file'} accept={accept || acceptDefault} onChange={handleOnChange} disabled={disabled} className={styled.InputFile} />
                <label htmlFor={'file'}>{t('fileSelect')}</label>
            </div>
        </div>
    );
};

export default MediaInput;
