import { AxiosResponse, AxiosError } from 'axios';
import { getAuthApi } from '../config/axios.config';
import SubscriptionModel from '../models/SubscriptionModel';
import ApiErrorModel from '../models/ApiErrorModel';
import LawTypeModel from '../models/LawTypeModel';
import LawModel from '../models/LawModel';

interface LawLibraryModel {
    monitoredLawsSize: number;
    unmonitoredLawsSize: number;
    lawTypes: LawTypeModel[];
    monitoredLaws: SubscriptionModel[];
    unmonitoredLaws: LawModel[];
}

interface AddRemoveSubscriptionsModel {
    companyId: number;
    subscriptionIdsToRemove: number[];
    lawIdsToAdd: number[];
}

const LawLibraryService = () => {
    const axiosInstance = getAuthApi();

    function getLawLibraryList(companyId: number): Promise<LawLibraryModel> {
        return axiosInstance
            .get<LawLibraryModel>(`/lawlibrary?companyId=${companyId}`)
            .then((response: AxiosResponse<LawLibraryModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function addAndRemoveSubscriptions(model: AddRemoveSubscriptionsModel): Promise<SubscriptionModel[]> {
        return axiosInstance
            .post<{ subscriptionsAdded: SubscriptionModel[] }>('/lawlibrary/subscriptions', model)
            .then((response: AxiosResponse<{ subscriptionsAdded: SubscriptionModel[] }>) => {
                return response.data.subscriptionsAdded;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getLawLibraryList,
        addAndRemoveSubscriptions,
    };
};

export default LawLibraryService;
