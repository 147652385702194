import React from 'react';
import { CellProps } from 'react-table';
import styled from './LawCell.module.css';
import SubscriptionModel, { isSubscription } from '../../../models/SubscriptionModel';
import _ from 'lodash';
import CustomLawModel, { isCustomLaw } from '../../../models/CustomLawModel';
import LawTypeBadge from '../../LawTypeBadge/LawTypeBadge';
import LawModel from '../../../models/LawModel';
import LawCellNotification from './LawCellNotification/LawCellNotification';
import RevisionSubscriptionModel, { isRevisionSubscription } from '../../../models/RevisionSubscriptionModel';
import CustomLawRevisionModel, { isCustomLawRevision } from '../../../models/CustomLawRevisionModel';
import RevisionNotification from './RevisionNotification/RevisionNotification';
import detachedCopy from '../../../assets/images/detachedCopy.svg';
import { useTranslation } from 'react-i18next';

const LawCell = (props: CellProps<SubscriptionModel | LawModel | CustomLawModel | RevisionSubscriptionModel | CustomLawRevisionModel>) => {
    const { t } = useTranslation();

    const values = props.row.original;
    const showNotification = _.get(props.column, 'showNotification');
    const sentFromConsultant = _.get(values, 'sentFromConsultant');

    return (
        <span className={styled.LawCell}>
            {isSubscription(values) && showNotification && <LawCellNotification acceptanceStatus={values.acceptanceStatus} sentFromConsultant={sentFromConsultant} />}
            {(isRevisionSubscription(values) || isCustomLawRevision(values)) && showNotification && <RevisionNotification revisionStatus={values.revisionStatus} />}
            <div className={styled.Title}>{values.name}</div>
            {isCustomLaw(values) && values.customLawEndDate ? (
                <div className={styled.Name}>
                    {t('customLawValidUntil')} <span className={[styled.WarnSpan, values.expired ? styled.Expired : ''].join(' ')}>{values.customLawEndDate}</span>
                </div>
            ) : (
                isSubscription(values) && values.subId && (
                    <div className={styled.Name}>
                        {values.detachedCopy && <img src={detachedCopy} title={t('detachedCopyIconTitle')} />} {t('lawSubId')} {values.subId}
                    </div>
                )
            )}
            <LawTypeBadge type={values.lawType || 'CUSTOM_LAW'} group={values.lawGroupName} />
        </span>
    );
};

export default LawCell;
