import React, { FC, useMemo } from 'react';
import CustomerInvoiceModel from '../../../models/CustomerInvoiceModel';
import styled from './AccordionUserItem.module.css';
import { getDisplayRoles, Role, RoleEnum, sortRoles } from '../../../../models/Role';
import { useTranslation } from 'react-i18next';

interface Props {
    data: CustomerInvoiceModel[];
}

const AccordionUserItem: FC<Props> = ({ data }) => {
    const { t, i18n } = useTranslation();

    const formatRoles = (roleList: Role[]) => {
        return getDisplayRoles(roleList)
            .sort(sortRoles)
            .map(role => t(RoleEnum[role]))
            .join(', ');
    };

    return (
        <>
            {data && data.length
                ? data.map(user => (
                      <div key={user?.userId} className={styled.Container}>
                          <table>
                              <tr className={styled.UpperRow}>
                                  <td className={styled.FullName}>{user?.fullName}</td>
                                  <td>Besök: {user?.loginCounter}</td>
                                  <td className={styled.LastLogin}>Senaste: {user?.lastLogin || 'Aldrig'}</td>
                              </tr>

                              <tr className={styled.LowerRow}>
                                  <td className={styled.Roles}>{formatRoles(user?.roles)}</td>
                              </tr>
                          </table>
                      </div>
                  ))
                : 'Inga externa användare'}
        </>
    );
};

export default AccordionUserItem;
