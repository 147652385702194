import React, { useState, useEffect, FC, useMemo } from 'react';
import styled from './MergeLaws.module.css';

import { useTranslation } from 'react-i18next';
import MergeLawService from '../services/MergeLawService';
import MergeLawLawModel from '../models/MergeLawLawModel';
import Dropdown from '../../components/Dropdown/Dropdown';
import MergeLawCompanyModel from '../models/MergeLawCompanyModel';
import Button from '../../components/Button/Button';
import Toggle from '../../components/Toggle/Toggle';
import ConfirmModal from '../modals/ConfirmModal/ConfirmModal';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import errorIcon from '../../assets/images/errorIcon.svg';
import MergeLawResultModel from '../models/MergeLawResultModel';
import ErrorMessagesModal from '../modals/ErrorMessagesModal/ErrorMessagesModal';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import { MERGE_LAWS } from '../../constants/Routes';
import lawlistIcon from '../../assets/images/lawlistIcon.svg';
import LawTypeFilter, { convertToLawTypeFilter } from '../../models/LawTypeFilter';
import { LawType } from '../../models/LawType';
import LawModel from '../../models/LawModel';

const MergeLaws: FC = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Laddar');
    const [laws, setAllLaws] = useState<LawModel[]>([]);
    const [lawTypeFilter, setLawTypeFilter] = useState<LawTypeFilter[]>([]);

    const [hideSubscriptionsAfterMerge, setHideSubscriptionsAfterMerge] = useState<boolean>(true);
    const [includeDestinationLaw, setIncludeDestinationLaw] = useState<boolean>(false);
    const [selectedLawsIds, setSelectedLawIds] = useState<number[]>([]);
    const [destinationLawId, setDestinationLawId] = useState<number>();

    const [selectedLaws, setSelectedLaws] = useState<LawModel[]>([]);
    const [destinationLaw, setDestinationLaw] = useState<LawModel>();

    const [affectedCompanyIds, setAffectedCompanyIds] = useState<number[]>([]);
    const [affectedCompanies, setAffectedCompanies] = useState<MergeLawCompanyModel[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showErrorMessagesModal, setShowErrorMessagesModal] = useState<boolean>(false);
    const [selectedMergeLawCompanyModel, setSelectedMergeLawCompanyModel] = useState<MergeLawCompanyModel | undefined>(undefined);
    const [showNoAffectedCompaniesInfo, setShowNoAffectedCompaniesInfo] = useState<boolean>(false);

    const CONTEXT_LINKS: {
        title: string;
        url: string;
        icon: string;
        active: boolean;
    }[] = [
        {
            title: 'Lagar',
            url: MERGE_LAWS,
            icon: lawlistIcon,
            active: true,
        },
    ];

    const fetchData = async () => {
        try {
            setLoading(true);

            const { uniqueLawTypes, lawModels } = await MergeLawService().getAllLaws();
            const lawTypeFilter: LawTypeFilter[] = convertToLawTypeFilter(uniqueLawTypes, undefined, undefined, false);
            setAllLaws(lawModels);
            setLawTypeFilter(lawTypeFilter);
        } catch (error) {
            console.log('error');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleLawTypeOpen = (type: LawType): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        );
    };

    const handleLawTypeChecked = (type: LawType): void => {
        setLawTypeFilter(s =>
            s.map(lawType => {
                if (lawType.name === type) {
                    return {
                        ...lawType,
                        lawGroups: lawType.lawGroups.map(lawGroup => ({
                            ...lawGroup,
                            checked: !lawType.lawGroups.every(l => l.checked),
                        })),
                    };
                }
                return lawType;
            }),
        );
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
        );
    };

    const handleLawGroupChecked = (lawGroupId: number): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
        );
    };

    const handleResetAllFilters = () => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: false,
                })),
            })),
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getAffectedCompanies = (hasDestinationLaw: boolean) => {
        setLoadingText(!hasDestinationLaw ? 'Hämtar berörda företag' : 'Hämtar alla med mållagen');
        setLoading(true);
        if (selectedLawsIds && destinationLawId) {
            MergeLawService()
                .getAffectedCompanies(selectedLawsIds, destinationLawId, hasDestinationLaw)
                .then((mergeLawCompanyModels: MergeLawCompanyModel[]) => {
                    setAffectedCompanies(mergeLawCompanyModels);
                    setShowNoAffectedCompaniesInfo(mergeLawCompanyModels.length === 0);
                    setLoading(false);
                });
        }
    };

    const handleGetAffectedCompanies = (hasDestinationLaw: boolean) => {
        setIncludeDestinationLaw(hasDestinationLaw);
        getAffectedCompanies(hasDestinationLaw);
    };

    const handleSelectLaw = (id: number) => {
        if (destinationLawId === id) {
            // Law used as destination. Not accepted as source.
            return;
        }

        setSelectedLawIds(selected => (selectedLawsIds.includes(id) ? selected.filter(lawId => lawId !== id) : [...selected, id]));
        const foundLaw = laws.find(law => law.lawId === id);
        if (foundLaw) {
            setSelectedLaws(selectedLaw => (selectedLaws.includes(foundLaw) ? selectedLaw.filter(law => law.lawId !== id) : [...selectedLaw, foundLaw]));
        }
    };

    const handleSelectDestinationLaw = (id: number) => {
        if (selectedLawsIds.includes(id)) {
            // Law used as source. Not accepted as destination
            return;
        }

        if (destinationLawId === id) {
            setDestinationLawId(undefined);
            setDestinationLaw(undefined);
        } else {
            setDestinationLawId(id);
            setDestinationLaw(laws.find(law => law.lawId === id));
        }
    };

    const onExecuteMerge = (company: MergeLawCompanyModel) => {
        if (company.id && selectedLawsIds && destinationLawId) {
            setLoadingText('Slår samman lagar.');
            setLoading(true);
            console.log('Execute merge for company: ', company.name);

            setAffectedCompanies(c => {
                return c.map(com => {
                    if (company.id == com.id) {
                        return {
                            ...company,
                            loading: true,
                        };
                    } else return com;
                });
            });

            MergeLawService()
                .executeMergeLaws(company.id, selectedLawsIds, destinationLawId, hideSubscriptionsAfterMerge)
                .then((mergeLawResultModel: MergeLawResultModel) => {
                    setAffectedCompanies(c => {
                        return c
                            .filter(com => {
                                return !(!mergeLawResultModel && company.id === com.id);
                            })
                            .map(com => {
                                // const foundModel = mergeLawResultModel.companyId === com.id ? mergeLawResultModel : null;
                                if (mergeLawResultModel && mergeLawResultModel.companyId === com.id && !mergeLawResultModel.success) {
                                    return {
                                        ...com,
                                        submitted: true,
                                        loading: false,
                                        failed: true,
                                        errors: mergeLawResultModel.errors,
                                    };
                                } else return com;
                            });
                    });
                    setLoading(false);
                    setShowNoAffectedCompaniesInfo(affectedCompanies.length === 0);
                })
                .catch(() => setLoading(false));
        }
    };

    const onExecuteMergeAll = () => {
        setLoading(true);
        setLoadingText('Slår samman lagar på alla företag. Detta kan ta flera minuter...');
        setShowConfirmModal(false);
        if (affectedCompanies && selectedLawsIds && destinationLawId) {
            console.log('Execute merge for all companies.');

            const companyIds: number[] = [];
            affectedCompanies.forEach(c => {
                if (!c.submitted || !c.loading || !c.failed) {
                    companyIds.push(c.id);
                }
            });

            MergeLawService()
                .executeAllMergeLaws(companyIds, selectedLawsIds, destinationLawId)
                .then((models: MergeLawResultModel[]) => {
                    setAffectedCompanies(c => {
                        return c
                            .filter(com => {
                                const result = models.find(model => model.companyId === com.id);
                                return result != null;
                            })
                            .map(com => {
                                const foundModel = models.find(model => model.companyId === com.id);
                                if (foundModel) {
                                    return {
                                        ...com,
                                        submitted: true,
                                        loading: false,
                                        failed: true,
                                        errors: foundModel.errors,
                                    };
                                } else return com;
                            });
                    });

                    setLoading(false);
                    setShowNoAffectedCompaniesInfo(affectedCompanies.length === 0);
                })
                .catch(() => setLoading(false));
        }
    };

    const handleClickForErrorMessages = (company: MergeLawCompanyModel) => {
        setSelectedMergeLawCompanyModel(company);
        setShowErrorMessagesModal(true);
    };

    const filteredLaws = useMemo(() => {
        // Apply lawGroup filtering
        const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
        const filteredData = laws.filter(obj => {
            return selectedLawGroupIds.includes(obj.lawGroupId);
        });
        return filteredData;
    }, [lawTypeFilter]);

    return (
        <div className={styled.MergeLaws}>
            {loading && <LoadingSpinner message={loadingText} />}

            <ContextMenu
                title={'Lagar'}
                lawTypeFilter={lawTypeFilter}
                onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                onToggleLawGroupChecked={handleLawGroupChecked}
                onToggleLawTypeChecked={handleLawTypeChecked}
                onToggleLawTypeOpen={handleLawTypeOpen}
                onResetAllFilters={handleResetAllFilters}
                links={CONTEXT_LINKS}
                visibleDataCount={filteredLaws.length}
                totalDataCount={laws.length}
                resetButtonText={'Nollställ filter'}
            />

            <div className={styled.Content}>
                <div className={styled.SourceWrapper}>
                    <label>
                        <strong>Välj källor:</strong>
                    </label>
                    <div className={styled.SelectLaws}>
                        <Dropdown
                            content={filteredLaws.map(law => ({
                                id: law.lawId,
                                text: law.lawGroupName + '. Lag: ' + law.name + '. Ändrad t.o.m: ' + law.subId + '. Id: ' + law.lawId + '.s Revision:' + law.revision,
                                checked: selectedLawsIds?.includes(law.lawId),
                            }))}
                            multiSelect
                            className={[styled.Dropdown, styled.ListWrapper].join(' ')}
                            title={'Välj lagar'}
                            listHeight={7000}
                            onChange={handleSelectLaw}
                        />
                    </div>
                </div>

                {selectedLaws.length > 0 && (
                    <div className={styled.MarginDiv}>
                        <label>Valda lagar:</label>
                        <div>
                            {selectedLaws.map(law => (
                                <li key={law.lawId} className={styled.SelectedLaws}>
                                    <strong>{law.name}</strong>, Ändrad t.o.m: {law.subId}, id: {law.lawId}
                                </li>
                            ))}
                        </div>
                    </div>
                )}

                <div className={styled.DestinationWrapper}>
                    <label>
                        <strong>Välj destination:</strong>
                    </label>
                    <div className={styled.SelectLaws}>
                        <Dropdown
                            content={filteredLaws.map(law => ({
                                id: law.lawId,
                                text: law.lawGroupName + '. Lag: ' + law.name + '. Ändrad t.o.m: ' + law.subId + '. Id: ' + law.lawId + '.s Revision:' + law.revision,
                                checked: destinationLawId === law.lawId,
                            }))}
                            multiSelect
                            className={[styled.Dropdown, styled.Header].join(' ')}
                            title={'Välj lag'}
                            listHeight={7000}
                            onChange={handleSelectDestinationLaw}
                        />
                    </div>
                </div>

                {destinationLaw && (
                    <div className={styled.MarginDiv}>
                        <label>Vald destination:</label>
                        <li key={destinationLaw.lawId} className={styled.SelectedLaws}>
                            <strong>{destinationLaw.name}</strong>, Ändrad t.o.m: {destinationLaw.subId}, id: {destinationLaw.lawId}
                        </li>
                    </div>
                )}

                <div className={[styled.MarginDiv, styled.CompanyButtonDiv].join(' ')}>
                    <Button
                        className={[styled.Button, styled.CompanyButton].join(' ')}
                        variant="Primary"
                        onClick={() => handleGetAffectedCompanies(false)}
                        disabled={loading || !selectedLawsIds || !destinationLawId}
                    >
                        Hämta berörda företag
                    </Button>

                    <Button className={styled.Button} variant="Light" onClick={() => handleGetAffectedCompanies(true)} disabled={loading || !selectedLawsIds || !destinationLawId}>
                        Hämta alla med destination
                    </Button>
                </div>

                <div className={styled.Divider}></div>

                {showNoAffectedCompaniesInfo && (
                    <div className={styled.MarginDiv}>
                        <h4>Inga företag berörs av vald kombination av lagar.</h4>
                        <p>Det betyder att sammanslagningan redan är klar eller att inga företag prenumererar på valda författningar (källor).</p>
                    </div>
                )}

                {affectedCompanies.length > 0 && (
                    <div>
                        <div className={[styled.MarginDiv, styled.End].join(' ')}>
                            <Button
                                variant="Primary"
                                disabled={loading || !hideSubscriptionsAfterMerge || affectedCompanies.length === 0 || !selectedLawsIds || !destinationLawId}
                                onClick={() => setShowConfirmModal(true)}
                            >
                                Utför sammanslagning på alla företag samtidigt.
                            </Button>
                        </div>

                        <div className={styled.MarginDiv}>
                            <Toggle
                                checked={hideSubscriptionsAfterMerge}
                                onChange={() => setHideSubscriptionsAfterMerge(hideSubscriptionsAfterMerge => !hideSubscriptionsAfterMerge)}
                                title={'Ta bort författningarna (källor) från laglistan'}
                            />
                        </div>

                        <div className={styled.MarginDiv}>
                            <div>
                                Berörda företag {includeDestinationLaw && !loading && '(har destination)'}: <strong>{affectedCompanies.length}</strong>
                            </div>
                        </div>
                        <div className={styled.MarginDiv}>
                            <div>
                                {affectedCompanies.map(company => (
                                    <div key={company.id} style={{ padding: '10px', display: 'flex' }}>
                                        <Button variant="Primary" disabled={company.submitted || company.loading} onClick={() => onExecuteMerge(company)}>
                                            Verkställ
                                        </Button>
                                        <div className={styled.companyDiv}>
                                            {company.name} - {company.type} {company.corporationName && <span>- {company.corporationName}</span>}
                                        </div>

                                        {company.failed && (
                                            <div className={styled.Warning}>
                                                <img onClick={() => handleClickForErrorMessages(company)} src={errorIcon} alt="!" />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {showConfirmModal && (
                    <ConfirmModal
                        companyCount={affectedCompanies.length}
                        selectedLaws={selectedLaws}
                        destinationLaw={destinationLaw}
                        onClose={() => setShowConfirmModal(false)}
                        onSubmit={onExecuteMergeAll}
                    />
                )}
                {showErrorMessagesModal && selectedMergeLawCompanyModel && (
                    <ErrorMessagesModal mergeLawCompanyModel={selectedMergeLawCompanyModel} onClose={() => setSelectedMergeLawCompanyModel(undefined)} />
                )}
            </div>
        </div>
    );
};

export default MergeLaws;
