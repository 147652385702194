import React, { FC, useState } from 'react';

import styled from './EditFieldModal.module.css';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';

interface Props {
    title: string;
    value: string;
    maxLength?: number;
    onCancel: () => void;
    onConfirm: (value: string) => void;
}

const EditFieldTextModal: FC<Props> = ({ title, value, maxLength, onCancel, onConfirm }) => {
    const { t } = useTranslation();
    const [fieldValue, setFieldValue] = useState(value);

    function handleOnClickConfirm() {
        onConfirm(fieldValue);
    }

    return (
        <Modal title={title} className={styled.Modal}>
            <div className={styled.Content}>
                <Input className={styled.InputField} maxLength={maxLength} value={fieldValue} type="text" onChange={value => setFieldValue(value)} label={''} autoFocus />
                <div className={styled.Options}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={handleOnClickConfirm}>
                        {t('buttonSave')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditFieldTextModal;
