import React from 'react';
import styled from './Changes.module.css';
import { CHANGES_LIST, CHANGES_ARCHIVE } from '../../constants/Routes';
import { Route } from 'react-router-dom';
import ChangeList from './ChangeList/ChangeList';
import { ChangeListContextProvider } from '../../contexts/ChangeListContext';
import ChangeArchive from './ChangeArchive/ChangeArchive';
import { ChangeArchiveContextProvider } from '../../contexts/ChangeArchiveContext';

const ChangeListComponent = () => (
    <ChangeListContextProvider>
        <ChangeList />
    </ChangeListContextProvider>
);

const ChangeArchiveComponent = () => (
    <ChangeArchiveContextProvider>
        <ChangeArchive />
    </ChangeArchiveContextProvider>
);

const Changes: React.FC = () => {
    return (
        <div className={styled.Changes}>
            <Route path={CHANGES_LIST + '/:subscriptionId?'} component={ChangeListComponent} />
            <Route path={CHANGES_ARCHIVE} component={ChangeArchiveComponent} />
        </div>
    );
};

export default Changes;
