import { AxiosResponse, AxiosError } from 'axios';
import { getAuthApi } from '../config/axios.config';
import SubscriptionModel from '../models/SubscriptionModel';
import ApiErrorModel from '../models/ApiErrorModel';
import LawListModel from '../models/LawListModel';
import LawTypeModel from '../models/LawTypeModel';
import CustomLawModel from '../models/CustomLawModel';

export interface LawListContentModel {
    lawTypes: LawTypeModel[];
    lawLists: LawListModel[];
    subscriptions: SubscriptionModel[];
    customLaws: CustomLawModel[];
}

const SubscriptionService = () => {
    const axiosInstance = getAuthApi();

    function getFullLawListStructure(companyId: number): Promise<LawListContentModel> {
        return axiosInstance
            .get<LawListContentModel>(`/subscription/fullcontent?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListContentModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateSubscription(subscription: SubscriptionModel): Promise<SubscriptionModel> {
        return axiosInstance
            .post<SubscriptionModel>(`/subscription/update`, subscription)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateSubscriptionKeywords(subscriptionId: number, isCustomLaw: boolean, keywordIds: number[]): Promise<SubscriptionModel | CustomLawModel> {
        return axiosInstance
            .post<SubscriptionModel | CustomLawModel>(`/subscription/keywords?id=${subscriptionId}&isCustomLaw=${isCustomLaw}&keyWordIds=${keywordIds}`, undefined)
            .then((response: AxiosResponse<SubscriptionModel | CustomLawModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateSubscriptionLawLists(subscriptionId: number, isCustomLaw: boolean, lawListIds: number[]): Promise<SubscriptionModel | CustomLawModel> {
        return axiosInstance
            .post<SubscriptionModel | CustomLawModel>(`/subscription/lawlists?id=${subscriptionId}&isCustomLaw=${isCustomLaw}&lawListIds=${lawListIds}`, undefined)
            .then((response: AxiosResponse<SubscriptionModel | CustomLawModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function unsubscribeSubscription(subscriptionIds: number[]): Promise<AxiosResponse<void>> {
        return axiosInstance.post<void>(`/subscription/unsubscribe?ids=${subscriptionIds}`, undefined).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    function getSubscribedLawTypes(companyId: number): Promise<LawTypeModel[]> {
        return axiosInstance
            .get<LawTypeModel[]>(`/subscription/lawtype?companyId=${companyId}`)
            .then((response: AxiosResponse<LawTypeModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getLatestSubscription(companyId: number, baseLawId: number): Promise<SubscriptionModel> {
        return axiosInstance
            .get<SubscriptionModel>(`/subscription/latest?companyId=${companyId}&baseLawId=${baseLawId}`)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getLatestDetachedSubscription(subscriptionId: number): Promise<SubscriptionModel> {
        return axiosInstance
            .get<SubscriptionModel>(`/subscription/latestDetached?subscriptionId=${subscriptionId}`)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getUniqueSubscriptionCount(companyId: number): Promise<number> {
        return axiosInstance
            .get<number>(`/subscription/count?companyId=${companyId}`)
            .then((response: AxiosResponse<number>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateFullSubscription(subscriptionId: number, subscription?: SubscriptionModel, keyWordIds?: number[], lawListIds?: number[]): Promise<SubscriptionModel> {
        const url = `/subscription/updatefull?subscriptionId=${subscriptionId}`;
        const params = [];
        if (keyWordIds !== undefined) {
            params.push(`keyWordIds=${keyWordIds}`);
        }
        if (lawListIds !== undefined) {
            params.push(`lawListIds=${lawListIds}`);
        }

        return axiosInstance
            .put<SubscriptionModel>([url, ...params].join('&'), subscription)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getFullLawListStructure,
        updateSubscription,
        updateSubscriptionKeywords,
        updateSubscriptionLawLists,
        unsubscribeSubscription,
        getSubscribedLawTypes,
        getLatestDetachedSubscription,
        getLatestSubscription,
        getUniqueSubscriptionCount,
        updateFullSubscription,
    };
};

export default SubscriptionService;
