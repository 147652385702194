import React, { FC } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './RemoveNewsFeedModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    onCancel: () => void;
    onConfirm: () => void;
}

const RemoveNewsFeedModal: FC<Props> = ({ onCancel, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('removeNewsFeedModalTitle')} subtitle={t('removeNewsFeedModalSubTitle')} className={styled.RemoveNewsFeedModal}>
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onCancel}>
                    {t('buttonNo')}
                </Button>
                <Button variant="Outline" onClick={onConfirm}>
                    {t('buttonYes')}
                </Button>
            </div>
        </Modal>
    );
};

export default RemoveNewsFeedModal;
