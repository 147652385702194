import React, { useState, useEffect } from 'react';
import styled from './Home.module.css';
import BannerModel from '../../models/BannerModel';
import HomeService from '../../services/HomeService';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NewsFeedModel from '../../models/NewsFeedModel';
import NewsFeed from '../../components/NewsFeed/NewsFeed';
import EditNewsFeedModal from '../../components/Modal/EditNewsFeedModal/EditNewsFeedModal';
import RemoveNewsFeedModal from '../../components/Modal/RemoveNewsFeedModal/RemoveNewsFeedModal';
import plusIcon from '../../assets/images/plusIcon.svg';
import useAuth from '../../hooks/useAuth';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import StatusCard from '../../components/StatusCard/StatusCard';
import DashboardStatus from '../../models/DashboardStatus';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const Home: React.FC = () => {
    const { isAboveConsultant, isAboveAnonymous, company, user } = useAuth();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [loadingNewsFeedOrder, setLoadingNewsFeedOrder] = useState(false);
    const [banner, setBanner] = useState<BannerModel>();

    // We're saving the original large banner for future use. Currently always invisible due to showBanner = false;
    const [showLargeBanner, setShowLargeBanner] = useState(false);
    const [showMiniBanner, setShowMiniBanner] = useState(true);

    const [dashboardStatuses, setDashboardStatuses] = useState<DashboardStatus[]>();
    const [newsFeed, setNewsFeed] = useState<NewsFeedModel[]>([]);
    const [saveNewsFeedTimeout, setSaveNewsFeedTimeout] = useState<any>();
    const [newsFeedModal, setNewsFeedModal] = useState<{ open: boolean; edit?: NewsFeedModel }>({ open: false });
    const [removeModal, setRemoveModal] = useState<{ open: boolean; id?: number }>({ open: false });

    const fetchData = async (companyId: number) => {
        try {
            setLoading(true);
            const newsFeed = await HomeService().getNewsFeed();
            const dashboardStatuses = await HomeService().getDashboardStatuses(companyId);
            if (showLargeBanner) {
                const banner = await HomeService().getStartBanner();
                setBanner(banner);
            }
            setNewsFeed(newsFeed);
            setDashboardStatuses(dashboardStatuses);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleNewOrder = (movedNewsFeed: NewsFeedModel, up: boolean) => {
        const index = _.indexOf(newsFeed, movedNewsFeed);
        const replaceIndex = up ? index - 1 : index + 1;
        const originalOrder = newsFeed[index].order;

        newsFeed[index].order = newsFeed[replaceIndex].order;
        newsFeed[replaceIndex].order = originalOrder;
        const sortedNewsFeed = _.sortBy(newsFeed, 'order').reverse();
        setNewsFeed(sortedNewsFeed);

        if (saveNewsFeedTimeout) {
            clearTimeout(saveNewsFeedTimeout);
        }
        const timeout = setTimeout(() => {
            setLoadingNewsFeedOrder(true);
            const newsFeed = HomeService()
                .updateNewsFeedOrder(sortedNewsFeed)
                .then((newsFeedModelList: NewsFeedModel[]) => {
                    const sortedNewsFeed = _.sortBy(newsFeedModelList, 'order').reverse();
                    setNewsFeed(sortedNewsFeed);
                    const timeout = setTimeout(() => {
                        setLoadingNewsFeedOrder(false);
                    }, 1000);
                });
        }, 1000);

        setSaveNewsFeedTimeout(timeout);
    };

    const handleRemoveNewsFeed = async (id: number) => {
        try {
            setLoading(true);
            setRemoveModal({ open: false });
            await HomeService().removeNewsFeed(id);
            setNewsFeed(s => s.filter(nf => nf.id !== id));
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleSubmitNewsFeed = async (newsFeed: NewsFeedModel) => {
        try {
            setLoading(true);
            setNewsFeedModal({ open: false });
            if (newsFeed.id !== undefined) {
                const response = await HomeService().updateNewsFeed(newsFeed);
                setNewsFeed(s => s.map(nf => (nf.id === newsFeed.id ? { ...nf, ...response } : nf)));
            } else {
                const response = await HomeService().createNewsFeed(newsFeed);
                setNewsFeed(s => [response, ...s]);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    // const handleUploadVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files && event.target.files[0]) {
    //         HomeService().uploadHomeVideo(event.target.files[0]);
    //     }
    // };

    useEffect(() => {
        if (company) {
            fetchData(company.id);
        }
    }, [company]);

    return (
        <div className={styled.Home}>
            {loading && <LoadingSpinner />}
            {loadingNewsFeedOrder && <LoadingSpinner message={'Sparar ordning'} />}
            {banner && showLargeBanner && (
                <div className={styled.Banner}>
                    <div className={styled.Content}>
                        <div className={styled.Text}>
                            <h1>{banner.title}</h1>
                            <p>{banner.text}</p>
                        </div>
                        <div className={styled.Video}>
                            <VideoPlayer />
                        </div>
                    </div>
                </div>
            )}

            {showMiniBanner && !showLargeBanner && (
                <div className={styled.MiniBanner}>
                    <div className={styled.Content}>
                        <div className={styled.Text}>
                            {isAboveAnonymous() && (
                                <h3>
                                    {t('homeSmallBannerWelcome')} {user?.firstName}!
                                </h3>
                            )}
                            {!isAboveAnonymous() && <h3>{t('homeSmallBannerWelcome')}!</h3>}
                        </div>
                    </div>
                </div>
            )}

            {dashboardStatuses && isAboveAnonymous() && !isAboveConsultant() && (
                <div className={styled.DashboardStatus}>
                    <h3>{t('dashboardStatusHeader')}</h3>
                    <StatusCard data={dashboardStatuses} />
                </div>
            )}

            {((newsFeed && newsFeed.length > 0) || isAboveConsultant()) && (
                <div className={styled.NewsFeed}>
                    <h3>
                        {t('homeNewsFeedTitle')} {isAboveConsultant() && <img src={plusIcon} alt="+" onClick={() => setNewsFeedModal({ open: true })} />}
                    </h3>
                    {newsFeed.map((nf, index) => (
                        <NewsFeed
                            key={nf.id}
                            data={nf}
                            onRemove={() => setRemoveModal({ open: true, id: nf.id })}
                            onEdit={() => setNewsFeedModal({ open: true, edit: nf })}
                            onMoveUp={() => handleNewOrder(nf, true)}
                            onMoveDown={() => handleNewOrder(nf, false)}
                            firstIndex={index === 0}
                            lastIndex={index === newsFeed.length - 1}
                        />
                    ))}
                </div>
            )}

            {newsFeedModal.open && (
                <EditNewsFeedModal
                    data={newsFeedModal.edit}
                    onSubmit={handleSubmitNewsFeed}
                    onCancel={() => setNewsFeedModal({ open: false })}
                    onRemove={() => {
                        newsFeedModal.edit && setRemoveModal({ open: true, id: newsFeedModal.edit.id });
                        setNewsFeedModal({ open: false });
                    }}
                />
            )}

            {removeModal.open && removeModal.id && (
                <RemoveNewsFeedModal onCancel={() => setRemoveModal({ open: false })} onConfirm={() => removeModal.id && handleRemoveNewsFeed(removeModal.id)} />
            )}
        </div>
    );
};

export default Home;
