import React, { FC, useEffect } from 'react';
import { HOME } from '../../constants/Routes';
import { useHistory } from 'react-router-dom';
import useStorage from '../../hooks/useStorage';
import useQuery from '../../hooks/useQuery';

const TokenLogin: FC = ({}) => {
    const history = useHistory();
    const query = useQuery();

    const token = 'Bearer ' + query.get('t');
    const loggedInAsCompanyId = parseInt(query.get('c') || '-1');
    const loggedInAsUserId = parseInt(query.get('u') || '-1');

    const { setToken, setCompanyId, setSignedInAsUserId } = useStorage();
    useEffect(() => {
        async function adminLoginAsCompany() {
            if (token && loggedInAsCompanyId - 1) {
                setCompanyId(loggedInAsCompanyId);
                if (loggedInAsUserId !== -1) {
                    //Admins have an option to select another user during login from Admin 2.0 to use the filters in Law Monitor 3.0.
                    //This authentication function are also in use by client QR logins from desktop => mobile. loggedInAsUserId is then always -1.
                    setSignedInAsUserId(loggedInAsUserId);
                }
                setToken(token);
                history.push(HOME);
            }
        }
        adminLoginAsCompany();
    }, []);

    return <div />;
};

export default TokenLogin;
