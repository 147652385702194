import React, { FC, useState, useMemo } from 'react';
import LawListModel, { UNGROUPED_LAWLIST_ID } from '../../models/LawListModel';
import accordionIcon from '../../assets/images/accordionIcon.svg';
import _ from 'lodash';
import useAuth from '../../hooks/useAuth';
import Checkbox from '../Checkbox/Checkbox';
import styled from './LawListEditor.module.css';
import { sortLawLists } from '../../utils/sorter';
import { useTranslation } from 'react-i18next';

interface Props {
    lawLists: LawListModel[];
    onChange: (lawLists: LawListModel[]) => void;
}

const LawListEditor: FC<Props> = ({ lawLists, onChange }) => {
    const { t } = useTranslation();
    const { company } = useAuth();
    const [openLawListGroupIds, setOpenLawListGroupIds] = useState<number[]>([]);

    const lawListGroups = useMemo(() => {
        const lawListGroups = _.unionBy(
            _.flatMap(_.groupBy(company?.lawLists, 'lawListGroup.name'), lawLists =>
                lawLists.map(lawList => ({
                    lawListGroupId: lawList.lawListGroup.lawListGroupId,
                    name: lawList.lawListGroup.name,
                    lawLists: lawLists.map(l => _.omit(l, 'lawListGroup')).map(l => ({ ...l, checked: false })),
                })),
            ),
            'lawListGroupId',
        ).sort(sortLawLists);
        setOpenLawListGroupIds(lawListGroups.map(llg => llg.lawListGroupId));
        return lawListGroups.map(lawListGroup => ({ ...lawListGroup, lawLists: lawListGroup.lawLists.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) }));
    }, [company?.lawLists]);

    const handleLawListGroupChange = (lawListGroupId: number): void => {
        if (lawLists.find(lawList => lawList.lawListGroup?.lawListGroupId === lawListGroupId)) {
            onChange(lawLists.filter(lawList => lawList.lawListGroup?.lawListGroupId !== lawListGroupId));
        } else {
            const addLawLists = company?.lawLists.filter(lawList => lawList.lawListGroup.lawListGroupId === lawListGroupId);
            if (addLawLists) {
                onChange([...lawLists, ...addLawLists]);
            }
        }
    };
    const handleLawListChange = (lawListId: number): void => {
        if (lawLists.find(lawList => lawList.lawListId === lawListId)) {
            onChange(lawLists.filter(lawList => lawList.lawListId !== lawListId));
        } else {
            const lawList = company?.lawLists.find(lawList => lawList.lawListId === lawListId);
            if (lawList) {
                onChange([...lawLists, lawList]);
            }
        }
    };

    const handleOpenLawListGroup = (lawListGroupId: number): void => {
        setOpenLawListGroupIds(s => (s.find(id => id === lawListGroupId) !== undefined ? s.filter(id => id !== lawListGroupId) : [...s, lawListGroupId]));
    };

    return (
        <div className={styled.LawListEditor}>
            <div className={styled.Header}>
                <h5>{t('columnLawLists')}</h5>
            </div>
            <ul className={styled.List}>
                {lawListGroups.map(lawListGroup => {
                    const isLawListGroupOpen = openLawListGroupIds.includes(lawListGroup.lawListGroupId);
                    return (
                        <React.Fragment key={lawListGroup.lawListGroupId}>
                            <li
                                className={[
                                    styled.LawListGroup,
                                    isLawListGroupOpen ? styled.Open : '',
                                    lawListGroup.lawListGroupId === UNGROUPED_LAWLIST_ID ? styled.Ungrouped : '',
                                ].join(' ')}
                                onClick={() => handleOpenLawListGroup(lawListGroup.lawListGroupId)}
                            >
                                <span>
                                    <Checkbox
                                        checked={lawListGroup.lawLists.every(lawList => lawLists.find(ll => ll.lawListId === lawList.lawListId))}
                                        indeterminate={
                                            lawListGroup.lawLists.some(lawList => lawLists.find(ll => ll.lawListId === lawList.lawListId)) &&
                                            !lawListGroup.lawLists.every(lawList => lawLists.find(ll => ll.lawListId === lawList.lawListId))
                                        }
                                        onChange={() => handleLawListGroupChange(lawListGroup.lawListGroupId)}
                                    />
                                    {lawListGroup.name}
                                </span>
                                <img src={accordionIcon} alt="Icon" className={styled.Icon} />
                            </li>

                            {isLawListGroupOpen && lawListGroup.lawLists.length > 0 && (
                                <ul className={styled.SubList}>
                                    {lawListGroup.lawLists.map(lawList => (
                                        <li key={lawList.lawListId} onClick={() => handleLawListChange(lawList.lawListId)}>
                                            <Checkbox checked={!!lawLists.find(ll => ll.lawListId === lawList.lawListId)} onChange={() => handleLawListChange(lawList.lawListId)} />
                                            {lawList.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );
};

export default LawListEditor;
