import React, { FC, useState } from 'react';
import styled from './WelcomePopup.module.css';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import helpIcon from '../../assets/images/helpIcon.png';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Button from '../Button/Button';
import useStorage from '../../hooks/useStorage';

const WelcomePopup: FC = () => {
    const { setHidePopup } = useStorage();
    const { t } = useTranslation();

    const [open, setOpen] = useState(true);

    const handleClosePopup = () => {
        setHidePopup();
        setOpen(false);
    };

    return (
        <div className={[styled.Popup, open ? styled.Open : ''].join(' ')}>
            {open && (
                <div>
                    <div className={styled.Arrow}></div>
                    <div className={styled.ArrowOverlay}></div>
                    <div className={styled.PopupContent}>
                        <div className={styled.Header}>
                            <h1>{t('welcomePopupHeader')}</h1>
                        </div>
                        <div className={styled.Text}>
                            <p>
                                {t('welcomePopupText')}
                                <span>
                                    <img src={helpIcon} alt="help" />
                                </span>
                            </p>
                        </div>

                        <div className={styled.Video}>{<VideoPlayer />}</div>
                        <div className={styled.Footer}>
                            <Button variant="Outline" onClick={handleClosePopup}>
                                {t('buttonClose')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WelcomePopup;
