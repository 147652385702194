import React, { FC } from 'react';
import styled from './EmailNotificationsToaster.module.css';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { EmailNotificationFilterType } from '../../models/EmailNotificationFilterType';

interface Props {
    selectOption: EmailNotificationFilterType;
    changesCount: number;
    onSubmit: () => void;
    onCancel?: () => void;
}

const EmailNotificationsToaster: FC<Props> = ({ selectOption, changesCount, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    return (
        <div className={styled.EmailNotificationsToaster}>
            <div className={styled.TextContainer}>
                <h6>{t('manageUserEmailToasterTitle')}</h6>
                {selectOption === EmailNotificationFilterType.STANDARD_LIST && <p>{t('manageUserEmailToasterLawGroupText', { changesCount })}</p>}
                {selectOption === EmailNotificationFilterType.CUSTOM_LIST && <p>{t('manageUserEmailToasterLawListText', { changesCount })}</p>}
                {selectOption === EmailNotificationFilterType.KEYWORDS && <p>{t('manageUserEmailToasterKeywordsText', { changesCount })}</p>}
            </div>
            <div className={styled.Buttons}>
                {onCancel && (
                    <Button variant="Primary" className={styled.CancelButton} onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                )}
                <Button variant="Light" onClick={onSubmit}>
                    {t('buttonSave')}
                </Button>
            </div>
        </div>
    );
};

export default EmailNotificationsToaster;
