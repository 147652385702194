import React, { FC } from 'react';
import styled from './EmptyListView.module.css';
import noChangesIcon from '../../assets/images/noChangesIcon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    title?: string;
    text?: string;
    emptyFilter?: boolean;
}

const EmptyListView: FC<Props> = ({ title, text, emptyFilter }) => {
    const { t } = useTranslation();
    return (
        <div className={styled.EmptyListView}>
            <div className={styled.Icon}>
                <img src={noChangesIcon} />
                {emptyFilter ? (
                    <>
                        <h6>{t('emptyListViewFilterTitle')}</h6>
                        <p>{t('emptyListViewFilterText')}</p>
                    </>
                ) : (
                    <>
                        <h6>{title}</h6>
                        {text && <p>{text}</p>}
                    </>
                )}
            </div>
        </div>
    );
};

export default EmptyListView;
