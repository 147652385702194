import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from './EditRevisionModal.module.css';
import Modal from '../Modal';
import useAuth from '../../../hooks/useAuth';
import _ from 'lodash';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    edit:
        | {
              name: string;
              userId: number;
          }
        | undefined;
    onConfirm: (name: string, userId: number, username: string) => void;
    onCancel: () => void;
}

const EditRevisionModal: FC<Props> = ({ edit, onConfirm, onCancel }) => {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState(edit ? edit.name : '');

    const handleConfirm = () => {
        if (!_.isEmpty(name) && edit) {
            onConfirm(name, edit.userId, '');
        }
    };

    return (
        <Modal title={t('buttonChangeName')} className={styled.Modal}>
            <div className={styled.Content}>
                <p className={styled.Label}>{t('createRevisionModalNameLabel')}</p>
                <input maxLength={499} type="text" className={styled.Input} placeholder={t('columnName')} value={name} onChange={e => setName(e.target.value)} autoFocus />
                <div className={styled.Options}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={handleConfirm} disabled={_.isEmpty(name)}>
                        {edit ? t('buttonSave') : t('buttonSelectLaws')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditRevisionModal;
