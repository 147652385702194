import React, { FC } from 'react';
import styled from './NewsFeed.module.css';
import NewsFeedModel from '../../models/NewsFeedModel';
import editIcon from '../../assets/images/editIcon.svg';
import minusIcon from '../../assets/images/minusIcon.svg';
import useAuth from '../../hooks/useAuth';
import backArrowIcon from '../../assets/images/backArrowIcon.svg';

interface Props {
    data: NewsFeedModel;
    onEdit: () => void;
    onRemove: () => void;
    onMoveUp: () => void;
    onMoveDown: () => void;
    firstIndex: boolean;
    lastIndex: boolean;
}

const NewsFeed: FC<Props> = ({ data, onEdit, onRemove, onMoveUp, onMoveDown, firstIndex, lastIndex }) => {
    const { isAboveConsultant } = useAuth();

    return (
        <div className={styled.NewsFeed}>
            <div className={styled.Header}>
                <h4>{data.title}</h4>
                <h4>{data.createdAt}</h4>
            </div>
            <div className={styled.Text} dangerouslySetInnerHTML={{ __html: data.text }} />

            {isAboveConsultant() && (
                <div className={styled.Toolbar}>
                    <img src={backArrowIcon} className={[styled.ArrowUp, firstIndex ? styled.Hide : ''].join(' ')} onClick={firstIndex ? undefined : onMoveUp} />
                    <img src={backArrowIcon} className={[styled.ArrowDown, lastIndex ? styled.Hide : ''].join(' ')} onClick={lastIndex ? undefined : onMoveDown} />
                    <img src={minusIcon} onClick={onRemove} />
                    <img src={editIcon} onClick={onEdit} />
                </div>
            )}
        </div>
    );
};

export default NewsFeed;
