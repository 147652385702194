import React, { FC, useState, useEffect } from 'react';
import styled from './EditLawListModal.module.css';
import Modal from '../Modal';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import useAuth from '../../../hooks/useAuth';
import Dropdown from '../../Dropdown/Dropdown';
import TextEditor from '../../TextEditor/TextEditor';
import { EditLawListModel } from '../../../models/LawListModel';
import CompanyService from '../../../services/CompanyService';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { Data } from '../../../containers/Settings/Company/LawLists/LawLists';
import { lawListNameMaxLength, lawListResponsibleMaxLength, lawListDescriptionMaxLength } from '../../../constants/Validation';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

interface Props {
    edit?: Data; // If undefined, this component will be used to create a new object. If this value is not undefined this component will be used to edit the defined object.
    onSave: (lawList: EditLawListModel) => void;
    onCancel: () => void;
}

const EditLawListModal: FC<Props> = ({ edit, onSave, onCancel }) => {
    const { t } = useTranslation();
    const { company } = useAuth();

    const [loading, setLoading] = useState(false);
    const [lawListGroups, setLawListGroups] = useState<{ id: number; text: string }[]>([]);

    const [name, setName] = useState<{ value: string; error?: string }>({ value: edit ? edit.name : '', error: undefined });
    const [lawListGroupId, setLawListGroupId] = useState(edit && edit.lawListGroupId ? edit.lawListGroupId : -1);
    const [responsible, setResponsible] = useState<{ value: string; error?: string }>({ value: edit ? edit.responsible : '', error: undefined });
    const [description, setDescription] = useState<{ value: string; error?: boolean }>({ value: edit ? edit.description : '', error: false });

    const nameValidationRegExp = new RegExp(
        '^[a-zA-ZàáâäæãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÆÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒČŠŽ∂ð0-9:…·•,„¸˛\\.\\\'`¨’´˙‘’°˝˜ˆ¯˘¬º⁄ˇ«»“”\\(\\)\\*;:\\+@#\\/\\&\\|§\\<\\>€£\\$\\!\\?\\{\\}¶\\\\\\"\\=≠≈~≤≥÷±√πΩ%‰∞#¡αβγ‡¥¢Œ∏˚◊∑∆∫©™®ß∂ƒªﬁﬂ†µıœ‹›\\^\\[\\]\\t\\r\\n\\s\\_\\—\\–\\-]*$',
    );
    const inputError = t('inputFieldKeywordValidatonError2');
    const fetchData = async () => {
        try {
            setLoading(true);
            const lawListGroupData = await CompanyService().getLawListGroups(company?.id || -1);
            if (lawListGroupData && lawListGroupData.length) {
                const lawListGroups = _.sortBy(
                    lawListGroupData.map(llg => ({
                        id: llg.lawListGroupId,
                        text: llg.name,
                    })),
                    llg => llg.text.toLowerCase(),
                );
                lawListGroups.push({
                    id: -1,
                    text: t('noGroup').toString(),
                });
                setLawListGroups(lawListGroups);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleNameChange = (value: string) => {
        let error;
        if (value.length < 1) {
            error = t('inputFieldErrorRequired');
        } else if (value.length > lawListNameMaxLength) {
            error = t('inputFieldErrorTooLong', { max: lawListNameMaxLength });
            value = name.value;
        }
        if (!nameValidationRegExp.test(value)) {
            error = inputError;
        }
        setName({ value, error });
    };

    const handleResponsibleChange = (value: string) => {
        let error;
        if (value.length > lawListResponsibleMaxLength) {
            error = t('inputFieldErrorTooLong', { max: lawListResponsibleMaxLength });
            value = responsible.value;
        }
        setResponsible({ value, error });
    };

    const handleSave = () => {
        const lawList: EditLawListModel = {
            lawListId: edit ? edit.lawListId : undefined,
            name: name.value,
            description: description.value,
            responsible: responsible.value,
            lawListGroup: lawListGroupId > -1 ? { lawListGroupId } : undefined,
        };
        onSave(lawList);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Modal title={edit ? t('editLawListModalTitle') : t('createLawListModalTitle')} className={styled.Modal}>
            {loading && <LoadingSpinner />}
            <div className={styled.Container}>
                <Input
                    value={name.value}
                    onChange={handleNameChange}
                    error={name.error}
                    label={t('inputFieldLawListName')}
                    className={styled.Input}
                    placeholder={t('inputFieldPlaceholderName')}
                    autoFocus
                />

                <div className={[styled.DropdownInput, styled.FirstDropdown, !lawListGroups.length && !loading ? styled.Disabled : ''].join(' ')}>
                    <p>{t('inputFieldLawListGroup')}</p>
                    <Dropdown
                        title={!lawListGroups.length ? t('missing').toString() : lawListGroups.find(llg => llg.id === lawListGroupId)?.text || t('noGroup').toString()}
                        content={lawListGroups}
                        onChange={setLawListGroupId}
                    />
                </div>

                <Input
                    value={responsible.value}
                    onChange={handleResponsibleChange}
                    label={t('inputFieldResponsible')}
                    className={styled.Input}
                    placeholder={t('inputFieldPlaceholderResponsible')}
                    error={responsible.error}
                />

                <div className={styled.TextInput}>
                    <p>{t('inputFieldDescription')}</p>
                    <TextEditor
                        initialValue={description.value}
                        onChange={value => setDescription(s => ({ ...s, value }))}
                        noOptions
                        maxLength={lawListDescriptionMaxLength}
                        onMaxLengthError={error => setDescription(s => ({ ...s, error }))}
                    />
                </div>

                <div className={styled.Buttons}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button
                        variant="Primary"
                        onClick={handleSave}
                        disabled={name.error !== undefined || name.value.length < 1 || responsible.error !== undefined || description.error}
                    >
                        {edit ? t('buttonSave') : t('buttonCreateLawList')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditLawListModal;
