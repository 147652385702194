import React, { FC, useEffect, useState } from 'react';
import styled from './DetachedCopies.module.css';
import Button from '../../../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import { SETTINGS_COMPANY_DETACHED_COPIES_CREATE, SETTINGS_COMPANY_DETACHED_COPIES_REMOVE } from '../../../../constants/Routes';
import useAuth from '../../../../hooks/useAuth';
import warningIcon from '../../../../assets/images/statusNotOkIcon.svg';
import DetachedCopiesService from '../../../../services/DetachedCopiesService';
import { useTranslation } from 'react-i18next';

const DetachedCopies: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { company } = useAuth();

    const [hasDetachedCopies, setHasDetachedCopies] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        if (company) {
            try {
                setLoading(true);
                const hasDetachedCopies = await DetachedCopiesService().hasDetachedCopies(company.id);
                setHasDetachedCopies(hasDetachedCopies);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={styled.DetachedCopies}>
            <h3 className={styled.Title}>{t('settingsMenuCompanyDetachedCopies')}</h3>
            <div className={styled.Information}>
                <h6>{t('detachedCopiesTitle')}</h6>
                <p>{t('detachedCopiesText')}</p>
                {!loading && (!company?.lawLists || !company?.lawLists.length) && (
                    <div className={styled.Warning}>
                        <img src={warningIcon} />
                        <p>{t('detachedCopiesNoLawListText')}</p>
                    </div>
                )}
                {!loading && (
                    <div className={styled.Buttons}>
                        <Button variant="Primary" onClick={() => history.push(SETTINGS_COMPANY_DETACHED_COPIES_CREATE)} disabled={!company?.lawLists || !company?.lawLists.length}>
                            {t('buttonCreateDetachedCopy')}
                        </Button>
                        {hasDetachedCopies && (
                            <Button variant="Primary" onClick={() => history.push(SETTINGS_COMPANY_DETACHED_COPIES_REMOVE)}>
                                {t('buttonRemoveDetachedCopy')}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DetachedCopies;
