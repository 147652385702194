import React, { FC, useState, useEffect, useMemo } from 'react';
import styled from './EditDetachedCopies.module.css';
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';
import SubscriptionModel from '../../../../../models/SubscriptionModel';
import DetachedCopiesService from '../../../../../services/DetachedCopiesService';
import useAuth from '../../../../../hooks/useAuth';
import LawTypeFilter, { convertToLawTypeFilter } from '../../../../../models/LawTypeFilter';
import RevisionTopbar from '../../../../../components/RevisionTopbar/RevisionTopbar';
import { SETTINGS_COMPANY_DETACHED_COPIES, SETTINGS_COMPANY_DETACHED_COPIES_CREATE } from '../../../../../constants/Routes';
import ContextMenu from '../../../../../components/ContextMenu/ContextMenu';
import lawlistIcon from '../../../../../assets/images/lawlistIcon.svg';
import { LawType, sortLawTypes } from '../../../../../models/LawType';
import Filterbar from '../../../../../components/Filterbar/Filterbar';
import KeywordModel from '../../../../../models/KeywordModel';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../../../../../models/LawListGroupFilter';
import { Column, SortingRule } from 'react-table';
import Table from '../../../../../components/Table/Table';
import LawCell from '../../../../../components/Table/LawCell/LawCell';
import KeywordCell from '../../../../../components/Table/KeywordCell/KeywordCell';
import _ from 'lodash';
import RevisionDateCell from '../../../../../components/Table/RevisionDateCell/RevisionDateCell';
import EditDetachedCopiesFullView from '../../../../../components/FullView/EditDetachedCopiesFullView/EditDetachedCopiesFullView';
import EditDetachedCopiesToaster from '../../../../../components/EditDetachedCopiesToaster/EditDetachedCopiesToaster';
import { useHistory, useLocation } from 'react-router-dom';
import CreateDetachedCopiesModal from '../../../../../components/Modal/CreateDetachedCopiesModal/CreateDetachedCopiesModal';
import RemoveDetachedCopiesModal from '../../../../../components/Modal/RemoveDetachedCopiesModal/RemoveDetachedCopiesModal';
import LawTypeModel from '../../../../../models/LawTypeModel';
import { useTranslation } from 'react-i18next';

const EditDetachedCopies: FC = () => {
    const { t, i18n } = useTranslation();

    const CONTEXT_LINKS: {
        title: string;
        icon: string;
        active: boolean;
    }[] = [{ title: t('contextMenuLawLists'), icon: lawlistIcon, active: true }];

    const history = useHistory();
    const location = useLocation();
    const { company, isAboveUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SubscriptionModel[]>([]);
    const [filteredData, setFilteredData] = useState<SubscriptionModel[]>([]);
    const [lawTypeFilter, setLawTypeFilter] = useState<LawTypeFilter[]>([]);
    const [selectedKeywords, setSelectedKeywords] = useState<(KeywordModel & { checked: boolean })[]>([]);
    const [lawListGroupFilter, setLawListGroupFilter] = useState<LawListGroupFilter[]>([]);
    const [globalSearch, setGlobalSearch] = useState('');
    const [showColumnSearch, setShowColumnSearch] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [visibleDataCount, setVisibleDataCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
    const [sorting, setSorting] = useState<SortingRule<object>[]>([]);
    const [isCreateMode, setCreateMode] = useState<boolean | undefined>(undefined);
    const [deleteWarning, setDeleteWarning] = useState<boolean>(false);
    const [columnSearch, setColumnSearch] = useState<
        {
            id: string;
            value: string | undefined;
        }[]
    >([]);
    const [columns, setColumns] = useState(() => {
        const columns: Array<Column<SubscriptionModel> & {
            id: string;
            visible: boolean;
            alwaysVisible?: boolean;
            Header: string;
            languageId?: string;
        }> = [
            {
                id: 'law',
                Header: t('columnLaw'),
                languageId: 'columnLaw',
                accessor: row => `${row.name} ${row.subId}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.law;
                    const lawB = rowB.values.law;
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
                visible: true,
                width: 2,
                alwaysVisible: true,
                Cell: LawCell,
            },
            {
                id: 'latestRevisionDate',
                Header: t('columnLatestRevisionDate'),
                languageId: 'columnLatestRevisionDate',
                accessor: row => row.latestRevisionDate || 'Aldrig',
                visible: true,
                Cell: RevisionDateCell,
                sortInverted: true,
            },
            {
                id: 'description',
                Header: t('columnDescription'),
                languageId: 'columnDescription',
                accessor: 'description',
                disableSortBy: true,
                visible: true,
            },
            {
                id: 'text',
                Header: t('columnText'),
                languageId: 'columnText',
                accessor: 'text',
                disableSortBy: true,
                visible: true,
            },
        ];

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                columns.push({
                    id: 'customerText' + i,
                    accessor: 'customerText' + i,
                    visible: true,
                    Header: title,
                    disableSortBy: true,
                });
            }
        }

        if (company?.hasLawLists) {
            columns.push({
                id: 'lawLists',
                visible: true,
                accessor: row => (row.lawLists ? row.lawLists.map(lawList => (lawList.lawListGroup ? lawList.lawListGroup.name + ': ' : '') + lawList.name) : ''),
                Cell: KeywordCell,
                Header: t('columnLawLists'),
                languageId: 'columnLawLists',
                disableSortBy: true,
            });
        }

        if (company?.hasKeyWords) {
            columns.push({
                id: 'keywords',
                visible: true,
                accessor: row => row.keywordIds.map((id: number) => _.find(company.keyWords, kw => kw.id === id)?.text),
                Cell: KeywordCell,
                Header: t('columnKeywords'),
                languageId: 'columnKeywords',
                disableSortBy: true,
            });
        }
        return columns;
    });
    const [createModal, setCreateModal] = useState<{ open: boolean; name?: string }>({ open: false, name: undefined });
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const selectedSubscriptionIds = useMemo(
        () => _.filter(data, subscription => selectedRows[subscription.subscriptionId] === true).map(subscription => subscription.subscriptionId),
        [data, selectedRows],
    );

    // const handleVisibleDataCountChange = (visibleCount: number) => {
    //     setVisibleDataCount(visibleCount);
    // };

    const handleResetLawTypeFilter = () => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
        );
    };

    const handleResetAllFilters = () => {
        handleResetLawTypeFilter();
        setGlobalSearch('');
        setSelectedKeywords(s => s.map(kw => ({ ...kw, checked: false })));
        setColumnSearch(s => s.map(c => ({ ...c, value: undefined })));
        setLawListGroupFilter(s =>
            s.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: false,
                })),
            })),
        );
        setShowColumnSearch(false);
    };

    const handleLawListFilterChange = (lawListId: number) => {
        setLawListGroupFilter(s =>
            s.map(llg => ({
                ...llg,
                lawLists: llg.lawLists.map(ll => ({
                    ...ll,
                    checked: ll.lawListId === lawListId ? !ll.checked : ll.checked,
                })),
            })),
        );
    };

    const handleLawListGroupFilterChange = (lawListGroupId: number) => {
        setLawListGroupFilter(s =>
            s.map(llg => {
                if (llg.lawListGroupId === lawListGroupId) {
                    return {
                        ...llg,
                        lawLists: llg.lawLists.map(ll => ({
                            ...ll,
                            checked: !llg.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return llg;
            }),
        );
    };

    const handleSelectedKeywordChange = (keywordId: number) => {
        setSelectedKeywords(s =>
            s.map(keyword => ({
                ...keyword,
                checked: keyword.id === keywordId ? !keyword.checked : keyword.checked,
            })),
        );
    };

    const handleToggleLawTypeOpen = (type: LawType) => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        );
    };

    const handleToggleLawTypeChecked = (type: LawType) => {
        setLawTypeFilter(s =>
            s.map(lawType => {
                if (lawType.name === type) {
                    return {
                        ...lawType,
                        lawGroups: lawType.lawGroups.map(lawGroup => ({
                            ...lawGroup,
                            checked: !lawType.lawGroups.every(l => l.checked),
                        })),
                    };
                }
                return lawType;
            }),
        );
    };

    const handleToggleLawGroupChecked = (lawGroupId: number) => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
        );
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
        );
    };

    const handleToggleColumnSearch = () => {
        if (showColumnSearch) {
            setColumnSearch(s => s.map(c => ({ ...c, value: undefined })));
        }
        setShowColumnSearch(!showColumnSearch);
    };

    const handleToggleColumnVisible = (id: string) => {
        setColumns(s => s.map(col => ({ ...col, visible: col.id === id ? !col.visible : col.visible })));
    };

    const handleSelectRowChange = (id: string) => {
        if (isCreateMode) {
            setSelectedRow(s => (s === +id ? -1 : +id));
        } else {
            setSelectedRows(s => ({
                ...s,
                [id]: !s[id],
            }));
        }
    };

    const handleSelectRowsChange = (filteredRowIds: string[]) => {
        setSelectedRows(s => {
            const allChecked = _.every(filteredRowIds, id => s[id] === true);
            return _.mapValues(s, (value, id) => {
                if (_.includes(filteredRowIds, id)) {
                    return !allChecked;
                }
                return value;
            });
        });
    };

    const calculateIfAllSubscriptionAreDeleted = (): boolean => {
        const lawMap: Map<number, SubscriptionModel[]> = new Map([]);
        data.forEach(sub => {
            if (!lawMap.has(sub.lawId)) {
                lawMap.set(sub.lawId, [sub]);
            } else {
                const subIds = lawMap.get(sub.lawId);
                if (subIds) {
                    subIds.push(sub);
                    lawMap.set(sub.lawId, subIds);
                } else {
                    lawMap.set(sub.lawId, [sub]);
                }
            }
        });

        let warning = false;
        lawMap.forEach(subscriptionModelsList => {
            if (_.every(subscriptionModelsList, s => selectedSubscriptionIds.includes(s.subscriptionId))) {
                warning = true;
            }
        });
        return warning;
    };

    const handleOpenModal = () => {
        if (isCreateMode) {
            const subscription = data.find(obj => obj.subscriptionId === selectedRow);
            if (subscription) {
                setCreateModal({ open: true, name: subscription.name });
            }
        } else {
            setDeleteWarning(calculateIfAllSubscriptionAreDeleted());
            setRemoveModalOpen(true);
        }
    };

    const fetchData = async (isCreateMode: boolean) => {
        if (!isAboveUser()) {
            history.push(SETTINGS_COMPANY_DETACHED_COPIES);
        }

        if (company) {
            try {
                setLoading(true);

                let lawTypes: LawTypeModel[];
                let subscriptions: SubscriptionModel[];
                if (isCreateMode) {
                    const data = await DetachedCopiesService().getSubscriptionsForAddCopy(company.id);
                    lawTypes = data.lawTypes;
                    subscriptions = data.subscriptions;
                } else {
                    const data = await DetachedCopiesService().getDetachedCopiesForRemove(company.id);
                    lawTypes = data.lawTypes;
                    subscriptions = data.subscriptions;
                }

                if (company?.hasKeyWords) {
                    const keywords = company.keyWords.map(kw => ({
                        ...kw,
                        checked: false,
                    }));
                    setSelectedKeywords(keywords);
                }

                const lawListGroupFilter: LawListGroupFilter[] = convertToLawListGroupFilter(company.lawLists);

                if (!isCreateMode) {
                    const selectedRows: Record<string, boolean> = {};
                    subscriptions.sort();

                    subscriptions.forEach(subscription => {
                        selectedRows[subscription.subscriptionId] = false;
                    });
                    setSelectedRows(selectedRows);
                }

                setLawListGroupFilter(lawListGroupFilter);
                setLawTypeFilter(convertToLawTypeFilter(lawTypes));
                setData(subscriptions);
                setVisibleDataCount(subscriptions.length)
                setCreateMode(isCreateMode);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData(location.pathname.indexOf(SETTINGS_COMPANY_DETACHED_COPIES_CREATE) > -1);
    }, []);

    useEffect(() => {
        setColumns(s =>
            s.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        );
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        // Apply lawGroup filtering
        const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
        let filteredData = data.filter(obj => selectedLawGroupIds.includes(obj.lawGroupId));

        // Apply lawList filtering
        const selectedLawListIds = lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId));
        if (selectedLawListIds.length) {
            filteredData = filteredData.filter(f => selectedLawListIds.some(id => f.lawLists.find(lawList => lawList.lawListId === id)));
        }

        // Apply keyword filtering
        const selectedKeywordIds = selectedKeywords.filter(kw => kw.checked).map(kw => kw.id);
        if (selectedKeywordIds.length) {
            filteredData = filteredData.filter(f => selectedKeywordIds.some(id => f.keywordIds.includes(id)));
        }

        if (filteredData[selectedIndex] !== data[selectedIndex]) {
            setSelectedIndex(-1);
        }

        setFilteredData(filteredData);
    }, [data, lawTypeFilter, lawListGroupFilter, selectedKeywords]);

    const hiddenColumns = useMemo(() => columns.filter(col => !col.visible).map(col => col.id), [columns]);

    const handleConfirmCreate = async (lawListIds: number[], close: boolean) => {
        try {
            setLoading(true);
            const newSub = await DetachedCopiesService().createDetachedCopy(selectedRow, lawListIds);
            if (close) {
                setLoading(false);
                history.push(SETTINGS_COMPANY_DETACHED_COPIES);
            } else {
                const original = newSub.find(s1 => s1.subscriptionId === selectedRow);
                const copy = newSub.find(s1 => s1.subscriptionId !== selectedRow);
                if (original && copy) {
                    setData(s => {
                        const newData = [...s, copy].map(sub => {
                            return sub.subscriptionId === original.subscriptionId ? original : sub;
                        });
                        newData.sort(
                            (a, b) =>
                                sortLawTypes(a.lawType, b.lawType) ||
                                (a.lawGroupName < b.lawGroupName ? -1 : a.lawGroupName > b.lawGroupName ? 1 : a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
                        );
                        return newData;
                    });
                }
                setSelectedRow(-1);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleConfirmRemove = async () => {
        try {
            setLoading(true);

            await DetachedCopiesService().deleteDetachedCopy(selectedSubscriptionIds);

            selectedSubscriptionIds.forEach(id => {
                setData(list => list.filter(item => item.subscriptionId !== id));
            });

            setLoading(false);
            history.push(SETTINGS_COMPANY_DETACHED_COPIES);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <div className={styled.EditDetachedCopies}>
            {loading && <LoadingSpinner />}
            <RevisionTopbar title={t('settingsMenuCompanyDetachedCopies')} backUrl={SETTINGS_COMPANY_DETACHED_COPIES} />
            <div className={styled.Content}>
                <ContextMenu
                    lawTypeFilter={lawTypeFilter}
                    hasCustomLaws={false}
                    onToggleLawTypeOpen={handleToggleLawTypeOpen}
                    onToggleLawTypeChecked={handleToggleLawTypeChecked}
                    onToggleLawGroupChecked={handleToggleLawGroupChecked}
                    visibleDataCount={visibleDataCount}
                    totalDataCount={data.length}
                    onResetLawTypeFilter={handleResetLawTypeFilter}
                    onResetAllFilters={handleResetAllFilters}
                    onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                    links={CONTEXT_LINKS}
                />
                <div className={styled.List}>
                    <Filterbar
                        globalSearch={globalSearch}
                        onGlobalSearchChange={setGlobalSearch}
                        selectedKeywords={selectedKeywords}
                        onSelectedKeywordChange={handleSelectedKeywordChange}
                        lawListGroupFilter={lawListGroupFilter}
                        onLawListGroupFilterChange={handleLawListGroupFilterChange}
                        onLawListFilterChange={handleLawListFilterChange}
                        showColumnSearch={showColumnSearch}
                        onColumnSearchToggle={handleToggleColumnSearch}
                        onColumnShowChange={handleToggleColumnVisible}
                        columns={columns.filter(col => !col.alwaysVisible)}
                    />

                    <div className={styled.TableWrapper}>
                        {isCreateMode !== undefined && (
                            <Table
                                columns={columns}
                                data={filteredData}
                                showColumnSearch={showColumnSearch}
                                globalSearch={globalSearch}
                                columnSearch={columnSearch}
                                selected={selectedIndex}
                                onSelect={setSelectedIndex}
                                onVisibleDataCountChange={setVisibleDataCount}
                                hiddenColumns={hiddenColumns}
                                sorting={sorting}
                                onSortingChange={setSorting}
                                selectedRow={selectedRow}
                                selectedRows={selectedRows}
                                onSelectedRowChange={handleSelectRowChange}
                                onSelectedRowsChange={handleSelectRowsChange}
                                singleSelect={isCreateMode}
                                multiSelect={!isCreateMode}
                            />
                        )}

                        <EditDetachedCopiesFullView open={selectedIndex > -1} data={filteredData[selectedIndex]} onClose={() => setSelectedIndex(-1)} />

                        {isCreateMode !== undefined && (
                            <EditDetachedCopiesToaster
                                isCreateMode={isCreateMode === true}
                                selectedCount={_.filter(selectedRows, row => row === true).length}
                                hasSelected={isCreateMode ? selectedRow > -1 : selectedSubscriptionIds.length > 0}
                                fullViewOpen={selectedIndex > -1}
                                onCancel={() => history.push(SETTINGS_COMPANY_DETACHED_COPIES)}
                                onConfirm={handleOpenModal}
                            />
                        )}
                    </div>

                    {createModal.open && createModal.name && selectedRow > -1 && (
                        <CreateDetachedCopiesModal subscriptionName={createModal.name} onCancel={() => setCreateModal({ open: false })} onConfirm={handleConfirmCreate} />
                    )}

                    {removeModalOpen && selectedSubscriptionIds.length > 0 && (
                        <RemoveDetachedCopiesModal
                            deleteWarning={deleteWarning}
                            selectedCount={selectedSubscriptionIds.length}
                            onCancel={() => setRemoveModalOpen(false)}
                            onConfirm={handleConfirmRemove}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditDetachedCopies;
