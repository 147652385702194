import { useContext } from 'react';
import { FinishedRevisionContext } from '../contexts/FinishedRevisionContext';
import useAuth from './useAuth';
import RevisionService from '../services/RevisionService';
import { SortingRule } from 'react-table';
import useExport from './useExport';

const useFinishedRevision = () => {
    const { state, setState } = useContext(FinishedRevisionContext);
    const { company, user } = useAuth();
    const { exportRevisionList } = useExport();

    const setLoading = (loading: boolean): void => {
        setState(s => ({
            ...s,
            loading,
        }));
    };

    const fetchData = async (fromDate: Date, toDate: Date) => {
        try {
            if (company && user) {
                setLoading(true);

                const from = fromDate.toLocaleDateString('sv').replace(/[^ -~]/g, '');
                const to = toDate.toLocaleDateString('sv').replace(/[^ -~]/g, '');
                const revisions = await RevisionService().getFinishedRevisions(company.id, from, to);

                setState(s => ({
                    ...s,
                    revisions,
                    loading: false,
                }));
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleGlobalSearchChange = (globalSearch: string): void => {
        setState(s => ({
            ...s,
            globalSearch,
        }));
    };

    const handleStartDateChange = (startDate: Date): void => {
        if (startDate !== null) {
            setState(s => ({
                ...s,
                startDate,
            }));
        }
    };

    const handleEndDateChange = (endDate: Date): void => {
        if (endDate !== null) {
            setState(s => ({
                ...s,
                endDate,
            }));
        }
    };

    const handleSortingChange = (sorting: SortingRule<object>[]): void => {
        setState(s => ({
            ...s,
            sorting,
        }));
    };

    const handleRemoveRevision = (revisionId: number): void => {
        RevisionService()
            .removeRevision(revisionId)
            .then(() => {
                setState(s => ({
                    ...s,
                    revisions: s.revisions.filter(rev => rev.id !== revisionId),
                }));
            });
    };

    const handleAlterRevision = (revisionId: number, version: number, revisionEditedName: string, responsibleUserId: number, callback: () => void): void => {
        if (company) {
            RevisionService()
                .updateRevision(company.id, revisionId, version, revisionEditedName, responsibleUserId)
                .then(() => {
                    setState(s => ({
                        ...s,
                        revisions: s.revisions.filter(rev => rev.id !== revisionId),
                    }));
                    callback();
                });
        }
    };

    const handleExport = async (revisionId: number) => {
        try {
            if (company && user) {
                setLoading(true);
                const revision = await RevisionService().getRevision(revisionId);
                const { subscriptionRevisions, customLawRevisions } = revision.revisionSubscriptionsListModel;
                const revisionName = 'Revision: ' + revision.name;
                const data = [...subscriptionRevisions, ...customLawRevisions];
                await exportRevisionList(data, `Revision avslutad ${revision.name}`, revision.comment, revisionName);

                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    return {
        ...state,
        fetchData,
        handleGlobalSearchChange,
        handleStartDateChange,
        handleEndDateChange,
        handleSortingChange,
        handleRemoveRevision,
        handleExport,
        handleAlterRevision,
    };
};

export default useFinishedRevision;
