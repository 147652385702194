import React, { FC, useState } from 'react';
import styled from './RevisionSettings.module.css';
import Input from '../../../../components/Input/Input';
import Toggle from '../../../../components/Toggle/Toggle';
import Button from '../../../../components/Button/Button';
import useAuth from '../../../../hooks/useAuth';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import CompanyService from '../../../../services/CompanyService';
import { useTranslation } from 'react-i18next';

const RevisionSettings: FC = () => {
    const { t } = useTranslation();
    const { company, updateRevisionWarning } = useAuth();

    const [loading, setLoading] = useState(false);
    const [interval, setInterval] = useState(company?.revisionWarningDays || company?.defaultRevisionWarningDays);
    const [active, setActive] = useState<boolean>(company?.disableRevisionWarning === false);

    const handleSave = async () => {
        if (company) {
            try {
                setLoading(true);
                const disableRevisionWarning = !active;
                const revisionWarningDays = await CompanyService().setRevisionWarningDays(company.id, interval ? interval : -1, disableRevisionWarning);
                updateRevisionWarning(revisionWarningDays, disableRevisionWarning);
                setInterval(revisionWarningDays);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleToggleActive = async () => {
        if (company) {
            try {
                setLoading(true);
                const newActive = !active;
                const revisionWarningDays = await CompanyService().setRevisionWarningDays(company.id, company.revisionWarningDays || -1, !newActive);
                updateRevisionWarning(revisionWarningDays, !newActive);
                setActive(newActive);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    return (
        <div className={styled.RevisionSettings}>
            {loading && <LoadingSpinner />}
            <h3 className={styled.Title}>{t('settingsMenuCompanyRevisions')}</h3>
            <div className={styled.Box}>
                <h6 className={styled.BoxTitle}>{t('companyRevisionSettingsTitle')}</h6>
                <p className={styled.BoxText}>{t('companyRevisionSettingsText')}</p>

                <div className={styled.ToggleContainer}>
                    <p>{t('companyRevisionSettingsToggle')}</p>
                    <Toggle checked={active} onChange={() => handleToggleActive()} className={styled.Toggle} />
                </div>

                <div className={styled.InputContainer}>
                    <p>{t('companyRevisionSettingsLabel')}</p>
                    <Input type="number" value={interval?.toString() || "0"} onChange={val => setInterval(parseInt(val))} className={styled.Input} disabled={!active} autoFocus />
                </div>

                {active && (
                    <div className={styled.ButtonContainer}>
                        <Button variant="Primary" className={styled.Button} onClick={handleSave} disabled={!active || interval === company?.revisionWarningDays}>
                            {t('buttonSave')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RevisionSettings;
