import React, { FC, useState, useEffect } from 'react';
import styled from './AcknowledgeEditView.module.css';
import SubscriptionModel, { getConsultantText } from '../../models/SubscriptionModel';
import TextEditor from '../TextEditor/TextEditor';
import Dropdown from '../Dropdown/Dropdown';
import _ from 'lodash';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import NavigationPrompt from 'react-router-navigation-prompt';
import useAuth from '../../hooks/useAuth';
import LawListDropdown from '../LawListDropdown/LawListDropdown';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../../models/LawListGroupFilter';
import { getMaxLengthValidations } from '../../constants/Validation';
import { useTranslation } from 'react-i18next';

interface Props {
    data: SubscriptionModel;
    customColumns: {
        id: number; // index
        accessor: string;
        Header: string;
        data: string;
        changed?: boolean;
    }[];
    headerTitle?: string;
    onClose: () => void;
    onSave: (subscriptionId: number, subscription?: SubscriptionModel, keywordIds?: number[], lawListIds?: number[]) => void;
}

const AcknowledgeEditView: FC<Props> = ({ data, customColumns, headerTitle, onClose, onSave }) => {
    const { t } = useTranslation();
    const textOnly: boolean = data.newLaw;
    const { company, isAdminSubs, isAboveSuperUser, isStandardCompany } = useAuth();
    const [selectedEditIndex, setSelectedEditIndex] = useState(isAboveSuperUser() && !isAdminSubs() && !isStandardCompany() ? 1 : 0);
    const [textsChanged, setTextsChanged] = useState(false);
    const [editingData, setEditingData] = useState(customColumns);

    const [keywordsChanged, setKeywordsChanged] = useState(false);
    const [keywords, setKeywords] = useState(!textOnly ? company?.keyWords.map(kw => ({ ...kw, checked: _.find(data.keywordIds, id => kw.id === id) !== undefined })) : undefined);

    const [lawListsChanged, setLawListsChanged] = useState(false);
    const [lawListGroups, setLawListGroups] = useState<LawListGroupFilter[]>(
        !textOnly
            ? () =>
                  convertToLawListGroupFilter(
                      company?.lawLists || [],
                      data.lawLists.map(ll => ll.lawListId),
                  )
            : [],
    );

    useEffect(() => {
        let isChanged = false;
        editingData.forEach(col => {
            if (!_.isEqual(_.get(col, 'data'), _.get(data, col.accessor))) {
                isChanged = true;
                col.changed = true;
            }
        });
        setTextsChanged(isChanged);
    }, [editingData]);

    useEffect(() => {
        if (!textOnly && keywords) {
            let isChanged = false;
            const keywordIds = keywords.filter(kw => kw.checked).map(kw => kw.id);
            if (!_.isEqual(data.keywordIds, keywordIds)) {
                isChanged = true;
            }
            setKeywordsChanged(isChanged);
        }
    }, [keywords]);

    useEffect(() => {
        if (!textOnly && lawListGroups) {
            let isChanged = false;
            const lawListIds = data.lawLists.map(ll => ll.lawListId);
            const selectedLawListIds = lawListGroups.flatMap(llg => llg.lawLists.filter(ll => ll.checked).map(ll => ll.lawListId));
            if (!_.isEqual(lawListIds, selectedLawListIds)) {
                isChanged = true;
            }
            setLawListsChanged(isChanged);
        }
    }, [lawListGroups]);

    const handleSelectEditIndex = (index: number) => {
        setSelectedEditIndex(index);
    };

    const handleChange = (htmlText: string) => {
        setEditingData(editingData =>
            editingData.map(col => {
                if (col.id === selectedEditIndex) {
                    return {
                        ...col,
                        data: htmlText,
                    };
                }
                return col;
            }),
        );
    };

    const handleSubmit = async () => {
        let subscription: SubscriptionModel | undefined;
        let keywordIds: number[] | undefined;
        let lawListIds: number[] | undefined;

        if (textsChanged) {
            const updatedData = {
                ...data,
            };
            editingData.forEach(col => {
                if (col.changed) {
                    _.set(updatedData, col.accessor, col.data);
                }
            });
            subscription = updatedData;

            const editChangeText = editingData.find(ed => ed.accessor === 'originalChangeText');
            if (editChangeText === undefined) {
                subscription.changeText = null;
            } else {
                subscription.changeText = editChangeText.data;
            }
        }

        if (!textOnly && keywords && keywordsChanged) {
            keywordIds = keywords.filter(kw => kw.checked).map(kw => kw.id);
        }

        if (!textOnly && lawListGroups && lawListsChanged) {
            lawListIds = lawListGroups.flatMap(llg => llg.lawLists.filter(ll => ll.checked).map(ll => ll.lawListId));
        }

        onSave(data.subscriptionId, subscription, keywordIds, lawListIds);
    };

    const handleKeywordSelect = (id: number) => {
        setKeywords(keywords =>
            keywords?.map(kw => ({
                ...kw,
                checked: kw.id === id ? !kw.checked : kw.checked,
            })),
        );
    };

    const handleLawListSelect = (lawListId: number) => {
        setLawListGroups(lawListGroups =>
            lawListGroups.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: lawList.lawListId === lawListId ? !lawList.checked : lawList.checked,
                })),
            })),
        );
    };

    const handleLawListGroupSelect = (lawListGroupId: number) => {
        setLawListGroups(lawListGroups =>
            lawListGroups.map(lawListGroup => {
                if (lawListGroup.lawListGroupId === lawListGroupId) {
                    return {
                        ...lawListGroup,
                        lawLists: lawListGroup.lawLists.map(lawList => ({
                            ...lawList,
                            checked: !lawListGroup.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return lawListGroup;
            }),
        );
    };

    return (
        <>
            <div className={styled.AcknowledgeEditView}>
                <div className={styled.Header}>
                    <span>{headerTitle ? headerTitle : t('acceptChangeTitle')}</span>
                </div>
                {editingData.length > 0 && (
                    <div className={[styled.Content, styled.TextEditor].join(' ')}>
                        <h4 className={styled.Title}>{t('buttonEditTexts')}</h4>
                        <TextEditor
                            title={
                                <Dropdown
                                    dropdownWidth={200}
                                    title={editingData[selectedEditIndex]?.Header}
                                    content={editingData.map(col => ({ ...col, text: col.Header }))}
                                    onChange={handleSelectEditIndex}
                                />
                            }
                            consultantText={isAboveSuperUser() ? getConsultantText(editingData[selectedEditIndex].accessor, data) : undefined}
                            initialValue={_.get(editingData[selectedEditIndex], 'data')}
                            onChange={handleChange}
                            maxLength={getMaxLengthValidations(true, editingData[selectedEditIndex].accessor)}
                        />
                    </div>
                )}
                {!textOnly && (company?.hasKeyWords || company?.hasLawLists) && (
                    <div className={styled.Content}>
                        <h4 className={styled.Title}>{t('buttonEditLawListAndKeyword')}</h4>
                        <div className={styled.Dropdowns}>
                            {company.hasLawLists && lawListGroups && (
                                <LawListDropdown
                                    title={t('columnLawLists')}
                                    lawListGroupFilter={lawListGroups}
                                    onLawListFilterChange={handleLawListSelect}
                                    onLawListGroupFilterChange={handleLawListGroupSelect}
                                />
                            )}
                            {company.hasKeyWords && keywords && <Dropdown title={t('columnKeywords').toString()} content={keywords} onChange={handleKeywordSelect} multiSelect />}
                        </div>
                    </div>
                )}
                <div className={styled.Footer}>
                    <Button variant="Outline" onClick={onClose}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={handleSubmit} disabled={!textsChanged && !keywordsChanged && !lawListsChanged}>
                        {t('buttonSave')}
                    </Button>
                </div>
            </div>

            <NavigationPrompt when={textsChanged || keywordsChanged || lawListsChanged}>
                {({ onCancel, onConfirm }) => (
                    <Modal title={t('leavePage')} subtitle={t('leavePageSavePrompt')}>
                        <div className={styled.ModalButtons}>
                            <div>
                                <Button
                                    variant="Primary"
                                    onClick={() => {
                                        onCancel();
                                    }}
                                >
                                    {t('buttonNo')}
                                </Button>
                                <Button
                                    variant="Outline"
                                    onClick={() => {
                                        onConfirm();
                                    }}
                                >
                                    {t('buttonYes')}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
            </NavigationPrompt>
        </>
    );
};

export default AcknowledgeEditView;
