import React, { FC } from 'react';
import Lottie from 'lottie-react-web';
import animationData from './loader_lottie.json';
import styled from './LoadingSpinner.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    message?: string;
    className?: string;
}

const LoadingSpinner: FC<Props> = ({ message, className = '' }) => {
    const { t } = useTranslation();
    return (
        <div className={[styled.Wrapper, className].join(' ')}>
            <div className={styled.LoadingCard}>
                <h6>{message ? message : t('loadingPage')}</h6>
                <Lottie
                    height={16}
                    width={56}
                    options={{
                        animationData,
                    }}
                />
            </div>
        </div>
    );
};

export default LoadingSpinner;
