import React, { FC } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './RemoveLawListGroupModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    onCancel: () => void;
    onConfirm: () => void;
}

const RemoveLawListGroupModal: FC<Props> = ({ onCancel, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('removeLawListGroupModalTitle')} subtitle={t('removeLawListGroupModalSubTitle')}>
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Outline" onClick={onConfirm}>
                    OK
                </Button>
            </div>
        </Modal>
    );
};

export default RemoveLawListGroupModal;
