import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from './CreateRevisionModal.module.css';
import Modal from '../Modal';
import Dropdown from '../../Dropdown/Dropdown';
import UserService from '../../../services/UserService';
import useAuth from '../../../hooks/useAuth';
import _ from 'lodash';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    edit:
        | {
              name: string;
              userId: number;
          }
        | undefined;
    onConfirm: (name: string, userId: number, username: string) => void;
    onCancel: () => void;
}

const CreateRevisionModal: FC<Props> = ({ edit, onConfirm, onCancel }) => {
    const { t, i18n } = useTranslation();
    const { company, user, isAboveSuperUser } = useAuth();
    const [name, setName] = useState(edit ? edit.name : '');
    const [companyUsers, setCompanyUsers] = useState<{ id: number; text: string }[]>([]);
    const [selectedUserId, setSelectedUserId] = useState(edit ? edit.userId : -1);

    useEffect(() => {
        if (company) {
            UserService()
                .getUsersWithRevisionRights(company.id)
                .then(users => {
                    setCompanyUsers(
                        users.map(u => ({
                            id: u.userId,
                            text: u.fullName,
                        })),
                    );

                    if (!edit) {
                        if (isAboveSuperUser()) {
                            const preselectedId = users.length > 0 ? users[0].userId : -1;
                            setSelectedUserId(preselectedId);
                        } else {
                            const preselectedId = user ? user.userId : users[0].userId;
                            setSelectedUserId(preselectedId);
                        }
                    }
                    //
                });
        }
    }, [company]);

    const dropdownTitle = useMemo(() => {
        const username = companyUsers.find(u => u.id === selectedUserId)?.text;

        if (username) {
            return username;
        }
        return <p>{t('buttonSelectUsers')}</p>;
    }, [companyUsers, selectedUserId, i18n.language]);

    const handleSelectUser = (id: number) => {
        setSelectedUserId(id);
    };

    const handleConfirm = () => {
        if (selectedUserId && selectedUserId > -1 && !_.isEmpty(name)) {
            onConfirm(name, selectedUserId, _.find(companyUsers, u => u.id === selectedUserId)?.text || '');
        }
    };

    return (
        <Modal title={edit ? t('editRevisionModalTitle') : t('createRevisionModalTitle')} className={styled.Modal}>
            <div className={styled.Content}>
                <p className={styled.Label}>{t('createRevisionModalNameLabel')}</p>
                <input maxLength={499} type="text" className={styled.Input} placeholder={t('columnName')} value={name} onChange={e => setName(e.target.value)} autoFocus />

                <p className={styled.Label}>{t('columnResponsible')}</p>
                <Dropdown title={dropdownTitle} content={companyUsers} onChange={handleSelectUser} className={styled.Dropdown} />

                <div className={styled.Options}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={handleConfirm} disabled={!selectedUserId || selectedUserId < 0 || _.isEmpty(name)}>
                        {edit ? t('buttonSave') : t('buttonSelectLaws')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateRevisionModal;
