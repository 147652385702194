import React, { FC, useState, useEffect } from 'react';
import styled from './Contact.module.css';
import Input from '../../../components/Input/Input';
import useAuth from '../../../hooks/useAuth';
import * as VALIDATION from '../../../constants/Validation';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Button from '../../../components/Button/Button';
import HelpService from '../../../services/HelpService';
import ContactMail, { SubjectType, SubjectEnum } from '../../../models/ContactMail';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import AcceptanceLogModel from '../../../models/AcceptanceLogModel';
import { ChangeActionEnum } from '../../../models/ChangeAction';
import { useTranslation, Trans } from 'react-i18next';
import Modal from '../../../components/Modal/Modal';
import UserModel from '../../../models/UserModel';

const Contact: FC = () => {
    const { t } = useTranslation();

    const SUBJECTS: { id: number; text: string; value: SubjectType }[] = [
        { id: 0, text: t('subjectNewLaw'), value: 'NEW_LAW' },
        { id: 1, text: t('subjectSuperuser'), value: 'SUPERUSER' },
        { id: 2, text: t('subjectRequestAccess'), value: 'REQUEST_ACCESS' },
        { id: 3, text: t('subjectRequestKeywords'), value: 'REQUEST_KEYWORDS' },
        { id: 4, text: t('subjectRequestRollback'), value: 'REQUEST_ROLLBACK' },
        { id: 5, text: t('subjectError'), value: 'ERROR' },
        { id: 6, text: t('subjectSuggestion'), value: 'SUGGESTION' },
        { id: 7, text: t('subjectInformation'), value: 'INFORMATION' },
        { id: 8, text: t('subjectQuestion'), value: 'QUESTION' },
    ];

    const location = useLocation();
    const { user, company } = useAuth();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<{ value: string; error?: string }>({ value: user?.userName || '' });
    const [selectedSubject, setSelectedSubject] = useState(-1);
    const [message, setMessage] = useState('');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const handleEmailChange = (value: string): void => {
        setEmail(s => ({ ...s, value, error: VALIDATION.userName(value) }));
    };

    const resetState = () => {
        setEmail({ value: user?.userName || '' });
        setSelectedSubject(-1);
        setMessage('');
    };

    const handleSubmit = async () => {
        if (!email.error && SUBJECTS[selectedSubject] && company) {
            try {
                setLoading(true);
                const contactMail: ContactMail = {
                    email: email.value,
                    companyId: company.id,
                    subject: SUBJECTS[selectedSubject].text,
                    message: message.split('\n').join('<br>'),
                };
                await HelpService().sendContactMail(contactMail);
                resetState();
                setConfirmModalOpen(true);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (location.state) {
            const subject: SubjectType = _.get(location.state, 'subject');
            if (subject && subject in SubjectEnum) {
                setSelectedSubject(SUBJECTS.findIndex(s => s.value === subject));

                if (subject === 'REQUEST_ROLLBACK' && _.get(location.state, 'acceptanceLog') && company) {
                    const acceptanceLog: AcceptanceLogModel = _.get(location.state, 'acceptanceLog');
                    setMessage(
                        t('contactRequestRollbackMessage', {
                            name: acceptanceLog.name,
                            subId: acceptanceLog.subId,
                            date: acceptanceLog.logDate,
                            action: t(ChangeActionEnum[acceptanceLog.changeAction]),
                            company: company.name,
                        }),
                    );
                }

                if (subject === 'REQUEST_ACCESS' && _.get(location.state, 'duplicateUser') && company) {
                    const duplicateUser: UserModel = _.get(location.state, 'duplicateUser');
                    setMessage(
                        t('contactRequestAccessForExternalUser', {
                            companyName: company.name,
                            externalUserName: duplicateUser.fullName,
                            externalUserEmail: duplicateUser.userName,
                            externalUserId: duplicateUser.userId,
                            sendersName: user?.fullName,
                        }),
                    );
                }
            }
        }
    }, []);

    return (
        <div className={styled.Contact}>
            {loading && <LoadingSpinner />}
            <div className={styled.Information}>
                <h6>{t('contactTitle')}</h6>
                <p>
                    <Trans i18nKey="contactText" components={[<a href="tel:+46106156000" key={0} />, <a href="mailto:lagbevakning@ramboll.se" key={1} />]} />
                </p>
            </div>

            <div className={styled.Form}>
                <div className={styled.FormHeader}>
                    <h6>{t('contactQuestionnaire')}</h6>
                </div>
                <div className={styled.FormContent}>
                    <Input value={email.value} onChange={handleEmailChange} label={t('inputFieldYourEmail')} error={email.error} type="email" />

                    <div className={styled.DropdownInput}>
                        <p>{t('inputFieldSubject')}</p>
                        <Dropdown title={SUBJECTS[selectedSubject]?.text || t('inputFieldSelectSubject').toString()} content={SUBJECTS} onChange={setSelectedSubject} />
                    </div>

                    <div className={styled.Textarea}>
                        <p>{t('inputFieldMessage')}</p>
                        <textarea value={message} onChange={e => setMessage(e.target.value)} autoFocus />
                    </div>

                    <div className={styled.Button}>
                        <Button variant="Primary" onClick={handleSubmit} disabled={email.error !== undefined || !message.length || selectedSubject < 0}>
                            {t('buttonSend')}
                        </Button>
                    </div>
                </div>
            </div>

            {confirmModalOpen && (
                <Modal title={t('contactModalTitle')} subtitle={t('contactModalText')}>
                    <Button className={styled.ConfirmModalButton} variant="Primary" onClick={() => setConfirmModalOpen(false)}>
                        {t('buttonClose')}
                    </Button>
                </Modal>
            )}
        </div>
    );
};

export default Contact;
