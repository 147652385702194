import React, { FC } from 'react';
import styled from './RemoveKeywordModal.module.css';
import Modal from '../Modal';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    onCancel: () => void;
    onConfirm: () => void;
}

const RemoveKeywordModal: FC<Props> = ({ onCancel, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('removeKeywordModalTitle')} subtitle={t('removeKeywordModalSubTitle')}>
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Outline" onClick={onConfirm}>
                    OK
                </Button>
            </div>
        </Modal>
    );
};

export default RemoveKeywordModal;
