import React, { FC, useMemo, useState } from 'react';
import styled from './AcknowledgeChangeView.module.css';
import RadioButton from '../RadioButton/RadioButton';
import TextEditor, { EMPTY_HTML_TEXT } from '../TextEditor/TextEditor';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import Toggle from '../Toggle/Toggle';
import SubscriptionModel from '../../models/SubscriptionModel';
import useAuth from '../../hooks/useAuth';
import { acceptanceLogCommentMaxLength } from '../../constants/Validation';
import UserModel from '../../models/UserModel';
import { AssessmentChoice } from '../../services/SubscriptionChangeService';
import { useTranslation } from 'react-i18next';
import revisionQuestionNotificationIcon from '../../assets/images/errorIcon.svg';
import _ from 'lodash';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface Props {
    data: SubscriptionModel;
    consultantStage?: boolean;
    assessmentUsers: UserModel[];
    editDisabled: boolean;
    onEdit: () => void;
    onEditRevisionQuestions?: () => void;
    onClose: () => void;
    onSubmit: (data: { comment: string | undefined; assessmentUsers: number[]; notifyEmail: boolean; assessmentChoice: AssessmentChoice }) => void;
    showDelagation: boolean;
}

const AcknowledgeChangeView: FC<Props> = ({ data, consultantStage, assessmentUsers, editDisabled, onEdit, onEditRevisionQuestions, onClose, onSubmit, showDelagation }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const singleUser = assessmentUsers.length === 0;

    const isDelegated = data?.acceptanceStatus === 'DELEGATED_ME';

    const {
        company,
        isAdminSubs,
        isEditor,
        isDelegateAssessor,
        isGroupCompany,
        isGroupHasRevisionQuestions,
        isRevisor,
        isAboveSuperUser,
        isAssessor,
        isStandardCompany,
    } = useAuth();
    const [selectedRadioButton, setSelectedRadioButton] = useState<AssessmentChoice>(data.partlyAccepted || !isStandardCompany() ? 'ACCEPT' : 'DECLINE');
    const [comment, setComment] = useState<{ value: string; error?: boolean }>(() => {
        let defaultComment = '';
        if (selectedRadioButton === 'DECLINE') {
            if (consultantStage && isGroupCompany()) {
                defaultComment = t('defaultCommentDeclineGeneral');
            } else {
                defaultComment = t('defaultCommentDecline', { companyNames: company?.name });
            }
        }
        return {
            value: defaultComment,
            error: undefined,
        };
    });
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>(isDelegated ? data.waitingForAcceptance.map(u => u.userId) : []);
    const [hasText, setHasText] = useState(false);
    const [hasEditTextEditor, setHasEditTextEditor] = useState(false);
    const [notifyEmail, setNotifyEmail] = useState(!consultantStage);

    const handleSubmit = () => {
        setLoading(true);
        setTimeout(() => {
            setLoadingSpinner(true);
        }, 1000);

        const text = hasText ? comment.value : '';
        const data = {
            comment: text,
            assessmentUsers: isDelegated && !isDelegateAssessor() ? [] : selectedUserIds,
            notifyEmail,
            assessmentChoice: selectedRadioButton,
        };
        onSubmit(data);
    };

    const handleOptionChange = (option: AssessmentChoice) => {
        if (option === 'FORWARD_NO_CHOICE' && consultantStage) {
            setComment({ value: t('defaultCommentForward') });
        } else if (option === 'FORWARD_NO_CHOICE' && !consultantStage && assessmentUsers.length === 1 && !isGroupCompany()) {
            setSelectedUserIds([assessmentUsers[0].userId]);
        } else if (option === 'DECLINE' && !isDelegated && _.isEmpty(data.waitingForAcceptance)) {
            setSelectedUserIds([]);
            if (consultantStage && isGroupCompany()) {
                setComment({ value: t('defaultCommentDeclineGeneral') });
            } else {
                setComment({ value: t('defaultCommentDecline', { companyNames: company?.name }) });
            }
        } else {
            setComment({ value: '' });
        }
        setHasEditTextEditor(false);
        setSelectedRadioButton(option);
    };

    const handleUserChange = (id: number) => {
        setSelectedUserIds(uids => (uids.includes(id) ? uids.filter(uid => uid !== id) : [...uids, id]));
    };

    const hasRevisionQuestionNotification = useMemo(() => {
        if (company && company.hasRevisionQuestions) {
            const revisionQuestions = [...data.revisionQuestionGroups.flatMap(rqg => rqg.revisionQuestions), ...data.customRevisionQuestions];
            return revisionQuestions.some(rq => rq.changedNotification);
        }
        return false;
    }, [company, data]);

    const consultantSaveButtonDisabled = consultantStage && ((!isGroupCompany() && !hasText) || comment.error);
    const saveButtonDisabled = !consultantStage && (!hasText || (selectedRadioButton === 'FORWARD_NO_CHOICE' && !selectedUserIds.length) || comment.error);

    const consultantButtonText =
        selectedRadioButton === 'ACCEPT' ? t('buttonSendToCustomer') : selectedRadioButton === 'DECLINE' ? t('buttonSendToArchive') : t('buttonAcceptChange');

    const handleCommentChange = (value: string) => {
        if (value !== EMPTY_HTML_TEXT && !hasEditTextEditor) {
            setHasEditTextEditor(true);
        }
        setComment(s => ({ ...s, value }));
    };

    return (
        <div className={styled.AcknowledgeChangeView}>
            {loadingSpinner && <LoadingSpinner message={t('spinnerAcceptChangeLoading')} />}
            <div className={styled.CloseBar}>
                <span>{t('acceptChangeTitle')}</span>
            </div>
            <div className={[styled.Content].join(' ')}>
                <h4 className={styled.Title}>{t('acceptChangeOptionTitle')}</h4>
                <div className={styled.RadioButtons}>
                    <RadioButton checked={selectedRadioButton === 'ACCEPT'} onChange={() => handleOptionChange('ACCEPT')} text={t('acceptChangeOptionAccept')} group="radio" />
                    <RadioButton checked={selectedRadioButton === 'DECLINE'} onChange={() => handleOptionChange('DECLINE')} text={t('acceptChangeOptionDecline')} group="radio" />
                    {!singleUser && (
                        <RadioButton
                            checked={selectedRadioButton === 'FORWARD_NO_CHOICE'}
                            onChange={() => handleOptionChange('FORWARD_NO_CHOICE')}
                            text={consultantStage ? t('acceptChangeOptionForwardPlus') : t('acceptNewChangeOptionForward')}
                            group="radio"
                        />
                    )}
                </div>
            </div>
            <div className={[styled.Content, styled.Comment].join(' ')}>
                <TextEditor
                    title={t('columnComment')}
                    optionalTitle={'(' + t('inputFieldErrorRequired2') + ')'}
                    initialValue={comment.value}
                    onChange={value => handleCommentChange(value)}
                    hasText={setHasText}
                    maxLength={acceptanceLogCommentMaxLength}
                    onMaxLengthError={error => setComment(s => ({ ...s, error }))}
                    errorMsg={!hasText && hasEditTextEditor ? t('inputFieldErrorRequired') : ''}
                />
            </div>
            <div className={[styled.Content, selectedRadioButton === 'DECLINE' || !isEditor() ? styled.Disabled : ''].join(' ')}>
                <h4 className={styled.Title}>{t('buttonEdit')}</h4>
                <div className={styled.Buttons}>
                    <Button variant="Outline" onClick={onEdit} disabled={editDisabled}>
                        {t('buttonEditTexts')}
                    </Button>
                    {((company && company.hasRevisionQuestions && !isGroupCompany()) ||
                        (company && company.hasRevisionQuestions && isGroupCompany() && isGroupHasRevisionQuestions())) && (
                        <Button
                            variant="Outline"
                            onClick={onEditRevisionQuestions}
                            disabled={(!isRevisor() && !isAboveSuperUser()) || (isAdminSubs() && consultantStage) || (!isAdminSubs() && isGroupCompany() && !isAboveSuperUser())}
                            className={styled.RevisionQuestionButton}
                        >
                            {t('buttonEditRevisionQuestions')} {hasRevisionQuestionNotification && <img src={revisionQuestionNotificationIcon} />}
                        </Button>
                    )}
                </div>
            </div>
            {showDelagation && !singleUser && (
                <div className={[styled.Content, selectedRadioButton === 'DECLINE' || !isDelegateAssessor() ? styled.Disabled : ''].join(' ')}>
                    <h4 className={styled.Title}>{consultantStage ? t('acceptChangeSetDelegatesTitle') : t('acceptChangeDelegatesRequiredTitle')}</h4>
                    <div className={styled.SelectUsers}>
                        <Dropdown
                            title={t('buttonSelectUsers').toString()}
                            content={assessmentUsers.map(u => ({
                                id: u.userId,
                                text: u.fullName,
                                checked: selectedUserIds.includes(u.userId),
                                ...(isDelegated ? { disabled: data.waitingForAcceptance.find(wfa => wfa.userId === u.userId) !== undefined } : {}),
                            }))}
                            onChange={handleUserChange}
                            multiSelect
                        />
                        <Toggle checked={notifyEmail} onChange={() => setNotifyEmail(notify => !notify)} title={t('buttonNotifyByEmail')} />
                    </div>
                </div>
            )}
            <div className={styled.Footer}>
                <Button variant="Outline" disabled={loading} onClick={onClose}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Primary" disabled={(consultantStage ? consultantSaveButtonDisabled : saveButtonDisabled) || loading} onClick={handleSubmit}>
                    {consultantStage ? consultantButtonText : t('buttonAcceptChange')}
                </Button>
            </div>
        </div>
    );
};

export default AcknowledgeChangeView;
