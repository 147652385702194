import React, { FC, useState, useMemo } from 'react';
import Modal from '../Modal';
import styled from './CreateDetachedCopiesModal.module.css';
import Button from '../../Button/Button';
import warningIcon from '../../../assets/images/statusNotOkIcon.svg';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../../../models/LawListGroupFilter';
import useAuth from '../../../hooks/useAuth';
import LawListDropdown from '../../LawListDropdown/LawListDropdown';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    subscriptionName: string;
    onCancel: () => void;
    onConfirm: (lawListIds: number[], close: boolean) => void;
}

const CreateDetachedCopiesModal: FC<Props> = ({ subscriptionName, onCancel, onConfirm }) => {
    const { t } = useTranslation();
    const { company } = useAuth();

    const [lawLists, setLawLists] = useState<LawListGroupFilter[]>(() => (company && company.hasLawLists ? convertToLawListGroupFilter(company.lawLists) : []));

    const handleLawListChange = (lawListId: number): void => {
        setLawLists(s =>
            s.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: lawList.lawListId === lawListId ? !lawList.checked : lawList.checked,
                })),
            })),
        );
    };

    const handleLawListGroupChange = (lawListGroupId: number): void => {
        setLawLists(s =>
            s.map(lawListGroup => {
                if (lawListGroup.lawListGroupId === lawListGroupId) {
                    return {
                        ...lawListGroup,
                        lawLists: lawListGroup.lawLists.map(lawList => ({
                            ...lawList,
                            checked: !lawListGroup.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return lawListGroup;
            }),
        );
    };

    const selectedLawLists = useMemo(() => lawLists.flatMap(llg => llg.lawLists.filter(ll => ll.checked)), [lawLists]);

    const handleConfirmAndClose = () => {
        const lawListIds = selectedLawLists.map(ll => ll.lawListId);
        onConfirm(lawListIds, true);
    };

    const handleConfirm = () => {
        const lawListIds = selectedLawLists.map(ll => ll.lawListId);
        onConfirm(lawListIds, false);
    };

    return (
        <Modal title={t('createDetachedCopiesModalTitle')} className={styled.CreateDetachedCopiesModal}>
            <div className={styled.Content}>
                <div className={styled.DropdownInput}>
                    <p>{t('inputFieldDetachedCopySelectLawList')}</p>
                    <LawListDropdown
                        reversed
                        title={t('inputFieldPlaceholderSelectLawList')}
                        lawListGroupFilter={lawLists}
                        onLawListFilterChange={handleLawListChange}
                        onLawListGroupFilterChange={handleLawListGroupChange}
                    />
                </div>

                {selectedLawLists.length > 0 && (
                    <div className={styled.Warning}>
                        <img src={warningIcon} alt="!" />
                        <p>
                            <Trans
                                i18nKey="createDetachedCopiesModalText"
                                components={[<strong key="0" />]}
                                values={{ subscriptionName, selectedLawLists: selectedLawLists.length }}
                            />
                        </p>
                    </div>
                )}

                <div className={styled.Buttons}>
                    <Button variant="Primary" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Outline" onClick={handleConfirmAndClose} disabled={selectedLawLists.length < 1}>
                        {t('buttonCreateAndClose')}
                    </Button>
                    <Button variant="Outline" onClick={handleConfirm} disabled={selectedLawLists.length < 1}>
                        {t('buttonCreate')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateDetachedCopiesModal;
