import React, { FC, useMemo } from 'react';
import Button from '../Button/Button';
import styled from './CreateRevisionToaster.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedCount: number;
    fullViewOpen: boolean;
    onCreate: () => void;
    onCancel: () => void;
    alterRevisionMode: boolean;
}

const CreateRevisionToaster: FC<Props> = ({ selectedCount, fullViewOpen, onCancel, onCreate, alterRevisionMode }) => {
    const { t, i18n } = useTranslation();
    const text = useMemo(() => (selectedCount > 0 ? t('createRevisionToasterText', { selectedCount }) : t('createRevisionToasterEmptyText')), [selectedCount, i18n.language]);

    return (
        <div className={[styled.Toaster, fullViewOpen ? styled.FullViewOpen : ''].join(' ')}>
            <div className={styled.Text}>
                {!alterRevisionMode && <h6>{t('createRevisionToasterTitle')}</h6>}
                {alterRevisionMode && <h6>{t('alterRevisionToasterTitle')}</h6>}

                <p>{text}</p>
                {alterRevisionMode && <p>{t('alterRevisionToasterText2')}</p>}
            </div>
            <div className={styled.Buttons}>
                <Button variant="Primary" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Light" onClick={onCreate} disabled={selectedCount < 1}>
                    {!alterRevisionMode && t('buttonCreateRevision')}
                    {alterRevisionMode && t('buttonAlterRevision')}
                </Button>
            </div>
        </div>
    );
};

export default CreateRevisionToaster;
