import { UNGROUPED_LAWLIST_ID } from '../models/LawListModel';
import _ from 'lodash';
import RevisionSubscriptionModel, { isRevisionSubscription } from '../models/RevisionSubscriptionModel';
import CustomLawRevisionModel from '../models/CustomLawRevisionModel';

interface LawList {
    lawListGroupId: number;
    name: string;
}

export const sortLawLists = (a: LawList, b: LawList): number => {
    // Sort ungrouped last in array
    if (a.lawListGroupId === UNGROUPED_LAWLIST_ID) return 1;
    if (b.lawListGroupId === UNGROUPED_LAWLIST_ID) return -1;

    // Sort by name for rest
    if (a.name > b.name) return 1;
    if (b.name > a.name) return -1;
    return 0;
};

export const sortRevisionQuestionGroups = (sub: RevisionSubscriptionModel): { title: string; revisionQuestions: string[] }[] => {
    const revisionQuestionGroups: { title: string; revisionQuestions: string[] }[] = [];

    _.sortBy(sub.revisionQuestionGroups, 'titleOrder')
        .map(rqg => ({
            ...rqg,
            revisionQuestions: rqg.revisionQuestions
                .filter(rq => !rq.excluded)
                .sort((a, b) =>
                    a.isCustomQuestion && b.isCustomQuestion
                        ? a.id > b.id
                            ? 1
                            : a.id < b.id
                            ? -1
                            : 0
                        : a.questionOrder! > b.questionOrder!
                        ? 1
                        : a.questionOrder! < b.questionOrder!
                        ? -1
                        : 0,
                ),
        }))
        .forEach(rqg => {
            if (rqg.revisionQuestions.length > 0) {
                const groupIndex = revisionQuestionGroups.findIndex(({ title }) => title === rqg.title);
                if (groupIndex > -1) {
                    revisionQuestionGroups[groupIndex] = {
                        ...revisionQuestionGroups[groupIndex],
                        revisionQuestions: [...revisionQuestionGroups[groupIndex].revisionQuestions, ...rqg.revisionQuestions.map(rq => rq.question)],
                    };
                } else {
                    revisionQuestionGroups.push({ title: rqg.title, revisionQuestions: rqg.revisionQuestions.map(rq => rq.question) });
                }
            }
        });

    return revisionQuestionGroups;
};
