import React, { FC, useState, useEffect } from 'react';
import styled from './RevisionComment.module.css';
import commentActiveIcon from '../../assets/images/commentActiveIcon.svg';
import commentIcon from '../../assets/images/commentIcon.svg';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    comment: string;
    disabled: boolean;
    onSubmit: (comment: string) => void;
}

const RevisionComment: FC<Props> = ({ comment = '', disabled, onSubmit }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(comment);

    useEffect(() => {
        setValue(comment);
    }, [comment]);

    const handleSubmit = () => {
        onSubmit(value);
        setOpen(!open);
    };

    return (
        <div className={styled.Container}>
            <img src={comment && comment.length ? commentActiveIcon : commentIcon} alt="comment" onClick={() => setOpen(!open)} />

            {open && (
                <div className={[styled.Content, disabled ? styled.Disabled : ''].join(' ')}>
                    <h3 className={styled.Title}>{t('columnComment')}</h3>
                    {comment && <div className={styled.Comments}>{comment}</div>}
                    <textarea className={styled.Input} value={value || ''} onChange={e => setValue(e.target.value)} autoFocus />
                    <Button variant="Primary" className={styled.Button} onClick={handleSubmit} disabled={comment === value}>
                        {t('buttonSaveComment')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default RevisionComment;
