import React, { FC } from 'react';
import styled from './RevisionDateCell.module.css';
import { CellProps } from 'react-table';
import _ from 'lodash';
import useAuth from '../../../hooks/useAuth';

const RevisionDateCell: FC<CellProps<any>> = ({ row }) => {
    const { company } = useAuth();
    const latestRevisionDate = _.get(row.original, 'latestRevisionDate');
    const hasRevisionWarning = _.get(row.original, 'hasRevisionWarning');

    return (
        <div
            className={[styled.RevisionDateCell, hasRevisionWarning ? styled.RevisionWarning : ''].join(' ')}
            title={hasRevisionWarning ? `Denna lag är inte reviderad på över ${company?.revisionWarningDays || company?.defaultRevisionWarningDays} dagar!` : undefined}
        >
            {latestRevisionDate || 'Aldrig'}
        </div>
    );
};

export default RevisionDateCell;
