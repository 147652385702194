import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import Modal from '../Modal';
import styled from './SaveSignedInAsUserFilterModal.module.css';

interface Props {
    onCancel: () => void;
    onConfirm: () => void;
}

const SaveSignedInAsUserFilterModal: FC<Props> = ({ onCancel, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('saveSignedInAsUserFilterModalTitle')} subtitle={t('saveSignedInAsUserFilterModalSubTitle')}>
            <div className={styled.Options}>
                <Button variant="Outline" onClick={onCancel}>
                    Avbryt
                </Button>
                <Button variant="Primary" onClick={onConfirm}>
                    OK
                </Button>
            </div>
        </Modal>
    );
};

export default SaveSignedInAsUserFilterModal;
