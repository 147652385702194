import React, { useState, FC } from 'react';
import styled from './RevisionQuestionItem.module.css';
import Checkbox from '../Checkbox/Checkbox';
import saveIcon from '../../assets/images/saveIcon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    onSave: (question: string, excluded: boolean) => void;
}

const CreateRevisionQuestionItem: FC<Props> = ({ onSave }) => {
    const { t } = useTranslation();
    const [excluded, setExcluded] = useState(false);
    const [question, setQuestion] = useState('');

    const handleSave = () => {
        if (question.length > 0) {
            onSave(question, excluded);
            setQuestion('');
        }
    };

    return (
        <div className={styled.RevisionQuestionItem}>
            <div className={styled.Checkbox}>
                <Checkbox big checked={!excluded} onChange={() => setExcluded(!excluded)} />
            </div>
            <textarea
                rows={2}
                autoFocus
                value={question}
                maxLength={500}
                onChange={e => setQuestion(e.target.value)}
                placeholder={t('inputFieldPlaceholderRevisionQuestion')}
                onFocus={e => {
                    const val = e.target.value;
                    e.target.value = '';
                    e.target.value = val;
                }}
            />
            <img src={saveIcon} alt="Save" onClick={handleSave} className={question.length < 1 ? styled.Disabled : ''} />
        </div>
    );
};

export default CreateRevisionQuestionItem;
