import React, { FC } from 'react';
import styled from './DetachedCopyFullViewHeader.module.css';
import Button from '../Button/Button';
import { useHistory } from 'react-router-dom';
import { SETTINGS_COMPANY_DETACHED_COPIES } from '../../constants/Routes';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface Props {
    withLink?: boolean;
}

const DetachedCopyFullViewHeader: FC<Props> = ({ withLink }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { isAboveUser } = useAuth();

    return (
        <div className={styled.DetachedCopyFullViewHeader}>
            {t('detachedCopy')}
            {withLink && isAboveUser() && (
                <Button variant="Outline" onClick={() => history.push(SETTINGS_COMPANY_DETACHED_COPIES)}>
                    {t('buttonManageDetachedCopies')}
                </Button>
            )}
        </div>
    );
};

export default DetachedCopyFullViewHeader;
