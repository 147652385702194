import React, { FC } from 'react';
import styled from './MonitorToaster.module.css';
import Button from '../Button/Button';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    fullViewOpen: boolean;
    monitoredLawsCount: number;
    addedCount: number;
    removedCount: number;
    onCancel: () => void;
    onSave: () => void;
}

const MonitorToaster: FC<Props> = ({ fullViewOpen, monitoredLawsCount, addedCount, removedCount, onCancel, onSave }) => {
    const { t } = useTranslation();
    return (
        <div className={[styled.MonitorToaster, fullViewOpen ? styled.FullViewOpen : ''].join(' ')}>
            <div className={styled.Text}>
                <h6>
                    <Trans i18nKey="lawLibraryToasterTitle" values={{ monitoredLawsCount, addedCount, removedCount }} components={{ span: <span /> }} />
                </h6>
                <p>{t('lawLibraryToasterText')}</p>
            </div>
            <div className={styled.Options}>
                <Button variant="Secondary" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Light" onClick={onSave}>
                    {t('buttonSave')}
                </Button>
            </div>
        </div>
    );
};

export default MonitorToaster;
