import React, { FC, useMemo } from 'react';
import styled from './AccordionServiceItem.module.css';
import { useTranslation } from 'react-i18next';
import ServiceInvoiceModel from '../../../models/ServiceInvoiceModel';

interface Props {
    data: ServiceInvoiceModel[];
}

const AccordionServiceItem: FC<Props> = ({ data }) => {
    const { t } = useTranslation();

    const totalCost: number = useMemo<number>(
        () => data.map(item => item.price * (item.quantity ? item.quantity : 1)).reduce<number>((sum: number, current: number) => sum + current, 0),
        [data],
    );
    return (
        <>
            {data && data.length
                ? data.map(item => (
                      <div key={item.title} className={styled.Container}>
                          <table>
                              <tbody>
                                  <tr className={styled.UpperRow}>
                                      <td className={styled.Title}>{item.title}</td>
                                      {item.quantity && <td className={styled.Quantity}>Antal: {item.quantity}</td>}
                                      <td className={styled.Price}>Pris: {item.price} kr</td>
                                  </tr>

                                  <tr className={styled.LowerRow}>
                                      <td className={styled.Description}>
                                          <td>{item.description}</td>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  ))
                : 'Inga tjänster'}
            <div className={styled.TotalPrice}>{totalCost} kr</div>
        </>
    );
};

export default AccordionServiceItem;
