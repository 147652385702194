import React, { FC } from 'react';
import SubscriptionModel from '../../../../models/SubscriptionModel';
import styled from './LawCellNotification.module.css';
import acknowledgeNotification from '../../../../assets/images/acknowledgeNotification.svg';
import delegatedNotification from '../../../../assets/images/delegatedNotification.svg';
import newNotification from '../../../../assets/images/newNotification.svg';
import newDelegatedNotification from '../../../../assets/images/newDelegatedNotification.svg';
import warningNotification from '../../../../assets/images/warningNotification.svg';
import { useLocation } from 'react-router-dom';
import { LAWS_LIBRARY, LAWS_LISTS } from '../../../../constants/Routes';
import useAuth from '../../../../hooks/useAuth';

interface Props {
    acceptanceStatus: SubscriptionModel['acceptanceStatus'];
    sentFromConsultant: boolean;
}

const LawCellNotification: FC<Props> = ({ acceptanceStatus, sentFromConsultant }) => {
    const { pathname } = useLocation();
    const { isAssessor, companyHasConsultant } = useAuth();

    if (acceptanceStatus === 'NOT_ACCEPTED' && !isAssessor()) {
        return null;
    }

    let title;
    let src;
    if (pathname === LAWS_LISTS) {
        if (companyHasConsultant() && !sentFromConsultant) {
            return null;
        }
        if (acceptanceStatus.includes('NEW')) {
            src = newNotification;
            title = 'Författningen reodivsas som ny under Ändringar och hanteras där';
        } else if (acceptanceStatus.includes('NOT_ACCEPTED')) {
            src = warningNotification;
            title = 'Denna författningen väntar på bedömning. Redovisas under Ändringar och hanteras där';
        } else {
            return null;
        }
    } else if (pathname === LAWS_LIBRARY) {
        if (acceptanceStatus.includes('NEW')) {
            src = newNotification;
            title = 'Författningen reodivsas som ny under Ändringar och hanteras där';
        } else {
            return null;
        }
    } else if (acceptanceStatus === 'DELEGATED_ME' || acceptanceStatus === 'NOT_ACCEPTED') {
        src = acknowledgeNotification;
    } else if (acceptanceStatus === 'DELEGATED_OTHER') {
        src = delegatedNotification;
    } else if (acceptanceStatus === 'NEW_DELEGATED_ME' || acceptanceStatus === 'NEW_NOT_ACCEPTED') {
        src = newNotification;
    } else if (acceptanceStatus === 'NEW_DELEGATED_OTHER') {
        src = newDelegatedNotification;
    } else {
        return null;
    }

    return <img className={styled.LawCellNotification} title={title} src={src} />;
};

export default LawCellNotification;
