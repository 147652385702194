import React, { FC, useMemo } from 'react';
import styled from './ContextMenuFilter.module.css';
import accordionIcon from '../../../assets/images/accordionIcon.svg';
import Checkbox from '../../Checkbox/Checkbox';
import { LawType } from '../../../models/LawType';

interface Props {
    title: string;
    open: boolean;
    customChecked?: boolean;
    type: LawType;
    options: {
        lawGroupId: number;
        lawGroupName: string;
        checked: boolean;
    }[];
    onToggleLawTypeOpen: () => void;
    onToggleLawGroupChecked: (lawGroupId: number) => void;
    onToggleLawTypeChecked: () => void;
}

const ContextMenuFilter: FC<Props> = ({ title, open, options, customChecked, type, onToggleLawTypeOpen, onToggleLawGroupChecked, onToggleLawTypeChecked }) => {
    const isCustom = useMemo(() => type === 'CUSTOM_LAW', [type]);
    const filterChecked = isCustom ? customChecked : options.every((o: any) => o.checked);
    const filterIndeterminate = options.some((o: any) => o.checked) && !options.every((o: any) => o.checked);

    return (
        <div className={[styled.ContextMenuFilter, open ? styled.Open : ''].join(' ')}>
            <div className={styled.Header}>
                <span className={styled.Title} onClick={isCustom ? onToggleLawTypeChecked : onToggleLawTypeOpen}>
                    <img src={accordionIcon} alt="Icon" className={options.length <= 0 ? styled.HiddenIcon : ''} />
                    {title}
                </span>
                <Checkbox checked={filterChecked} indeterminate={filterIndeterminate} onChange={onToggleLawTypeChecked} />
            </div>

            {open && options.length > 0 && (
                <ul className={styled.List}>
                    {options.map((option: any) => (
                        <li key={option.lawGroupId}>
                            <span className={styled.ListTitle} onClick={() => onToggleLawGroupChecked(option.lawGroupId)}>
                                {option.lawGroupName}
                            </span>
                            <Checkbox checked={option.checked} onChange={() => onToggleLawGroupChecked(option.lawGroupId)} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ContextMenuFilter;
