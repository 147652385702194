import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from './RevisionEditor.module.css';
import TextEditor, { EMPTY_HTML_TEXT } from '../TextEditor/TextEditor';
import RadioButton from '../RadioButton/RadioButton';
import { RevisionObjStatus, isAcceptableRevisionStatus, isAllowedImageUploadRevisionStatus } from '../../models/RevisionObjStatus';
import Button from '../Button/Button';
import RevisionCommentModel from '../../models/RevisionCommentModel';
import { revisionCommentMaxLength } from '../../constants/Validation';
import { useTranslation } from 'react-i18next';
import RevisionMediaEditor from '../RevisionMediaEditor/RevisionMediaEditor';
import RevisionSubscriptionModel from '../../models/RevisionSubscriptionModel';
import CustomLawRevisionModel from '../../models/CustomLawRevisionModel';
import useEditRevision from '../../hooks/useEditRevision';
import WarningModal from '../../Invoicing/components/modals/WarningModal/WarningModal';

interface Props {
    finished?: boolean;
    initialComment?: RevisionCommentModel;
    initialStatus?: RevisionObjStatus;
    selectedSub: RevisionSubscriptionModel | CustomLawRevisionModel;
    onCancel: () => void;
    onConfirm: (comment: string, status: RevisionObjStatus) => void;
    // onMediaChange: (comment: string, status: RevisionObjStatus) => void;
    loading: boolean;
    editRevisionOpen?: boolean;
}

const RevisionEditor: FC<Props> = ({ finished, initialComment, initialStatus = 'NO_VALUE', onCancel, onConfirm, loading, selectedSub, editRevisionOpen }) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState<{ value: string; error?: boolean }>({ value: initialComment && !finished ? initialComment.comment : '', error: false });
    const [commentHasText, setCommentHasText] = useState(false);
    const [status, setStatus] = useState<RevisionObjStatus>(initialStatus);
    const [hasEditTextEditor, setHasEditTextEditor] = useState(false);

    const isDisabled = useMemo(() => finished && isAcceptableRevisionStatus(initialStatus), [finished, initialStatus]);

    const showAcceptedOptions = useMemo(() => finished && (initialStatus === 'NOT_ACCEPTED' || initialStatus === 'ACCEPTED_AFTER_NOT_ACCEPTED'), [finished, initialStatus]);
    const showObservationOptions = useMemo(() => finished && (initialStatus === 'OBSERVATION' || initialStatus === 'ACCEPTED_AFTER_OBSERVATION'), [finished, initialStatus]);
    const showFullOptions = useMemo(() => !finished || initialStatus === 'NO_VALUE' || (finished && (initialStatus === 'ACCEPTED' || initialStatus === 'NOT_RELEVANT')), [
        finished,
        initialStatus,
    ]);
    const { handleDeleteRevisionMedia } = useEditRevision();
    const [showUploadImage, setShowUploadImage] = useState();
    const [showWarningModal, setShowWarningModal] = useState<{ mediaId: number; show: boolean; status: string }>({ mediaId: -1, show: false, status: '' });

    const handleStatusChange = (value: RevisionObjStatus) => {
        if (selectedSub.mediaAttachments?.length > 0 && !isAllowedImageUploadRevisionStatus(value)) {
            setShowWarningModal({ mediaId: selectedSub.mediaAttachments[0].mediaId, show: true, status: status });
        }
        setStatus(value);
        setHasEditTextEditor(false);
    };

    const handleCommentChange = (value: string) => {
        if (value && value !== EMPTY_HTML_TEXT && !hasEditTextEditor) {
            setHasEditTextEditor(true);
        }
        setComment(s => ({ ...s, value }));
    };
    const handleCloseModal = (value: any) => {
        handleStatusChange(value);
        setShowWarningModal({ mediaId: -1, show: false, status: '' });
    };

    useEffect(() => {
        if (isAllowedImageUploadRevisionStatus(status)) {
            setShowUploadImage(true);
        } else {
            setShowUploadImage(false);
        }
    }, [status]);

    const handleAlreadyHasComment = (value: string) => {
        if (value && value !== EMPTY_HTML_TEXT && !hasEditTextEditor) {
            setCommentHasText(true);
        }
    };

    useEffect(() => {
        handleAlreadyHasComment(comment.value);
    }, [selectedSub]);

    return (
        <div className={styled.RevisionEditor}>
            <div className={styled.CloseBar}>{t('reviseLaw')}</div>
            <div className={styled.Content}>
                <div className={[styled.Options, isDisabled ? styled.Disabled : ''].join(' ')}>
                    <h3>Status</h3>

                    {showFullOptions && (
                        <>
                            <RadioButton text={t('revisionStatusTitleAccepted')} checked={status === 'ACCEPTED'} onChange={() => handleStatusChange('ACCEPTED')} group="status" />
                            <RadioButton
                                text={t('revisionStatusTitleNotRelevant')}
                                checked={status === 'NOT_RELEVANT'}
                                onChange={() => handleStatusChange('NOT_RELEVANT')}
                                group="status"
                            />
                            <RadioButton
                                text={t('revisionStatusTitleNotAccepted')}
                                checked={status === 'NOT_ACCEPTED'}
                                onChange={() => handleStatusChange('NOT_ACCEPTED')}
                                group="status"
                            />
                            <RadioButton
                                text={t('revisionStatusTitleObservation')}
                                checked={status === 'OBSERVATION'}
                                onChange={() => handleStatusChange('OBSERVATION')}
                                group="status"
                            />
                            <RadioButton text={t('revisionStatusTitleNoValue')} checked={status === 'NO_VALUE'} onChange={() => handleStatusChange('NO_VALUE')} group="status" />
                        </>
                    )}

                    {showAcceptedOptions && (
                        <>
                            <RadioButton
                                text={t('revisionStatusTitleNotAccepted')}
                                checked={status === 'NOT_ACCEPTED'}
                                onChange={() => handleStatusChange('NOT_ACCEPTED')}
                                group="status"
                            />
                            <RadioButton
                                text={t('revisionStatusTitleAcceptedAfterNotAccepted')}
                                checked={status === 'ACCEPTED_AFTER_NOT_ACCEPTED'}
                                onChange={() => handleStatusChange('ACCEPTED_AFTER_NOT_ACCEPTED')}
                                group="status"
                            />
                        </>
                    )}

                    {showObservationOptions && (
                        <>
                            <RadioButton
                                text={t('revisionStatusTitleObservation')}
                                checked={status === 'OBSERVATION'}
                                onChange={() => handleStatusChange('OBSERVATION')}
                                group="status"
                            />
                            <RadioButton
                                text={t('revisionStatusTitleAcceptedAfterObservation')}
                                checked={status === 'ACCEPTED_AFTER_OBSERVATION'}
                                onChange={() => handleStatusChange('ACCEPTED_AFTER_OBSERVATION')}
                                group="status"
                            />
                        </>
                    )}
                </div>
                <div className={styled.TextEditor}>
                    <TextEditor
                        title={t('comment')}
                        optionalTitle={status === 'OBSERVATION' || status === 'NOT_ACCEPTED' ? '(' + t('inputFieldErrorRequired2') + ')' : ''}
                        initialValue={comment.value}
                        onChange={value => handleCommentChange(value)}
                        hasText={setCommentHasText}
                        maxLength={revisionCommentMaxLength}
                        onMaxLengthError={error => setComment(s => ({ ...s, error }))}
                        errorMsg={!commentHasText && hasEditTextEditor && (status === 'OBSERVATION' || status === 'NOT_ACCEPTED') ? t('inputFieldErrorRequired') : ''}
                    />
                </div>
                {showUploadImage && (
                    <RevisionMediaEditor
                        selectedSub={selectedSub}
                        editRevisionLeftViewOpen
                        onCancel={() => undefined}
                        onConfirm={() => undefined}
                        status={status}
                    ></RevisionMediaEditor>
                )}
                <div className={styled.Buttons}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button
                        variant="Primary"
                        onClick={() => onConfirm(comment.value, status)}
                        disabled={((status === 'NOT_ACCEPTED' || status === 'OBSERVATION') && !commentHasText) || comment.error || loading}
                    >
                        {t('buttonSave')}
                    </Button>
                    {showWarningModal.show && selectedSub && showWarningModal.mediaId > -1 && (
                        <WarningModal
                            onClose={() => handleCloseModal(showWarningModal.status)}
                            onAccept={() => {
                                handleDeleteRevisionMedia(showWarningModal.mediaId);
                                setShowWarningModal({ mediaId: -1, show: false, status: '' });
                            }}
                            title={t('revisionStatusChangeWarningTitle')}
                            subtitle={t('revisionStatusChangeWarningSubTitle')}
                            className={styled.WarningModel}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RevisionEditor;
