import MiscService from '../services/MiscService';
import { useContext } from 'react';
import { MiscContext } from '../contexts/MiscContext';

const useMisc = () => {
    const { state, setState } = useContext(MiscContext);

    const fetchEnv = async () => {
        const envName = await MiscService().getEnvironmentName();
        const commit = await MiscService().getCommit();
        const oldApplicationBaseURL = await MiscService().getOldApplicationBaseURL();
        const logoutURL = await MiscService().getLogoutURL();

        setState(s => ({
            ...s,
            envName,
            commit,
            oldApplicationBaseURL,
            logoutURL,
        }));
    };
    const isProdEnvironment = () => {
        return (state.envName == 'prod');
    };

    return {
        envName: state.envName,
        commit: state.commit,
        oldApplicationBaseURL: state.oldApplicationBaseURL,
        logoutURL: state.logoutURL,
        fetchEnv,
        isProdEnvironment,
    };
};

export default useMisc;
