import React, { FC } from 'react';
import Modal from '../Modal';
import ApiErrorModel from '../../../models/ApiErrorModel';
import Button from '../../Button/Button';
import styled from './ErrorModal.module.css';
import errorIcon from '../../../assets/images/errorIcon.svg';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    error: ApiErrorModel;
    onClose: () => void;
}

const ErrorModal: FC<Props> = ({ error, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('errorModalTitle')} subtitle={t('errorModalSubTitle')} className={styled.Modal}>
            <img src={errorIcon} alt="error" className={styled.ErrorIcon} />
            <div>
                {error.errorId ? (
                    <p>
                        <Trans i18nKey="errorModalText1" values={{ errorId: error.errorId }} components={[<strong key={0} />]} />
                    </p>
                ) : (
                    <p>{t('errorModalText1NoErrorId')}</p>
                )}
                <p>{t('errorModalText2')}</p>
                <p>
                    <Trans i18nKey="errorModalText3" components={[<a href="mailto:lagbevakning@ramboll.se" key={0} />]} />
                </p>
                <p>{t('errorModalText4')}</p>
                <div className={styled.Buttons}>
                    <Button variant="Primary" onClick={onClose}>
                        OK
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ErrorModal;
