import React, { FC, ReactNode } from 'react';

interface Props {
    data: string;
    link?: ReactNode;
}

const AccordionTextItem: FC<Props> = ({ data, link }) => {
    return data ? (
        <>
            {link && link}
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </>
    ) : (
        <div />
    );
};

export default AccordionTextItem;
