import { AxiosResponse, AxiosError } from 'axios';
import { getAuthApi } from '../config/axios.config';
import CompanyModel, { CompanyColumnUpdateModel } from '../models/CompanyModel';
import ApiErrorModel from '../models/ApiErrorModel';
import _ from 'lodash';
import { sortLawLists } from '../utils/sorter';
import LawListModel, { UNGROUPED_LAWLIST_NAME, UNGROUPED_LAWLIST_ID, EditLawListModel, LawListSettingsModel } from '../models/LawListModel';
import LawListGroupModel, { EditLawListGroupModel } from '../models/LawListGroupModel';
import KeywordModel, { KeywordSettingsModel, EditKeywordModel } from '../models/KeywordModel';
import EditListContentModel from '../models/ConnectedLawsModel';
import SignContractModel from '../models/SignContractModel';

const CompanyService = () => {
    const axiosInstance = getAuthApi();

    function getCompanyById(companyId: number): Promise<CompanyModel> {
        return axiosInstance
            .get<CompanyModel>(`/company/?id=${companyId}&fetchFull=true`)
            .then((response: AxiosResponse<CompanyModel>) => {
                const { data } = response;

                const lawLists = data.lawLists
                    .map(lawList => {
                        if (!lawList.lawListGroup) {
                            return {
                                ...lawList,
                                lawListGroup: {
                                    lawListGroupId: UNGROUPED_LAWLIST_ID,
                                    name: UNGROUPED_LAWLIST_NAME,
                                    description: '',
                                    responsible: '',
                                },
                            };
                        }
                        return lawList;
                    })
                    .sort((a, b) => sortLawLists(a.lawListGroup, b.lawListGroup));

                return {
                    ...data,
                    lawLists,
                    keyWords: _.sortBy(data.keyWords, kw => kw.text.toLowerCase()),
                };
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getCompanyNamesThatHasSubscription(lawId: number, corporationId: number): Promise<string[]> {
        return axiosInstance
            .get<string[]>(`/company/names/withsubscription?lawId=${lawId}&corporationId=${corporationId}`)
            .then((response: AxiosResponse<string[]>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function setRevisionWarningDays(companyId: number, revisionWarningDays: number, disableRevisionWarning: boolean): Promise<number> {
        return axiosInstance
            .put<number>(`/company/revisionWarning?companyId=${companyId}&revisionWarningDays=${revisionWarningDays}&disableRevisionWarning=${disableRevisionWarning}`)
            .then((response: AxiosResponse<number>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomColumns(customColumns: CompanyColumnUpdateModel): Promise<CompanyColumnUpdateModel> {
        return axiosInstance
            .put<CompanyColumnUpdateModel>('/company/columns', customColumns)
            .then((response: AxiosResponse<CompanyColumnUpdateModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeColumnData(companyId: number, columnIndex: number): Promise<AxiosResponse> {
        return axiosInstance.delete(`/company/columns?companyId=${companyId}&columnIndex=${columnIndex}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function createLawList(companyId: number, lawList: EditLawListModel): Promise<LawListModel> {
        return axiosInstance
            .post<LawListModel>(`/company/lawlist?companyId=${companyId}`, lawList)
            .then((response: AxiosResponse<LawListModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getLawLists(companyId: number): Promise<LawListModel[]> {
        return axiosInstance
            .get<LawListModel[]>(`/company/lawlist?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeLawList(companyId: number, lawListId: number): Promise<AxiosResponse> {
        return axiosInstance.delete(`/company/lawlist?companyId=${companyId}&lawListId=${lawListId}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function createLawListGroup(companyId: number, lawListGroup: EditLawListGroupModel): Promise<LawListGroupModel> {
        return axiosInstance
            .post<LawListGroupModel>(`/company/lawlistgroup?companyId=${companyId}`, lawListGroup)
            .then((response: AxiosResponse<LawListGroupModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeLawListGroup(companyId: number, lawListGroupId: number): Promise<AxiosResponse> {
        return axiosInstance.delete(`/company/lawlistgroup?companyId=${companyId}&lawListGroupId=${lawListGroupId}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function getLawListGroups(companyId: number): Promise<LawListGroupModel[]> {
        return axiosInstance
            .get<LawListGroupModel[]>(`/company/lawlistgroup?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListGroupModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getLawListSettings(companyId: number, lawListId: number): Promise<LawListSettingsModel> {
        return axiosInstance
            .get<LawListSettingsModel>(`/company/lawlist/settings?companyId=${companyId}&lawListId=${lawListId}`)
            .then((response: AxiosResponse<LawListSettingsModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function saveLawListConnections(editLawListContent: EditListContentModel): Promise<AxiosResponse> {
        return axiosInstance.put('/company/lawlist/connections', editLawListContent).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function getFullKeywords(companyId: number): Promise<KeywordModel[]> {
        return axiosInstance
            .get<KeywordModel[]>(`/company/keyword?companyId=${companyId}`)
            .then((response: AxiosResponse<KeywordModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function createKeyword(companyId: number, keyword: EditKeywordModel): Promise<KeywordModel> {
        return axiosInstance
            .post<KeywordModel>(`/company/keyword?companyId=${companyId}`, keyword)
            .then((response: AxiosResponse<KeywordModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateKeyword(companyId: number, keyword: EditKeywordModel): Promise<KeywordModel> {
        return axiosInstance
            .put<KeywordModel>(`/company/keyword?companyId=${companyId}`, keyword)
            .then((response: AxiosResponse<KeywordModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeKeyword(companyId: number, keywordId: number): Promise<AxiosResponse> {
        return axiosInstance.delete(`/company/keyword?companyId=${companyId}&keywordId=${keywordId}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function getKeywordSettings(companyId: number, keywordId: number): Promise<KeywordSettingsModel> {
        return axiosInstance
            .get<KeywordSettingsModel>(`/company/keyword/settings?companyId=${companyId}&keywordId=${keywordId}`)
            .then((response: AxiosResponse<KeywordSettingsModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function saveKeywordConnections(editKeywordContent: EditListContentModel): Promise<AxiosResponse> {
        return axiosInstance.put('/company/keyword/connections', editKeywordContent).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function updateLawList(companyId: number, lawList: EditLawListModel): Promise<LawListModel> {
        return axiosInstance
            .put<LawListModel>(`/company/lawlist?companyId=${companyId}`, lawList)
            .then((response: AxiosResponse<LawListModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateLawListGroup(companyId: number, lawListGroup: EditLawListGroupModel): Promise<LawListGroupModel> {
        return axiosInstance
            .put<LawListGroupModel>(`/company/lawlistgroup?companyId=${companyId}`, lawListGroup)
            .then((response: AxiosResponse<LawListGroupModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateExcludedRevisionQuestions(companyId: number, addedIds: number[], removedIds: number[]): Promise<number[]> {
        return axiosInstance
            .post<number[]>(`/company/revisionquestion/excluded?companyId=${companyId}&addedIds=${addedIds}&removedIds=${removedIds}`, undefined)
            .then((response: AxiosResponse<number[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function signContract(signContractModel: SignContractModel): Promise<AxiosResponse> {
        const url = `/company/signcontract`;

        return axiosInstance
            .post(url, signContractModel)
            .then((response: AxiosResponse) => {
                return response;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    function updateAdminInfo(companyId: number, adminInfo: string): Promise<string> {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
            },
        };
        return axiosInstance
            .put<string>(`/company/adminInfo?companyId=${companyId}`, adminInfo, config)
            .then((response: AxiosResponse<string>) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    return {
        getCompanyById,
        getCompanyNamesThatHasSubscription,
        setRevisionWarningDays,
        updateCustomColumns,
        removeColumnData,
        createLawList,
        getLawLists,
        removeLawList,
        createLawListGroup,
        removeLawListGroup,
        getLawListGroups,
        getLawListSettings,
        saveLawListConnections,
        getFullKeywords,
        createKeyword,
        updateKeyword,
        removeKeyword,
        getKeywordSettings,
        saveKeywordConnections,
        updateLawList,
        updateLawListGroup,
        updateExcludedRevisionQuestions,
        signContract,
        updateAdminInfo,
    };
};

export default CompanyService;
