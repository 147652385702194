import React, { FC, useMemo } from 'react';
import styled from './MediaCard.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    imageSrc: any;
    stage: string;
    title?: string;
    addedBy?: string;
    addedAt?: string;
    userComment?: string;
}

const BASE_64_STRING = 'data:image/png;base64,';

function cleanDate(q: string | undefined) {
    const string = q?.split(' ')[0];
    return string;
}

const MediaCard: FC<Props> = ({ imageSrc, stage, title, addedBy, addedAt, userComment }) => {
    const { t } = useTranslation();

    const maxAllowedMediaAttachments = useMemo(() => {
        if (stage === 'NOT_FINISHED') {
            return t('mediaStatusNotFinished');
        } else {
            return t('mediaStatusFinished');
        }
    }, [stage]);

    return (
        <div className={styled.Card}>
            <div className={styled.ImageContainer}>
                <img className={styled.Image} src={BASE_64_STRING + imageSrc} title={title} />
            </div>
            <div className={styled.TextContainer}>
                <h5 className={styled.Name}>{addedBy}</h5>
                <p className={styled.Comment}>{userComment ? userComment : ''}</p>
                <p>{maxAllowedMediaAttachments}</p>
                <p>{addedAt ? cleanDate(addedAt) : ''}</p>
            </div>
        </div>
    );
};

export default MediaCard;
