import SubscriptionModel from '../models/SubscriptionModel';
import CustomLawModel from '../models/CustomLawModel';
import { Column } from 'react-table';
import _ from 'lodash';
import AcceptanceLogModel from '../models/AcceptanceLogModel';

// Generic logic for including subscriptions in excel export. Currently in use for LawList,ChangeList and ChangeListArchive
const filterExport = () => {
    const isIncludedInSearchColumnsFiltering = (
        data: SubscriptionModel | CustomLawModel | AcceptanceLogModel,
        searchColumns: Column<any>[],
        columnSearch: { id: string; value: string | undefined }[],
    ): boolean => {
        const allMatch = _.every(searchColumns, col => {
            const searchValue = columnSearch.find(cs => cs.id == col.id)?.value || '';
            let columnValue = '';
            if (col.accessor) {
                if (typeof col.accessor === 'function') {
                    const value = col.accessor(data, -1, { subRows: [], depth: 0, data: [] });
                    columnValue = Array.isArray(value) ? value.join(' ') : value;
                } else {
                    columnValue = _.get(data, col.accessor);
                }
            }

            if (columnValue && !Array.isArray(columnValue) && columnValue.toLowerCase().includes(searchValue.toLowerCase())) {
                return true;
            }
            return false;
        });
        return allMatch;
    };

    const hasGlobalSearchMatch = (
        data: SubscriptionModel | CustomLawModel | AcceptanceLogModel,
        columns: Column<any>[],
        globalSearch: string,
        revisionQuestions?: string,
        customColumns?: Column<any>[],
    ): boolean => {
        let foundMatchOnGlobalSearch = false;
        //Concatenates custom columns with default columns for Global Search
        const concatenatedColumns = customColumns ? columns.concat(customColumns) : columns;
        concatenatedColumns.forEach(col => {
            let columnValue = '';
            if (col.accessor) {
                if (typeof col.accessor === 'function') {
                    const value = col.accessor(data, -1, { subRows: [], depth: 0, data: [] });
                    columnValue = Array.isArray(value) ? value.join(' ') : value;
                } else {
                    columnValue = _.get(data, col.accessor);
                }
            }
            if (columnValue && !Array.isArray(columnValue) && columnValue.toLowerCase().includes(globalSearch.toLowerCase())) {
                foundMatchOnGlobalSearch = true;
                return;
            }
        });

        if (revisionQuestions) {
            if (revisionQuestions.toLowerCase().includes(globalSearch.toLowerCase())) {
                foundMatchOnGlobalSearch = true;
            }
        }

        return foundMatchOnGlobalSearch;
    };

    const includeSubscriptionInExport = (
        data: SubscriptionModel | CustomLawModel | AcceptanceLogModel,
        columnSearch: { id: string; value: string | undefined }[],
        searchColumns: Column<any>[],
        columns: Column<any>[],
        globalSearch: string,
        revisionQuestions?: string,
        customColumns?: Column<any>[],
    ): boolean => {
        const globalSearchMatch = hasGlobalSearchMatch(data, columns, globalSearch, revisionQuestions, customColumns);
        const includedFiltering = isIncludedInSearchColumnsFiltering(data, searchColumns, columnSearch);

        if (globalSearch != '' && searchColumns.length > 0) {
            return globalSearchMatch && includedFiltering;
        } else {
            if (globalSearch != '') {
                return globalSearchMatch;
            }
            if (searchColumns.length > 0) {
                return includedFiltering;
            }
        }
        return true;
    };

    return {
        includeSubscriptionInExport,
    };
};

export default filterExport;
