export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/;
export const userName = (value: string): string | undefined => (value.length < 1 ? 'inputFieldErrorRequired' : !emailRegex.test(value) ? 'inputFieldErrorEmail' : undefined);
export const userNameRegex = (value: string): boolean => emailRegex.test(value);
export const password = (value: string): string | undefined =>
    value.length < 1 ? undefined : !/^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$)/.test(value) || value.length < 8 ? 'inputFieldErrorPassword' : undefined;
export const confirmPassword = (confirmPassword: string, password: string): string | undefined =>
    password.length && confirmPassword !== password ? 'inputFieldErrorConfirmPassword' : undefined;
export const name = (value: string): string | undefined => (value.length < 1 ? 'inputFieldErrorRequired' : undefined);

// Subscription
export const subscriptionTextMaxLength = 20000; // (html) max 24000 in DB
export const subscriptionCustomerTextsMaxLength = 5000; // (html) max 6000 in DB
export const uploadImageMaxSize = 10506316; // max 10 MB

// CustomLaw
export const customLawTextMaxLength = 16000; // (html) max 20000 in DB
export const customLawDescriptionMaxLength = 16000; // (html) max 20000 in DB
export const customLawCustomerTextsMaxLength = 3000; // (html) max 4000 in DB

// LawList
export const lawListNameMaxLength = 50; // max 200 in DB
export const lawListResponsibleMaxLength = 200; // max 200 in DB
export const lawListDescriptionMaxLength = 1600; // max 2000 in DB

// LawListGroup
export const lawListGroupNameMaxLength = 50; // max 100 in DB
export const lawListGroupResponsibleMaxLength = 100; // max 100 in DB
export const lawListGroupDescriptionMaxLength = 300; // max 400 in DB

// Keyword
export const keywordTextMaxLength = 50; // max 200 in DB
export const keywordDescriptionMaxLength = 300; // max 400 in DB

// AcceptanceLog
export const acceptanceLogCommentMaxLength = 600; // max 800 in DB
export const acceptanceLogConsultantChangeText = 5000; // max 6000 in DB

// Revision / RevisionComment
export const revisionCommentMaxLength = 2000; // max 2500 in DB

// Mobile
export const mobileSizeWidth = 1000;

// QR
export const qrCodeTime = 20;
export const qrCodeTimeMs = 20000;

// Revision Media Attachment
export const maxAllowedMediaAttachment = 1;
export const maxAllowedMediaAttachmentExtendedRevision = 1;

export const getMaxLengthValidations = (isSubscription: boolean, field: string): number | undefined => {
    if (isSubscription) {
        if (field === 'text') {
            return subscriptionTextMaxLength;
        }
        if (field.includes('customerText')) {
            return subscriptionCustomerTextsMaxLength;
        }
    } else {
        if (field === 'text') {
            return customLawTextMaxLength;
        }
        if (field === 'description') {
            return customLawDescriptionMaxLength;
        }
        if (field.includes('customerText')) {
            return customLawCustomerTextsMaxLength;
        }
    }
};
