import { AxiosResponse } from 'axios';
import { getAuthApi } from '../../config/axios.config';
import ApiErrorModel from '../../models/ApiErrorModel';
import MergeLawCompanyModel from '../models/MergeLawCompanyModel';
import MergeLawResultModel from '../models/MergeLawResultModel';
import MergeLawModelWrapper from '../models/MergeLawModelWrapper';

const MergeLawService = () => {
    const axiosInstance = getAuthApi();

    function getAllLaws(): Promise<MergeLawModelWrapper> {
        return axiosInstance
            .get(`/merge/laws`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getAffectedCompanies(lawSources: number[], lawDestinationId: number, hasDestinationLaw: boolean): Promise<MergeLawCompanyModel[]> {
        return axiosInstance
            .get(`/merge/companies?lawSources=${lawSources}&lawDestinationId=${lawDestinationId}&hasDestinationLaw=${hasDestinationLaw}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function executeMergeLaws(companyId: number, lawSources: number[], lawDestinationId: number, hideSubscriptions: boolean): Promise<MergeLawResultModel> {
        return axiosInstance
            .put(`/merge/execute?companyId=${companyId}&lawSources=${lawSources}&lawDestinationId=${lawDestinationId}&hideSubscriptions=${hideSubscriptions}`)
            .then((response: AxiosResponse<MergeLawResultModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function executeAllMergeLaws(companyIds: number[], lawSources: number[], lawDestinationId: number): Promise<MergeLawResultModel[]> {
        // Here we need a mush longer timeout because of the long processing time when merging laws for all companies.
        // 5 minutes should be enough;
        const extendedTimeout = 5 * 60 * 1000;

        return axiosInstance
            .post(`/merge/execute/all?lawSources=${lawSources}&lawDestinationId=${lawDestinationId}`, { companyIds: companyIds }, { timeout: extendedTimeout })
            .then((response: AxiosResponse<{ mergeLawModels: MergeLawResultModel[] }>) => {
                return response.data.mergeLawModels;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getAllLaws,
        getAffectedCompanies,
        executeMergeLaws,
        executeAllMergeLaws,
    };
};

export default MergeLawService;
