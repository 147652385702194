import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import Modal from '../Modal';
import styled from './VersionErrorModal.module.css';

interface Props {
    onClose: () => void;
}

const VersionErrorModal: FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('errorVersionModalTitle')} subtitle={t('errorVersionModalSubTitle')} className={styled.Modal}>
            <div className={styled.Buttons}>
                <Button variant="Primary" onClick={onClose}>
                    Ok
                </Button>
            </div>
        </Modal>
    );
};

export default VersionErrorModal;
