import React, { FC, useMemo, ReactNode, useState } from 'react';
import styled from './EditListContentFullView.module.css';
import _ from 'lodash';
import AccordionTextItem from '../../Accordion/AccordionTextItem/AccordionTextItem';
import AccordionListItem from '../../Accordion/AccordionListItem/AccordionListItem';
import useAuth from '../../../hooks/useAuth';
import SubscriptionModel, { isSubscription } from '../../../models/SubscriptionModel';
import CustomLawModel from '../../../models/CustomLawModel';
import FullView from '../FullView';
import Accordion from '../../Accordion/Accordion';
import { useTranslation } from 'react-i18next';

interface Props {
    data?: SubscriptionModel | CustomLawModel;
    open: boolean;
    onClose: () => void;
}

const EditListContentFullView: FC<Props> = ({ data, open, onClose }) => {
    const { t, i18n } = useTranslation();
    const { company } = useAuth();

    const [openAccordions, setOpenAccordions] = useState<number[]>([]);

    const accordionItems = useMemo(() => {
        const items: { title: string; value: ReactNode }[] = [];

        items.push({
            title: t('columnDescription'),
            value: <AccordionTextItem data={data?.description || ''} />,
        });

        items.push({
            title: t('columnText'),
            value: <AccordionTextItem data={data?.text || ''} />,
        });

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                items.push({
                    title,
                    value: <AccordionTextItem data={_.get(data, `customerText${i}`)} />,
                });
            }
        }

        if (company?.hasLawLists) {
            items.push({
                title: t('columnLawLists'),
                value: <AccordionListItem data={data?.lawLists?.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name) || []} />,
            });
        }

        if (company?.hasKeyWords) {
            items.push({
                title: t('columnKeywords'),
                value: <AccordionListItem data={data?.keywordIds?.map(kwId => _.find(company.keyWords, kw => kw.id === kwId)?.text || '') || []} />,
            });
        }

        return items;
    }, [data, i18n.language]);

    const handleToggleAccordion = (index: number) => {
        setOpenAccordions(s => (s.includes(index) ? s.filter(i => i !== index) : [...s, index]));
    };

    return (
        <FullView
            lawSourceId={data && (isSubscription(data) ? data.lawId : data.customLawId)}
            title={data?.name || ''}
            subId={(data && (isSubscription(data) ? data.subId : data.customLawEndDate)) || ''}
            isCustomLaw={data !== undefined && !isSubscription(data)}
            isExpired={data && !isSubscription(data) && data.expired}
            lawSource={data?.lawSources.length ? data.lawSources[0] : undefined}
            open={open}
            onClose={onClose}
        >
            <div className={styled.Content}>
                <Accordion
                    id={data ? (isSubscription(data) ? data.subscriptionId : data.customLawId) : -1}
                    data={accordionItems}
                    initiallyOpenIndicies={openAccordions}
                    onToggle={handleToggleAccordion}
                />
            </div>
        </FullView>
    );
};

export default EditListContentFullView;
