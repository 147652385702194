import LawListGroupModel from './LawListGroupModel';
import SubscriptionModel from './SubscriptionModel';
import CustomLawModel from './CustomLawModel';
import LawTypeModel from './LawTypeModel';
import KeywordModel from './KeywordModel';

interface LawListModel {
    name: string;
    lawListId: number;
    description: string;
    responsible: string;
    lawListGroup: LawListGroupModel;
    lawCount?: number;
}

export interface LawListSettingsModel {
    lawList: LawListModel;
    lawTypes: LawTypeModel[];
    subscriptions: SubscriptionModel[];
    customLaws: CustomLawModel[];
    includedSubscriptionIds: number[];
    includedCustomLawIds: number[];
}

export interface EditLawListModel {
    lawListId?: number;
    name: string;
    description: string;
    responsible: string;
    lawListGroup?: {
        lawListGroupId: number;
    };
}

export const isLawList = (obj: LawListModel | KeywordModel): obj is LawListModel => {
    return (obj as LawListModel).lawListId !== undefined;
};

export const UNGROUPED_LAWLIST_ID = 0;
export const UNGROUPED_LAWLIST_NAME = 'Ogrupperade';

export default LawListModel;
