import React, { ReactNode } from 'react';
import { CellProps, Cell } from 'react-table';
import _ from 'lodash';
import styled from './KeywordCell.module.css';

const KeywordCell = (props: CellProps<Cell>): ReactNode => {
    let keywords = _.sortBy(props.cell.value);
    const keywordTitle = keywords.join('\n');

    if (keywords.length > 5) {
        keywords = [...keywords.slice(0, 4), '...'];
    }

    return (
        <div className={styled.KeywordCell} title={keywordTitle}>
            {keywords.map((keyword: string) => (
                <div key={keyword} className={styled.KeywordValue}>
                    {keyword}
                </div>
            ))}
        </div>
    );
};

export default KeywordCell;
