import React, { FC, useState, useMemo } from 'react';
import styled from './CustomColumns.module.css';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import useAuth from '../../../../hooks/useAuth';
import _ from 'lodash';
import { CompanyColumnUpdateModel } from '../../../../models/CompanyModel';
import CompanyService from '../../../../services/CompanyService';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import RemoveColumnDataModal from '../../../../components/Modal/RemoveColumnDataModal/RemoveColumnDataModal';
import { useTranslation } from 'react-i18next';

const CustomColumns: FC = () => {
    const { t } = useTranslation();
    const { company, setCompanyColumns } = useAuth();

    const [loading, setLoading] = useState(false);
    const [customColumns, setCustomColumns] = useState<{ name: string; active: boolean; error?: string }[]>([
        { name: company?.customHeaderName1 || '', active: !_.isEmpty(company?.customHeaderName1), error: undefined },
        { name: company?.customHeaderName2 || '', active: !_.isEmpty(company?.customHeaderName2), error: undefined },
        { name: company?.customHeaderName3 || '', active: !_.isEmpty(company?.customHeaderName3), error: undefined },
        { name: company?.customHeaderName4 || '', active: !_.isEmpty(company?.customHeaderName4), error: undefined },
        { name: company?.customHeaderName5 || '', active: !_.isEmpty(company?.customHeaderName5), error: undefined },
    ]);
    const [removeDataModal, setRemoveDataModal] = useState({ open: false, index: -1 });

    const handleNameChange = (index: number, name: string): void => {
        setCustomColumns(s =>
            s.map((cc, i) => {
                if (i === index) {
                    let error;
                    const active = name.length > 0;
                    if (cc.active && name.length < 1) {
                        error = t('inputFieldErrorColumnNameMissing');
                    } else if (active && name.length >= 50) {
                        error = t('inputFieldErrorTooLong', { max: 50 });
                    }
                    return { ...cc, name: name.length >= 50 ? cc.name : name, error, active };
                }
                return cc;
            }),
        );
    };

    const handleSubmit = async () => {
        if (company) {
            const customColumnsModel: CompanyColumnUpdateModel = {
                companyId: company.id,
                customHeaderName1: customColumns[0].active ? customColumns[0].name : null,
                customHeaderName2: customColumns[1].active ? customColumns[1].name : null,
                customHeaderName3: customColumns[2].active ? customColumns[2].name : null,
                customHeaderName4: customColumns[3].active ? customColumns[3].name : null,
                customHeaderName5: customColumns[4].active ? customColumns[4].name : null,
            };

            try {
                setLoading(true);
                const updatedColumns = await CompanyService().updateCustomColumns(customColumnsModel);

                setCompanyColumns(updatedColumns);
                setCustomColumns([
                    { name: updatedColumns.customHeaderName1 || '', active: !_.isEmpty(updatedColumns.customHeaderName1), error: undefined },
                    { name: updatedColumns.customHeaderName2 || '', active: !_.isEmpty(updatedColumns.customHeaderName2), error: undefined },
                    { name: updatedColumns.customHeaderName3 || '', active: !_.isEmpty(updatedColumns.customHeaderName3), error: undefined },
                    { name: updatedColumns.customHeaderName4 || '', active: !_.isEmpty(updatedColumns.customHeaderName4), error: undefined },
                    { name: updatedColumns.customHeaderName5 || '', active: !_.isEmpty(updatedColumns.customHeaderName5), error: undefined },
                ]);

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleRemoveData = async () => {
        if (company && removeDataModal.index >= 1 && removeDataModal.index <= 5) {
            const index = removeDataModal.index;
            try {
                const customColumnsModel: CompanyColumnUpdateModel = {
                    companyId: company.id,
                    customHeaderName1: index === 1 ? null : company.customHeaderName1,
                    customHeaderName2: index === 2 ? null : company.customHeaderName2,
                    customHeaderName3: index === 3 ? null : company.customHeaderName3,
                    customHeaderName4: index === 4 ? null : company.customHeaderName4,
                    customHeaderName5: index === 5 ? null : company.customHeaderName5,
                };
                setRemoveDataModal({ open: false, index: -1 });
                setLoading(true);
                await CompanyService().removeColumnData(company.id, index);
                const updatedColumns = await CompanyService().updateCustomColumns(customColumnsModel);

                setCompanyColumns(updatedColumns);
                setCustomColumns(s => s.map((cc, i) => (i === index - 1 ? { ...cc, active: false, name: '' } : { ...cc, active: true })));

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const hasChanges = useMemo(() => {
        return (
            (company?.customHeaderName1 || '') !== customColumns[0].name ||
            (company?.customHeaderName2 || '') !== customColumns[1].name ||
            (company?.customHeaderName3 || '') !== customColumns[2].name ||
            (company?.customHeaderName4 || '') !== customColumns[3].name ||
            (company?.customHeaderName5 || '') !== customColumns[4].name
        );
    }, [customColumns, company]);

    return (
        <div className={styled.CustomColumns}>
            {loading && <LoadingSpinner />}
            <h3 className={styled.Title}>{t('settingsMenuCompanyColumns')}</h3>

            <div className={styled.InfoBox}>
                <h6 className={styled.InfoBoxTitle}>{t('customColumnsTitle')}</h6>
                <p>{t('customColumnsText1')}</p>
                <p>{t('customColumnsText2')}</p>
            </div>

            <div className={styled.ColumnBox}>
                <div className={styled.ColumnBoxHeader}>
                    <h6>{t('settingsMenuCompanyColumns')}</h6>
                </div>
                {customColumns.map((cc, index) => (
                    <div key={index} className={styled.ColumnBoxSection}>
                        <p className={styled.ColumnBoxTitle}>{t('customColumn', { index: index + 1 })}</p>
                        <Input value={cc.name} onChange={value => handleNameChange(index, value)} className={styled.Input} error={cc.error} />
                        <Button
                            variant="Primary"
                            onClick={() => setRemoveDataModal({ open: true, index: index + 1 })}
                            disabled={
                                (index === 0 && _.isEmpty(company?.customHeaderName1)) ||
                                (index === 1 && _.isEmpty(company?.customHeaderName2)) ||
                                (index === 2 && _.isEmpty(company?.customHeaderName3)) ||
                                (index === 3 && _.isEmpty(company?.customHeaderName4)) ||
                                (index === 4 && _.isEmpty(company?.customHeaderName5))
                            }
                        >
                            {t('buttonRemove')}
                        </Button>
                    </div>
                ))}
                <div className={styled.ColumnBoxFooter}>
                    <Button variant="Primary" disabled={customColumns.some(cc => cc.error) || !hasChanges} onClick={handleSubmit}>
                        {t('buttonSave')}
                    </Button>
                </div>
            </div>

            {removeDataModal.open && (
                <RemoveColumnDataModal
                    columnName={customColumns[removeDataModal.index - 1].name}
                    onCancel={() => setRemoveDataModal({ open: false, index: -1 })}
                    onConfirm={handleRemoveData}
                />
            )}
        </div>
    );
};

export default CustomColumns;
