import React, { Dispatch, SetStateAction, createContext, FC, useState, useEffect } from 'react';
import RevisionModel from '../models/RevisionModel';
import { Column, SortingRule } from 'react-table';

interface State {
    revisions: RevisionModel[];

    columns: Array<Column<RevisionModel>>;

    globalSearch: string;
    sorting: SortingRule<object>[];

    startDate: Date;
    endDate: Date;

    loading: boolean;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const startDateYearValue = 2;

const intialState: State = {
    revisions: [],

    columns: [],

    globalSearch: '',
    sorting: [{ id: 'finishedAt', desc: false }],

    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - startDateYearValue)),
    endDate: new Date(),

    loading: false,
};

const FinishedRevisionContext = createContext<Context>({
    state: intialState,
    setState: () => null,
});

const FinishedRevisionContextProvider: FC = ({ children }) => {
    const [state, setState] = useState<State>({ ...intialState });

    useEffect(() => {
        setState(s => ({
            ...s,
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - startDateYearValue)),
            endDate: new Date(),
        }));
    }, []);

    return <FinishedRevisionContext.Provider value={{ state, setState }}>{children}</FinishedRevisionContext.Provider>;
};

export { FinishedRevisionContext, FinishedRevisionContextProvider };
