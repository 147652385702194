import React, { FC, useState, useEffect, useMemo } from 'react';
import styled from './Consultant.module.css';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import SubscriptionModel from '../../models/SubscriptionModel';
import useAuth from '../../hooks/useAuth';
import LawTypeFilter, { convertToLawTypeFilter } from '../../models/LawTypeFilter';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import eyeIcon from '../../assets/images/eyeIcon.svg';
import { LawType, LawTypeEnum } from '../../models/LawType';
import Table from '../../components/Table/Table';
import Filterbar from '../../components/Filterbar/Filterbar';
import KeywordModel from '../../models/KeywordModel';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../../models/LawListGroupFilter';
import { Column, SortingRule } from 'react-table';
import LawCell from '../../components/Table/LawCell/LawCell';
import _ from 'lodash';
import KeywordCell from '../../components/Table/KeywordCell/KeywordCell';
import ConsultantFullView from '../../components/FullView/ConsultantFullView/ConsultantFullView';
import ConsultantSubscriptionChangesService, { AcceptSubscriptionModel } from '../../services/ConsultantSubscriptionChangesService';
import ConsultantToaster from '../../components/ConsultantToaster/ConsultantToaster';
import AcknowledgeChangeView from '../../components/AcknowledgeChangeView/AcknowledgeChangeView';
import UserService from '../../services/UserService';
import UserModel from '../../models/UserModel';
import AcknowledgeNewChangeView from '../../components/AcknowledgeNewChangeView/AcknowledgeNewChangeView';
import { AssessmentChoice } from '../../services/SubscriptionChangeService';
import AcknowledgeEditView from '../../components/AcknowledgeEditView/AcknowledgeEditView';
import SubscriptionService from '../../services/SubscriptionService';
import EditView from '../../components/EditView/EditView';
import LawListModel from '../../models/LawListModel';
import ConsultantMultiAcceptModal from '../../components/Modal/ConsultantMultiAcceptModal/ConsultantMultiAcceptModal';
import LawTypeModel from '../../models/LawTypeModel';
import EditOldWarningModal from '../../components/Modal/EditOldWarningModal/EditOldWarningModal';
import EditLatestSubscriptionModal from '../../components/Modal/EditLatestSubscriptionModal/EditLatestSubscriptionModal';
import EditRevisionQuestionView from '../../components/EditRevisionQuestionView/EditRevisionQuestionView';
import RevisionQuestion from '../../models/RevisionQuestion';
import RevisionQuestionService from '../../services/RevisionQuestionService';
import { useTranslation } from 'react-i18next';
import EmptyListView from '../../components/EmptyListView/EmptyListView';
import ExcelColumnWidth, { columnKeys } from '../../constants/ExcelColumnWidth';
import useExport from '../../hooks/useExport';

const CONTEXT_LINKS: {
    title: string;
    icon: string;
    active: boolean;
}[] = [
    {
        title: 'Ändringar',
        icon: eyeIcon,
        active: true,
    },
];

const Consultant: FC = () => {
    const { t, i18n } = useTranslation();
    const {
        company,
        user,
        isAdminSubs,
        isGroupCompany,
        isPlusCompany,
        fetchNumberOfChangesForCompany,
        fetchNumberOfConsultantChangesForCompany,
        numberOfConsultantChangesForCompany,
        setNumberOfConsultantChangesForCompany,
    } = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SubscriptionModel[]>([]);
    const [filteredData, setFilteredData] = useState<SubscriptionModel[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showColumnSearch, setShowColumnSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [lawTypeFilter, setLawTypeFilter] = useState<LawTypeFilter[]>([]);
    const [lawListGroupFilter, setLawListGroupFilter] = useState<LawListGroupFilter[]>([]);
    const [selectedKeywords, setSelectedKeywords] = useState<(KeywordModel & { checked: boolean })[]>([]);
    const [visibleDataCount, setVisibleDataCount] = useState<number>(0);
    const [sorting, setSorting] = useState<SortingRule<object>[]>([{ id: 'publishedDate', desc: true }]);
    const [columnSearch, setColumnSearch] = useState<
        {
            id: string;
            value: string | undefined;
        }[]
    >([]);
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
    const [assessmentUsers, setAssessmentUsers] = useState<UserModel[]>([]);
    const [acknowledgeChangeOpen, setAcknowledgeChangeOpen] = useState(false);
    const [acknowledgeNewChangeOpen, setAcknowledgeNewChangeOpen] = useState(false);
    const [acknowledgeEditOpen, setAcknowledgeEditOpen] = useState(false);
    const [editKeywords, setEditKeywords] = useState<{ open: boolean; keywordIds: number[] }>({ open: false, keywordIds: [] });
    const [editLawLists, setEditLawLists] = useState<{ open: boolean; lawLists: LawListModel[] }>({ open: false, lawLists: [] });
    const [multiAcceptModalOpen, setMultiAcceptModalOpen] = useState(false);
    const initialEditOldModalState = { warningOpen: false, editOpen: false };
    const [editOldModal, setEditOldModal] = useState<{ warningOpen: boolean; editOpen: boolean; subscription?: SubscriptionModel }>(initialEditOldModalState);
    const [editRevisionQuestionsOpen, setEditRevisionQuestionsOpen] = useState(false);
    const [hideFilterbar, setHideFilterbar] = useState(false);
    const { exportList } = useExport();

    const [columns, setColumns] = useState(() => {
        const columns: Array<Column<SubscriptionModel> & {
            id: columnKeys;
            visible: boolean;
            Header: string;
            alwaysVisible?: boolean;
            showNotification?: boolean;
            languageId?: string;
        }> = [
            {
                id: 'law',
                Header: t('columnLaw'),
                languageId: 'columnLaw',
                accessor: row => `${row.name} ${row.subId}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.law;
                    const lawB = rowB.values.law;
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
                visible: true,
                width: 2,
                alwaysVisible: true,
                showNotification: true,
                Cell: LawCell,
            },
            {
                id: 'changeText',
                Header: t('columnChange'),
                languageId: 'columnChange',
                accessor: 'changeText',
                visible: true,
                width: 2,
                alwaysVisible: true,
                disableSortBy: true,
            },
            {
                id: 'publishedDate',
                Header: t('columnDate'),
                languageId: 'publishedDate',
                accessor: row => (row.publishedDate ? row.publishedDate : ''),
                visible: true,
                alwaysVisible: true,
                sortInverted: true,
            },
            {
                id: 'newLaw',
                Header: '',
                accessor: row => `${row.newLaw}`,
                visible: false,
                alwaysVisible: true,
                defaultCanSort: true,
            },
        ];

        if (isGroupCompany()) {
            columns.push({
                id: 'companies',
                Header: t('columnCompanies'),
                languageId: 'columnCompanies',
                accessor: row => (row.subscribedByAllInGroup ? [t('all')] : row.companyNames),
                Cell: KeywordCell,
                disableSortBy: true,
                visible: true,
            });
        } else if (isPlusCompany()) {
            columns.push({
                id: 'description',
                Header: t('columnDescription'),
                languageId: 'columnDescription',
                accessor: 'description',
                disableSortBy: true,
                visible: false,
            });
            columns.push({
                id: 'text',
                Header: t('columnText'),
                languageId: 'columnText',
                accessor: 'text',
                disableSortBy: true,
                visible: true,
            });

            for (let i = 1; i <= 5; i++) {
                const title = _.get(company, 'customHeaderName' + i);
                if (!_.isEmpty(title)) {
                    columns.push({
                        id: ('customerText' + i) as columnKeys,
                        accessor: 'customerText' + i,
                        visible: false,
                        Header: title,
                        disableSortBy: true,
                    });
                }
            }

            if (company?.hasLawLists) {
                columns.push({
                    id: 'lawLists',
                    visible: false,
                    accessor: row => (row.lawLists ? row.lawLists.map(lawList => (lawList.lawListGroup ? lawList.lawListGroup.name + ': ' : '') + lawList.name) : ''),
                    Cell: KeywordCell,
                    Header: t('columnLawLists'),
                    languageId: 'columnLawLists',
                    disableSortBy: true,
                });
            }

            if (company?.hasKeyWords) {
                columns.push({
                    id: 'keywords',
                    visible: false,
                    accessor: row => row.keywordIds.map((id: number) => _.find(company.keyWords, kw => kw.id === id)?.text),
                    Cell: KeywordCell,
                    Header: t('columnKeywords'),
                    languageId: 'columnKeywords',
                    disableSortBy: true,
                });
            }
        }

        return columns;
    });

    const editViewColumns: { id: number; accessor: string; Header: string; data: string }[] = useMemo(() => {
        const editColumns = [];

        if (selectedIndex > -1) {
            let index = 0;

            if (!isAdminSubs()) {
                editColumns.push({
                    id: index++,
                    accessor: 'text',
                    Header: t('columnText'),
                    data: _.get(filteredData[selectedIndex], 'text'),
                });
            }

            editColumns.push({
                id: index++,
                accessor: 'originalChangeText',
                Header: t('columnChange'),
                data: _.get(filteredData[selectedIndex], 'originalChangeText'),
            });

            if (!isGroupCompany()) {
                const customerCustomColumns = columns.filter(col => col.id.startsWith('customer'));
                if (customerCustomColumns.length) {
                    editColumns.push(
                        ...customerCustomColumns.map(col => ({
                            id: index++,
                            accessor: typeof col.accessor === 'string' ? col.accessor : col.id,
                            Header: col.Header,
                            data: _.get(filteredData[selectedIndex], typeof col.accessor === 'string' ? col.accessor : col.id),
                        })),
                    );
                }
            }
        }

        return editColumns;
    }, [selectedIndex, i18n.language, filteredData[selectedIndex]]);

    const fetchData = async () => {
        if (company) {
            try {
                setLoading(true);
                let subscriptions: SubscriptionModel[] = [];
                let lawTypes: LawTypeModel[] = [];

                if (isGroupCompany()) {
                    const data = await ConsultantSubscriptionChangesService().getGroupCompanyChanges(company.id);
                    subscriptions = data.subscriptions;
                    lawTypes = data.lawTypes;
                } else {
                    const data = await ConsultantSubscriptionChangesService().getChanges(company.id);
                    subscriptions = data.subscriptions;
                    lawTypes = data.lawTypes;
                }
                const assessmentUsers = await UserService().getUsersWithAssessmentRights(company.id);

                if (company.hasKeyWords) {
                    setSelectedKeywords(
                        company.keyWords.map(kw => ({
                            ...kw,
                            checked: false,
                        })),
                    );
                }

                if (company.hasLawLists) {
                    setLawListGroupFilter(convertToLawListGroupFilter(company.lawLists));
                }

                const selection: Record<string, boolean> = {};
                subscriptions.forEach(item => {
                    selection[item.subscriptionId] = false;
                });
                setSelectedRows(selection);

                fetchNumberOfConsultantChangesForCompany();

                setData(subscriptions);
                setAssessmentUsers(assessmentUsers);
                setLawTypeFilter(convertToLawTypeFilter(lawTypes));
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleRowsChange = (filteredRowIds: string[]) => {
        setSelectedRows(sr => {
            const anyChecked = _.some(filteredRowIds, id => sr[id] === true);
            return _.mapValues(sr, (value, id) => {
                if (_.includes(filteredRowIds, id)) {
                    const obj: SubscriptionModel | undefined = data.find(d => d.subscriptionId === +id);
                    if (obj !== undefined) {
                        if (obj.newLaw) {
                            return value;
                        }
                    }
                    return !anyChecked;
                }
                return value;
            });
        });
    };

    const handleRowChange = (id: string) => {
        setSelectedRows(s => ({
            ...s,
            [id]: !s[id],
        }));
    };

    const handleLawTypeOpen = (type: LawType): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        );
    };

    const handleLawTypeChecked = (type: LawType): void => {
        setLawTypeFilter(s =>
            s.map(lawType => {
                if (lawType.name === type) {
                    return {
                        ...lawType,
                        lawGroups: lawType.lawGroups.map(lawGroup => ({
                            ...lawGroup,
                            checked: !lawType.lawGroups.every(l => l.checked),
                        })),
                    };
                }
                return lawType;
            }),
        );
    };

    const handleLawGroupChecked = (lawGroupId: number): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
        );
    };

    const handleSelectedKeywordChange = (keywordId: number): void => {
        setSelectedKeywords(s =>
            s.map(keyword => ({
                ...keyword,
                checked: keyword.id === keywordId ? !keyword.checked : keyword.checked,
            })),
        );
    };

    const handleSelectAllKeywords = (checked: boolean): void => {
        setSelectedKeywords(s =>
            s.map(kw => ({
                ...kw,
                checked,
            })),
        );
    };

    const handleLawListGroupFilterChange = (lawListGroupId: number): void => {
        setLawListGroupFilter(s =>
            s.map(lawListGroup => {
                if (lawListGroup.lawListGroupId === lawListGroupId) {
                    return {
                        ...lawListGroup,
                        lawLists: lawListGroup.lawLists.map(lawList => ({
                            ...lawList,
                            checked: !lawListGroup.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return lawListGroup;
            }),
        );
    };

    const handleLawListFilterChange = (lawListId: number): void => {
        setLawListGroupFilter(s =>
            s.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: lawList.lawListId === lawListId ? !lawList.checked : lawList.checked,
                })),
            })),
        );
    };

    const handleAllLawListChange = (checked: boolean): void => {
        setLawListGroupFilter(s =>
            s.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked,
                })),
            })),
        );
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
        );
    };

    const handleResetLawTypeFilter = (): void => {
        setLawTypeFilter(s =>
            s.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
        );
    };

    const handleResetAllFilters = () => {
        setGlobalSearch('');
        setSelectedKeywords(s => s.map(kw => ({ ...kw, checked: false })));
        setLawListGroupFilter(s =>
            s.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: false,
                })),
            })),
        );
        setColumnSearch(s => s.map(cs => ({ ...cs, value: undefined })));
        setShowColumnSearch(false);
        handleResetLawTypeFilter();
    };

    const handleColumnVisibleChange = (id: string): void => {
        setColumns(s =>
            s.map(column => ({
                ...column,
                visible: column.id === id ? !column.visible : column.visible,
            })),
        );
    };

    const handleCancelSelection = () => {
        setSelectedRows(s => _.mapValues(s, () => false));
    };

    const handleSubmitAcceptance = async (assessmentChoice: AssessmentChoice, comment: string | undefined = '', delegateUserIds: number[], sendMailToDelegates: boolean) => {
        const subscriptionId = filteredData[selectedIndex].subscriptionId;
        if (subscriptionId && user && company) {
            try {
                setAcknowledgeChangeOpen(false);
                setLoading(true);
                if (isPlusCompany()) {
                    const acceptSubscriptionModel: AcceptSubscriptionModel = {
                        subscriptionIds: [subscriptionId],
                        userId: user.userId,
                        assessmentChoice,
                        comment,
                        delegateUserIds,
                        sendMailToDelegates,
                    };
                    await ConsultantSubscriptionChangesService().acceptSubscription(acceptSubscriptionModel);
                } else if (isGroupCompany()) {
                    const acceptSubscriptionModel: AcceptSubscriptionModel = {
                        subscriptionIds: [subscriptionId],
                        userId: user.userId,
                        assessmentChoice,
                        comment,
                        groupCompany: true,
                    };
                    await ConsultantSubscriptionChangesService().acceptGroupSubscription(acceptSubscriptionModel);
                }
                setData(s => s.filter(sub => sub.subscriptionId !== subscriptionId));
                setSelectedIndex(-1);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleSubmitNewAssessment = async (assessmentChoice: AssessmentChoice, comment: string | undefined = '', delegateUserIds: number[], sendMailToDelegates: boolean) => {
        const subscriptionId = filteredData[selectedIndex].subscriptionId;
        if (subscriptionId && user && company) {
            try {
                setAcknowledgeNewChangeOpen(false);
                setLoading(true);
                if (isPlusCompany()) {
                    const acceptSubscriptionModel: AcceptSubscriptionModel = {
                        subscriptionIds: [subscriptionId],
                        userId: user.userId,
                        assessmentChoice,
                        comment,
                        delegateUserIds,
                        sendMailToDelegates,
                    };
                    await ConsultantSubscriptionChangesService().acceptSubscription(acceptSubscriptionModel);
                } else if (isGroupCompany()) {
                    const acceptSubscriptionModel: AcceptSubscriptionModel = {
                        subscriptionIds: [subscriptionId],
                        userId: user.userId,
                        assessmentChoice,
                        comment,
                        groupCompany: true,
                    };
                    await ConsultantSubscriptionChangesService().acceptGroupSubscription(acceptSubscriptionModel);
                }
                setData(s => s.filter(sub => sub.subscriptionId !== subscriptionId));
                setSelectedIndex(-1);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleSubmitKeywords = async () => {
        const subscriptionId = filteredData[selectedIndex].subscriptionId;
        if (subscriptionId) {
            await SubscriptionService()
                .updateSubscriptionKeywords(subscriptionId, false, editKeywords.keywordIds)
                .then(subscription => {
                    setData(s =>
                        s.map(sub =>
                            sub.subscriptionId === subscriptionId
                                ? { ...sub, keywordIds: subscription.keywordIds, subscriptionVersion: _.get(subscription, 'subscriptionVersion') }
                                : sub,
                        ),
                    );
                });
            setEditKeywords({ open: false, keywordIds: [] });
        }
    };

    const handleSubmitLawLists = async () => {
        const lawListIds = editLawLists.lawLists.map(ll => ll.lawListId);
        const subscriptionId = filteredData[selectedIndex].subscriptionId;
        if (subscriptionId) {
            await SubscriptionService()
                .updateSubscriptionLawLists(subscriptionId, false, lawListIds)
                .then(subscription => {
                    setData(s =>
                        s.map(sub =>
                            sub.subscriptionId === subscriptionId
                                ? { ...sub, lawLists: subscription.lawLists, subscriptionVersion: _.get(subscription, 'subscriptionVersion') }
                                : sub,
                        ),
                    );
                });
            setEditLawLists({ open: false, lawLists: [] });
        }
    };

    const handleSaveEdit = async (subscriptionId: number, subscription?: SubscriptionModel, keywordIds?: number[], lawListIds?: number[]) => {
        try {
            setLoading(true);
            await SubscriptionService()
                .updateFullSubscription(subscriptionId, subscription, keywordIds, lawListIds)
                .then(updatedSubscription => {
                    setData(s =>
                        s.map(obj => {
                            if (obj.subscriptionId === subscriptionId) {
                                return {
                                    ...obj,
                                    ...updatedSubscription,
                                    companyNames: obj.companyNames,
                                };
                            }
                            return obj;
                        }),
                    );
                });
            setLoading(false);
            setAcknowledgeEditOpen(false);
            setEditOldModal(initialEditOldModalState);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setColumns(s =>
            s.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        );
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        // Apply lawGroup filtering
        const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
        let filteredData = data.filter(obj => {
            return selectedLawGroupIds.includes(obj.lawGroupId);
        });

        // Apply lawList filtering
        const selectedLawListIds = lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId));
        if (selectedLawListIds.length) {
            filteredData = filteredData.filter(f => selectedLawListIds.some(id => f.lawLists.find(lawList => lawList.lawListId === id)));
        }

        // Apply keyword filtering
        const selectedKeywordIds = selectedKeywords.filter(kw => kw.checked).map(kw => kw.id);
        if (selectedKeywordIds.length) {
            filteredData = filteredData.filter(f => selectedKeywordIds.some(id => f.keywordIds.includes(id)));
        }

        setFilteredData(filteredData);
    }, [data, lawTypeFilter, lawListGroupFilter, selectedKeywords]);

    // Filter selectedRows if any subscription is removed
    useEffect(() => {
        const selection: Record<string, boolean> = {};
        data.forEach(item => {
            selection[item.subscriptionId] = selectedRows[item.subscriptionId];
        });
        setSelectedRows(selection);

        fetchNumberOfChangesForCompany();
        if (data && data.length !== numberOfConsultantChangesForCompany) {
            setNumberOfConsultantChangesForCompany(data.length);
        }
    }, [data.length]);

    const hiddenColumns: string[] = useMemo(() => columns.filter(column => !column.visible).map(column => column.id), [columns]);
    const selectedRowIds = useMemo(() => _.filter(data, obj => selectedRows[obj.subscriptionId] === true).map(obj => obj.subscriptionId), [selectedRows, data]);

    const handleSubmitMultiAccept = async (assessmentChoice: AssessmentChoice, comment: string) => {
        if (user) {
            setLoading(true);
            setMultiAcceptModalOpen(false);
            const acceptSubscriptionModel: AcceptSubscriptionModel = {
                subscriptionIds: selectedRowIds,
                userId: user.userId,
                assessmentChoice,
                comment,
                groupCompany: isGroupCompany(),
            };
            await ConsultantSubscriptionChangesService().acceptSubscription(acceptSubscriptionModel);
            setData(s => s.filter(sub => !selectedRowIds.includes(sub.subscriptionId)));
            handleCancelSelection();
            setLoading(false);
        }
    };

    const handleEdit = async () => {
        const selected = filteredData[selectedIndex];
        async function getLatestSubscription(companyId: number, subscription: SubscriptionModel) {
            if (!selected.detachedCopy) {
                return await SubscriptionService().getLatestSubscription(companyId, subscription.baseLawId);
            } else {
                return await SubscriptionService().getLatestDetachedSubscription(subscription.subscriptionId);
            }
        }
        if (selected && company) {
            setLoading(true);
            const latestSubscription = await getLatestSubscription(selected.companyId, selected);
            if (latestSubscription.subscriptionId === selected.subscriptionId) {
                setAcknowledgeEditOpen(true);
            } else {
                setEditOldModal({ warningOpen: true, editOpen: false, subscription: latestSubscription });
            }
            setLoading(false);
        }
    };

    const handleCreateRevisionQuestion = async (subscriptionId: number, revisionQuestion: Partial<RevisionQuestion>): Promise<number> => {
        setLoading(true);
        const createdRevisionQuestion: RevisionQuestion = await RevisionQuestionService().createCustomRevisionQuestion(subscriptionId, revisionQuestion);
        setData(s =>
            s.map(obj =>
                obj.subscriptionId === subscriptionId
                    ? {
                          ...obj,
                          customRevisionQuestions: [...obj.customRevisionQuestions, createdRevisionQuestion],
                      }
                    : obj,
            ),
        );
        setLoading(false);
        return createdRevisionQuestion.id;
    };

    const handleUpdateRevisionQuestion = async (subscriptionId: number, revisionQuestion: RevisionQuestion) => {
        setLoading(true);
        const updatedRevisionQuestion: RevisionQuestion = await RevisionQuestionService().updateCustomRevisionQuestion(revisionQuestion);
        setData(s =>
            s.map(obj =>
                obj.subscriptionId === subscriptionId
                    ? {
                          ...obj,
                          customRevisionQuestions: obj.customRevisionQuestions.map(crq => (crq.id === updatedRevisionQuestion.id ? { ...crq, ...updatedRevisionQuestion } : crq)),
                      }
                    : obj,
            ),
        );
        setLoading(false);
    };

    const handleRemoveRevisionQuestion = async (subscriptionId: number, revisionQuestionId: number) => {
        setLoading(true);
        await RevisionQuestionService().removeCustomRevisionQuestion(revisionQuestionId);
        setData(s =>
            s.map(obj =>
                obj.subscriptionId === subscriptionId
                    ? {
                          ...obj,
                          customRevisionQuestions: obj.customRevisionQuestions.filter(crq => crq.id !== revisionQuestionId),
                      }
                    : obj,
            ),
        );
        setLoading(false);
    };

    const handleSubmitRevisionQuestionExclusions = async (excludedRevisionQuestionIds: number[], excludedCustomRevisionQuestionIds: number[]) => {
        const selected = filteredData[selectedIndex];
        if (selected) {
            setLoading(true);
            const customRevisionQuestions = await RevisionQuestionService().updateCustomRevisionQuestions(
                selected.customRevisionQuestions.map(crq => ({ ...crq, excluded: excludedCustomRevisionQuestionIds.includes(crq.id) })),
            );
            const subscriptionModel = await RevisionQuestionService().updateExcludedRevisionQuestions(selected.subscriptionId, excludedRevisionQuestionIds);
            setData(s =>
                s.map(obj =>
                    obj.subscriptionId === selected.subscriptionId
                        ? {
                              ...obj,
                              excludedIds: subscriptionModel.excludedBaseRevisionQuestionIds,
                              subscriptionVersion: subscriptionModel.subscriptionVersion,
                              customRevisionQuestions,
                              revisionQuestionGroups: obj.revisionQuestionGroups.map(rqg => ({
                                  ...rqg,
                                  revisionQuestions: rqg.revisionQuestions.map(rq => ({
                                      ...rq,
                                      changedNotification:
                                          rq.changedNotification === true ? subscriptionModel.excludedBaseRevisionQuestionIds.includes(rq.id) : rq.changedNotification,
                                      excluded: subscriptionModel.excludedBaseRevisionQuestionIds.includes(rq.questionBaseId || rq.id),
                                  })),
                              })),
                          }
                        : obj,
                ),
            );
            setEditRevisionQuestionsOpen(false);
            setLoading(false);
        }
    };

    const handleWarningEditCurrent = () => {
        setEditOldModal(initialEditOldModalState);
        setAcknowledgeEditOpen(true);
    };

    const handleExport = () => {
        //default columns that always exist
        let exportColumns: Array<{ key: columnKeys; Header: string; width: number }> = [
            { key: 'lawType', Header: t('columnLawGroup'), width: ExcelColumnWidth.lawType },
            { key: 'name', Header: t('columnLawName'), width: ExcelColumnWidth.name },
            { key: 'subId', Header: t('columnSubId'), width: ExcelColumnWidth.subId },
            { key: 'newLaw', Header: t('columnNewLaw'), width: ExcelColumnWidth.newLaw },
        ];

        //Get all column ids for columns that are visible. CustomColumns are included in 'columns'
        columns.filter(c => c.visible).forEach(c => exportColumns.push({ key: c.id, Header: c.Header, width: ExcelColumnWidth[c.id] }));

        //Remove column Law. This is instead replaced by the 3 static columns
        exportColumns = exportColumns.filter(c => c.key !== 'law');

        //If new columns are added in table, they need to be added here also for export to work
        const exportData = filteredData.map(obj => ({
            lawType: t(LawTypeEnum[obj.lawType]) + ': ' + obj.lawGroupName,
            name: obj.name,
            subId: obj.subId ? t('lawSubId') + ` ${obj.subId}` : '',
            newLaw: obj.newLaw ? t('columnNewLaw') : '',
            changeText: obj.changeText,
            publishedDate: obj.publishedDate,
            description: obj.description,
            text: obj.text,
            customerText1: obj.customerText1,
            customerText2: obj.customerText2,
            customerText3: obj.customerText3,
            customerText4: obj.customerText4,
            customerText5: obj.customerText5,
            lawLists: company?.hasLawLists && obj.lawLists ? obj.lawLists.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name).join('<br>') : '',
            keywords: company?.hasKeyWords && obj.keywordIds ? obj.keywordIds.map(id => _.find(company.keyWords, kw => kw.id === id)?.text).join('<br>') : '',
            companies: company?.corporationId ? (obj.subscribedByAllInGroup ? 'Alla' : obj.companyNames.join('<br>')) : '',
        }));

        exportList(exportColumns, exportData, 'Ändringar konsult');
    };

    return (
        <div className={styled.Consultant}>
            {loading && <LoadingSpinner />}
            <ContextMenu
                lawTypeFilter={lawTypeFilter}
                onToggleLawTypeOpen={handleLawTypeOpen}
                onToggleLawTypeChecked={handleLawTypeChecked}
                onToggleLawGroupChecked={handleLawGroupChecked}
                onResetLawTypeFilter={handleResetLawTypeFilter}
                onResetAllFilters={handleResetAllFilters}
                onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                visibleDataCount={visibleDataCount}
                totalDataCount={data.length}
                links={CONTEXT_LINKS}
            />
            <div className={styled.List}>
                {!hideFilterbar && (
                    <Filterbar
                        globalSearch={globalSearch}
                        onGlobalSearchChange={setGlobalSearch}
                        selectedKeywords={selectedKeywords}
                        onSelectedKeywordChange={handleSelectedKeywordChange}
                        showColumnSearch={showColumnSearch}
                        onColumnSearchToggle={() => setShowColumnSearch(!showColumnSearch)}
                        lawListGroupFilter={lawListGroupFilter}
                        onLawListFilterChange={handleLawListFilterChange}
                        onLawListGroupFilterChange={handleLawListGroupFilterChange}
                        onColumnShowChange={handleColumnVisibleChange}
                        columns={columns.filter(col => !col.alwaysVisible)}
                        onSelectAllKeywords={handleSelectAllKeywords}
                        onAllLawListChange={handleAllLawListChange}
                        onExport={handleExport}
                    />
                )}
                <div className={styled.TableWrapper}>
                    {!loading && data && data.length > 0 && visibleDataCount !== undefined && visibleDataCount < 1 ? <EmptyListView emptyFilter /> : null}

                    <Table
                        columns={columns}
                        data={filteredData}
                        selected={selectedIndex}
                        onSelect={setSelectedIndex}
                        showColumnSearch={showColumnSearch}
                        globalSearch={globalSearch}
                        columnSearch={columnSearch}
                        onVisibleDataCountChange={setVisibleDataCount}
                        hiddenColumns={hiddenColumns}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={handleRowsChange}
                        onSelectedRowChange={handleRowChange}
                        hideDisabledCheckboxes
                        multiSelect
                        useDivider
                    />

                    {acknowledgeChangeOpen && selectedIndex > -1 && (
                        <AcknowledgeChangeView
                            editDisabled={editViewColumns.length < 1 && company !== undefined && !company.hasLawLists && !company.hasKeyWords}
                            data={filteredData[selectedIndex]}
                            assessmentUsers={assessmentUsers}
                            onClose={() => {
                                setAcknowledgeChangeOpen(false);
                                setHideFilterbar(false);
                            }}
                            onEdit={() => handleEdit()}
                            onEditRevisionQuestions={() => setEditRevisionQuestionsOpen(true)}
                            onSubmit={data => {
                                handleSubmitAcceptance(data.assessmentChoice, data.comment, data.assessmentUsers, data.notifyEmail);
                                setHideFilterbar(false);
                            }}
                            consultantStage
                            showDelagation={!isGroupCompany()}
                        />
                    )}

                    {acknowledgeNewChangeOpen && selectedIndex > -1 && (
                        <AcknowledgeNewChangeView
                            data={filteredData[selectedIndex]}
                            assessmentUsers={assessmentUsers}
                            onClose={() => {
                                setAcknowledgeNewChangeOpen(false);
                                setHideFilterbar(false);
                            }}
                            onSubmit={data => {
                                handleSubmitNewAssessment(data.assessmentChoice, data.comment, data.assessmentUsers, data.notifyEmail);
                                setHideFilterbar(false);
                            }}
                            onEditKeywords={() => setEditKeywords({ open: true, keywordIds: filteredData[selectedIndex].keywordIds })}
                            onEditLawLists={() => setEditLawLists({ open: true, lawLists: filteredData[selectedIndex].lawLists })}
                            onEditTexts={() => setAcknowledgeEditOpen(true)}
                            onEditRevisionQuestions={() => setEditRevisionQuestionsOpen(true)}
                            consultantStage
                            showDelagation={!isGroupCompany()}
                        />
                    )}

                    {acknowledgeEditOpen && selectedIndex > -1 && (
                        <AcknowledgeEditView
                            data={filteredData[selectedIndex]}
                            customColumns={editViewColumns}
                            onClose={() => setAcknowledgeEditOpen(false)}
                            onSave={handleSaveEdit}
                        />
                    )}

                    {acknowledgeNewChangeOpen && editKeywords.open && selectedIndex > -1 && (
                        <EditView
                            title={t('columnKeywords')}
                            value={editKeywords.keywordIds}
                            field="keywordIds"
                            onChange={keywordIds => setEditKeywords({ open: true, keywordIds })}
                            onClose={() => setEditKeywords({ open: false, keywordIds: [] })}
                            onSubmit={handleSubmitKeywords}
                        />
                    )}
                    {acknowledgeNewChangeOpen && editLawLists.open && selectedIndex > -1 && (
                        <EditView
                            title={t('columnLawLists')}
                            value={editLawLists.lawLists}
                            field="lawLists"
                            onChange={lawLists => setEditLawLists({ open: true, lawLists })}
                            onClose={() => setEditLawLists({ open: false, lawLists: [] })}
                            onSubmit={handleSubmitLawLists}
                        />
                    )}

                    {editRevisionQuestionsOpen && filteredData[selectedIndex] && (
                        <EditRevisionQuestionView
                            data={filteredData[selectedIndex]}
                            onCreateRevisionQuestion={data => handleCreateRevisionQuestion(filteredData[selectedIndex].subscriptionId, data)}
                            onUpdateRevisionQuestion={data => handleUpdateRevisionQuestion(filteredData[selectedIndex].subscriptionId, data)}
                            onRemoveRevisionQuestion={id => handleRemoveRevisionQuestion(filteredData[selectedIndex].subscriptionId, id)}
                            onCancel={() => setEditRevisionQuestionsOpen(false)}
                            onSubmit={handleSubmitRevisionQuestionExclusions}
                        />
                    )}

                    <ConsultantFullView
                        data={filteredData[selectedIndex]}
                        open={selectedIndex > -1}
                        onClose={() => {
                            setSelectedIndex(-1);
                            setEditRevisionQuestionsOpen(false);
                            setAcknowledgeChangeOpen(false);
                            setAcknowledgeEditOpen(false);
                            setAcknowledgeNewChangeOpen(false);
                            setHideFilterbar(false);
                        }}
                        hideFooter={editRevisionQuestionsOpen || acknowledgeChangeOpen || acknowledgeEditOpen || acknowledgeNewChangeOpen || selectedRowIds.length > 0}
                        disableAcknowledge={selectedRowIds.length > 0}
                        onAcknowledgeChange={() => {
                            setAcknowledgeChangeOpen(true);
                            setHideFilterbar(true);
                        }}
                        onAcknowledgeNewChange={() => {
                            setAcknowledgeNewChangeOpen(true);
                            setHideFilterbar(true);
                        }}
                    />
                </div>

                {selectedRowIds.length > 0 && (
                    <ConsultantToaster
                        counter={selectedRowIds.length}
                        fullViewOpen={selectedIndex > -1}
                        onCancel={handleCancelSelection}
                        onSave={() => setMultiAcceptModalOpen(true)}
                    />
                )}

                {editOldModal.warningOpen && selectedIndex > -1 && (
                    <EditOldWarningModal
                        onClose={() => setEditOldModal(initialEditOldModalState)}
                        onEditCurrent={handleWarningEditCurrent}
                        onEditLatest={() => setEditOldModal(s => ({ ...s, warningOpen: false, editOpen: true }))}
                    />
                )}

                {editOldModal.editOpen && editOldModal.subscription && (
                    <EditLatestSubscriptionModal
                        subscription={editOldModal.subscription}
                        customColumns={editViewColumns}
                        onClose={() => setEditOldModal(initialEditOldModalState)}
                        onSave={handleSaveEdit}
                    />
                )}

                {multiAcceptModalOpen && (
                    <ConsultantMultiAcceptModal count={selectedRowIds.length} onCancel={() => setMultiAcceptModalOpen(false)} onSubmit={handleSubmitMultiAccept} />
                )}
            </div>
        </div>
    );
};

export default Consultant;
