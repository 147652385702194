import React, { createRef, FC, useState } from 'react';
import styled from './UploadCompanyDocumentModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import { Trans, useTranslation } from 'react-i18next';
import { CompanyDocumentTypeEnum,  } from '../../../models/CompanyDocumentType';
import CompanyDocumentModel from '../../../models/CompanyDocumentModel';
import UploadCompanyDocument from '../UploadCompanyDocument/UploadCompanyDocument';

interface Props {
    documents?: CompanyDocumentModel[];
    onSave: (type: CompanyDocumentTypeEnum, file: File) => void;
    onClose: () => void;
}

const UploadCompanyDocumentModal: FC<Props> = ({ onClose, onSave, documents }) => {
    const { t } = useTranslation();

    return (
        <Modal title={'Ladda upp nytt dokument'} subtitle={'Välj kategori:'} className={styled.Modal}>
            <UploadCompanyDocument onClose={onClose} onSave={onSave} documents={documents}></UploadCompanyDocument>
        </Modal>
    );
};

export default UploadCompanyDocumentModal;
