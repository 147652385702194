import React, { FC, useState, useEffect, useMemo } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './CloseRevisionConfirmModal.module.css';
import RevisionSubscriptionModel from '../../../models/RevisionSubscriptionModel';
import CustomLawRevisionModel from '../../../models/CustomLawRevisionModel';
import warningIcon from '../../../assets/images/statusNotOkIcon.svg';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';

interface Props {
    name: string;
    data: (RevisionSubscriptionModel | CustomLawRevisionModel)[];
    responsibleName: string;
    comment: string;
    onConfirm: () => void;
    onUpdateComment: (comment: string) => void;
    onCancel: () => void;
}

const CloseRevisionConfirmModal: FC<Props> = ({ name, data, responsibleName, comment, onUpdateComment, onConfirm, onCancel }) => {
    const { t } = useTranslation();
    const [commentValue, setCommentValue] = useState(comment ? comment : '');
    const [loading, setLoading] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const noValueCount = useMemo(() => data.filter(obj => obj.revisionStatus === 'NO_VALUE').length, [data]);
    const notAcceptedCount = useMemo(() => data.filter(obj => obj.revisionStatus === 'NOT_ACCEPTED').length, [data]);
    const acceptedCount = useMemo(() => data.filter(obj => obj.revisionStatus === 'ACCEPTED').length, [data]);
    const notRelevantCount = useMemo(() => data.filter(obj => obj.revisionStatus === 'NOT_RELEVANT').length, [data]);
    const observationCount = useMemo(() => data.filter(obj => obj.revisionStatus === 'OBSERVATION').length, [data]);
    const totalNotAcceptedCount = useMemo(() => data.filter(obj => obj.revisionStatus === 'NOT_ACCEPTED' || obj.revisionStatus === 'OBSERVATION').length, [data]);

    useEffect(() => {
        setCommentValue(comment);
    }, [comment]);

    const handleConfirm = () => {
        setLoading(true);
        onConfirm();
        setTimeout(() => {
            setLoadingSpinner(true);
        }, 1000);
    };

    const handleUpdateComment = () => {
        onUpdateComment(commentValue);
    };

    return (
        <Modal title={t('closeRevisionAuditHeaderTitle', { name })} className={styled.Modal}>
            {loadingSpinner && <LoadingSpinner message={t('spinnerFinishRevisionLoading')} />}
            <div className={[styled.Content, noValueCount > 0 ? styled.Warning : ''].join(' ')}>
                <div className={styled.Summary}>
                    <p className={noValueCount > 0 ? styled.NoValueWarning : ''}>
                        {t('revisionStatusTitleNoValue')} {noValueCount}
                    </p>
                    <p>
                        {t('revisionStatusTitleNotAccepted')}: {notAcceptedCount}
                    </p>
                    <p>
                        {t('revisionStatusTitleAccepted')}: {acceptedCount}
                    </p>
                    <p>
                        {t('revisionStatusTitleNotRelevant')}: {notRelevantCount}
                    </p>
                    <p>
                        {t('revisionStatusTitleObservation')}: {observationCount}
                    </p>
                    <p>{t('closeRevisionSummary', { lawCount: data.length, notAccepted: totalNotAcceptedCount })}</p>
                    <p>
                        {t('closeRevisionResponsiblePerson')}: {responsibleName}
                    </p>
                </div>

                <div className={styled.Comments}>
                    <h4 className={styled.CommentTitle}>{t('closeRevisioncommentTitle')}</h4>
                    <textarea
                        className={styled.Input}
                        value={commentValue}
                        onChange={e => setCommentValue(e.target.value)}
                        placeholder={t('inputFieldPlaceholderRevisionQuestion')}
                        disabled={loading}
                    />
                    <Button variant="Primary" className={styled.CommentButton} onClick={handleUpdateComment} disabled={comment === commentValue || loading}>
                        {t('buttonSaveComment')}
                    </Button>
                </div>

                <h4 className={styled.OptionsTitle}>{t('closeRevisionControlQuestionTitle')}</h4>
                {noValueCount > 0 && (
                    <p className={styled.WarningText}>
                        <img src={warningIcon} />
                        {t('closeRevisionObserveUnresolvedLawsTitle')}
                    </p>
                )}
                <div className={styled.Options}>
                    <Button variant="Outline" disabled={loading} onClick={onCancel}>
                        {t('buttonReturnToAudit')}
                    </Button>
                    <Button variant="Primary" disabled={loading} onClick={handleConfirm}>
                        {t('buttonYes')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CloseRevisionConfirmModal;
