import React, { FC } from 'react';
import { LawType, LawTypeEnum } from '../../models/LawType';
import styled from './LawTypeBadge.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    type: LawType;
    group?: string;
}

const LawTypeBadge: FC<Props> = ({ type, group }) => {
    const { t } = useTranslation();
    return (
        <div className={[styled.LawTypeBadge, styled[type]].join(' ')} title={t(LawTypeEnum[type]) + `${group ? ': ' + group : ''}`}>
            {t(LawTypeEnum[type])}
            {group && `: ${group}`}
        </div>
    );
};

export default LawTypeBadge;
