import React, { FC, useEffect, useState } from 'react';
import Button from '../../Button/Button';
import styled from './MediaPreviewAndCommentModal.module.css';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal/Modal';
import TextEditor from '../../TextEditor/TextEditor';
import RevisionMediaAttachmentModel from '../../../models/RevisionMediaAttachmentModel';
import removeIcon from '../../../assets/images/removeIconRed.svg';
import WarningModal from '../../../Invoicing/components/modals/WarningModal/WarningModal';
import useEditRevision from '../../../hooks/useEditRevision';

interface Props {
    file: File | undefined;
    selectedMedia: RevisionMediaAttachmentModel | undefined;
    onCancel: () => void;
    onConfirm: (value: string) => void;
    onDelete: (mediaId: number) => void;
    onEdit: (mediaId: number, comment: string) => void;
}
const BASE_64_STRING = 'data:image/png;base64,';
const MediaPreviewAndCommentModal: FC<Props> = ({ file, selectedMedia, onCancel, onConfirm, onDelete, onEdit }) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState<{ value: string }>();
    const [filePreview, setFilePreview] = useState<any>();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [maxLengthError, setMaxLengthError] = useState<boolean>(false);
    const [hasEditTextEditor, setHasEditTextEditor] = useState<boolean>(false);
    const [selectedMediaObj, setSelectedMediaObj] = useState<RevisionMediaAttachmentModel>();
    const [showWarningModal, setShowWarningModal] = useState<{ mediaId: number; show: boolean }>({ mediaId: -1, show: false });
    const [allowedToDelete, setAllowedToDelete] = useState(true);
    const { revision } = useEditRevision();

    useEffect(() => {
        setComment(undefined);
        if (file) {
            setFilePreview(URL.createObjectURL(file));
        } else if (selectedMedia) {
            setSelectedMediaObj(selectedMedia);
            setEditMode(true);
            if (selectedMedia.fullData) {
                setFilePreview(BASE_64_STRING + selectedMedia.fullData);
            } else {
                setFilePreview(BASE_64_STRING + selectedMedia.thumbnail);
            }
            if (revision?.finished && selectedMedia.stage === 'NOT_FINISHED') {
                setAllowedToDelete(false);
            }
        }
    }, [file, selectedMedia, selectedMedia?.fullData]);

    const handleConfirmImageAndComment = () => {
        onConfirm(comment?.value || '');
    };

    const handleOnCancel = () => {
        setSelectedMediaObj(undefined);
        setComment(undefined);
        setFilePreview(undefined);
        onCancel();
    };

    const handleCommentChange = (value: string) => {
        const newVal = value.slice(0, value.length - 4).slice(3);
        if (selectedMediaObj?.comment !== newVal) {
            setHasEditTextEditor(true);
            setComment(s => ({ ...s, value }));
        } else {
            setHasEditTextEditor(false);
        }
    };

    const handleSaveComment = () => {
        if (selectedMediaObj && comment) {
            onEdit(selectedMediaObj?.mediaId, comment.value);
        }
    };

    return (
        <>
            <Modal title={''} className={styled.ImageModal}>
                <div className={styled.Container}>
                    <div className={styled.ModalTopBar}>
                        <h4 className={styled.Title}>{!editMode ? t('confirmImageTitle') : t('selectedMediaTitle')}</h4>
                        {editMode && allowedToDelete && (
                            <img
                                onClick={() => (selectedMediaObj?.mediaId ? setShowWarningModal({ mediaId: selectedMediaObj.mediaId, show: true }) : undefined)}
                                src={removeIcon}
                                className={styled.RemoveIcon}
                                alt="Remove"
                            />
                        )}
                    </div>
                    <div className={styled.ImageContainer}>
                        <img className={styled.ImagePreview} src={filePreview} />
                    </div>
                    <div className={styled.Editor}>
                        <TextEditor
                            title={t('mediaComment')}
                            initialValue={selectedMediaObj?.comment}
                            onChange={value => handleCommentChange(value)}
                            maxLength={100}
                            noOptions={true}
                            errorMsg={maxLengthError ? 'Kommentaren är för lång.' : ''}
                            onMaxLengthError={setMaxLengthError}
                        />
                    </div>
                    <div className={styled.Buttons}>
                        <Button variant="Outline" onClick={handleOnCancel}>
                            {t('buttonClose')}
                        </Button>
                        {editMode ? (
                            <Button variant="Primary" disabled={!allowedToDelete || maxLengthError || !hasEditTextEditor} onClick={handleSaveComment}>
                                {t('saveMediaComment')}
                            </Button>
                        ) : (
                            <Button variant="Primary" disabled={maxLengthError} onClick={handleConfirmImageAndComment}>
                                {t('buttonOk')}
                            </Button>
                        )}
                    </div>
                </div>
            </Modal>
            {showWarningModal.show && selectedMediaObj && showWarningModal.mediaId > -1 && (
                <WarningModal
                    onClose={() => setShowWarningModal({ mediaId: -1, show: false })}
                    onAccept={() => {
                        onDelete(showWarningModal.mediaId);
                        setShowWarningModal({ mediaId: -1, show: false });
                    }}
                    title={t('deleteImage')}
                    subtitle={t('mediaDeleteControl')}
                    className={styled.WarningModel}
                />
            )}
        </>
    );
};

export default MediaPreviewAndCommentModal;
