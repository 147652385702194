import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import Modal from '../Modal';
import styled from './EditRevisionWarningModal.module.css';

interface Props {
    onConfirm: () => void;
    onCancel: () => void;
}

const EditRevisionWarningModal: FC<Props> = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('editRevisionWarningModalTitle')} subtitle={t('editRevisionWarningModalSubTitle')}>
            <div className={styled.Options}>
                <Button variant="Outline" onClick={onCancel}>
                    {t('buttonNo')}
                </Button>
                <Button variant="Primary" onClick={onConfirm}>
                    {t('buttonYes')}
                </Button>
            </div>
        </Modal>
    );
};

export default EditRevisionWarningModal;
