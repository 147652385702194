import React, { useState, ReactNode, useMemo, useCallback } from 'react';
import styled from './ContextMenu.module.css';
import backIcon from '../../assets/images/backIcon.svg';
import ContextMenuFilter from './ContextMenuFilter/ContextMenuFilter';
import { LawTypeEnum, LawType } from '../../models/LawType';
import ContextMenuCount from './ContextMenuCount/ContextMenuCount';
import ContextMenuReset from './ContextMenuReset/ContextMenuReset';
import { Link, useLocation } from 'react-router-dom';
import LawTypeFilter from '../../models/LawTypeFilter';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Checkbox/Checkbox';
import { map } from 'lodash';

const ConditionalLink = ({ url, active, children }: { url?: string; active: boolean; children: ReactNode }) => {
    const location = useLocation();
    return url && location.pathname !== url ? (
        <Link className={[styled.ContextMenuItem, active ? styled.Active : ''].join(' ')} to={url}>
            {children}
        </Link>
    ) : (
        <div className={[styled.ContextMenuItem, styled.Active].join(' ')}>{children}</div>
    );
};

interface Props {
    title?: string;
    lawTypeFilter?: LawTypeFilter[];
    customLawsSelected?: boolean;
    hasCustomLaws?: boolean;
    onToggleLawTypeOpen?: (type: LawType) => void;
    onToggleLawTypeChecked?: (type: LawType) => void;
    onToggleLawGroupChecked?: (lawGroupId: number) => void;
    onResetLawTypeFilter?: () => void;
    onResetAllFilters?: () => void;
    onToggleAllLawTypesChecked?: (allChecked: boolean) => void;
    visibleDataCount: number;
    totalDataCount: number;
    links?: {
        title: string;
        url?: string;
        icon: string;
        active: boolean;
    }[];
    resetButtonText?: string;
}

const ContextMenu: React.FC<Props> = ({
    title,
    lawTypeFilter,
    customLawsSelected,
    hasCustomLaws,
    onToggleLawTypeOpen,
    onToggleLawTypeChecked,
    onToggleLawGroupChecked,
    onResetLawTypeFilter,
    onResetAllFilters,
    onToggleAllLawTypesChecked,
    visibleDataCount,
    totalDataCount,
    links,
    resetButtonText,
}) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(true);

    const handleToggle = (): void => {
        setOpen(!open);
    };

    const allChecked = useMemo(() => lawTypeFilter?.every(ltf => ltf.lawGroups.every(lg => lg.checked)) && (hasCustomLaws ? customLawsSelected : true), [
        lawTypeFilter,
        hasCustomLaws,
        customLawsSelected,
    ]);

    const someChecked = useMemo(() => lawTypeFilter?.some(ltf => ltf.lawGroups.some(lg => lg.checked)) || (hasCustomLaws ? customLawsSelected : false), [
        lawTypeFilter,
        hasCustomLaws,
        customLawsSelected,
    ]);

    return (
        <div className={[styled.ContextMenu, open ? '' : styled.Closed].join(' ')}>
            <div className={styled.Header}>
                <span className={styled.Title}>{title || t('contextMenuLawTitle')}</span>
                <img src={backIcon} alt="Toggle" className={styled.Toggle} onClick={handleToggle} />
            </div>

            <div className={styled.ContextMenuList}>
                {links?.map(link => (
                    <React.Fragment key={link.title}>
                        <ConditionalLink url={link.url} active={link.active}>
                            <img className={styled.Icon} src={link.icon} alt="Icon" />
                            <span className={styled.Text}>{link.title}</span>
                            {link.active && open && lawTypeFilter && onToggleAllLawTypesChecked && (
                                <div className={styled.CheckboxAll}>
                                    <Checkbox checked={allChecked} indeterminate={someChecked && !allChecked} onChange={() => onToggleAllLawTypesChecked(!!allChecked)} />
                                </div>
                            )}
                        </ConditionalLink>

                        {link.active && lawTypeFilter && onToggleLawGroupChecked && onToggleLawTypeOpen && onToggleLawTypeChecked && (
                            <div className={styled.ContextMenuFilter}>
                                {lawTypeFilter.map(f => {
                                    return (
                                        <ContextMenuFilter
                                            key={f.name}
                                            type={f.name}
                                            title={t(LawTypeEnum[f.name])}
                                            options={f.lawGroups}
                                            customChecked={customLawsSelected}
                                            open={f.open}
                                            onToggleLawTypeOpen={() => onToggleLawTypeOpen(f.name)}
                                            onToggleLawTypeChecked={() => onToggleLawTypeChecked(f.name)}
                                            onToggleLawGroupChecked={onToggleLawGroupChecked}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>

            {onResetAllFilters && (
                <ContextMenuReset open={open} onResetAllFilters={onResetAllFilters} visibleDataCount={visibleDataCount} totalDataCount={totalDataCount} btnText={resetButtonText} />
            )}
        </div>
    );
};

export default ContextMenu;
