import React, { FC } from 'react';
import styled from './RadioButton.module.css';

interface Props {
    checked: boolean;
    onChange: () => void;
    text: string;
    group: string;
}

const RadioButton: FC<Props> = ({ checked, onChange, text, group }) => {
    return (
        <label className={styled.Container}>
            <span className={styled.Text}>{text}</span>
            <input type="radio" checked={checked} onChange={onChange} name={group} />
            <span className={styled.Checkmark}></span>
        </label>
    );
};

export default RadioButton;
