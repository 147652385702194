import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from './EmailNotifications.module.css';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Toggle from '../../../../components/Toggle/Toggle';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import Search from '../../../../components/Search/Search';
import Table from '../../../../components/Table/Table';
import { Column } from 'react-table';
import CompanyService from '../../../../services/CompanyService';
import useAuth from '../../../../hooks/useAuth';
import accordionIcon from '../../../../assets/images/accordionIcon.svg';
import DenseCell from '../../../../components/Table/DenseCell/DenseCell';
import CheckboxCell from '../../../../components/Table/CheckboxCell/CheckboxCell';
import EmailNotificationsToaster from '../../../../components/EmailNotificationsToaster/EmailNotificationsToaster';
import { getLawTypeId, LawTypeEnum, sortLawTypes } from '../../../../models/LawType';
import SubscriptionService from '../../../../services/SubscriptionService';
import UserService from '../../../../services/UserService';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import UserModel from '../../../../models/UserModel';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import LawTypeModel from '../../../../models/LawTypeModel';
import { EmailNotificationFilterType } from '../../../../models/EmailNotificationFilterType';

interface Data {
    parentId?: number;
    childId?: number;
    name: string;
    description?: string;
    subRows?: Data[];
}

const EmailNotifications: FC = () => {
    const { t, i18n } = useTranslation();
    const { company, user, signedInAsUser, updateEmailNoticeSettings, updateEmailNotificationActive, isAboveConsultant } = useAuth();

    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState<EmailNotificationFilterType>(EmailNotificationFilterType.STANDARD_LIST);
    const [search, setSearch] = useState('');
    const [lawLists, setLawLists] = useState<Data[]>([]);
    const [lawGroups, setLawGroups] = useState<Data[]>([]);
    const [keywords, setKeywords] = useState<Data[]>([]);
    const [selectedLawListIds, setSelectedLawListIds] = useState<number[]>([]);
    const [selectedLawGroupIds, setSelectedLawGroupIds] = useState<number[]>([]);
    const [selectedKeywordIds, setSelectedKeywordIds] = useState<number[]>([]);

    // Admin
    const [users, setUsers] = useState<UserModel[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserModel | undefined>(signedInAsUser || undefined);
    const [lawTypes, setLawTypes] = useState<LawTypeModel[]>([]);

    // -----

    function getActiveUser(): UserModel | undefined {
        return selectedUser || user;
    }

    function emailNotificationSettingsIdNull(): boolean {
        return getActiveUser()?.emailNoticeSettings?.id === null;
    }

    function updateSelectedUser(updatedUser: UserModel): void {
        const updatedUsers = users.map(u => {
            if (u.userId === updatedUser.userId) {
                return updatedUser;
            }
            return u;
        });
        setUsers(updatedUsers);
        setSelectedUser(updatedUsers.find(u => u.userId === updatedUser.userId));
    }

    const fetchUsers = async (): Promise<void> => {
        const users = await UserService().getCompanyUsersWithEmailNoticeSettings(company?.id || -1);
        setUsers(users);
    };

    const fetchLawGroups = async (): Promise<void> => {
        const lawTypes = await SubscriptionService().getSubscribedLawTypes(company?.id || -1);
        const data: Data[] = lawTypes
            .sort((a, b) => sortLawTypes(a.name, b.name))
            .map<Data>(lawType => ({
                parentId: getLawTypeId(lawType.name),
                name: LawTypeEnum[lawType.name],
                subRows: lawType.lawGroups.map(lawGroup => ({
                    parentId: getLawTypeId(lawType.name),
                    childId: lawGroup.lawGroupId,
                    name: lawGroup.lawGroupName,
                })),
            }));
        setLawTypes(lawTypes);
        setLawGroups(data);
    };

    const fetchLawLists = async (): Promise<void> => {
        const lawListGroups = await CompanyService().getLawListGroups(company?.id || -1);
        const lawLists = await CompanyService().getLawLists(company?.id || -1);

        const data: Data[] = lawListGroups.map<Data>(lawListGroup => ({
            parentId: lawListGroup.lawListGroupId,
            name: lawListGroup.name,
            subRows: [],
        }));

        lawLists.forEach(lawList => {
            if (lawList.lawListGroup) {
                const lawListGroupIndex = data.findIndex(llg => llg.parentId === lawList.lawListGroup.lawListGroupId);
                if (lawListGroupIndex > -1 && data[lawListGroupIndex]) {
                    const lawListGroup = data[lawListGroupIndex];

                    if (!lawListGroup.subRows) {
                        lawListGroup.subRows = [];
                    }

                    lawListGroup.subRows.push({
                        childId: lawList.lawListId,
                        parentId: lawList.lawListGroup.lawListGroupId,
                        name: lawList.name,
                    });
                }
            } else {
                data.push({
                    childId: lawList.lawListId,
                    name: lawList.name,
                });
            }
        });
        setLawLists(data);
    };

    const fetchKeywords = async (): Promise<void> => {
        const keywords = await CompanyService().getFullKeywords(company?.id || -1);

        const data: Data[] = keywords.map<Data>(keyword => ({
            parentId: keyword.id,
            name: keyword.text,
            description: keyword.description,
        }));

        setKeywords(data);
    };

    const fetchData = async (): Promise<void> => {
        if (company) {
            try {
                setLoading(true);

                if (isAboveConsultant()) {
                    await fetchUsers();
                }

                await fetchLawGroups();
                if (company.hasLawLists) {
                    await fetchLawLists();
                }
                if (company.hasKeyWords) {
                    await fetchKeywords();
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const setUserData = async () => {
        if (company) {
            try {
                setLoading(true);

                // Resetting selected option
                setSelectedOption(EmailNotificationFilterType.STANDARD_LIST);
                const activeUser = getActiveUser();
                setActive(activeUser?.emailNotification === true);
                if (emailNotificationSettingsIdNull()) {
                    //SET ALL SELECTED FOR LEGACY USERS WITH NO PREVIOUS SETTINGS.
                    const lawGroupsIdHolder: number[] = [];
                    lawTypes.forEach(lt => lt.lawGroups.forEach(lawgroup => lawGroupsIdHolder.push(lawgroup.lawGroupId)));
                    setSelectedLawGroupIds(lawGroupsIdHolder);
                    const emailNoticeSettings = await UserService().saveEmailNoticeSettings(activeUser?.userId || -1, EmailNotificationFilterType.STANDARD_LIST, lawGroupsIdHolder);
                    if (!selectedUser) {
                        updateEmailNoticeSettings(emailNoticeSettings);
                    } else {
                        updateSelectedUser({
                            ...selectedUser,
                            emailNoticeSettings,
                        });
                    }
                } else {
                    setSelectedLawGroupIds(activeUser?.emailNoticeSettings?.selectedLawGroups || []);
                    if (company.hasLawLists) {
                        setSelectedLawListIds(activeUser?.emailNoticeSettings?.selectedLawLists || []);
                    }

                    if (company.hasKeyWords) {
                        setSelectedKeywordIds(activeUser?.emailNoticeSettings?.selectedKeywords || []);
                    }
                }

                switch (activeUser?.emailNoticeSettings.emailNotificationFilterType) {
                    case EmailNotificationFilterType.STANDARD_LIST.toString():
                        setSelectedOption(EmailNotificationFilterType.STANDARD_LIST);
                        break;
                    case EmailNotificationFilterType.CUSTOM_LIST.toString():
                        setSelectedOption(EmailNotificationFilterType.CUSTOM_LIST);
                        break;
                    case EmailNotificationFilterType.KEYWORDS.toString():
                        setSelectedOption(EmailNotificationFilterType.KEYWORDS);
                        break;
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setUserData();
    }, [selectedUser]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleLawListSelect = (lawListId: number): void => {
        setSelectedLawListIds(s => {
            if (s.includes(lawListId)) {
                return s.filter(id => id !== lawListId);
            }
            return [...s, lawListId];
        });
    };

    const handleLawGroupSelect = (lawGroupId: number): void => {
        setSelectedLawGroupIds(s => {
            if (s.includes(lawGroupId)) {
                return s.filter(id => id !== lawGroupId);
            }
            return [...s, lawGroupId];
        });
    };

    const handleLawListGroupSelect = (lawListGroupId: number): void => {
        const lawListGroup = lawLists.find(llg => llg.parentId === lawListGroupId);
        if (lawListGroup) {
            const lawListIds: number[] = lawListGroup.subRows?.map(ll => ll.childId || -1).filter(id => id !== -1) || [];
            setSelectedLawListIds(s => {
                if (lawListIds.some(id => s.includes(id))) {
                    return s.filter(id => !lawListIds.includes(id));
                } else {
                    return [...s, ...lawListIds];
                }
            });
        }
    };

    const handleLawTypeSelect = (lawTypeId: number): void => {
        const lawType = lawGroups.find(llg => llg.parentId === lawTypeId);
        if (lawType) {
            const lawGroupIds: number[] = lawType.subRows?.map(lt => lt.childId || -1).filter(id => id !== -1) || [];
            setSelectedLawGroupIds(s => {
                if (lawGroupIds.some(id => s.includes(id))) {
                    return s.filter(id => !lawGroupIds.includes(id));
                } else {
                    return [...s, ...lawGroupIds];
                }
            });
        }
    };

    const handleKeywordSelect = (keywordId: number): void => {
        const keyword = keywords.find(k => k.parentId === keywordId);
        if (keyword) {
            if (selectedKeywordIds.includes(keywordId)) {
                setSelectedKeywordIds(selectedKeywordIds.filter(id => id !== keywordId));
            } else {
                setSelectedKeywordIds([...selectedKeywordIds, keywordId]);
            }
        }
    };

    const handleEmailNoticeActiveChange = async (): Promise<void> => {
        const newActive = !active;
        try {
            setLoading(true);
            await UserService().setEmailNoticeActive(getActiveUser()?.userId || -1, newActive);
            setActive(newActive);
            if (!selectedUser) {
                updateEmailNotificationActive(newActive);
            } else {
                updateSelectedUser({
                    ...selectedUser,
                    emailNotification: newActive,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleSubmit = async (): Promise<void> => {
        try {
            setLoading(true);
            let emailNoticeSettings;
            const activeUser = getActiveUser();

            switch (selectedOption) {
                case EmailNotificationFilterType.STANDARD_LIST:
                    emailNoticeSettings = await UserService().saveEmailNoticeSettings(
                        activeUser?.userId || -1,
                        EmailNotificationFilterType.STANDARD_LIST.toString(),
                        selectedLawGroupIds,
                    );
                    break;
                case EmailNotificationFilterType.CUSTOM_LIST:
                    emailNoticeSettings = await UserService().saveEmailNoticeSettings(
                        activeUser?.userId || -1,
                        EmailNotificationFilterType.CUSTOM_LIST.toString(),
                        selectedLawListIds,
                    );
                    break;
                case EmailNotificationFilterType.KEYWORDS:
                    emailNoticeSettings = await UserService().saveEmailNoticeSettings(
                        activeUser?.userId || -1,
                        EmailNotificationFilterType.KEYWORDS.toString(),
                        selectedKeywordIds,
                    );
                    break;
            }

            if (emailNoticeSettings) {
                if (!selectedUser) {
                    updateEmailNoticeSettings(emailNoticeSettings);
                } else {
                    updateSelectedUser({
                        ...selectedUser,
                        emailNoticeSettings,
                    });
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleCancel = (): void => {
        const activeUser = getActiveUser();
        setSelectedLawGroupIds(activeUser?.emailNoticeSettings?.selectedLawGroups || []);
        setSelectedLawListIds(activeUser?.emailNoticeSettings?.selectedLawLists || []);
        setSelectedKeywordIds(activeUser?.emailNoticeSettings?.selectedKeywords || []);
    };

    const handleChangeSelectedOption = (option: EmailNotificationFilterType): void => {
        setSearch('');
        handleCancel();
        setSelectedOption(option);
    };

    const selectedData = useMemo(() => {
        if (selectedOption === EmailNotificationFilterType.STANDARD_LIST) return lawGroups;
        if (selectedOption === EmailNotificationFilterType.CUSTOM_LIST) return lawLists;
        if (selectedOption === EmailNotificationFilterType.KEYWORDS) return keywords;
        return [];
    }, [selectedOption, selectedUser, lawGroups, lawLists, keywords]);

    const columns = useMemo(() => {
        const columns: Array<Column<Data> & { Header: string; languageId?: string }> = [];

        let nameHeader;
        switch (selectedOption) {
            case EmailNotificationFilterType.STANDARD_LIST:
                nameHeader = t('columnLawTypeGroup');
                break;
            case EmailNotificationFilterType.CUSTOM_LIST:
                nameHeader = t('columnLawGroupList');
                break;
            case EmailNotificationFilterType.KEYWORDS:
                nameHeader = t('columnKeywords');
                break;
        }

        columns.push({
            id: 'expander',
            width: '42px',
            Header: '',
            accessor: row => (row.subRows ? 1 : -1),
            Cell: function expanderCell({ row }): JSX.Element {
                return row.original.subRows ? (
                    <span
                        {...(row.original.subRows && row.original.subRows.length < 1 ? {} : row.getToggleRowExpandedProps())}
                        className={[styled.ToggleCell, row.original.subRows && row.original.subRows.length < 1 ? styled.Disabled : ''].join(' ')}
                    >
                        {row.isExpanded ? <img src={accordionIcon} className={styled.ExpandedIcon} /> : <img src={accordionIcon} />}
                    </span>
                ) : (
                    <span className={styled.ToggleCell} />
                );
            },
        });
        columns.push({
            id: 'name',
            Header: nameHeader,
            languageId: selectedOption === EmailNotificationFilterType.STANDARD_LIST ? 'columnLawTypeGroup' : 'columnLawGroupList',
            Cell: function nameCell(props): JSX.Element {
                return <DenseCell {...props} noBold={!props.row.original.subRows} />;
            },
            accessor: row => (selectedOption === EmailNotificationFilterType.STANDARD_LIST ? t(row.name) : row.name),
        });

        if (selectedOption === EmailNotificationFilterType.KEYWORDS) {
            columns.push({
                id: 'description',
                Header: t('inputFieldDescription'),
                languageId: 'columnKeywords',
                Cell: function nameCell(props): JSX.Element {
                    return <DenseCell {...props} noBold={!props.row.original.subRows} />;
                },
                accessor: row => row.description,
            });
        }

        columns.push({
            id: 'selected',
            Header: t('columnSelection'),
            languageId: 'columnSelection',
            Cell: function checkboxCell(props): JSX.Element | null {
                const parentId = props.row.original.parentId;
                const childId = props.row.original.childId;
                let selectedIds: number[];
                let dataList;
                let handleChildSelect: any;
                let handleParentSelect: any;
                switch (selectedOption) {
                    case EmailNotificationFilterType.STANDARD_LIST:
                        selectedIds = selectedLawGroupIds;
                        dataList = lawGroups;
                        handleChildSelect = handleLawGroupSelect;
                        handleParentSelect = handleLawTypeSelect;
                        break;
                    case EmailNotificationFilterType.CUSTOM_LIST:
                        selectedIds = selectedLawListIds;
                        dataList = lawLists;
                        handleChildSelect = handleLawListSelect;
                        handleParentSelect = handleLawListGroupSelect;
                        break;
                    case EmailNotificationFilterType.KEYWORDS:
                        selectedIds = selectedKeywordIds;
                        dataList = keywords;
                        handleParentSelect = handleKeywordSelect;
                        break;
                    default: {
                        selectedIds = selectedLawGroupIds;
                        dataList = lawGroups;
                        handleChildSelect = handleLawGroupSelect;
                        handleParentSelect = handleLawTypeSelect;
                    }
                }

                if (childId !== undefined) {
                    const childId: number = props.row.original.childId || -1;
                    const checked: boolean = selectedIds.includes(childId);
                    return <CheckboxCell {...props} checked={checked} indeterminate={false} onChange={() => handleChildSelect(childId)} />;
                } else if (parentId !== undefined) {
                    const parentObject = dataList.find(lg => lg.parentId === parentId);
                    if (parentObject) {
                        let checked = false;
                        if (parentObject.subRows) {
                            checked = parentObject.subRows.length > 0 && parentObject.subRows.every(ll => selectedIds.includes(ll.childId || -1));
                        } else {
                            checked = selectedIds.includes(parentId);
                        }
                        const indeterminate: boolean =
                            parentObject.subRows !== undefined &&
                            parentObject.subRows.some(ll => selectedIds.includes(ll.childId || -1)) &&
                            !parentObject.subRows.every(ll => selectedIds.includes(ll.childId || -1));
                        return (
                            <CheckboxCell
                                {...props}
                                checked={checked}
                                indeterminate={indeterminate}
                                onChange={() => handleParentSelect(parentId)}
                                disabled={parentObject.subRows && !parentObject.subRows.length}
                            />
                        );
                    }
                }
                return null;
            },
        });

        return columns;
    }, [selectedUser, selectedData, lawGroups, lawLists, selectedLawListIds, selectedLawGroupIds, selectedKeywordIds, selectedOption, i18n.language]);

    const renderToaster = useMemo(() => {
        const activeUser = getActiveUser();

        let showToster = false;
        let showCancelButton = true;
        let count = 0;
        if (selectedOption === EmailNotificationFilterType.STANDARD_LIST) {
            count = selectedLawGroupIds.length;
            showToster = !_.isEqual(selectedLawGroupIds.sort(), activeUser?.emailNoticeSettings?.selectedLawGroups.sort() || []);
        } else if (selectedOption === EmailNotificationFilterType.CUSTOM_LIST) {
            count = selectedLawListIds.length;
            showToster = !_.isEqual(selectedLawListIds.sort(), activeUser?.emailNoticeSettings?.selectedLawLists.sort() || []);
        } else if (selectedOption === EmailNotificationFilterType.KEYWORDS) {
            count = selectedKeywordIds.length;
            showToster = !_.isEqual(selectedKeywordIds.sort(), activeUser?.emailNoticeSettings?.selectedKeywords.sort() || []);
        }

        if (activeUser?.emailNoticeSettings !== null && selectedOption !== activeUser?.emailNoticeSettings.emailNotificationFilterType) {
            showToster = true;
            showCancelButton = false;
        }

        return (
            showToster &&
            !loading && (
                <EmailNotificationsToaster selectOption={selectedOption} changesCount={count} onSubmit={handleSubmit} onCancel={showCancelButton ? handleCancel : undefined} />
            )
        );
    }, [selectedLawGroupIds, selectedLawListIds, selectedKeywordIds, selectedOption, loading]);

    return (
        <div className={styled.EmailNotifications}>
            {loading && <LoadingSpinner />}
            <div className={styled.Container}>
                <h3 className={styled.Title}>{t('settingsMenuUserEmail')}</h3>

                <div className={styled.Box}>
                    <h6 className={styled.BoxTitle}>{t('manageUserEmailTitle')}</h6>
                    <p>{t('manageUserEmailText')}</p>

                    {isAboveConsultant() && (
                        <Dropdown
                            title={selectedUser?.fullName || t('buttonSelectUsers').toString()}
                            content={users.map(u => ({
                                id: u.userId,
                                text: u.fullName,
                                checked: u.userId === selectedUser?.userId,
                            }))}
                            onChange={id => setSelectedUser(users.find(u => u.userId === id))}
                        />
                    )}

                    {!(isAboveConsultant() && !selectedUser) && (
                        <div className={styled.ToggleContainer}>
                            <p>{t('manageUserEmailToggle')}</p>
                            <Toggle checked={active} onChange={handleEmailNoticeActiveChange} className={styled.Toggle} />
                        </div>
                    )}
                </div>
            </div>
            <div className={[styled.Table, !active ? styled.Disabled : ''].join(' ')}>
                <div>
                    <p className={styled.FilterText}>{t('manageUserEmailTableText')}</p>
                </div>
                <div className={styled.Filterbar}>
                    <div className={styled.Options}>
                        <RadioButton
                            text={t('filterbarStandardFilter')}
                            checked={selectedOption === EmailNotificationFilterType.STANDARD_LIST}
                            onChange={() => handleChangeSelectedOption(EmailNotificationFilterType.STANDARD_LIST)}
                            group="Options"
                        />
                        {company?.hasLawLists && (
                            <RadioButton
                                text={t('filterbarCustomFilter')}
                                checked={selectedOption === EmailNotificationFilterType.CUSTOM_LIST}
                                onChange={() => handleChangeSelectedOption(EmailNotificationFilterType.CUSTOM_LIST)}
                                group="Options"
                            />
                        )}
                        {company?.hasKeyWords && (
                            <RadioButton
                                text={t('filterbarCustomKeywords')}
                                checked={selectedOption === EmailNotificationFilterType.KEYWORDS}
                                onChange={() => handleChangeSelectedOption(EmailNotificationFilterType.KEYWORDS)}
                                group="Options"
                            />
                        )}
                    </div>
                    <Search value={search} onChange={setSearch} />
                </div>

                <Table
                    data={selectedData}
                    columns={columns}
                    showColumnSearch={false}
                    columnSearch={[]}
                    globalSearch={search}
                    rowHeight={48}
                    dense
                    headerClassName={styled.TableHeader}
                    expandable
                />
            </div>

            {renderToaster}
        </div>
    );
};

export default EmailNotifications;
