import React, { FC, useEffect } from 'react';
import styled from './Toast.module.css';
import closeIcon from '../../assets/images/closeIcon.svg';

interface Props {
    type: 'ACCEPTED' | 'DELEGATED' | undefined;
    title: string | undefined;
    text: string | undefined;
    onClose: () => void;
}

const TIMEOUT_MS = 3000;

const Toast: FC<Props> = ({ type = '', title, text, onClose }) => {
    useEffect(() => {
        const interval = setInterval(() => {
            onClose();
        }, TIMEOUT_MS);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className={[styled.Toast, styled[type]].join(' ')}>
            <img src={closeIcon} className={styled.Close} onClick={onClose} />
            <h6 className={styled.Title}>{title}</h6>
            <p className={styled.Text}>{text}</p>
        </div>
    );
};

export default Toast;
