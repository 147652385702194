import React, { FC } from 'react';
import styled from './CompanyDocuments.module.css';
import { getDocumentTypesTranslationKeyWithString } from '../../Invoicing/models/CompanyDocumentType';
import linkIcon from '../../assets/images/linkIcon.svg';
import CompanyDocumentModel from '../../Invoicing/models/CompanyDocumentModel';
import { useTranslation } from 'react-i18next';
import InvoiceService from '../../Invoicing/services/InvoiceService';
import Button from '../Button/Button';

interface Props {
    companyDocuments?: CompanyDocumentModel[];
    companyId?: number;
    onCancel?: () => void;
    showInfo?: boolean;
}

const CompanyDocuments: FC<Props> = ({ companyDocuments, companyId, onCancel, showInfo }) => {
    const { t, i18n } = useTranslation();

    const handleDocumentDownload = (doc: CompanyDocumentModel) => {
        if (companyId && doc) {
            InvoiceService().downloadCompanyDocument(companyId, doc);
        }
    };

    return (
        <div className={styled.DocumentPage}>
            {showInfo && (
                <div>
                    <h4>{t('settingsMenuContractAgreement')}</h4>
                    <div className={styled.Info}>
                        <h4>Avtal Lagbevakningen</h4>
                        <p>Nedan finns avtal och eventuellt andra dokument som hör till er tjänst.</p>
                    </div>
                </div>
            )}
            <table className={styled.Table}>
                {companyDocuments && companyDocuments.length
                    ? companyDocuments.map(doc => (
                          <div key={doc?.id} className={styled.Documents}>
                              <tr className={styled.Row}>
                                  <td>
                                      <p className={styled.DocumentType}>{t(getDocumentTypesTranslationKeyWithString(doc?.type))}</p>
                                  </td>
                              </tr>

                              <tr className={styled.Row}>
                                  <td>
                                      <div>
                                          <div className={[styled.FileNameLink].join(' ')} title={doc.fileName} onClick={() => handleDocumentDownload(doc)}>
                                              {doc.fileName}
                                              <img src={linkIcon} className={styled.LinkIcon} alt="Link" />
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </div>
                      ))
                    : 'Inga dokument'}
            </table>
            {onCancel && (
                <div className={styled.Cancel}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonClose')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CompanyDocuments;
