import React, { useState, createRef, ReactChild } from 'react';
import styled from './Dropdown.module.css';
import Checkbox from '../Checkbox/Checkbox';
import useClickOutside from '../../hooks/useClickOutside';
import accordionIcon from '../../assets/images/accordionIcon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string | ReactChild;
    content: {
        id: number;
        text: string;
        checked?: boolean;
        disabled?: boolean;
    }[];
    onChange: (id: number) => void;
    multiSelect?: boolean;
    className?: string;
    onAllChange?: (checked: boolean) => void;
    listHeight?: number;
    dropdownWidth?: number | string;
    translateText?: boolean;
}

const Dropdown: React.FC<Props> = ({ title, content, onChange, onAllChange, multiSelect, className, listHeight, dropdownWidth, translateText }) => {
    const { t } = useTranslation();
    const ref = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);

    useClickOutside(ref, () => {
        setOpen(false);
    });

    const dropdownTitle = multiSelect
        ? content.some(kw => kw.checked)
            ? content
                  .filter(kw => kw.checked)
                  .map(kw => kw.text)
                  .join(', ')
            : title
        : title;

    const handleChange = (id: number) => {
        onChange(id);
        if (!multiSelect) {
            setOpen(false);
        }
    };

    const handleAllChange = () => {
        if (onAllChange) {
            const someChecked = content.some(c => c.checked);
            onAllChange(!someChecked);
        }
    };

    return (
        <div ref={ref} className={[styled.Dropdown, open ? '' : styled.Closed, className ? className : ''].join(' ')}>
            <div className={[styled.Header, 'dropdownHeader'].join(' ')} style={{ width: dropdownWidth }} onClick={() => setOpen(!open)}>
                <div className={styled.Title}>{dropdownTitle}</div>
                <img src={accordionIcon} alt="Accordion" className={styled.Icon} />
            </div>
            <div className={styled.ListWrapper} style={{ width: dropdownWidth }}>
                <div className={styled.List} style={{ maxHeight: listHeight }}>
                    {onAllChange && multiSelect && (
                        <div
                            className={styled.ListItem}
                            onClick={e => {
                                e.preventDefault();
                                handleAllChange();
                            }}
                        >
                            <Checkbox
                                checked={content.every(c => c.checked)}
                                indeterminate={content.some(c => c.checked) && !content.every(c => c.checked)}
                                onChange={handleAllChange}
                            />
                            {t('selectAll')}
                        </div>
                    )}
                    {content.map(keyword => (
                        <div
                            key={keyword.id}
                            className={styled.ListItem}
                            onClick={
                                !keyword.disabled
                                    ? e => {
                                          e.preventDefault();
                                          handleChange(keyword.id);
                                      }
                                    : undefined
                            }
                        >
                            {multiSelect && (
                                <Checkbox checked={keyword.checked} disabled={keyword.disabled} onChange={!keyword.disabled ? () => handleChange(keyword.id) : undefined} />
                            )}
                            {translateText ? t(keyword.text) : keyword.text}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Dropdown;
