import React, { InputHTMLAttributes } from 'react';
import styled from './Checkbox.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    title?: string;
    checked: boolean | undefined;
    indeterminate?: boolean;
    onChange: (() => void) | undefined;
    big?: boolean;
    disabled?: boolean;
    locked?: boolean | undefined;
}

const Checkbox: React.FC<Props> = ({ title, checked = false, indeterminate = false, onChange, big = false, disabled, locked = false }) => {
    return (
        <label className={[styled.Label, big ? styled.Big : '', disabled ? styled.Disabled : ''].join(' ')}>
            {title}
            <input
                type="checkbox"
                className={locked ? styled.Locked : ''}
                checked={checked}
                disabled={disabled}
                onChange={!disabled ? onChange : undefined}
                data-indeterminate={indeterminate}
            />
            <span className={styled.Checkmark} />
        </label>
    );
};

export default Checkbox;
