import { AxiosResponse, AxiosError } from 'axios';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import CustomLawModel, { EditCustomLawModel } from '../models/CustomLawModel';

const CustomLawService = () => {
    const axiosInstance = getAuthApi();

    function getCustomLawList(companyId: number): Promise<CustomLawModel[]> {
        return axiosInstance
            .get<CustomLawModel[]>(`/customlaw/list?companyId=${companyId}`)
            .then((response: AxiosResponse<CustomLawModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomLawTexts(customLaw: CustomLawModel): Promise<CustomLawModel> {
        return axiosInstance
            .post<CustomLawModel>('/customlaw/update', customLaw)
            .then((response: AxiosResponse<CustomLawModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function createCustomLaw(customLaw: EditCustomLawModel, file?: File): Promise<CustomLawModel> {
        const form = new FormData();
        form.append('editCustomLawModel', new Blob([JSON.stringify(customLaw)], { type: 'application/json' }));
        if (file) {
            form.append('file', file);
        }

        return axiosInstance
            .post<CustomLawModel>('/customlaw', form)
            .then((response: AxiosResponse<CustomLawModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomLaw(customLaw: EditCustomLawModel, file?: File): Promise<CustomLawModel> {
        const form = new FormData();
        form.append('editCustomLawModel', new Blob([JSON.stringify(customLaw)], { type: 'application/json' }));
        if (file) {
            form.append('file', file);
        }

        return axiosInstance
            .put<CustomLawModel>('/customlaw', form)
            .then((response: AxiosResponse<CustomLawModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeCustomLaw(customLawId: number): Promise<AxiosResponse<void>> {
        return axiosInstance.delete<void>(`/customlaw?customLawId=${customLawId}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    return {
        getCustomLawList,
        updateCustomLawTexts,
        createCustomLaw,
        updateCustomLaw,
        removeCustomLaw,
    };
};

export default CustomLawService;
