import React from 'react';
import Lottie from 'lottie-react-web';
import animationData from './loader_lottie.json';

const LoadingSpinnerSmall = () => {
    return (
        <Lottie
            height={16}
            width={56}
            options={{
                animationData,
            }}
        />
    );
};

export default LoadingSpinnerSmall;
