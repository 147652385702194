import React, { FC, useState, createRef } from 'react';
import styled from './UserMenu.module.css';
import useClickOutside from '../../../hooks/useClickOutside';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { COMPANY_LOGIN, HELP_INSTRUCTIONS, SETTINGS_USER_INFO } from '../../../constants/Routes';
import useAuth from '../../../hooks/useAuth';
import useMisc from '../../../hooks/useMisc';
import { LawTypeEnum } from '../../../models/LawType';
import { CompanyTypeEnum } from '../../../models/CompanyType';
import { useTranslation, Trans } from 'react-i18next';
import avatarIcon from '../../../assets/images/avatarIcon.svg';
import desktopIcon from '../../../assets/images/desktopViewIcon.svg';
import mobileIcon from '../../../assets/images/mobileViewIcon.svg';
import useMobileDevice from '../../../hooks/useMobileDevice';
import useStorage from '../../../hooks/useStorage';
import { isMobile } from 'react-device-detect';

interface Props {
    initials: string;
    fullName: string;
}

const UserMenu: FC<Props> = ({ initials, fullName }) => {
    const {
        isAuthenticated,
        company,
        setCompany,
        setSignedInAsUser,
        logout,
        logoutAndRedirectExternalPage,
        hasConnectedCompanies,
        signedInAsUser,
        isUserOrAbove,
        isAboveSuperUser,
    } = useAuth();

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { oldApplicationBaseURL, logoutURL, isProdEnvironment } = useMisc();
    const isMobileDevice = useMobileDevice();
    const { setDesktopView } = useStorage();
    const ref = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);

    useClickOutside(ref, () => {
        setOpen(false);
    });

    const handleChangeCompany = () => {
        setCompany(undefined);
        setSignedInAsUser(undefined);
        history.push(COMPANY_LOGIN);
    };

    const handleLogout = () => {
        if (isProdEnvironment()) {
            logoutAndRedirectExternalPage(logoutURL);
        } else {
            logout();
        }
    };

    const handleShowDesktopView = () => {
        setDesktopView(isMobileDevice);
        window.location.reload();
    };

    return (
        <div ref={ref} className={[styled.UserMenu, open ? styled.Open : ''].join(' ')}>
            <div className={styled.Icon} onClick={() => setOpen(!open)}>
                <img src={avatarIcon} alt="avatar" />
            </div>
            {open && (
                <div className={styled.Menu} onClick={() => setOpen(false)}>
                    <div className={styled.Header}>
                        <div className={styled.FullName}>{isAuthenticated && isUserOrAbove() ? fullName : t('navbarAnonymousReader')}</div>
                        <div className={styled.CompanyName}>{company?.name || t('navbarNoCompany')}</div>
                    </div>

                    {!isMobileDevice && (
                        <div className={styled.List}>
                            {isAuthenticated && isUserOrAbove() && <Link to={{ pathname: SETTINGS_USER_INFO, state: { from: location.pathname } }}>{t('navbarMySettings')}</Link>}
                            {isAuthenticated && <Link to={{ pathname: HELP_INSTRUCTIONS, state: { from: location.pathname } }}>{t('navbarHelp')}</Link>}
                        </div>
                    )}

                    <div className={styled.BottomList}>
                        <>
                            {isMobileDevice ? (
                                <div className={styled.ViewMode} onClick={handleShowDesktopView}>
                                    <span>{t('navbarDesktopVersionOption')}</span>
                                    <img src={desktopIcon} alt="desktop version" />
                                </div>
                            ) : (
                                isMobile && (
                                    <div className={styled.ViewMode} onClick={handleShowDesktopView}>
                                        <span>{t('navbarMobileVersionOption')}</span>
                                        <img src={mobileIcon} alt="mobile version" />
                                    </div>
                                )
                            )}
                        </>
                        {isAboveSuperUser() && oldApplicationBaseURL && (
                            <div onClick={() => window.open(oldApplicationBaseURL, '_blank')}>{t('navbarConsultantLawAssessments')}</div>
                        )}
                        {isAuthenticated && hasConnectedCompanies() && <div onClick={handleChangeCompany}>{t('navbarChangeCompany')}</div>}
                        <div onClick={handleLogout}>{t('navbarLogout')}</div>
                    </div>
                    {isAuthenticated && company && (
                        <div className={styled.Footer}>
                            <p>
                                <Trans
                                    i18nKey="navbarSignedInAs"
                                    values={{
                                        fullName: signedInAsUser ? signedInAsUser.fullName : isAuthenticated && isUserOrAbove() ? fullName : t('navbarAnonymousReader'),
                                        companyName: company.name,
                                        companyType: CompanyTypeEnum[company.type],
                                        companyLawTypes: company.lawTypes.map(lt => t(LawTypeEnum[lt])).join(', '),
                                    }}
                                />
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UserMenu;
