import React, { FC } from 'react';
import Modal from '../Modal';
import styled from './CorruptReaderLoginModal.module.css';
import Button from '../../Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import errorIcon from '../../../assets/images/errorIcon.svg';

interface Props {
    onConfirm: () => void;
    unsupportedOldLink?: boolean;
}

const CorruptReaderLoginModal: FC<Props> = ({ onConfirm, unsupportedOldLink }) => {
    const { t } = useTranslation();
    return (
        <Modal title={!unsupportedOldLink ? t('corruptReaderLoginTitle') : t('unsupportedReaderLoginTitle')} className={styled.Modal}>
            <img src={errorIcon} alt="error" className={styled.ErrorIcon} />
            <div className={styled.Content}>
                {!unsupportedOldLink && (
                    <div>
                        <p>{t('corruptReaderLoginText1')}</p>
                        <p>
                            <Trans i18nKey="corruptReaderLoginText2" components={[<a href="mailto:lagbevakning@ramboll.se" key={0} />]} />
                        </p>
                    </div>
                )}

                {unsupportedOldLink && (
                    <div>
                        <p>{t('unsupportedReaderLoginText1')}</p>
                        <p>
                            <Trans i18nKey="unsupportedReaderLoginText2" components={[<a href="mailto:lagbevakning@ramboll.se" key={0} />]} />
                        </p>
                    </div>
                )}
                <div className={styled.Options}>
                    <Button variant="Primary" onClick={onConfirm}>
                        {t('buttonOk')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CorruptReaderLoginModal;
