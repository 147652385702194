import React, { Context, FC, useEffect, useMemo, useState } from 'react';
import styled from './CustomLaws.module.css';
import Button from '../../../../components/Button/Button';
import Search from '../../../../components/Search/Search';
import Table from '../../../../components/Table/Table';
import { Column, SortingRule } from 'react-table';
import CustomLawModel, { EditCustomLawModel } from '../../../../models/CustomLawModel';
import DenseCell from '../../../../components/Table/DenseCell/DenseCell';
import MenuCell, { isLastRow } from '../../../../components/Table/MenuCell/MenuCell';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import CustomLawService from '../../../../services/CustomLawService';
import useAuth from '../../../../hooks/useAuth';
import _ from 'lodash';
import HtmlCell from '../../../../components/Table/HtmlCell/HtmlCell';
import { useHistory } from 'react-router-dom';
import Toggle from '../../../../components/Toggle/Toggle';
import EditCustomLawModal from '../../../../components/Modal/EditCustomLawModal/EditCustomLawModal';
import RemoveCustomLawModal from '../../../../components/Modal/RemoveCustomLawModal/RemoveCustomLawModal';
import useExport from '../../../../hooks/useExport';
import { useTranslation } from 'react-i18next';
import FilterbarMenu from '../../../../components/Filterbar/FilterbarMenu/FilterbarMenu';
import DocumentService from '../../../../services/DocumentService';
import LawSourceModel from '../../../../models/LawSourceModel';
import ExcelColumnWidth, { columnKeys } from '../../../../constants/ExcelColumnWidth';
import { ReactComponent as AccordionIcon } from '../../../../assets/images/accordionIcon.svg';
import { getActiveFilterExport, getActiveFilterExportFromState } from '../../../../models/ActiveFilterExport';

const COLUMN_SEARCH: { id: string; value: string | undefined }[] = [];

const CustomLaws: FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { company, isAboveUser } = useAuth();
    const { exportList } = useExport();

    const [loading, setLoading] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [data, setData] = useState<CustomLawModel[]>([]);
    const [showInformation, setShowInformation] = useState(true);
    const [visibleDataCount, setVisibleDataCount] = useState(0);
    const [showColumnSearch, setShowColumnSearch] = useState(true);
    const [sorting, setSorting] = useState<SortingRule<object>[]>([{ id: 'name', desc: false }]);
    const [customLawModal, setCustomLawModal] = useState<{ open: boolean; edit?: CustomLawModel }>({ open: false, edit: undefined });
    const [removeModal, setRemoveModal] = useState<{ open: boolean; customLawId?: number; name?: string }>({ open: false, customLawId: undefined, name: undefined });
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    const fetchData = async () => {
        if (company) {
            try {
                setLoading(true);
                const data = await CustomLawService().getCustomLawList(company.id);
                setData(data);
                setVisibleDataCount(data.length);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleSave = (customLaw: EditCustomLawModel, file?: File) => {
        if (customLaw.customLawId) {
            CustomLawService()
                .updateCustomLaw(customLaw, file)
                .then(updatedCustomLaw => {
                    setData(s => s.map(cl => (cl.customLawId === customLaw.customLawId ? { ...updatedCustomLaw } : cl)));
                    setCustomLawModal({ open: false, edit: undefined });
                });
        } else {
            CustomLawService()
                .createCustomLaw(customLaw, file)
                .then(newCustomLaw => {
                    setData(s => [...s, newCustomLaw]);
                    setCustomLawModal({ open: false, edit: undefined });
                });
        }
    };

    const handleRemove = async () => {
        const customLaw = _.find(data, cl => cl.customLawId === removeModal.customLawId);
        if (customLaw && company) {
            try {
                setRemoveModal({ open: false, customLawId: undefined, name: undefined });
                setLoading(true);

                await CustomLawService().removeCustomLaw(customLaw.customLawId);
                setData(s => s.filter(u => u.customLawId !== customLaw.customLawId));

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleOpenEditModal = (customLawId: number): void => {
        const customLaw = _.find(data, cl => cl.customLawId === customLawId);
        if (customLaw) {
            setCustomLawModal({ open: true, edit: customLaw });
        }
    };

    const handleOpenRemoveModal = (customLawId: number) => {
        const customLaw = _.find(data, cl => cl.customLawId === customLawId);
        if (customLaw) {
            setCustomLawModal({ open: false, edit: undefined });
            setRemoveModal({ open: true, customLawId, name: customLaw.name });
        }
    };

    const handleExport = () => {
        // FIXME Consolidate duplicate code
        const customColumns: { key: columnKeys; Header: string; width: number }[] = [];
        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                const customerTextKey = `customerText${i}` as columnKeys;
                customColumns.push({ key: customerTextKey, Header: title, width: ExcelColumnWidth[customerTextKey] });
            }
        }

        const exportColumns: Array<{ key: columnKeys; Header: string; width: number }> = [
            { key: 'name', Header: t('columnName'), width: ExcelColumnWidth.name },
            ...(company?.hasLawLists ? [{ key: 'lawLists' as columnKeys, Header: t('columnLawLists'), width: ExcelColumnWidth.lawLists }] : []),
            ...(company?.hasKeyWords ? [{ key: 'keywords' as columnKeys, Header: t('columnKeywords'), width: ExcelColumnWidth.keywords }] : []),
            { key: 'text', Header: t('columnText'), width: ExcelColumnWidth.text },
            ...customColumns,
            { key: 'warningDate', Header: t('columnWarningDate'), width: ExcelColumnWidth.warningDate },
        ];

        const exportData = data.map(obj => ({
            name: obj.name,
            lawLists: company?.hasLawLists && obj.lawLists ? obj.lawLists.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name).join('<br>') : '',
            keywords: company?.hasKeyWords && obj.keywordIds ? obj.keywordIds.map(id => _.find(company.keyWords, kw => kw.id === id)?.text).join('<br>') : '',
            text: obj.text,
            customerText1: obj.customerText1,
            customerText2: obj.customerText2,
            customerText3: obj.customerText3,
            customerText4: obj.customerText4,
            customerText5: obj.customerText5,
            warningDate: obj.customLawEndDate,
        }));

        exportList(exportColumns, exportData, t('lawTypeCustomLaw'));
    };

    const handleColumnShowChange = (id: string) => {
        setHiddenColumns(hiddenColumns.includes(id) ? hiddenColumns.filter(colId => colId !== id) : [...hiddenColumns, id]);
    };

    const handleDownloadLawDocument = (customLawId: number, lawSource: LawSourceModel) => {
        DocumentService().getLawDocument(customLawId, true, true, lawSource.fileName);
    };

    const handleToggleShowInformation = () => {
        setShowInformation(s => !s);
    };

    const columns = useMemo<Array<Column<CustomLawModel> & { id: string; Header: string; visible: boolean; alwaysVisible?: boolean }>>(() => {
        const columns: Array<Column<CustomLawModel> & { id: string; Header: string; visible: boolean; alwaysVisible?: boolean }> = [
            {
                id: 'name',
                Header: t('columnName'),
                Cell: function nameCell(props) {
                    const { customLawId, lawSources } = props.row.original;
                    return <DenseCell onClick={lawSources && lawSources[0] ? () => handleDownloadLawDocument(customLawId, lawSources[0]) : undefined} {...props} multiRow />;
                },
                accessor: row => row.name,
                defaultCanSort: true,
                visible: true,
                alwaysVisible: true,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.name.toLowerCase();
                    const lawB = rowB.values.name.toLowerCase();
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
            },
        ];

        if (company?.hasLawLists) {
            columns.push({
                id: 'lawList',
                Header: t('columnLawLists'),
                Cell: DenseCell,
                accessor: row => row.lawLists.map(ll => ll.name).join(', '),
                disableSortBy: true,
                visible: !hiddenColumns.includes('lawList'),
            });
        }

        if (company?.hasKeyWords) {
            columns.push({
                id: 'keywords',
                Header: t('columnKeywords'),
                Cell: DenseCell,
                accessor: row => row.keywordIds.map(id => company.keyWords.find(kw => kw.id === id)?.text).join(', '),
                disableSortBy: true,
                visible: !hiddenColumns.includes('keywords'),
            });
        }

        columns.push({
            id: 'text',
            Header: t('columnText'),
            Cell: HtmlCell,
            accessor: row => row.text,
            disableSortBy: true,
            visible: !hiddenColumns.includes('text'),
        });

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                columns.push({
                    id: 'customerText' + i,
                    accessor: 'customerText' + i,
                    Header: title,
                    Cell: HtmlCell,
                    disableSortBy: true,
                    visible: !hiddenColumns.includes('customerText' + i),
                });
            }
        }

        columns.push({
            id: 'warningDate',
            Header: t('columnWarningDate'),
            Cell: DenseCell,
            accessor: row => row.customLawEndDate,
            disableSortBy: true,
            visible: !hiddenColumns.includes('warningDate'),
        });

        columns.push({
            id: 'menu',
            Header: '',
            accessor: row => row.customLawId,
            Cell: function Cell({ row, sortedRows }) {
                const { customLawId } = row.original;
                const lastRow = isLastRow(row, sortedRows);
                return (
                    <MenuCell
                        lastRow={lastRow}
                        title={t('manageCustomLaw')}
                        items={[
                            { text: t('buttonEdit'), function: () => handleOpenEditModal(customLawId) },
                            { text: t('buttonRemove'), function: () => handleOpenRemoveModal(customLawId) },
                        ]}
                    />
                );
            },
            disableFilters: true,
            disableSortBy: true,
            alwaysVisible: true,
            visible: true,
        });

        return columns;
    }, [handleOpenEditModal, handleOpenRemoveModal, i18n.language, hiddenColumns]);

    useEffect(() => {
        if (!isAboveUser()) {
            history.goBack();
        }
        fetchData();
    }, []);

    return (
        <div className={styled.CustomLaws}>
            {loading && <LoadingSpinner />}
            <div className={styled.Top}>
                <h3 className={styled.Title}>{t('settingsMenuCompanyCustomLaws')}</h3>
                <div className={styled.Information}>
                    <h6 onClick={handleToggleShowInformation}>
                        {t('companyCustomLawsTitle')} <AccordionIcon className={[styled.Icon, showInformation ? styled.Open : ''].join(' ')} />
                    </h6>
                    {showInformation && <p>{t('companyCustomLawsText')}</p>}
                    <div className={styled.Buttons}>
                        <Button variant="Outline" onClick={handleExport}>
                            {t('buttonExportList')}
                        </Button>
                        <Button variant="Primary" onClick={() => setCustomLawModal({ open: true, edit: undefined })}>
                            {t('buttonAddCustomLaw')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className={styled.SearchBar}>
                <div className={styled.SearchContainer}>
                    <Search value={globalSearch} onChange={setGlobalSearch} />
                    <p className={[styled.SearchCounter, visibleDataCount < data.length ? styled.Filtered : ''].join(' ')}>
                        {visibleDataCount} {t('of')} {data.length}
                    </p>
                </div>
                <Toggle title={t('columnSearch')} checked={showColumnSearch} onChange={() => setShowColumnSearch(!showColumnSearch)} className={styled.Toggle} />
                <FilterbarMenu columns={columns.filter(col => !col.alwaysVisible)} onColumnShowChange={handleColumnShowChange} />
            </div>

            <div className={styled.TableWrapper}>
                <Table
                    data={data}
                    columns={columns}
                    showColumnSearch={showColumnSearch}
                    columnSearch={COLUMN_SEARCH}
                    globalSearch={globalSearch}
                    hiddenColumns={hiddenColumns}
                    rowHeight={64}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    onVisibleDataCountChange={setVisibleDataCount}
                    headerClassName={styled.TableHeader}
                />
            </div>

            {customLawModal.open && (
                <EditCustomLawModal
                    edit={customLawModal.edit}
                    onClose={() => setCustomLawModal({ open: false, edit: undefined })}
                    onSave={handleSave}
                    onRemove={handleOpenRemoveModal}
                />
            )}
            {removeModal.open && removeModal.name && (
                <RemoveCustomLawModal name={removeModal.name} onCancel={() => setRemoveModal({ open: false, name: undefined, customLawId: undefined })} onConfirm={handleRemove} />
            )}
        </div>
    );
};

export default CustomLaws;
