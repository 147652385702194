import React, { FC, useState } from 'react';
import styled from './ConsultantMultiAcceptModal.module.css';
import Modal from '../Modal';
import RadioButton from '../../RadioButton/RadioButton';
import { AssessmentChoice } from '../../../services/SubscriptionChangeService';
import TextEditor from '../../TextEditor/TextEditor';
import Button from '../../Button/Button';
import ConsultantMultiAcceptConfirmModal from './ConsultantMultiAcceptConfirmModal/ConsultantMultiAcceptConfirmModal';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface Props {
    count: number;
    onCancel: () => void;
    onSubmit: (option: AssessmentChoice, comment: string) => void;
}

const ConsultantMultiAcceptModal: FC<Props> = ({ count, onCancel, onSubmit }) => {
    const { t } = useTranslation();
    const { isGroupCompany, company } = useAuth();

    const [selectedRadioButton, setSelectedRadioButton] = useState<AssessmentChoice>('DECLINE');
    const [comment, setComment] = useState<string>(
        !isGroupCompany() && company ? t('consultantMultiAcceptModalCommentDecline', { companyName: company.name }) : t('defaultCommentDeclineGeneral'),
    );
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const handleSubmit = () => {
        setConfirmModalOpen(false);
        onSubmit(selectedRadioButton, comment);
    };

    const handleChangeSelectedRadioButton = (selectedRadioButton: AssessmentChoice) => {
        if (selectedRadioButton === 'DECLINE') {
            if (!isGroupCompany()) {
                setComment(t('consultantMultiAcceptModalCommentDecline', { companyName: company?.name }));
            } else {
                setComment(t('defaultCommentDeclineGeneral'));
            }
        } else if (selectedRadioButton === 'FORWARD_NO_CHOICE') {
            setComment(t('consultantMultiAcceptModalCommentForward'));
        }

        setSelectedRadioButton(selectedRadioButton);
    };

    return (
        <>
            {!confirmModalOpen && (
                <Modal title="" className={styled.ConsultantMultiAcceptModal}>
                    <div className={styled.Content}>
                        <div className={styled.RadioButtons}>
                            <h4>{t('consultantMultiAcceptModalTitle')}</h4>
                            <RadioButton
                                checked={selectedRadioButton === 'DECLINE'}
                                onChange={() => handleChangeSelectedRadioButton('DECLINE')}
                                text={t('consultantMultiAcceptModalOptionDecline')}
                                group="radio"
                            />
                            <RadioButton
                                checked={selectedRadioButton === 'FORWARD_NO_CHOICE'}
                                onChange={() => handleChangeSelectedRadioButton('FORWARD_NO_CHOICE')}
                                text={t('consultantMultiAcceptModalOptionForward')}
                                group="radio"
                            />
                        </div>
                        <div className={styled.Comment}>
                            <TextEditor title={t('columnComment')} initialValue={comment} onChange={setComment} />
                        </div>
                        <div className={styled.Buttons}>
                            <Button variant="Outline" onClick={onCancel}>
                                {t('buttonCancel')}
                            </Button>
                            <Button variant="Primary" onClick={() => setConfirmModalOpen(true)}>
                                {t('consultantMultiAcceptModalButton')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
            {confirmModalOpen && (
                <ConsultantMultiAcceptConfirmModal count={count} option={selectedRadioButton} onCancel={() => setConfirmModalOpen(false)} onConfirm={handleSubmit} />
            )}
        </>
    );
};

export default ConsultantMultiAcceptModal;
