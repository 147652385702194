import { AxiosResponse } from 'axios';
import { getAuthApi } from '../../config/axios.config';
import ApiErrorModel from '../../models/ApiErrorModel';
import InvoiceModel from '../models/InvoiceModel';
import CompanyInvoiceFullModel from '../models/CompanyInvoiceFullModel';
import AgreementPeriodModel from '../models/AgreementPeriodModel';
import ServiceInvoiceModel from '../models/ServiceInvoiceModel';
import CompanyAgreementPeriodModel from '../models/CompanyAgreementPeriodModel';
import UploadCompanyDocumentModel from '../models/UploadCompanyDocumentModel';
import CompanyDocumentModel from '../models/CompanyDocumentModel';
import DocumentService from '../../services/DocumentService';

const InvoiceService = () => {
    const axiosInstance = getAuthApi();

    function getAllCompanies(): Promise<InvoiceModel> {
        return axiosInstance
            .get(`/invoice/all`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getFullCompany(companyId: number): Promise<CompanyInvoiceFullModel> {
        return axiosInstance
            .get(`/invoice?companyId=${companyId}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getItems(companyId: number): Promise<ServiceInvoiceModel[]> {
        return axiosInstance
            .get(`/invoice/items?companyId=${companyId}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function markCompaniesAsInvoiced(companyIds: number[]): Promise<CompanyAgreementPeriodModel[]> {
        return axiosInstance
            .put(`/invoice/markCompaniesAsInvoiced`, { companyIds })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function markCompaniesAsNotInvoiced(companyIds: number[]): Promise<void> {
        return axiosInstance
            .put(`/invoice/markCompaniesAsNotInvoiced`, { companyIds })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomerReferenceNumber(companyId: number, customerReferenceNumber: string): Promise<void> {
        return axiosInstance
            .put(`/invoice/updateCustomerReferenceNumber`, { companyId: companyId, newCustomerReferenceNumber: customerReferenceNumber })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updatePrice(companyId: number): Promise<number> {
        return axiosInstance
            .put(`/invoice/updatePrice?companyId=${companyId}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function resetAllPricesForGroup(companyId: number): Promise<number> {
        return axiosInstance
            .put(`/invoice/resetAllPricesForGroup?companyId=${companyId}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateDiscount(companyId: number, discount: number): Promise<void> {
        return axiosInstance
            .put(`/invoice/updateDiscount?companyId=${companyId}`, { price: discount })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateAdditionalFee(companyId: number, additionalFee: number): Promise<void> {
        return axiosInstance
            .put(`/invoice/updateAdditionalFee?companyId=${companyId}`, { price: additionalFee })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomerNumber(companyId: number, customerNumber: number): Promise<void> {
        return axiosInstance
            .put(`/invoice/updateCustomerNumber?companyId=${companyId}`, { customerReferenceNumber: customerNumber })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateAttentionPerson(companyId: number, customerId: number): Promise<void> {
        return axiosInstance
            .put(`/invoice/attention?companyId=${companyId}`, { newAttentionPersonId: customerId })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateAgreementFrom(companyId: number, agreementFrom: Date): Promise<AgreementPeriodModel> {
        return axiosInstance
            .put(`/invoice/agreement?companyId=${companyId}`, { agreementFrom: agreementFrom })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateAgreementTo(companyId: number, agreementTo: Date): Promise<void> {
        return axiosInstance
            .put(`/invoice/agreement?companyId=${companyId}`, { agreementTo: agreementTo })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateInvoiceDescription(companyId: number, invoiceDescription: string): Promise<void> {
        return axiosInstance
            .put(`/invoice/invoiceDescription?companyId=${companyId}`, { text: invoiceDescription })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateAdminInfo(companyId: number, adminInfo: string): Promise<void> {
        return axiosInstance
            .put(`/invoice/adminInfo?companyId=${companyId}`, { text: adminInfo })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function generateExcelExportForSelectedCompanies(companyIds: number[]): Promise<void> {
        // Here we need a mush longer timeout because of the long processing time when selecting all companies.
        // 5 minutes should be enough;
        const extendedTimeout = 5 * 60 * 1000;

        return axiosInstance
            .put(`/invoice/export`, { companyIds }, { timeout: extendedTimeout, responseType: 'blob' })
            .then((response: AxiosResponse) => {
                const blob = new Blob([response.data]);
                saveAs(blob, `Invoice ${new Date().toLocaleDateString('sv')}.xlsx`);
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function uploadCompanyDocument(uploadCompanyDocumentModel: UploadCompanyDocumentModel, file?: File): Promise<void> {
        const form = new FormData();

        form.append('uploadCompanyDocumentModel', new Blob([JSON.stringify(uploadCompanyDocumentModel)], { type: 'application/json' }));
        if (file) {
            form.append('file', file);
        }
        return axiosInstance
            .post<UploadCompanyDocumentModel>('/invoice/document', form)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function downloadCompanyDocument(companyId: number, companyDocumentModel: CompanyDocumentModel): Promise<void> {
        return axiosInstance
            .get(`/invoice/document/download?companyId=${companyId}&documentId=${companyDocumentModel.id}`, { responseType: 'blob' })
            .then((response: AxiosResponse) => {
                const fileName = companyDocumentModel.fileName || 'nameMissing.pdf';
                DocumentService().createAndSavePDF(response, fileName);
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function deleteCompanyDocument(companyId: number, documentId: number): Promise<void> {
        return axiosInstance
            .delete(`/invoice/document/delete?companyId=${companyId}&documentId=${documentId}`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getAllCompanies,
        getFullCompany,
        getItems,
        updateCustomerReferenceNumber,
        updateCustomerNumber,
        updateAttentionPerson,
        updatePrice,
        resetAllPricesForGroup,
        updateDiscount,
        updateAdditionalFee,
        updateAgreementFrom,
        updateAgreementTo,
        updateInvoiceDescription,
        updateAdminInfo,
        markCompaniesAsInvoiced,
        markCompaniesAsNotInvoiced,
        generateExcelExportForSelectedCompanies,
        uploadCompanyDocument,
        downloadCompanyDocument,
        deleteCompanyDocument,
    };
};

export default InvoiceService;
