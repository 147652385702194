import React, { FC, useMemo, useState } from 'react';
import styled from './AcknowledgeNewChangeView.module.css';
import RadioButton from '../RadioButton/RadioButton';
import TextEditor, { EMPTY_HTML_TEXT } from '../TextEditor/TextEditor';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import Toggle from '../Toggle/Toggle';
import SubscriptionModel from '../../models/SubscriptionModel';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import useAuth from '../../hooks/useAuth';
import { AssessmentChoice } from '.././../services/SubscriptionChangeService';
import { acceptanceLogCommentMaxLength } from '../../constants/Validation';
import UserModel from '../../models/UserModel';
import { useTranslation } from 'react-i18next';
import revisionQuestionNotificationIcon from '../../assets/images/errorIcon.svg';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface Props {
    data: SubscriptionModel;
    //consultantStage indicates when the law is assessed from the consultant view.
    consultantStage?: boolean;
    assessmentUsers: UserModel[];
    editDisabled?: boolean;
    onEditTexts: () => void;
    onClose: () => void;
    onEditKeywords: () => void;
    onEditLawLists: () => void;
    onEditRevisionQuestions?: () => void;
    onSubmit: (data: SubmitData) => void;
    showDelagation: boolean;
}

export interface SubmitData {
    assessmentChoice: AssessmentChoice;
    comment: string;
    assessmentUsers: number[];
    notifyEmail: boolean;
}

const AcknowledgeNewChangeView: FC<Props> = ({
    data,
    consultantStage,
    assessmentUsers,
    editDisabled,
    onEditTexts,
    onEditKeywords,
    onEditLawLists,
    onEditRevisionQuestions,
    onClose,
    onSubmit,
    showDelagation,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const { company, isAdminSubs, isEditor, isGroupCompany, isRevisor, isAboveSuperUser, isGroupHasRevisionQuestions, isStandardCompany } = useAuth();
    const singleUser = assessmentUsers.length === 0;
    const [hasEditTextEditor, setHasEditTextEditor] = useState(false);

    const [selectedRadioButton, setSelectedRadioButton] = useState<AssessmentChoice>(data.partlyAccepted || !isStandardCompany() ? 'ACCEPT' : 'DECLINE');
    const [comment, setComment] = useState<{ value: string; error?: boolean }>(() => {
        let defaultComment = '';
        if (selectedRadioButton === 'DECLINE') {
            if (consultantStage && isGroupCompany()) {
                defaultComment = t('defaultCommentNewDeclineGeneral');
            } else {
                defaultComment = t('defaultCommentNewDecline', { companyNames: company?.name });
            }
        }
        return {
            value: defaultComment,
            error: false,
        };
    });
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [hasText, setHasText] = useState(false);
    const [notifyEmail, setNotifyEmail] = useState(!consultantStage);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const hasRevisionQuestionNotification = useMemo(() => {
        if (company && company.hasRevisionQuestions) {
            const revisionQuestions = [...data.revisionQuestionGroups.flatMap(rqg => rqg.revisionQuestions), ...data.customRevisionQuestions];
            return revisionQuestions.some(rq => rq.changedNotification);
        }
        return false;
    }, [company, data]);

    const handleSubmit = () => {
        setLoading(true);
        setTimeout(() => {
            setLoadingSpinner(true);
        }, 1000);
        const data: SubmitData = {
            assessmentChoice: selectedRadioButton,
            comment: hasText ? comment.value : '',
            assessmentUsers: selectedRadioButton !== 'DECLINE' ? selectedUserIds : [],
            notifyEmail,
        };
        onSubmit(data);
    };

    const handleUserChange = (id: number) => {
        setSelectedUserIds(uids => (uids.includes(id) ? uids.filter(uid => uid !== id) : [...uids, id]));
    };

    const handleChangeOption = (option: AssessmentChoice) => {
        if (selectedUserIds.length < 1) {
            if ((option === 'ACCEPT' || option === 'FORWARD_NO_CHOICE') && consultantStage && assessmentUsers.length === 1 && !isGroupCompany()) {
                setSelectedUserIds([assessmentUsers[0].userId]);
            } else {
                setSelectedUserIds([]);
            }
        }
        if (option === 'DECLINE') {
            setSelectedUserIds([]);
            if (consultantStage && isGroupCompany()) {
                setComment({ value: t('defaultCommentNewDeclineGeneral') });
            } else {
                setComment({ value: t('defaultCommentNewDecline', { companyNames: company?.name }) });
            }
        } else {
            setComment({ value: '' });
        }
        setHasEditTextEditor(false);
        setSelectedRadioButton(option);
    };

    const consultantButtonText = selectedRadioButton === 'DECLINE' ? t('buttonSendToArchive') : t('buttonSendToCustomer');
    const saveButtonDisabled =
        (!consultantStage && selectedRadioButton === 'ACCEPT' && !hasText) ||
        (!consultantStage && selectedRadioButton === 'DECLINE' && !hasText) ||
        (!consultantStage && selectedRadioButton === 'FORWARD_NO_CHOICE' && (!selectedUserIds.length || !hasText)) ||
        comment.error;
    const consultantSaveButtonDisabled = consultantStage && (comment.error || !hasText);

    const handleCommentChange = (value: string) => {
        if (value !== EMPTY_HTML_TEXT && !hasEditTextEditor) {
            setHasEditTextEditor(true);
        }
        setComment(s => ({ ...s, value }));
    };

    //The radio button choices differ depending on the state of the new law and if the law has delegates or not
    const renderRadioButtons = () => {
        const buttons = [];
        if (data.partlyAccepted) {
            buttons.push(
                <RadioButton checked={selectedRadioButton === 'ACCEPT'} onChange={() => handleChangeOption('ACCEPT')} text={t('acceptNewChangeLockedOnAccept')} group="radio" />,
            );
        } else {
            buttons.push(
                <RadioButton checked={selectedRadioButton === 'ACCEPT'} onChange={() => handleChangeOption('ACCEPT')} text={t('acceptNewChangeOptionAccept')} group="radio" />,
                <RadioButton checked={selectedRadioButton === 'DECLINE'} onChange={() => handleChangeOption('DECLINE')} text={t('acceptNewChangeOptionDecline')} group="radio" />,
            );
            if (!singleUser) {
                buttons.push(
                    <RadioButton
                        checked={selectedRadioButton === 'FORWARD_NO_CHOICE'}
                        onChange={() => handleChangeOption('FORWARD_NO_CHOICE')}
                        text={t('acceptNewChangeOptionForward')}
                        group="radio"
                    />,
                );
            }
        }
        return buttons;
    };

    return (
        <div className={styled.AcknowledgeNewChangeView}>
            {loadingSpinner && <LoadingSpinner message={t('spinnerAcceptChangeLoading')} />}
            <div className={styled.CloseBar}>
                <span>{t('acceptNewChangeTitle')}</span>
            </div>
            <div className={[styled.Content].join(' ')}>
                <h4 className={styled.Title}>{t('acceptChangeTitle')}</h4>
                <div className={styled.RadioButtons}>{renderRadioButtons()}</div>
            </div>
            <div className={[styled.Content, styled.Comment].join(' ')}>
                <TextEditor
                    title={t('columnComment')}
                    optionalTitle={'(' + t('inputFieldErrorRequired2') + ')'}
                    initialValue={comment.value}
                    onChange={value => handleCommentChange(value)}
                    hasText={setHasText}
                    maxLength={acceptanceLogCommentMaxLength}
                    onMaxLengthError={error => setComment(s => ({ ...s, error }))}
                    errorMsg={
                        !consultantStage && !hasText && hasEditTextEditor
                            ? t('inputFieldErrorRequired')
                            : '' || (consultantStage && !hasText && hasEditTextEditor)
                            ? t('inputFieldErrorRequired')
                            : ''
                    }
                />
            </div>
            <div className={[styled.Content, selectedRadioButton === 'DECLINE' || !isEditor() ? styled.Disabled : ''].join(' ')}>
                <h4 className={styled.Title}>{t('buttonEdit')}</h4>
                <div className={styled.Buttons}>
                    <Button variant="Outline" onClick={onEditTexts} disabled={editDisabled}>
                        {t('buttonEditTexts')}
                    </Button>
                    {((company && company.hasRevisionQuestions && !isGroupCompany()) ||
                        (company && company.hasRevisionQuestions && isGroupCompany() && isGroupHasRevisionQuestions())) && (
                        <Button
                            variant="Outline"
                            onClick={onEditRevisionQuestions}
                            disabled={(!isRevisor() && !isAboveSuperUser()) || (isAdminSubs() && consultantStage) || (!isAdminSubs() && isGroupCompany() && !isAboveSuperUser())}
                            className={styled.RevisionQuestionButton}
                        >
                            {t('buttonEditRevisionQuestions')} {hasRevisionQuestionNotification && <img src={revisionQuestionNotificationIcon} />}
                        </Button>
                    )}
                </div>
                <div className={styled.Buttons}>
                    {company?.hasLawLists && (
                        <Button variant="Outline" onClick={onEditLawLists}>
                            {t('buttonAddToLawLists')}
                        </Button>
                    )}
                    {company?.hasKeyWords && (
                        <Button variant="Outline" onClick={onEditKeywords}>
                            {t('buttonAddToKeywords')}
                        </Button>
                    )}
                </div>
            </div>
            {showDelagation && !singleUser && (
                <div className={[styled.Content, selectedRadioButton === 'DECLINE' ? styled.Disabled : ''].join(' ')}>
                    <div className={styled.Title}>
                        <h4>{consultantStage ? t('acceptChangeSetDelegatesTitle') : t('acceptChangeDelegatesRequiredTitle')}</h4>
                        <p>{!consultantStage && selectedRadioButton == 'FORWARD_NO_CHOICE' && ' (' + t('inputFieldErrorRequired2') + ')'}</p>
                    </div>
                    <div className={styled.SelectUsers}>
                        <Dropdown
                            title={t('buttonSelectUsers').toString()}
                            content={assessmentUsers.map(u => ({
                                id: u.userId,
                                text: u.fullName,
                                checked: selectedUserIds.includes(u.userId) || !!data.waitingForAcceptance.find(wfa => wfa.userId === u.userId),
                                ...(data.acceptanceStatus.includes('DELEGATED') ? { disabled: data.waitingForAcceptance.find(wfa => wfa.userId === u.userId) !== undefined } : {}),
                            }))}
                            onChange={handleUserChange}
                            multiSelect
                        />
                        <Toggle checked={notifyEmail} onChange={() => setNotifyEmail(notify => !notify)} title={t('buttonNotifyByEmail')} />
                    </div>
                </div>
            )}
            <div className={styled.Footer}>
                <Button variant="Outline" onClick={onClose} disabled={loading}>
                    {t('buttonCancel')}
                </Button>
                <Button
                    variant="Primary"
                    disabled={saveButtonDisabled || consultantSaveButtonDisabled || loading}
                    onClick={() => (selectedRadioButton === 'DECLINE' && !consultantStage ? setShowConfirmModal(true) : handleSubmit())}
                >
                    {consultantStage ? consultantButtonText : selectedRadioButton === 'FORWARD_NO_CHOICE' ? t('buttonForwardLaw') : t('buttonAcceptNewLaw')}
                </Button>
            </div>

            {showConfirmModal && (
                <ConfirmModal
                    lawName={data.name}
                    onCancel={() => setShowConfirmModal(false)}
                    onConfirm={() => {
                        setShowConfirmModal(false);
                        handleSubmit();
                    }}
                />
            )}
        </div>
    );
};

export default AcknowledgeNewChangeView;
