import React, { FC, useState } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './RemoveDetachedCopiesModal.module.css';
import { Trans, useTranslation } from 'react-i18next';
import warningIcon from '../../../assets/images/statusNotOkIcon.svg';

interface Props {
    selectedCount: number;
    onCancel: () => void;
    onConfirm: () => void;
    deleteWarning: boolean;
}

const RemoveDetachedCopiesModal: FC<Props> = ({ selectedCount, onCancel, onConfirm, deleteWarning }) => {
    const { t } = useTranslation();
    const [showWarningText, setShowWarningText] = useState<boolean>(false);

    return (
        <Modal title={t('removeDetachedCopiesModalTitle')} subtitle={t('removeDetachedCopiesModalSubTitle', { selectedCount })}>
            <div className={styled.Content}>
                {deleteWarning && showWarningText && (
                    <div className={styled.Warning}>
                        <img src={warningIcon} alt="!" />
                        <p>{t('removeDetachedCopiesModalWarningText')}</p>
                    </div>
                )}
                <div className={styled.Buttons}>
                    <Button variant="Primary" onClick={onCancel}>
                        {t('buttonNo')}
                    </Button>
                    <Button variant="Outline" onClick={() => (deleteWarning && !showWarningText ? setShowWarningText(true) : onConfirm())}>
                        {t('buttonYes')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default RemoveDetachedCopiesModal;
