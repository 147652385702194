import Axios, { AxiosRequestConfig } from 'axios';
import useStorage from '../hooks/useStorage';
import useAuth from '../hooks/useAuth';

const AxiosConfig: AxiosRequestConfig = {
    withCredentials: true,
    timeout: 30000,
    baseURL: '/api',
    headers: {
        common: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            'Content-Type': 'application/json',
            Accept: 'application/json, application/octet-stream',
        },
    },
};

const { getToken, setToken, removeToken, getSsoMode } = useStorage();

const authApi = Axios.create(AxiosConfig);
authApi.interceptors.request.use((config: AxiosRequestConfig) => ({
    ...config,
    headers: {
        ...config.headers,
        Authorization: getToken(),
    },
}));
authApi.interceptors.response.use(response => {
    if (response && response.headers && response.headers['refreshtoken']) {
        setToken(response.headers['refreshtoken']);
    } else if (response && response.headers && response.headers['expiredssotoken'] === 'true') {
        const refreshTokenURI = response.headers['refreshtokenuri'];
        window.location.href = refreshTokenURI;
        removeToken();
    }
    return response;
});

const authApiPassword = Axios.create(AxiosConfig);
authApiPassword.interceptors.request.use((config: AxiosRequestConfig) => ({
    ...config,
    headers: {
        ...config.headers,
        Authorization: getToken(),
    },
}));
authApiPassword.interceptors.response.use(response => {
    if (response && response.headers && response.headers['refreshtoken2']) {
        setToken(response.headers['refreshtoken2']);
    } else if (response && response.headers && response.headers['expiredssotoken2'] === 'true') {
        const refreshTokenURI = response.headers['refreshtokenuri2'];
        window.location.href = refreshTokenURI;
        removeToken();
    }
    return response;
});

export const getAuthApiForPasswordChange = () => authApiPassword;
export const getAuthApi = () => authApi;
export const getApi = () => Axios.create({ ...AxiosConfig, withCredentials: false });

export default AxiosConfig;
