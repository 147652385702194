import React, { useState, useEffect, useMemo, FC } from 'react';
import styled from './Invoicing.module.css';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { LawType, LawTypeEnum } from '../../../models/LawType';
import useInvoicing from '../../hooks/useInvoicing';
import { Column } from 'react-table';
import accordionIcon from '../../../assets/images/accordionIcon.svg';
import { CompanyType, CompanyTypeEnum } from '../../../models/CompanyType';
import { useTranslation } from 'react-i18next';
import statusOkIcon from '../../../assets/images/statusOkIcon.svg';
import statusNotOkIcon from '../../../assets/images/statusNotOkIcon.svg';
import InvoiceFullView from '../InvoiceFullView/InvoiceFullView';
import InvoiceToaster from '../../components/InvoiceToaster/InvoiceToaster';
import InvoiceChangeInvoiceStatusToInvoicedModal from '../../components/modals/InvoiceChangeInvoiceStatusModal/InvoiceChangeInvoiceStatusToInvoicedModal';
import InvoiceChangeInvoiceStatusToNotInvoicedModal from '../../components/modals/InvoiceChangeInvoiceStatusModal/InvoiceChangeInvoiceStatusToNotInvoicedModal';
import Table from '../../../components/Table/Table';
import DenseCell from '../../../components/Table/DenseCell/DenseCell';
import RevisionCell from '../../../components/Table/RevisionCell/RevisionCell';
import CheckboxCell from '../../../components/Table/CheckboxCell/CheckboxCell';
import CompanyDocumentModel from '../../models/CompanyDocumentModel';

export interface Data {
    companyId?: number;
    corporationId?: number;
    name: string;
    type: CompanyType;
    lawTypes: LawType[];
    features: string;
    customerNumber?: number;
    customerReferenceNumber: string;
    agreementFromTo: string;
    agreementFrom?: Date;
    agreementTo?: Date;
    totalPrice?: number;
    concatenatedPrice?: number;
    invoiced?: boolean;
    checked?: boolean;
    subRows?: Data[];
}

interface ColumnSearch {
    id: string;
    value: string | undefined;
}

const Invoicing: FC = () => {
    const [globalSearch, setGlobalSearch] = useState('');
    const [columnSearch, setColumnSearch] = useState<ColumnSearch[]>([]);
    const [showColumnSearch, setShowColumnSearch] = useState(true);

    const [showChangeInvoiceStatusToInvoiced, setShowChangeInvoiceStatusToInvoiced] = useState(false);
    const [showChangeInvoiceStatusToNotInvoiced, setShowChangeInvoiceStatusToNotInvoiced] = useState(false);

    const [showChangeInvoiceStatusSelectedCompanyToInvoiced, setShowChangeInvoiceStatusSelectedCompanyToInvoiced] = useState(false);
    const [showChangeInvoiceStatusSelectedCompanyToNotInvoiced, setShowChangeInvoiceStatusSelectedCompanyToNotInvoiced] = useState(false);

    const {
        loading,
        loadingText,
        data,
        toggleChecked,
        checkCorporation,
        checkedCorporations,
        checked,
        checkAll,
        allChecked,
        setSelectedCompanyId,
        selectedCompanyId,
        company,
        updateCustomerReferenceNumber,
        updateCustomerNumber,
        handleChangeAgreementFrom,
        handleChangeAgreementTo,
        initAttentionPerson,
        attentionPerson,
        updateAttentionPerson,
        attentionCandidates,
        handleUpdatePrice,
        handleResetAllPricesForGroup,
        updateDiscount,
        updateAdditionalFee,
        updateInvoiceDescription,
        updateAdminInfo,
        handleSortingChange,
        sorting,
        setSelectedCompaniesAsInvoiced,
        setSelectedCompaniesAsNotInvoiced,
        markCompanyAsNotInvoiced,
        markCompanyAsInvoiced,
        generateExcelExportForSelectedCompanies,
        handleUploadCompanyDocument,
        handleDeleteCompanyDocument,
    } = useInvoicing();

    const { t, i18n } = useTranslation();

    const openModalSetSelectedCompaniesAsInvoiced = () => {
        setShowChangeInvoiceStatusToInvoiced(true);
    };

    const acceptSetSelectedCompaniesAsInvoiced = () => {
        setSelectedCompaniesAsInvoiced();
        setShowChangeInvoiceStatusToInvoiced(false);
    };

    const openModalSetSelectedCompaniesAsNotInvoiced = () => {
        setShowChangeInvoiceStatusToNotInvoiced(true);
    };

    const acceptSetSelectedCompaniesAsNotInvoiced = () => {
        setSelectedCompaniesAsNotInvoiced();
        setShowChangeInvoiceStatusToNotInvoiced(false);
    };

    const acceptSetSelectedCompanyAsInvoiced = () => {
        if (selectedCompanyId) {
            markCompanyAsInvoiced(selectedCompanyId);
        }
        setShowChangeInvoiceStatusSelectedCompanyToInvoiced(false);
    };

    const acceptSetSelectedCompanyAsNotInvoiced = () => {
        if (selectedCompanyId) {
            markCompanyAsNotInvoiced(selectedCompanyId);
        }
        setShowChangeInvoiceStatusSelectedCompanyToNotInvoiced(false);
    };

    const handleOnExport = () => {
        if (selectedCompanyId) {
            generateExcelExportForSelectedCompanies();
        }
    };

    const columns: Array<Column<Data> & { Header: any }> = useMemo(() => {
        return [
            {
                id: 'expander',
                Header: '',
                languageId: '--',
                accessor: 'name',
                sortInverted: true,
                visible: true,
                width: '42px',
                alwaysVisible: true,
                showNotification: true,

                Cell: function expanderCell({ row }) {
                    return row.canExpand ? (
                        <span {...row.getToggleRowExpandedProps()} className={styled.ToggleCell}>
                            {row.isExpanded ? <img src={accordionIcon} className={styled.OpenAccordion} /> : <img src={accordionIcon} />}
                        </span>
                    ) : (
                        <span className={styled.ToggleCell}></span>
                    );
                },
            },
            {
                id: 'name',
                Header: t('name'),
                languageId: 'name',
                accessor: 'name',
                disableSortBy: true,
                visible: true,
                width: 3,
                alwaysVisible: true,
                Cell: DenseCell,
                showNotification: true,
            },
            {
                id: 'type',
                Header: t('companyType'),
                languageId: 'companyType',
                accessor: ({ type }) => CompanyTypeEnum[type],
                visible: true,
                width: 2,
                Cell: DenseCell,
                sortInverted: true,
            },
            {
                id: 'lawtypes',
                Header: t('lawtypes'),
                languageId: 'lagtyper',
                accessor: row =>
                    row.lawTypes
                        .map(type => {
                            return t(LawTypeEnum[type]);
                        })
                        .join(', '),
                disableSortBy: true,
                width: 2,
                Cell: function nameCell(props) {
                    return <DenseCell {...props} multiRow />;
                },
                visible: true,
            },
            {
                id: 'customerNumber',
                Header: t('customerNumber'),
                languageId: 'customerNumber',
                accessor: row => row.customerNumber || '',
                sortInverted: true,
                editable: true,
                width: 2,
                field: 'text',
                Cell: DenseCell,
                visible: true,
            },
            {
                id: 'contractPeriod',
                Header: t('contractPeriod'),
                languageId: 'contractPeriod',
                accessor: row => row.agreementFromTo || '',
                filter: (rows, columnIds, filterValue) => {
                    return rows.filter(row => {
                        const agreementFromTo = row.original.agreementFromTo;
                        return agreementFromTo.startsWith(filterValue, 0);
                    });
                },
                sortInverted: true,
                editable: true,
                width: 3,
                field: 'text',
                Cell: DenseCell,
                visible: true,
            },
            {
                id: 'totalPrice',
                Header: t('price'),
                languageId: 'price',
                accessor: row =>
                    row.companyId ? (row.totalPrice === 0 && row.concatenatedPrice && row.concatenatedPrice > 0 ? row.concatenatedPrice : row.totalPrice) + ' kr' : '',
                disableSortBy: true,
                editable: false,
                field: 'text',
                width: 1,
                Cell: DenseCell,
                disableFilters: true,
                visible: true,
            },
            {
                id: 'status',
                Header: t('status'),
                languageId: 'status',
                disableSortBy: true,
                editable: false,
                Cell: function Cell(props) {
                    const value = (
                        <div className={styled.InvoiceStatusImg}>
                            <img
                                className={styled.StatusIcon}
                                title={props.row.original.invoiced ? t('invoiced') : t('notInvoiced')}
                                src={props.row.original.invoiced ? statusOkIcon : statusNotOkIcon}
                                alt="status"
                            />
                        </div>
                    );
                    return (
                        <div>
                            {props.row.original.companyId && (
                                <RevisionCell
                                    {...{
                                        ...props,
                                        cell: { ...props.cell, value },
                                    }}
                                />
                            )}
                        </div>
                    );
                },
                accessor: row => (row.invoiced ? row.invoiced : false),
                disableFilters: true,
                width: 1,
                field: 'text',
                visible: true,
            },
            {
                id: 'invoiced',
                Header: function Cell() {
                    // const value = <img src={props.row.original.invoiced ? statusOkIcon : statusNotOkIcon} alt="status" />;
                    return (
                        <div>
                            <CheckboxCell
                                disabled={false}
                                locked={false}
                                checked={allChecked > 0}
                                indeterminate={allChecked === 1}
                                onChange={() => {
                                    checkAll();
                                }}
                            />
                        </div>
                    );
                },
                languageId: 'invoiced',
                accessor: 'invoiced',
                disableSortBy: true,
                editable: true,
                field: 'text',
                visible: true,
                width: 1,
                disableFilters: true,
                Cell: function Cell(props) {
                    const value = <img src={props.row.original.invoiced ? statusOkIcon : statusNotOkIcon} alt="status" />;
                    return (
                        <div className={styled.CheckboxCell}>
                            {props.row.original.companyId && (
                                <CheckboxCell
                                    disabled={false}
                                    locked={false}
                                    checked={checked[props.row.original.companyId]}
                                    indeterminate={false}
                                    onChange={() => {
                                        toggleChecked(props.row.original.companyId ? props.row.original.companyId : -1);
                                        setSelectedCompanyId(-1);
                                    }}
                                />
                            )}

                            {!props.row.original.companyId && props.row.original.corporationId && (
                                <CheckboxCell
                                    disabled={false}
                                    locked={false}
                                    checked={checkedCorporations[props.row.original.corporationId] === 2}
                                    indeterminate={checkedCorporations[props.row.original.corporationId] === 1}
                                    onChange={() => {
                                        checkCorporation(props.row.original.corporationId ? props.row.original.corporationId : -1);
                                    }}
                                />
                            )}
                        </div>
                    );
                },
            },
        ];
    }, [data, i18n.language, checked, checkedCorporations]);

    return (
        <div className={styled.TableWrapper}>
            {loading && <LoadingSpinner message={loadingText} />}
            <Table
                columns={columns}
                data={data || []}
                columnSearch={columnSearch}
                showColumnSearch={showColumnSearch}
                globalSearch={globalSearch}
                selected={selectedCompanyId}
                onSelect={setSelectedCompanyId}
                expandable
                sorting={sorting}
                onSortingChange={handleSortingChange}
                getRowIdFunction={(row: any) => {
                    if (row.original.companyId) {
                        return row.original.companyId;
                    } else {
                        return -1;
                    }
                }}
            />
            {Object.keys(checked).length > 0 && (
                <InvoiceToaster
                    fullViewOpen={selectedCompanyId != undefined && selectedCompanyId > -1}
                    numberOfCompaniesMarked={Object.keys(checked).length}
                    onExport={handleOnExport}
                    onSetAsInvoiced={openModalSetSelectedCompaniesAsInvoiced}
                    onSetAsNotInvoiced={openModalSetSelectedCompaniesAsNotInvoiced}
                />
            )}
            {showChangeInvoiceStatusToInvoiced && (
                <InvoiceChangeInvoiceStatusToInvoicedModal
                    onAccept={() => {
                        acceptSetSelectedCompaniesAsInvoiced();
                    }}
                    onClose={() => {
                        setShowChangeInvoiceStatusToInvoiced(false);
                    }}
                    numberOfCompaniesMarked={Object.keys(checked).length}
                />
            )}
            {showChangeInvoiceStatusToNotInvoiced && (
                <InvoiceChangeInvoiceStatusToNotInvoicedModal
                    onAccept={() => {
                        acceptSetSelectedCompaniesAsNotInvoiced();
                    }}
                    onClose={() => {
                        setShowChangeInvoiceStatusToNotInvoiced(false);
                    }}
                    numberOfCompaniesMarked={Object.keys(checked).length}
                />
            )}
            {showChangeInvoiceStatusSelectedCompanyToInvoiced && (
                <InvoiceChangeInvoiceStatusToInvoicedModal
                    onAccept={() => {
                        acceptSetSelectedCompanyAsInvoiced();
                    }}
                    onClose={() => {
                        setShowChangeInvoiceStatusSelectedCompanyToInvoiced(false);
                    }}
                    numberOfCompaniesMarked={1}
                />
            )}
            {showChangeInvoiceStatusSelectedCompanyToNotInvoiced && (
                <InvoiceChangeInvoiceStatusToNotInvoicedModal
                    onAccept={() => {
                        acceptSetSelectedCompanyAsNotInvoiced();
                    }}
                    onClose={() => {
                        setShowChangeInvoiceStatusSelectedCompanyToNotInvoiced(false);
                    }}
                    numberOfCompaniesMarked={1}
                />
            )}

            {company && (
                <InvoiceFullView
                    initAttentionPerson={initAttentionPerson}
                    updateAttentionPerson={updateAttentionPerson}
                    attentionPerson={attentionPerson}
                    attentionCandidates={attentionCandidates}
                    company={company}
                    updateCustomerReferenceNumber={updateCustomerReferenceNumber}
                    updateCustomerNumber={updateCustomerNumber}
                    handleChangeAgreementFrom={handleChangeAgreementFrom}
                    handleChangeAgreementTo={handleChangeAgreementTo}
                    handleUpdatePrice={handleUpdatePrice}
                    handleResetAllPricesForGroup={handleResetAllPricesForGroup}
                    updateDiscount={updateDiscount}
                    updateAdditionalFee={updateAdditionalFee}
                    updateInvoiceDescription={updateInvoiceDescription}
                    updateAdminInfo={updateAdminInfo}
                    handleUploadCompanyDocument={handleUploadCompanyDocument}
                    handleDeleteCompanyDocument={handleDeleteCompanyDocument}
                    open={selectedCompanyId != undefined && selectedCompanyId > -1}
                    markCompanyAsInvoiced={() => {
                        setShowChangeInvoiceStatusSelectedCompanyToInvoiced(true);
                    }}
                    markCompanyAsNotInvoiced={() => {
                        setShowChangeInvoiceStatusSelectedCompanyToNotInvoiced(true);
                    }}
                    onClose={() => {
                        setSelectedCompanyId(-1);
                    }}
                />
            )}
        </div>
    );
};

export default Invoicing;
