import React, { FC, useEffect, useRef, useState } from 'react';
import styled from './VideoPlayer.module.css';
import videoThumbnail from '../../assets/images/videoThumbnail.svg';

interface Props {
    dynamicVideoContentId?: number;
    currentVideoIdPlaying?: number;
    fullScreen?: boolean;
    onChange?: (id: number) => void;
}

const VideoPlayer: FC<Props> = ({ dynamicVideoContentId, currentVideoIdPlaying, fullScreen, onChange }) => {
    const [play, setPlay] = useState(false);
    const [height, setHeight] = useState('100%');
    const videoElement = useRef<HTMLVideoElement | null>(null);

    const videoUrl = dynamicVideoContentId ? '/api/video/help?id=' + dynamicVideoContentId : '/api/video/banner';

    const handleFullscreen = () => {
        if (fullScreen && videoElement && videoElement.current) {
            (videoElement.current as HTMLVideoElement).requestFullscreen();
        }
    };

    useEffect(() => {
        if (currentVideoIdPlaying !== -1 && currentVideoIdPlaying !== dynamicVideoContentId) {
            setPlay(false);
        }
    }, [currentVideoIdPlaying]);

    const handlePlay = () => {
        setPlay(true);
        if (dynamicVideoContentId && onChange) {
            onChange(dynamicVideoContentId);
        }
    };

    const handlePause = () => {
        if (videoElement && videoElement.current) {
            (videoElement.current as HTMLVideoElement).remove();
        }
    };
    return (
        <div className={styled.VideoPlayer}>
            {play && (
                <video ref={fullScreen ? videoElement : undefined} onPause={handlePause} onLoadStart={handleFullscreen} height={height} controls autoPlay>
                    <source type="video/mp4" src={videoUrl}></source>
                    Your browser does not support the video tag.
                </video>
            )}
            <img src={videoThumbnail} alt="Thumbnail" onClick={handlePlay} className={play ? styled.Hidden : undefined} />
        </div>
    );
};

export default VideoPlayer;
