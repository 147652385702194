import React, { FC } from 'react';
import styled from './AccordionCommentItem.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    data: { date: string; username: string; comment: string }[];
}

const getDateWithoutTime = (date: string): string => {
    const splitTime = date.split(' ');
    return splitTime[0] ? splitTime[0] : date;
};

const AccordionCommentItem: FC<Props> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <>
            {data.length
                ? data
                      .sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0))
                      .map((item, i) => (
                          <div key={i + item.date} className={styled.AccordionCommentItem}>
                              <div className={styled.Header}>
                                  <p>
                                      {item.username} - {getDateWithoutTime(item.date)}
                                  </p>
                              </div>
                              <div dangerouslySetInnerHTML={{ __html: item.comment }} />
                          </div>
                      ))
                : t('noPreviousComments')}
        </>
    );
};

export default AccordionCommentItem;
