import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from './RevisionMediaEditor.module.css';
import { RevisionObjStatus } from '../../models/RevisionObjStatus';
import { useTranslation } from 'react-i18next';
import MediaInput from '../MediaInput/MediaInput';
import MediaPreviewAndCommentModal from './MediaPreviewAndCommentModal/MediaPreviewAndCommentModal';
import useEditRevision from '../../hooks/useEditRevision';
import RevisionMediaAttachmentModel from '../../models/RevisionMediaAttachmentModel';
import RevisionSubscriptionModel from '../../models/RevisionSubscriptionModel';
import CustomLawRevisionModel from '../../models/CustomLawRevisionModel';
import MediaCard from '../MediaCard/MediaCard';
import Modal from '../../Invoicing/components/modals/Modal';
import Button from '../Button/Button';
import { maxAllowedMediaAttachment, maxAllowedMediaAttachmentExtendedRevision, uploadImageMaxSize } from '../../constants/Validation';
import useAuth from '../../hooks/useAuth';
import MediaPreviewModal from './MediaPreviewModal/MediaPreviewModal';

interface Props {
    status?: RevisionObjStatus;
    onCancel: () => void;
    onConfirm: () => void;
    selectedSub: RevisionSubscriptionModel | CustomLawRevisionModel | undefined;
    blockEditFunctions?: boolean;
    editRevisionLeftViewOpen?: boolean;
}

const RevisionMediaEditor: FC<Props> = ({ selectedSub, blockEditFunctions, editRevisionLeftViewOpen }) => {
    const { t } = useTranslation();
    const { isGroupHasExtendedRevisionMedia, company } = useAuth();
    const [selectedFile, setSelectedFile] = useState<File>();
    const [showMediaPreview, setShowMediaPreview] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<RevisionMediaAttachmentModel>();
    const [sizeValidator, setSizeValidator] = useState(false);
    const [finishedRevMediaAdded, setFinishedRevMediaAdded] = useState(false);
    const [unfinishedRevMediaAdded, setUnfinishedRevMediaAdded] = useState(false);
    const { handleGetRevisionMediaFullData, handleAddRevisionMedia, handleEditRevisionMediaComment, handleDeleteRevisionMedia, revision } = useEditRevision();

    const handleOnCancel = () => {
        setShowMediaPreview(false);
        setSelectedFile(undefined);
        setSizeValidator(false);
    };

    const handleOnEdit = (mediaId: number, comment: string) => {
        setShowMediaPreview(false);
        handleEditRevisionMediaComment(mediaId, comment);
    };

    const handleOnDelete = (mediaId: number) => {
        handleDeleteRevisionMedia(mediaId);
        setShowMediaPreview(false);
        setUnfinishedRevMediaAdded(false);
        setFinishedRevMediaAdded(false);
    };

    const handleMediaPreviewConfirm = (comment: string) => {
        if (selectedFile) {
            handleAddRevisionMedia(selectedFile, comment);
            setShowMediaPreview(false);
            setSelectedFile(undefined);
        }
    };

    const handleMediaInputChange = (file: File) => {
        if (file.size > uploadImageMaxSize) {
            setSizeValidator(true);
        } else {
            setSelectedFile(file);
            setShowMediaPreview(true);
        }
    };

    const handleOnMediaClick = (media: RevisionMediaAttachmentModel) => {
        if (blockEditFunctions && editRevisionLeftViewOpen) {
            return;
        }
        if (!media.fullData) {
            handleGetRevisionMediaFullData(media.mediaId);
        }
        setSelectedMedia(media);
        setShowMediaPreview(true);
    };

    const maxAllowedMediaAttachments = useMemo(() => {
        let unfinished = 0;
        if (selectedSub?.mediaAttachments) {
            for (let i = 0; i < selectedSub.mediaAttachments.length; i++) {
                if (selectedSub?.mediaAttachments[i].stage === 'NOT_FINISHED') {
                    unfinished++;
                }
            }
        }
        if (company?.corporationHasExtendedRevisionMedia || isGroupHasExtendedRevisionMedia()) {
            return revision?.finished ? maxAllowedMediaAttachmentExtendedRevision + unfinished : maxAllowedMediaAttachmentExtendedRevision;
        } else {
            return revision?.finished ? maxAllowedMediaAttachment + unfinished : maxAllowedMediaAttachment;
        }
    }, []);

    useEffect(() => {
        if (selectedSub?.mediaAttachments) {
            for (let i = 0; i < selectedSub.mediaAttachments.length; i++) {
                if (revision?.finished && selectedSub.mediaAttachments[i].stage === 'FINISHED') {
                    if (company?.corporationHasExtendedRevisionMedia && maxAllowedMediaAttachments === selectedSub.mediaAttachments.length) {
                        setFinishedRevMediaAdded(true);
                    } else if (!company?.corporationHasExtendedRevisionMedia && maxAllowedMediaAttachments === selectedSub.mediaAttachments.length) {
                        setFinishedRevMediaAdded(true);
                    }
                }
                if (!revision?.finished && selectedSub?.mediaAttachments[i].stage === 'NOT_FINISHED') {
                    if (company?.corporationHasExtendedRevisionMedia && maxAllowedMediaAttachments === selectedSub.mediaAttachments.length) {
                        setUnfinishedRevMediaAdded(true);
                    } else if (!company?.corporationHasExtendedRevisionMedia && maxAllowedMediaAttachments === selectedSub.mediaAttachments.length) {
                        setUnfinishedRevMediaAdded(true);
                    }
                }
            }
        }
    }, [selectedSub?.mediaAttachments]);

    return (
        <div>
            {!blockEditFunctions && <div className={styled.CloseBar}>{t('fileSelect')}</div>}
            <div>
                <div className={styled.MediaAttachmentWrapper}>
                    {selectedSub && selectedSub.mediaAttachments && selectedSub.mediaAttachments.length > 0
                        ? selectedSub.mediaAttachments.map(media => (
                              <div key={media.mediaId}>
                                  <div className={styled.MediaCard} onClick={() => handleOnMediaClick(media)}>
                                      <MediaCard imageSrc={media.thumbnail} stage={media.stage} addedBy={media.addedBy} addedAt={media.addedAt} userComment={media.comment} />
                                  </div>
                              </div>
                          ))
                        : ''}
                    {showMediaPreview && !blockEditFunctions && editRevisionLeftViewOpen && (
                        <MediaPreviewAndCommentModal
                            file={selectedFile}
                            selectedMedia={selectedMedia}
                            onCancel={handleOnCancel}
                            onConfirm={handleMediaPreviewConfirm}
                            onEdit={handleOnEdit}
                            onDelete={handleOnDelete}
                        />
                    )}
                    {showMediaPreview && blockEditFunctions && !editRevisionLeftViewOpen && <MediaPreviewModal selectedMedia={selectedMedia} onCancel={handleOnCancel} />}
                </div>
                <div className={[styled.Options, unfinishedRevMediaAdded || finishedRevMediaAdded ? styled.Disabled : ''].join(' ')}>
                    {!blockEditFunctions && (
                        <>
                            <span style={{ marginRight: 10 }}>
                                {selectedSub?.mediaAttachments ? selectedSub.mediaAttachments.length : 0}/{maxAllowedMediaAttachments}
                            </span>
                            <div>
                                <MediaInput onChange={handleMediaInputChange} />
                            </div>
                        </>
                    )}
                </div>
                {sizeValidator && (
                    <Modal className={styled.Modal} title={'Varning'} subtitle={'Filstorleken är för stor, maximalt tillåtna storlek är 10mb '}>
                        <div className={styled.ButtonContainer}>
                            <Button onClick={handleOnCancel} variant="Primary">
                                Ok
                            </Button>
                        </div>
                    </Modal>
                )}{' '}
            </div>
        </div>
    );
};

export default RevisionMediaEditor;
