import React, { FC } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import styled from './LeavePageModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    onSave: () => void;
    onCancel: () => void;
    onContinue: () => void;
}

const LeavePageModal: FC<Props> = ({ onSave, onCancel, onContinue }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('lawLibraryLeavePageModalTitle')} subtitle={t('lawLibraryLeavePageModalSubTitle')}>
            <div className={styled.Options}>
                <Button variant="Outline" onClick={onSave}>
                    {t('buttonSaveSelection')}
                </Button>
                <div className={styled.RightSide}>
                    <Button variant="Primary" onClick={onCancel}>
                        {t('buttonNo')}
                    </Button>
                    <Button variant="Outline" onClick={onContinue}>
                        {t('buttonYes')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default LeavePageModal;
