import React, { FC, useState, useEffect } from 'react';
import styled from './EditView.module.css';
import { ReactComponent as CloseIcon } from '../../assets/images/closeIcon.svg';
import Button from '../Button/Button';
import TextEditor from '../TextEditor/TextEditor';
import KeywordEditor from '../KeywordEditor/KeywordEditor';
import LawListEditor from '../LawListEditor/LawListEditor';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    value: any;
    field: string;
    maxLength?: number;
    consultantText?: string;
    onChange: (value: any) => void;
    onSubmit: () => void;
    onClose: () => void;
}

const EditView: FC<Props> = ({ title, field, maxLength, consultantText, onSubmit, onClose, value, onChange, children }) => {
    const { t } = useTranslation();
    const [isChanged, setIsChanged] = useState(false);

    const handleChange = (props: any) => {
        if (!isChanged && value !== props) {
            setIsChanged(true);
        }
        onChange(props);
    };

    useEffect(() => {
        setIsChanged(false);
    }, [title]);

    const renderTextEditor = (
        <div className={styled.Editor}>
            <TextEditor title={title} initialValue={value} onChange={handleChange} maxLength={maxLength} consultantText={consultantText} />
        </div>
    );

    const renderKeywordEditor = (
        <div className={styled.Editor}>
            <KeywordEditor values={value} onChange={handleChange} />
        </div>
    );

    const renderLawListEditor = (
        <div className={styled.Editor}>
            <LawListEditor lawLists={value} onChange={handleChange} />
        </div>
    );

    const renderEditor = () => {
        switch (field) {
            case 'keywordIds':
                return renderKeywordEditor;
            case 'lawLists':
                return renderLawListEditor;
            default:
                return renderTextEditor;
        }
    };

    return (
        <div className={styled.EditView}>
            <div className={styled.CloseBar}>
                <CloseIcon onClick={onClose} />
            </div>
            <div className={styled.Content}>
                {renderEditor()}
                {children && <div className={styled.Children}>{children}</div>}
                <div className={styled.Footer}>
                    <Button variant="Outline" onClick={onClose}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={onSubmit} disabled={!isChanged}>
                        {t('buttonSave')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditView;
