export type SubjectType = 'NEW_LAW' | 'SUPERUSER' | 'REQUEST_ACCESS' | 'REQUEST_KEYWORDS' | 'REQUEST_ROLLBACK' | 'ERROR' | 'SUGGESTION' | 'INFORMATION' | 'QUESTION' | undefined;

export enum SubjectEnum {
    'NEW_LAW' = 'subjectNewLaw',
    'SUPERUSER' = 'subjectSuperuser',
    'REQUEST_ACCESS' = 'subjectRequestAccess',
    'REQUEST_KEYWORDS' = 'subjectRequestKeywords',
    'REQUEST_ROLLBACK' = 'subjectRequestRollback',
    'ERROR' = 'subjectError',
    'SUGGESTION' = 'subjectSuggestion',
    'INFORMATION' = 'subjectInformation',
    'QUESTION' = 'subjectQuestion',
}

export default interface ContactMail {
    email: string;
    companyId: number;
    subject: string;
    message: string;
}
