import React, { FC, useState, useEffect } from 'react';

import styled from './ErrorMessagesModal.module.css';
import { useTranslation } from 'react-i18next';
import warningIcon from '../../../assets/images/statusWarningIcon.svg';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import MergeLawCompanyModel from '../../models/MergeLawCompanyModel';

interface Props {
    mergeLawCompanyModel?: MergeLawCompanyModel;
    onClose: () => void;
}

const ErrorMessagesModal: FC<Props> = ({ onClose, mergeLawCompanyModel }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    return (
        <Modal title={'Aktuella fel.'} className={styled.Modal} subtitle={'Lös problemen först för att slå samman lagarna.'}>
            <div className={styled.Content}>
                {mergeLawCompanyModel?.name}
                <div className={styled.Warning}>
                    <img src={warningIcon} alt="!" />
                    {mergeLawCompanyModel && mergeLawCompanyModel.errors && (
                        <ul>
                            {mergeLawCompanyModel.errors.map(error => (
                                <li key={error}>{error}</li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className={styled.ButtonRow1}>
                    <Button variant="Primary" onClick={onClose}>
                        {t('buttonCancel')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ErrorMessagesModal;
