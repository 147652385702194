import React, { useState, createRef, FC, useEffect, useMemo } from 'react';
import moreIcon from '../../../assets/images/moreIcon.svg';
import styled from './MenuCell.module.css';
import useClickOutside from '../../../hooks/useClickOutside';
import { Row } from 'react-table';
import accordionIcon from '../../../assets/images/accordionIcon.svg';
import _ from 'lodash';

interface Props {
    title: string;
    items: { text: string; function?: () => void; disabled?: boolean; subItems?: { text: string; function: () => void }[] }[];
    lastRow?: boolean;
}

export const isLastRow = (row: Row<any>, rows: Array<Row<any>>): boolean => {
    const rowId = row.id;
    const lastRow = rows[rows.length - 1];
    if (lastRow == undefined) {
        return false;
    }

    const lastRowId = lastRow.subRows && lastRow.subRows.length > 0 && lastRow.isExpanded ? lastRow.subRows[lastRow.subRows.length - 1].id : lastRow.id;
    return rowId === lastRowId;
};

const MenuCell: FC<Props> = ({ title, items, lastRow }) => {
    const ref = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);
    const [subMenuItemIndexOpen, setSubMenuItemIndexOpen] = useState<number>(-1);
    const hasSubItems: boolean = useMemo(() => _.find(items, i => i.subItems && i.subItems.length) !== undefined, [items]);

    useClickOutside(ref, () => setOpen(false));

    const handleClick = (callback: () => void) => {
        setOpen(false);
        setSubMenuItemIndexOpen(-1);
        callback();
    };

    useEffect(() => {
        if (ref.current && open && lastRow) {
            ref.current.scrollIntoView();
        }
    }, [open]);

    return (
        <div ref={ref} className={styled.MenuCell}>
            <img src={moreIcon} alt="menu" className={[styled.MenuIcon, open && styled.Open].join(' ')} onClick={() => setOpen(open => !open)} />
            {open && (
                <div className={styled.Menu}>
                    <h6 className={styled.Title}>{title}</h6>
                    {items.map((item, itemIndex) => (
                        <div
                            key={item.text}
                            onMouseEnter={hasSubItems ? () => setSubMenuItemIndexOpen(itemIndex) : undefined}
                            onMouseLeave={hasSubItems ? () => setSubMenuItemIndexOpen(-1) : undefined}
                        >
                            <p
                                key={item.text}
                                className={[styled.Item, item.disabled ? styled.Disabled : ''].join(' ')}
                                onClick={() => (item.function ? handleClick(item.function) : undefined)}
                            >
                                {hasSubItems ? (
                                    item.subItems ? (
                                        <img src={accordionIcon} className={subMenuItemIndexOpen === itemIndex ? styled.SubMenuOpen : ''} />
                                    ) : (
                                        <span className={styled.SubMenuIconSpacer} />
                                    )
                                ) : null}
                                {item.text}
                            </p>

                            {item.subItems && subMenuItemIndexOpen === itemIndex && (
                                <div className={styled.SubMenu}>
                                    {item.subItems.map(subItem => (
                                        <p key={subItem.text} className={styled.SubItem} onClick={() => handleClick(subItem.function)}>
                                            {subItem.text}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MenuCell;
