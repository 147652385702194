export type columnKeys =
    | 'law'
    | 'lawGroup'
    | 'publishedDate'
    | 'newLaw'
    | 'acceptableBy'
    | 'logDate'
    | 'changeAction'
    | 'comment'
    | 'userFullName'
    | 'email'
    | 'roles'
    | 'lastLogin'
    | 'externalUser'
    | 'changeHasImpact'
    | 'lawType'
    | 'name'
    | 'latestRevisionDate'
    | 'subId'
    | 'description'
    | 'text'
    | 'customerText1'
    | 'customerText2'
    | 'customerText3'
    | 'customerText4'
    | 'customerText5'
    | 'lawLists'
    | 'keywords'
    | 'revisionQuestions'
    | 'acceptanceStatus'
    | 'createdAt'
    | 'finishedAt'
    | 'revisedAt'
    | 'warningDate'
    | 'responsible'
    | 'status'
    | 'changeText'
    | 'companies'
    | 'delegated'
    | 'attachments';

const ExcelColumnWidth: Record<columnKeys, number> = {
    law: 20,
    lawGroup: 20,
    publishedDate: 11,
    logDate: 11,
    changeAction: 10,
    comment: 20,
    userFullName: 20,
    email: 25,
    roles: 40,
    lastLogin: 11,
    externalUser: 10,
    changeHasImpact: 10,
    newLaw: 10,
    acceptableBy: 10,
    lawType: 20,
    name: 20,
    latestRevisionDate: 11,
    subId: 13,
    description: 30,
    text: 30,
    customerText1: 20,
    customerText2: 20,
    customerText3: 20,
    customerText4: 20,
    customerText5: 20,
    lawLists: 20,
    keywords: 20,
    revisionQuestions: 40,
    acceptanceStatus: 10,
    createdAt: 11,
    finishedAt: 11,
    revisedAt: 11,
    warningDate: 11,
    responsible: 10,
    status: 15,
    changeText: 40,
    companies: 40,
    attachments: 10,
    delegated: 40,
};

export default ExcelColumnWidth;
