import React, { FC, useState } from 'react';
import Modal from '../../Modal/Modal';
import styled from './LinkModal.module.css';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import * as VALIDATION from '../../../constants/Validation';
import { useTranslation } from 'react-i18next';

interface Props {
    linkText?: string;
    linkUrl?: string;
    onRemove: () => void;
    onCancel: () => void;
    onSubmit: (text: string, url: string) => void;
}

const URL_VALIDATION_REGEX = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.?(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

const LinkModal: FC<Props> = ({ linkText = '', linkUrl = '', onRemove, onCancel, onSubmit }) => {
    const { t } = useTranslation();

    const [text, setText] = useState<{ value: string; error?: string }>({ value: linkText });
    const [url, setUrl] = useState<{ value: string; error?: string }>({ value: linkUrl });

    const handleSubmit = () => {
        onSubmit(text.value, url.value.startsWith('http') ? url.value : 'http://' + url.value);
    };

    const handleTextChange = (text: string): void => {
        setText({ value: text, error: VALIDATION.name(text) });
    };

    const handleChangeURL = (url: string) => {
        setUrl({ value: url, error: !URL_VALIDATION_REGEX.test(url) ? 'Länken är inte rätt format' : undefined });
    };

    return (
        <Modal title="Lägg till länk" className={styled.Modal}>
            <div className={styled.Content}>
                <Input value={text.value} onChange={handleTextChange} disabled={!!linkText && linkText.length > 0} label="Visningstext" error={text.error} autoFocus />

                <Input value={url.value} onChange={handleChangeURL} label="URL" error={url.error} />
            </div>
            <div className={styled.Buttons}>
                {linkUrl && linkUrl.length && (
                    <Button variant="Outline" onClick={onRemove} className={styled.RemoveButton}>
                        {t('buttonRemove')}
                    </Button>
                )}
                <Button variant="Outline" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Primary" onClick={handleSubmit} disabled={text.value.length < 1 || text.error !== undefined || url.value.length < 1 || url.error !== undefined}>
                    {t('buttonSave')}
                </Button>
            </div>
        </Modal>
    );
};

export default LinkModal;
