import { AxiosResponse } from 'axios';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import ContactMail from '../models/ContactMail';
import DynamicVideoContent from '../models/DynamicVideoContent';

const HelpService = () => {
    const axiosInstance = getAuthApi();

    function sendContactMail(contactMail: ContactMail): Promise<AxiosResponse> {
        return axiosInstance.post(`/help/contact`, contactMail).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function getDynamicHelpVideoContents(): Promise<DynamicVideoContent[]> {
        return axiosInstance
            .get<DynamicVideoContent[]>('/help/dynamicvideocontent')
            .then((response: AxiosResponse<DynamicVideoContent[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        sendContactMail,
        getDynamicHelpVideoContents,
    };
};

export default HelpService;
