import React, { FC } from 'react';
import styled from './ViewContract.module.css';
import useAuth from '../../../../hooks/useAuth';
import Contract from '../../../../components/Contract/Contract';
import CompanyDocuments from '../../../../components/CompanyDocuments/CompanyDocuments';

const ViewContract: FC = () => {
    const { company, isPlusCompany, isGroupCompany } = useAuth();

    if (isPlusCompany() || isGroupCompany()) {
        return (
            <div className={styled.ContractPage}>
                <div className={styled.CompanyDocuments}>
                    <CompanyDocuments companyId={company?.id} companyDocuments={company?.companyDocuments} showInfo />
                </div>
            </div>
        );
    } else {
        return (
            <div className={styled.ContractPage}>
                <div>
                    <Contract />
                </div>
            </div>
        );
    }
};

export default ViewContract;
